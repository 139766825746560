import React, { Suspense, useEffect, useState, useMemo } from 'react'

import { useHistory } from 'react-router-dom'
import { Card, CardHeader } from '@mui/material'
import { Button } from '@mui/material'
import CardLoader from './CardLoader'
import ViewForm from '../ViewForm'
import FormatDate from '../FormatDate'
import { useFormInstances } from '../../../hooks/forms'
import { StickyNote2Rounded } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import siteMap from '../../../siteMap'
import { Notice } from './AlertsCard'

const ActivityCard = ({ ...props }) => {
  const history = useHistory()
  const { data: formInstances, isLoading } = useFormInstances({
    property_id: props.property_id,
    form_state: 'CLOSED',
  })

  const forms = useMemo(() => {
    const todaysDate = new Date()
    const pastDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate() - 7)
    const recentFormInstances = formInstances.map(form => {
      const updated_at = new Date(form['updated_at'])
      if (pastDate <= updated_at && updated_at <= todaysDate) {
        return form
      }
    })
    return recentFormInstances.filter(form => form !== undefined && form !== null)
  }, [formInstances])

  // redirects to activity page and sets state to viewing Alerts
  const handleActivityButton = () => {
    history.push(
      siteMap.CompletedForms.getPath({
        propertyID: props.property_id,
        propertyForms: true,
        back: true,
      }),
    )
  }

  const showActivity = () => {
    if (forms.length === 0) {
      return (
        <Stack direction="row" spacing={1} p={1}>
          <StickyNote2Rounded />
          <Typography>There are no activities reported</Typography>
        </Stack>
      )
    } else {
      return forms.slice(0, 5).map((instance, index) => (
        <Notice key={index} icon={require('../../../images/report.png')}>
          <p className="notice__text">
            <span style={{ fontSize: 14, fontWeight: 500, color: 'rgb(99, 81, 87)' }}>
              {instance?.template?.template.type.title}
            </span>
          </p>
          <p className="notice__text">
            <a className="mitigationurl" onClick={() => ViewForm(instance, history, props.property_id, null)}>
              {instance?.title}
            </a>

            {' form was completed by ' + instance.assignee?.full_name ??
              'No Assignee' + ' at ' + FormatDate(instance.updated_at)}
          </p>
        </Notice>
      ))
    }
  }
  return (
    <>
      {forms === null ? (
        <Card style={{ overflow: 'hidden' }}>
          <CardHeader title="Activity" />
          <CardLoader />
        </Card>
      ) : (
        <Card>
          <CardHeader
            title="Activity"
            action={
              <Button size="small" variant="text" onClick={handleActivityButton}>
                View more
              </Button>
            }
          />
          <Stack>{showActivity()}</Stack>
        </Card>
      )}
    </>
  )
}

export default ActivityCard
