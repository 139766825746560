import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import c from './CheckBox.module.scss'
import cn from 'classnames'
import Icon from '../../images/icons/checkmark.svg'

/**
  `import CheckBox from 'balkerne-components/CheckBox'`
**/

const CheckBox = props => {
  const [checked, setChecked] = useState(props.value)

  useEffect(() => {
    if (props.disabled) {
      setChecked(false)
    }
  }, [props.disabled])

  const sizes = {
    normal: [c.normal],
    large: [c.large],
  }
  const containerClasses = cn([
    c.container,
    {
      [c.wide]: props.wide,
      [c.reverse]: props.reverse,
    },
  ])
  const checkboxClasses = cn([
    c.checkbox,
    sizes[props.size],
    {
      [c.checked]: checked,
      [c.disabled]: props.disabled,
    },
  ])
  const checkboxStyles = { backgroundColor: props.color }

  const handleClick = () => {
    const callback = props.onChange || props.onClick
    if (callback) {
      callback(!checked)
    }
    setChecked(!checked)
  }

  return (
    <div className={containerClasses}>
      <div
        className={checkboxClasses}
        style={checked ? checkboxStyles : undefined}
        onClick={() => (!props.disabled ? handleClick() : null)}>
        <img src={Icon} />
      </div>
      <span className={c.children}>{props.children}</span>
    </div>
  )
}

CheckBox.defaultProps = {
  value: false,
  wide: false,
  reverse: false,
  size: 'normal',
  color: '#283E59',
  disabled: false,
  onChange: checked => console.log('[CheckBox] Default callback, none provided. Value: ', checked),
}

CheckBox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  wide: PropTypes.bool,
  reverse: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'large']),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
}

export default CheckBox
