import Map, { Marker, Source, MapRef, Layer, Popup } from 'react-map-gl'
import ConfigProvider from 'balkerne-core/config'
import * as turf from '@turf/turf'
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
  Stack,
  Typography,
  Switch,
  Button,
  IconButton,
  useTheme,
  Icon,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useLocations } from '../hooks/locations'
import {
  HurricaneHistorical,
  HurricaneHistoricalTrack,
  HurricaneForecast,
  useHurricaneForecasts,
  useHurricaneTrack,
  useHurricanes,
} from '../hooks/analytics'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import StormIcon from '@mui/icons-material/Storm'
import CollapseListItem from '../components/CollapseListItem'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import mapboxgl from 'mapbox-gl'

type MenuComponentProps = {
  onClose: () => void
}

const ComponentHeader = (props: { title?: string; onClose: () => void }) => {
  return (
    <Stack gap={1} justifyContent="start" alignItems="center" sx={{ p: 1 }} direction="row">
      <IconButton onClick={props.onClose}>
        <ClearRoundedIcon />
      </IconButton>
      <Typography variant="h6">{props?.title ?? ''}</Typography>
    </Stack>
  )
}

const statusToColor = {
  'Deep Depression': '#02b96f', // Green
  'Cyclonic Storm': '#5cdd32', // Light Green
  'Severe Cyclonic Storm': '#f5d742', // Yellow
  'Very Severe Cyclonic Storm': '#f5a742', // Orange
  'Extremely Severe Cyclonic Storm': '#f57c42', // Dark Orange
}

type HurricaneComponentItemProps = {
  sustainedWind: number
  maxWindGust: number
  status: string
  date: string
  latitude: number
  longitude: number
  onClick: (timeStep: { status: string; latitude: number; longitude: number }) => void
}

const HurricaneComponentItem = (props: HurricaneComponentItemProps) => {
  const theme = useTheme()

  return (
    <Box
      p={1}
      onClick={props.onClick.bind(null, {
        status: props.status,
        latitude: props.latitude,
        longitude: props.longitude,
      })}
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.background.default,
          cursor: 'pointer',
        },
      }}>
      <Stack direction="column" alignItems="start" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <StormIcon sx={{ color: statusToColor[props.status] ?? 'grey' }} />
          <Typography variant="body1">{props.status}</Typography>
        </Stack>
        <Typography variant="subtitle2">
          {new Date(props.date).toLocaleString('en-UK', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            year: '2-digit',
            hour12: false,
          })}
        </Typography>
      </Stack>
      <Stack direction="row" gap={2} mt={1}>
        <Box>
          <Typography variant="caption">Sustained Wind</Typography>
          <Typography variant="body1">{props.sustainedWind} mph</Typography>
        </Box>
        <Box>
          <Typography variant="caption">Max Wind Gust</Typography>
          <Typography variant="body1">{props.maxWindGust} mph</Typography>
        </Box>
      </Stack>
    </Box>
  )
}

type ActiveStormComponentProps = {
  hurricane: HurricaneForecast | null
  map: MapRef | null
} & MenuComponentProps

const ActiveStormComponent = (props: ActiveStormComponentProps) => {
  const [popup, setPopup] = useState<mapboxgl.Popup | null>(null)
  const hurricane = props.hurricane
  if (hurricane === null) return null
  const forecasts = hurricane.forecasts.slice().reverse()
  const histories = hurricane.history.slice()

  /* Time step is a forecast or history */
  const handlePopupOpen = (timeStep: { status: string; latitude: number; longitude: number }) => {
    const map = props.map
    if (map === null) return
    if (popup !== null) popup.remove()
    map.flyTo({
      center: [timeStep.longitude, timeStep.latitude],
      zoom: 6,
    })
    const newPopup = new mapboxgl.Popup()
    newPopup.setHTML(`<span>${timeStep.status}</span>`)
    newPopup.setLngLat([timeStep.longitude, timeStep.latitude])
    newPopup.addTo(map.getMap())
    setPopup(newPopup)
  }
  return (
    <Box
      sx={{
        top: '0',
        right: '0',
        zIndex: 100,
        position: 'absolute',
        bgcolor: 'background.paper',
        width: '300px',
        maxWidth: '300px',
        height: '100%',
        overflow: 'auto',
      }}>
      <ComponentHeader onClose={props.onClose} title={hurricane.name} />
      {/* Forecast Track */}
      <Typography variant="body1" sx={{ p: 1 }}>
        Forecasts
      </Typography>
      {forecasts.map(forecast => (
        <HurricaneComponentItem
          key={forecast.date}
          onClick={handlePopupOpen}
          date={forecast.date}
          latitude={forecast.latitude}
          longitude={forecast.longitude}
          status={forecast.status}
          maxWindGust={forecast.max_gust_wind}
          sustainedWind={forecast.sustained_wind}
        />
      ))}
      <Divider />
      <Typography variant="body1" sx={{ p: 1 }}>
        History
      </Typography>
      {/* Historical Track */}
      {histories.map(history => (
        <HurricaneComponentItem
          key={history.date}
          onClick={handlePopupOpen}
          date={history.date}
          latitude={history.latitude}
          longitude={history.longitude}
          status={history.status}
          maxWindGust={history.max_gust_wind}
          sustainedWind={history.sustained_wind}
        />
      ))}
    </Box>
  )
}

type HistoricalStormComponentProps = {
  hurricane: { sid: string; name: string; year: number } | null
  map: MapRef | null
} & MenuComponentProps

const HistoricalStormComponent = (props: HistoricalStormComponentProps) => {
  const [popup, setPopup] = useState<mapboxgl.Popup | null>(null)
  const hurricane = props.hurricane
  const { data: track } = useHurricaneTrack(hurricane?.sid ?? null)
  if (hurricane === null) return <></>

  /* Time step is a forecast or history */
  const handlePopupOpen = (timeStep: { status: string; latitude: number; longitude: number }) => {
    const map = props.map
    if (map === null) return
    if (popup !== null) popup.remove()
    map.flyTo({
      center: [timeStep.longitude, timeStep.latitude],
      zoom: 6,
    })
    const newPopup = new mapboxgl.Popup()
    newPopup.setHTML(`<span>${timeStep.status}</span>`)
    newPopup.setLngLat([timeStep.longitude, timeStep.latitude])
    newPopup.addTo(map.getMap())
    setPopup(newPopup)
  }

  return (
    <Box
      sx={{
        top: '0',
        right: '0',
        zIndex: 100,
        position: 'absolute',
        bgcolor: 'background.paper',
        width: '300px',
        maxWidth: '300px',
        height: '100%',
        overflow: 'auto',
      }}>
      <ComponentHeader onClose={props.onClose} title={hurricane.name} />
      {/* Forecast Track */}
      <Typography variant="body1" sx={{ p: 1 }}>
        History
      </Typography>
      {track.map(forecast => (
        <HurricaneComponentItem
          key={forecast.date}
          onClick={handlePopupOpen}
          date={forecast.date}
          latitude={forecast.lat}
          longitude={forecast.lon}
          status={forecast.nature}
          maxWindGust={forecast.wind_speed ?? -1}
          sustainedWind={-1}
        />
      ))}
    </Box>
  )
}

export const PortfolioOperations = () => {
  const mapRef = useRef<MapRef | null>(null)
  const { data: locations } = useLocations()
  const { data: hurricaneForecasts } = useHurricaneForecasts()
  const { data: hurricaneHistories } = useHurricanes()
  const hurricaneHistoriesByYear = useMemo(() => {
    if (hurricaneHistories === undefined) return {}
    return hurricaneHistories.reduce((acc, hurricane) => {
      if (acc[hurricane.year] === undefined) acc[hurricane.year] = []
      acc[hurricane.year].push(hurricane)
      return acc
    }, {} as { [key: number]: any[] })
  }, [hurricaneHistories])

  const [selectedHurricaneHistory, setSelectedHurricaneHistory] = useState<HurricaneHistorical | null>(null)
  const [selectedHurricaneForecast, setSelectedHurricaneForecast] = useState<HurricaneForecast | null>(null)

  const [showActiveStorms, setShowActiveStorms] = useState<boolean>(true)
  const [showHistoricalStorms, setShowHistoricalStorms] = useState<boolean>(false)
  const [showWeatherTiles, setShowWeatherTiles] = useState<boolean>(false)

  const [year, setYear] = useState<number>(2023)
  const [weatherTileType, setWeatherTileType] = useState<string>('precipitationIntensity')

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '90vh',
      }}>
      <Map
        ref={mapRef}
        style={{ width: '100%', height: '100%', zIndex: 0 }}
        mapStyle={'mapbox://styles/mapbox/light-v11'}
        initialViewState={{
          zoom: 3,
          latitude: 39.7944839,
          longitude: -38.7418876,
        }}
        mapboxAccessToken={ConfigProvider.mapboxPublicKey}>
        {/* 🔨🐒 Temporarily hardcoded API key for demo.
                  Move to backend and proxy through API afterwards.
        */}
        {showWeatherTiles && (
          <>
            {weatherTileType === 'precipitationIntensity' && (
              <Source
                id="precipitationIntensity"
                type="raster"
                tiles={[
                  `https://api.tomorrow.io/v4/map/tile/{z}/{x}/{y}/precipitationIntensity/now.png?apikey=okxkhE8dMto5dfVbTDIZqr1Vjs6KE21X`,
                ]}>
                <Layer type="raster" />
              </Source>
            )}
            {weatherTileType === 'windSpeed' && (
              <Source
                id="windSpeed"
                type="raster"
                tiles={[
                  `https://api.tomorrow.io/v4/map/tile/{z}/{x}/{y}/windSpeed/now.png?apikey=okxkhE8dMto5dfVbTDIZqr1Vjs6KE21X`,
                ]}>
                <Layer type="raster" />
              </Source>
            )}
          </>
        )}
        {showHistoricalStorms && (
          <HurricaneHistoricalTrackLayer map={mapRef?.current} hurricaneId={selectedHurricaneHistory?.sid ?? null} />
        )}
        {showActiveStorms && <HurricaneForecastLayer map={mapRef?.current} />}

        {locations.map(location => (
          <Marker
            key={location.id}
            style={{ cursor: 'pointer' }}
            longitude={location.coordinates.longitude}
            latitude={location.coordinates.latitude}
            anchor="bottom">
            <Box>
              <HomeRoundedIcon sx={{ width: '12px', height: '12px', backgroundColor: 'white', borderRadius: '100%' }} />
            </Box>
          </Marker>
        ))}
      </Map>

      {/* Active Storm Page */}
      <ActiveStormComponent
        map={mapRef?.current}
        hurricane={selectedHurricaneForecast}
        onClose={() => setSelectedHurricaneForecast(null)}
      />

      {/* Historical Storm Page */}
      <HistoricalStormComponent
        map={mapRef?.current}
        hurricane={selectedHurricaneHistory}
        onClose={() => setSelectedHurricaneHistory(null)}
      />

      {/* Drawer Menu */}
      <Box
        sx={{
          top: '0',
          right: '0',
          zIndex: 10,
          position: 'absolute',
          bgcolor: 'background.paper',
          width: '300px',
          maxWidth: '300px',
          height: '100%',
          overflow: 'auto',
        }}>
        {/* Menu Items */}
        <List>
          {/* Active Storms */}
          <CollapseListItem
            dense
            render={() => (
              <>
                <ListItemText>Active Storms ({hurricaneForecasts?.length ?? 0})</ListItemText>
                <Switch
                  checked={showActiveStorms}
                  onClick={e => {
                    e.stopPropagation()
                    setShowActiveStorms(!showActiveStorms)
                  }}
                  size="small"
                />
              </>
            )}>
            {hurricaneForecasts.map(hurricane => (
              <ListItemButton key={hurricane.name} onClick={() => setSelectedHurricaneForecast(hurricane)}>
                <ListItemIcon>
                  <StormIcon />
                </ListItemIcon>
                <ListItemText>{hurricane.name}</ListItemText>
              </ListItemButton>
            ))}
          </CollapseListItem>
          <Divider />

          {/* Historical Storms */}
          <CollapseListItem
            dense
            render={() => (
              <>
                <ListItemText>Historical Storms</ListItemText>
                <Switch
                  checked={showHistoricalStorms}
                  onClick={e => {
                    e.stopPropagation()
                    setShowHistoricalStorms(!showHistoricalStorms)
                  }}
                  size="small"
                />
              </>
            )}>
            <Box
              sx={{
                maxHeight: '400px',
                overflow: 'auto',
              }}>
              {/* Controls */}
              <Box
                sx={{
                  p: 1,
                }}>
                <FormControl>
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    label="Year"
                    value={year}
                    size="small"
                    onChange={(e: any) => setYear(e.target.value)}>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2020}>2020</MenuItem>
                    <MenuItem value={2019}>2019</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* Historical Hurricane List */}
              <List dense>
                {hurricaneHistoriesByYear?.[year]?.map(hurricane => (
                  <ListItemButton key={hurricane.sid} onClick={() => setSelectedHurricaneHistory(hurricane)}>
                    <ListItemIcon>
                      <StormIcon />
                    </ListItemIcon>
                    <ListItemText>{hurricane.name}</ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </CollapseListItem>
          <Divider />

          {/* Weather */}
          <CollapseListItem
            dense
            render={() => (
              <>
                <ListItemText>Weather</ListItemText>
                <Switch
                  checked={showWeatherTiles}
                  onClick={e => {
                    e.stopPropagation()
                    setShowWeatherTiles(!showWeatherTiles)
                  }}
                  size="small"
                />
              </>
            )}>
            <Box px={2}>
              <FormControl>
                <RadioGroup value={weatherTileType} onChange={(e: any) => setWeatherTileType(e.target.value)}>
                  <FormControlLabel
                    value="precipitationIntensity"
                    control={<Radio size="small" />}
                    label="Precipitation Intensity"
                  />
                  <FormControlLabel value="windSpeed" control={<Radio size="small" />} label="Wind Speed" />
                </RadioGroup>
              </FormControl>
            </Box>
          </CollapseListItem>
          <Divider />
        </List>
      </Box>
    </Box>
  )
}

export default PortfolioOperations

type HurricaneForecastLayerProps = {
  map: MapRef | null
}

function HurricaneForecastLayer({ map }: HurricaneForecastLayerProps) {
  const { data: hurricaneForecasts } = useHurricaneForecasts()
  const color = '#E8CF90'
  const currentLocationColor = '#FCC538'
  const lineWidth = 2
  const hurricanes = useMemo<any>(() => {
    const hurricanes: any[] = []
    for (const hurricane of hurricaneForecasts) {
      const track: { lat: number; lon: number; radius: number }[] = hurricane.forecasts.map((x, i) => ({
        lat: x.latitude,
        lon: x.longitude,
        radius: 15 + 10 * (i + 1),
      }))

      let historyLine: turf.helpers.Feature | null = null
      if (hurricane.history.length > 0) {
        track.unshift({ lat: hurricane.history[0].latitude, lon: hurricane.history[0].longitude, radius: 2 })
        const currentDot = hurricane.history?.[0]
        track.unshift({ lat: currentDot.latitude, lon: currentDot.longitude, radius: 2 })
        historyLine = turf.lineString(hurricane.history.map(f => [f.longitude, f.latitude]))
      }

      let forecastLine: turf.helpers.Feature | null = null
      if (hurricane.forecasts.length > 0) {
        forecastLine = turf.lineString(hurricane.forecasts.map(f => [f.longitude, f.latitude]))
      }

      const currentLocation = hurricane.history?.[0] || hurricane.forecasts?.[0] || null
      const polygon = trackToPolygon(track)
      hurricanes.push({ ...hurricane, polygon, forecastLine, historyLine, currentLocation })
    }
    return hurricanes
  }, [hurricaneForecasts])

  return (
    <>
      {hurricanes?.map(hurricane => (
        <Fragment key={hurricane.name}>
          {/* Forecast Dots */}
          {hurricane.forecasts.map((forecast, index) => (
            <Marker key={index} latitude={forecast.latitude} longitude={forecast.longitude}>
              <StormIcon sx={{ color: color }} />
            </Marker>
          ))}

          {/* Current Dot */}
          {hurricane.currentLocation && (
            <Marker latitude={hurricane.currentLocation.latitude} longitude={hurricane.currentLocation.longitude}>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: '35px',
                  height: '35px',
                  backgroundColor: currentLocationColor,
                  borderRadius: '100%',
                  opacity: 0.75,
                }}>
                <StormIcon />
              </Stack>
            </Marker>
          )}

          {/* Forecast Area Polygon */}
          <Source id={`hurricane-forecast-polygon-${hurricane.name}`} type="geojson" data={hurricane.polygon}>
            <Layer
              id={`polygon-forecast-polygon-layer-${hurricane.name}`}
              source={`hurricane-forecast-polygon-${hurricane.name}`}
              type="fill"
              paint={{
                'fill-color': color,
                'fill-opacity': 0.2,
                'fill-outline-color': color,
              }}
            />
            <Layer
              id={`polygon-forecast-outline-layer-${hurricane.name}`}
              source={`hurricane-forecast-polygon-${hurricane.name}`}
              type="line"
              paint={{
                'line-color': color,
                'line-width': lineWidth,
              }}
            />
          </Source>

          {/* History Line */}
          <Source id={`hurricane-history-line-${hurricane.name}`} type="geojson" data={hurricane.historyLine}>
            <Layer
              id={`polygon-history-line-layer-${hurricane.name}`}
              source={`hurricane-history-line-${hurricane.name}`}
              type="line"
              paint={{
                'line-color': color,
                'line-width': lineWidth,
              }}
            />
          </Source>
        </Fragment>
      ))}
    </>
  )
}

type HurricaneHistoricalTrackLayerProps = {
  hurricaneId: string | null
  map: MapRef | null
}

function HurricaneHistoricalTrackLayer({ hurricaneId, map }: HurricaneHistoricalTrackLayerProps) {
  const { data: track } = useHurricaneTrack(hurricaneId)
  const [selectedDate, setSelectedDate] = useState<string | null>(null)

  const trackByDate = useMemo(() => {
    if (track == null) {
      return null
    }
    const byDate: Record<string, HurricaneHistoricalTrack> = {}
    track.forEach(t => (byDate[t.date] = t))
    return byDate
  }, [track])

  const selectedTrack = useMemo(() => {
    if (trackByDate == null || selectedDate == null) {
      return null
    }
    return trackByDate[selectedDate] ?? null
  }, [trackByDate, selectedDate])

  useEffect(() => {
    if (map !== null && track?.length > 0) {
      map.flyTo({
        center: [track[0].lon, track[0].lat],
        zoom: 5,
        speed: 1,
      })
    }
  }, [map, track])

  const line = useMemo(
    () => (Array.isArray(track) && track.length > 1 ? turf.lineString(track.map(t => [t.lon, t.lat])) : null),
    [track],
  )
  const polygon = useMemo(
    () =>
      Array.isArray(track) && track.length > 1
        ? trackToPolygon(track.map(x => ({ lat: x.lat, lon: x.lon, radius: x.roci })))
        : null,
    [track],
  )

  if (polygon == null || line == null) {
    return <></>
  }

  const color = '#E8CF90'
  const currentLocationColor = '#FCC538'
  const lineWidth = 2

  return (
    <>
      {/* {track.map(track => (
        <Marker
          key={track.date}
          style={{ cursor: 'pointer' }}
          longitude={track.lon}
          latitude={track.lat}
          anchor="bottom">
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: 'black',
              borderRadius: '100%',
              opacity: 0.5,
            }}
            onClick={e => {
              e.stopPropagation()
              setSelectedDate(track.date)
            }}>
            <StormIcon sx={{ width: '14px', height: '14px', color: 'white', borderRadius: '100%' }} />
          </Stack>
        </Marker>
      ))} */}

      <Source id="hurricane-track" type="geojson" data={line}>
        <Layer
          id="line-layer"
          source="hurricane-track"
          type="line"
          paint={{
            'line-color': color,
            'line-width': lineWidth,
          }}
        />
      </Source>

      <Source id="hurricane-polygon" type="geojson" data={polygon}>
        <Layer
          id="polygon-layer"
          source="hurricane-polygon"
          type="fill"
          paint={{
            'fill-color': color,
            'fill-opacity': 0.25,
          }}
        />
        <Layer
          id="polygon-outline-layer"
          source="hurricane-polygon"
          type="line"
          paint={{
            'line-color': color,
            'line-width': lineWidth,
          }}
        />
      </Source>
    </>
  )
}

function toPoint(trackPoint: { lat: number; lon: number }) {
  return trackPoint ? turf.point([trackPoint.lon, trackPoint.lat]) : null
}

/*
 * This function takes a list of track points and returns a polygon that represents the area
 * of the track with its radius. The polygon is created by creating a circle around each point and then
 * connecting the circles with lines.
 *
 * @param dots - list of track points
 * @returns a polygon that represents the area of the hurricane
 */
function trackToPolygon(dots: { lat: number; lon: number; radius: number | null }[]): any {
  const DEFAULT_WIDTH = 23
  const ring: turf.Feature<turf.Point, turf.Properties>[] = []
  const trackbackRing: turf.Feature<turf.Point, turf.Properties>[] = []
  for (let i = 0; i < dots.length; i++) {
    if (dots[i].radius == -1) {
      dots[i].radius = null
    }

    const trackPoint = dots[i]
    const point = toPoint(dots[i])!
    const pointBefore = toPoint(dots?.[i - 1])
    const pointAfter = toPoint(dots?.[i + 1])

    // Middle points
    if (pointBefore != null && pointAfter != null) {
      const bearingBefore = turf.bearing(pointBefore.geometry.coordinates, point.geometry.coordinates)
      const bearingAfter = turf.bearing(point.geometry.coordinates, pointAfter.geometry.coordinates)
      const bearing = (bearingBefore + bearingAfter) / 2
      trackbackRing.push(
        turf.destination(point, trackPoint.radius ?? DEFAULT_WIDTH, (bearing + 90) % 360, { units: 'nauticalmiles' }),
      )
      ring.push(
        turf.destination(point, trackPoint.radius ?? DEFAULT_WIDTH, (bearing - 90) % 360, { units: 'nauticalmiles' }),
      )
    }
    // End point
    else if (pointAfter != null) {
      const bearing = turf.bearing(point.geometry.coordinates, pointAfter.geometry.coordinates)
      const bearingStart = (bearing + 90) % 360
      const bearingEnd = (bearingStart + 180) % 360
      const sector = turf.sector(point, trackPoint.radius ?? DEFAULT_WIDTH, bearingStart, bearingEnd + 1, {
        units: 'nauticalmiles',
        steps: 30,
      })
      sector.geometry.coordinates[0].slice(1, sector.geometry.coordinates[0].length - 1).forEach(coordinate => {
        ring.push(turf.point(coordinate))
      })
    }
    // Start point
    else if (pointBefore != null) {
      const bearing = turf.bearing(point.geometry.coordinates, pointBefore.geometry.coordinates)
      const bearingStart = (bearing + 90) % 360
      const bearingEnd = (bearingStart + 180) % 360
      const radius = trackPoint.radius ?? DEFAULT_WIDTH
      const sector = turf.sector(point, radius, bearingStart, bearingEnd + 1, {
        units: 'nauticalmiles',
        steps: 30,
      })
      sector.geometry.coordinates[0].slice(1, sector.geometry.coordinates[0].length - 1).forEach(coordinate => {
        ring.push(turf.point(coordinate))
      })
    }
  }
  trackbackRing.reverse()

  /* Track is a closed ring which we assembled above. We need to add the first point to the end of the ring
  so that we can create a polygon from it. The polygon sometimes results with rough edges. 
  
  To fix this, we will additionally create and union with:
  - A line which is buffered with default width, resulting in a polygon
  - Collection of circles with the radii of the track points. 
  The resulting union has smoothed out the edges of initial polygon.
   */
  const newTrack = [...ring, ...trackbackRing, ring[0]]
  const line = turf.lineString(newTrack.map(r => r.geometry.coordinates))
  const polygon = turf.buffer(turf.lineStringToPolygon(line), 0)

  // Create a polygon from a line buffered with default width
  const trackLine = turf.buffer(turf.lineString(dots.map(t => [t.lon, t.lat])), DEFAULT_WIDTH, {
    units: 'nauticalmiles',
  })

  // Create a collection of circles with the radii of the track points
  const circlePolygons = dots.map(t =>
    turf.buffer(turf.point([t.lon, t.lat]), t.radius ?? DEFAULT_WIDTH, { units: 'nauticalmiles', steps: 16 }),
  )
  const circleMultiPolygon = turf.multiPolygon([...circlePolygons.map(c => c.geometry.coordinates)])

  const union = turf.union(turf.union(trackLine, polygon)!, circleMultiPolygon)
  return union
}
