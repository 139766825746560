import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Page from '../components/Page'
import { Button } from '@mui/material'
import siteMap from '../siteMap'
import NewTemplateManager from '../components/templateManager/NewTemplateManager'

const TemplateManager = () => {
  const history = useHistory()

  const handleNewTemplate = () => {
    history.push(siteMap.CreateTemplate.path)
  }

  return (
    <Page
      title="Form Templates"
      size="lg"
      back
      PageActions={() => (
        <Button variant="contained" onClick={handleNewTemplate}>
          New Template
        </Button>
      )}>
      <NewTemplateManager />
    </Page>
  )
}

export default TemplateManager
