import React from 'react'
import './Displaycrimerisk.css'
import { Riskrating } from '../maps/riskrating'
import Card from 'react-bootstrap/Card'
import { LineChartWidget } from '../widgets/LineChartWidget'
import { Box } from '@mui/material'
import FormatDate from 'balkerne-components/FormatDate'

/**
 * this function takes a value and return a string => a color in the HEX format.
 * It returns black as default.
 * @param {Number} value
 * @returns {String}
 */
export const fromValueToColor = value => {
  let color = '#000000'
  if (value > 0 && value <= 25) {
    color = '#378805'
  } else if (value > 25 && value <= 50) {
    color = '#ffe800'
  } else if (value > 50 && value <= 75) {
    color = '#ffa701'
  } else {
    color = '#dc1c33'
  }
  return color
}

type DisplaycrimeriskProps = {
  riskrating?: number
  riskratingdescription?: string
  riskratingdate?: string
  crime_data_prop: any
  crimeStatisticsDataProp: any
}
type DisplaycrimeriskState = {
  risk_rating?: number
  selected_crime: string
  chart: {
    isShowingChart: boolean
    value: number
    title: string
    data: any[]
  }
}

export class Displaycrimerisk extends React.Component<DisplaycrimeriskProps, DisplaycrimeriskState> {
  constructor(props) {
    super(props)
    this.state = {
      risk_rating: this.props.riskrating,
      selected_crime: 'None selected',
      chart: {
        isShowingChart: false,
        value: 0, // change the name of this variable,
        title: '',
        data: [],
      },
    }
  }

  componentDidMount() {
    this.setCurrentCrimeStatistics('Antisocial behaviour')
  }

  Riskchartscore() {
    switch (this.state.risk_rating) {
      case 1:
        return 1

      case 2:
        return 2

      case 3:
        return 3

      case 4:
        return 6

      default:
        return 0
    }
  }

  setCurrentCrimeStatistics(crime_type) {
    let value = this.state.chart.value //
    let chartData = this.state.chart.data ?? []
    switch (crime_type) {
      case 'Antisocial behaviour':
        value = this.props.crime_data_prop.anti_social_behaviour
        chartData = this.props.crimeStatisticsDataProp?.anti_social_behaviour ?? []
        break

      case 'Burglary':
        value = this.props.crime_data_prop.burglary
        chartData = this.props.crimeStatisticsDataProp?.burglary ?? []
        break

      case 'Criminal Damage and Arson':
        value = this.props.crime_data_prop.criminal_damage_and_arson
        chartData = this.props.crimeStatisticsDataProp?.criminal_damage_and_arson ?? []
        break

      case 'Possession of Weapons':
        value = this.props.crime_data_prop.weapons_possesion
        chartData = this.props.crimeStatisticsDataProp?.weapons_possesion ?? []
        break

      case 'Robbery':
        value = this.props.crime_data_prop.robbery
        chartData = this.props.crimeStatisticsDataProp?.robbery ?? []
        break

      case 'Shoplifting':
        value = this.props.crime_data_prop.shoplifting
        chartData = this.props.crimeStatisticsDataProp?.shoplifting ?? []
        break
      case 'Vehicle_Crime':
        value = this.props.crime_data_prop.vehicle
        chartData = this.props.crimeStatisticsDataProp?.vehicle ?? [] ?? []
        break

      case 'Theft from the Person':
        value = this.props.crime_data_prop.theft_from_person
        chartData = this.props.crimeStatisticsDataProp?.theft_from_person ?? []
        break
    }

    this.setState({ selected_crime: crime_type }) //
    this.setState({ chart: { isShowingChart: true, value, title: crime_type, data: chartData } })
  }

  render() {
    return (
      <>
        <Card className="Crimeriskhighlightsnew">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h6>Crime Statistics | Last Updated: {FormatDate(this.props.crime_data_prop.date)}</h6>
          </Card.Header>
          <Card.Body>
            <div className="crimeoverview">
              <p>
                The data below shows crime rates in your area. 0 equals lowest and 100 equals highest ranked crime rate.
              </p>
            </div>
            <div className="Crimeriskcontent">
              <div className="crimstats">
                <Box
                  id="stat_crime"
                  onClick={() => this.setCurrentCrimeStatistics('Antisocial behaviour')}
                  sx={{
                    backgroundColor:
                      this.state.chart.isShowingChart && this.state.selected_crime === 'Antisocial behaviour'
                        ? 'background.default'
                        : 'background.paper',
                  }}>
                  <Riskrating
                    title={'Antisocial behaviour'}
                    value={this.props.crime_data_prop.anti_social_behaviour || 0}
                    rating_text={Math.round(this.props.crime_data_prop.anti_social_behaviour) || 0}
                  />
                </Box>
                <Box
                  id="stat_crime"
                  onClick={() => this.setCurrentCrimeStatistics('Burglary')}
                  sx={{
                    backgroundColor:
                      this.state.chart.isShowingChart && this.state.selected_crime === 'Burglary'
                        ? 'background.default'
                        : 'background.paper',
                  }}>
                  <Riskrating
                    title={'Burglary'}
                    value={this.props.crime_data_prop.burglary || 0}
                    rating_text={Math.round(this.props.crime_data_prop.burglary) || 0}
                  />
                </Box>

                <Box
                  id="stat_crime"
                  onClick={() => this.setCurrentCrimeStatistics('Criminal Damage and Arson')}
                  sx={{
                    backgroundColor:
                      this.state.chart.isShowingChart && this.state.selected_crime === 'Criminal Damage and Arson'
                        ? 'background.default'
                        : 'background.paper',
                  }}>
                  <Riskrating
                    title={'Criminal Damage and Arson'}
                    value={this.props.crime_data_prop.criminal_damage_and_arson || 0}
                    rating_text={Math.round(this.props.crime_data_prop.criminal_damage_and_arson) || 0}
                  />
                </Box>
                <Box
                  id="stat_crime"
                  onClick={() => this.setCurrentCrimeStatistics('Possession of Weapons')}
                  sx={{
                    backgroundColor:
                      this.state.chart.isShowingChart && this.state.selected_crime === 'Possession of Weapons'
                        ? 'background.default'
                        : 'background.paper',
                  }}>
                  <Riskrating
                    title={'Possession of Weapons'}
                    value={this.props.crime_data_prop.weapons_possesion || 0}
                    rating_text={Math.round(this.props.crime_data_prop.weapons_possesion) || 0}
                  />
                </Box>
                <Box
                  id="stat_crime"
                  onClick={() => this.setCurrentCrimeStatistics('Robbery')}
                  sx={{
                    backgroundColor:
                      this.state.chart.isShowingChart && this.state.selected_crime === 'Robbery'
                        ? 'background.default'
                        : 'background.paper',
                  }}>
                  <Riskrating
                    title={'Robbery'}
                    value={this.props.crime_data_prop.robbery || 0}
                    rating_text={Math.round(this.props.crime_data_prop.robbery) || 0}
                  />
                </Box>
                <Box
                  id="stat_crime"
                  onClick={() => this.setCurrentCrimeStatistics('Shoplifting')}
                  sx={{
                    backgroundColor:
                      this.state.chart.isShowingChart && this.state.selected_crime === 'Shoplifting'
                        ? 'background.default'
                        : 'background.paper',
                  }}>
                  <Riskrating
                    title={'Shoplifting'}
                    value={this.props.crime_data_prop.shoplifting || 0}
                    rating_text={Math.round(this.props.crime_data_prop.shoplifting) || 0}
                  />
                </Box>
                <Box
                  id="stat_crime"
                  onClick={() => this.setCurrentCrimeStatistics('Theft from the Person')}
                  sx={{
                    backgroundColor:
                      this.state.chart.isShowingChart && this.state.selected_crime === 'Theft from the Person'
                        ? 'background.default'
                        : 'background.paper',
                  }}>
                  <Riskrating
                    title={'Theft from the Person'}
                    value={this.props.crime_data_prop.theft_from_person || 0}
                    rating_text={Math.round(this.props.crime_data_prop.theft_from_person) || 0}
                  />
                </Box>
                <Box
                  id="stat_crime"
                  onClick={() => this.setCurrentCrimeStatistics('Vehicle_Crime')}
                  sx={{
                    backgroundColor:
                      this.state.chart.isShowingChart && this.state.selected_crime === 'Vehicle_Crime'
                        ? 'background.default'
                        : 'background.paper',
                  }}>
                  <Riskrating
                    title={'Vehicle Crime'}
                    value={this.props.crime_data_prop.vehicle || 0}
                    rating_text={Math.round(this.props.crime_data_prop.vehicle) || 0}
                  />
                </Box>
              </div>
              {this.state.chart.isShowingChart ? (
                <LineChartWidget
                  data={this.state.chart.data}
                  value={this.state.chart.value || 0}
                  title={this.state.chart.title}
                />
              ) : (
                <div></div>
              )}
            </div>
          </Card.Body>
        </Card>
      </>
    )
  }
}
export default Displaycrimerisk
