import { ReactComponent as RainIcon } from '../images/alert_icons/rain.svg'
import { ReactComponent as HeatIcon } from '../images/alert_icons/heat.svg'
import { ReactComponent as FloodIcon } from '../images/alert_icons/flood.svg'
import { ReactComponent as SnowIcon } from '../images/alert_icons/snow.svg'
import { ReactComponent as ColdIcon } from '../images/alert_icons/cold.svg'
import { ReactComponent as RiverGaugeIcon } from '../images/alert_icons/river_gauge.svg'
import { ReactComponent as WindSpeedIcon } from '../images/alert_icons/wind_speed.svg'

const icons = {
  rain_forecast_long_range: RainIcon,
  rain_forecast_6h: RainIcon,
  rain_forecast_12h: RainIcon,
  rain_forecast_24h: RainIcon,
  rain_forecast_1h_near: RainIcon,
  rain_forecast_6h_near: RainIcon,
  rain_forecast_12h_near: RainIcon,
  rain_forecast_24h_near: RainIcon,
  rain: RainIcon,
  heat: HeatIcon,
  flood: FloodIcon,
  snow: SnowIcon,
  snow_6h: SnowIcon,
  snow_12h: SnowIcon,
  snow_24h: SnowIcon,
  cold: ColdIcon,
  river_gauge: RiverGaugeIcon,
  wind: WindSpeedIcon,
  wind_gust: WindSpeedIcon,
}

const AlertTypeIcon = ({ type, ...props }) => {
  const Icon = icons[type]
  if (!Icon) {
    console.error(`No icon found for type ${type}`)
    return <></>
  }
  return <Icon {...props} />
}

export default AlertTypeIcon
