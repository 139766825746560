import { useQueryClient, useMutation, useQuery } from 'react-query'
import api from 'balkerne-core/api'
import { useEffect, useMemo, useState } from 'react'
import { AreaMetricQuery } from '../types/areas'

export const useConfigurationSubscriptions = (configurationId: number) => {
  const path = `/alerts/configurations/${configurationId}/subscriptions`
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery(path, fetch, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: configurationId !== undefined && configurationId !== null,
  })
  return { ...rest, data: data ?? [] }
}

export const useAlertingSubscriptions = () => {
  const path = '/alerts/subscriptions'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery(path, fetch, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
  return { ...rest, data: data ?? [] }
}

export const useConfigurations = (args: any = {}) => {
  const path = '/alerts/configurations'
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const data = useQuery([path, { ...args }], fetch, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
  return data
}

export const useEnableAlertConfiguration = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (configurationId: number) => {
      const path = `/alerts/configurations/${configurationId}/enable`
      return await api.post(path)
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries(['/alerts/configurations'])
      },
      onError: error => {
        console.error('error', error)
      },
    },
  )
}

export const useDisableAlertConfiguration = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (configurationId: number) => {
      const path = `/alerts/configurations/${configurationId}/disable`
      return await api.post(path)
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries(['/alerts/configurations'])
      },
      onError: error => {
        console.error('error', error)
      },
    },
  )
}

export const useAlertAreas = (params: any, config: any = {}) => {
  const path = `/alerts/areas`
  const fetch = async () => await api.get(path, { params: { ...params } }).then(res => res.data)
  const queryKey = [path, { ...params }]
  const { data, ...rest } = useQuery(queryKey, fetch, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...config,
  })
  return { ...rest, data: data ?? [], path, queryKey }
}

export const useAreaLatestMetric = (areaMetrics: AreaMetricQuery[], config: any = {}) => {
  const path = `/alerts/areas/latest_metric`
  const fetch = async () => await api.post(path, areaMetrics).then(res => res.data)
  const queryKey = [path, [...areaMetrics]]
  const { data, ...rest } = useQuery(queryKey, fetch, {
    keepPreviousData: true,
    ...config,
  })
  return { ...rest, data: data ?? null }
}

export const useAlertAreasWithLatestMetrics = (params: any, alertType: any) => {
  // fetch alert areas only when alert type is selected, and cache results for 5 minutes
  const { data: areas, ...areasQuery } = useAlertAreas(params, {
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 5,
    enabled: alertType !== undefined,
  })

  // generate areaMetricIds from fetched areas and selected alert_type
  const areaMetricIds = useMemo(() => {
    if (!areas || !alertType) return []
    return areas.map((area: any) => ({
      ref: area.ref.toUpperCase(),
      type: area.type.name.toUpperCase(),
      subtype: area.subtype.name.toUpperCase(),
      alert_type: alertType.name.toUpperCase(),
    }))
  }, [areas, alertType])

  // fetch latest metrics for areaMetricIds, and cache results for 5 minutes
  const { data: latestMetrics, ...latestMetricsQuery } = useAreaLatestMetric(areaMetricIds, {
    enabled: areaMetricIds.length > 0,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 5,
  })
  // combine areas and their respective latest metric if any
  const result = useMemo(() => {
    if (!areas || !latestMetrics) return []
    const metricsMap = Object.fromEntries(latestMetrics.map((metric: any) => [metric.area_ref, metric]))

    const areasWithMetrics = areas.map((area: any) => {
      const metric = metricsMap[area.ref]
      return {
        ...area,
        metric: metric ?? null,
      }
    })

    return areasWithMetrics
  }, [areas, latestMetrics])

  return {
    data: result,
    isFetching: areasQuery.isFetching || latestMetricsQuery.isFetching,
    isLoading: areasQuery.isLoading || latestMetricsQuery.isLoading,
    isError: areasQuery.isError || latestMetricsQuery.isError,
    error: areasQuery.error || latestMetricsQuery.error,
  }
}

export const useAlertArea = (id: any, args: any = {}) => {
  const path = `/alerts/areas/${id}`
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  return useQuery([path, { ...args }], fetch, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: id !== undefined && id !== null,
  })
}

export const useAlertAreaTypes = (args: any = {}) => {
  const path = '/alerts/areas/types'
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const data = useQuery([path, { ...args }], fetch, { refetchOnWindowFocus: false })
  return data
}

export const usePortfolioAlerts = (args: any = {}) => {
  const path = '/alerts'
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })
  return { ...rest, data: data ?? [] }
}

export const useLocationAlerts = (locationId: number, args: any = {}) => {
  const path = `/alerts?property_id=${locationId}`
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch)
  return { ...rest, data: data ?? [] }
}

export const useAlertTypes = () => {
  const path = '/alerts/types'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch, { refetchOnWindowFocus: false })
  return { ...rest, data: data ?? [] }
}

export const useNotificationMutes = () => {
  const path = '/alerts/mutes'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch, { refetchOnWindowFocus: false })
  return { ...rest, data: data ?? {}, path }
}

export const useEventAlerts = (eventId: any) => {
  const path = `/alerts/events/${eventId}`
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch, { enabled: eventId !== undefined })
  return { ...rest, data: data ?? [] }
}
