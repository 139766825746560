import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
  Card,
  useTheme,
  capitalize,
  Slider,
  InputLabel,
  FormControl,
  Grid,
  Chip,
  Divider,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useClimateAnalyticsWeather,
  useClimateAnalyticsWeatherBreakdown,
  usePropertyClimateAnalyticsExtremeWind,
  usePropertyClimateAnalyticsHeatStress,
  usePropertyClimateAnalyticsFireHazard,
  useClimatePropertyData,
} from '../../../hooks/analytics'
import ConfigProvider from 'balkerne-core/config'
import { Line, Chart } from 'react-chartjs-2'
import { useParams } from 'react-router-dom'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
} from 'chart.js'
import { DataGrid } from '@mui/x-data-grid'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { Layer, Map, MapRef, Source } from 'react-map-gl'
import { FeatureCollection, Point } from '@turf/turf'
import { HazardProps } from '../types'
import { CardTitle } from '../common/Card'
import LoadingIcon from '../../LoadingIcon'
import { useLocations } from '../../../hooks/locations'
import mapboxgl from 'mapbox-gl'
import { GradientLegend } from '../common/Legend'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
)

const PropertyExtremeWind: React.FC<HazardProps> = ({ selectedScenario, setScenarios }) => {
  const { id: propertyId } = useParams()
  const { data } = useClimatePropertyData(propertyId, 'fire')

  const availableScenarios = useMemo(() => {
    return Object.keys(data?.indicators ?? [])
  }, [data])

  // Set hazard's scenarios
  useEffect(() => {
    setScenarios(availableScenarios)
    return () => {
      setScenarios([])
    }
  }, [availableScenarios])

  const scenarioData = data?.indicators?.[selectedScenario]?.['fwi'].data ?? null

  if (!data || !scenarioData) {
    return <Typography>No data available for this scenario</Typography>
  }

  const currentYear = new Date().getFullYear()
  const midTermYear = 2050
  const longTermYear = 2090
  const currentYearIndex = scenarioData.years.indexOf(currentYear)
  const midTermIndex = scenarioData.years.indexOf(midTermYear)
  const longTermIndex = scenarioData.years.indexOf(longTermYear)

  const highRiskCurrent = scenarioData.fwi_nods_gt_30.percentiles['50th'][currentYearIndex]
  const highRiskMidTerm = scenarioData.fwi_nods_gt_30.percentiles['50th'][midTermIndex]
  const highRiskLongTerm = scenarioData.fwi_nods_gt_30.percentiles['50th'][longTermIndex]

  const highRiskMidTermPct = (highRiskMidTerm / highRiskCurrent - 1) * 100 || 0
  const highRiskLongTermPct = (highRiskLongTerm / highRiskCurrent - 1) * 100 || 0

  return (
    <Stack direction="column" spacing={5}>
      {/* Overview */}
      <Stack direction="column">
        <Typography variant="overline">
          <strong>Overview</strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InfoCard
              lstring={`Mid-Term`}
              rstring={`${highRiskMidTerm.toFixed(0)} days (${highRiskMidTermPct.toFixed(0)}%)`}
              description={`${highRiskMidTerm - highRiskCurrent} days (${highRiskMidTermPct.toFixed(
                0,
              )}%) difference in high-risk days compared to baseline of ${highRiskCurrent.toFixed(
                0,
              )} days, for the year ${midTermYear}.`}
            />
          </Grid>
          <Grid item xs={6}>
            <InfoCard
              lstring={`Long-Term`}
              rstring={`${highRiskLongTerm.toFixed(0)} days (${highRiskLongTermPct.toFixed(0)}%)`}
              description={`${highRiskLongTerm - highRiskCurrent} days (${highRiskLongTermPct.toFixed(
                0,
              )}%) difference in high-risk days compared to baseline of ${highRiskCurrent.toFixed(
                0,
              )} days, for the year ${longTermYear}.`}
            />
          </Grid>
        </Grid>
      </Stack>

      {/* Chart */}
      <Stack direction="column">
        <Typography variant="overline">
          <strong>Risk Days</strong>
        </Typography>
        <Grid container>
          {/* Chart */}
          <Grid item xs={12}>
            <Line
              height={400}
              data={{
                labels: scenarioData.years,
                datasets: [
                  {
                    label: 'Moderate Risk',
                    data: scenarioData.fwi_nods_gt_15.percentiles['50th'],
                    fill: true,
                    backgroundColor: '#fcf0ae44',
                    borderColor: '#fcf0ae99',
                    tension: 0.5,
                    pointRadius: 3,
                  },
                  {
                    label: 'High Risk',
                    data: scenarioData.fwi_nods_gt_30.percentiles['50th'],
                    fill: true,
                    backgroundColor: '#ed924e22',
                    borderColor: '#ed924e44',
                    tension: 0.5,
                    pointRadius: 3,
                  },
                  {
                    label: 'Very High Risk',
                    data: scenarioData.fwi_nods_gt_45.percentiles['50th'],
                    fill: true,
                    backgroundColor: '#e04d3522',
                    borderColor: '#e04d3544',
                    tension: 0.5,
                    pointRadius: 3,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: true,
                  },
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  y: {
                    min: 0,
                    max: 350,
                    ticks: {
                      callback: function (value, index, values) {
                        return `${value} days`
                      },
                    },
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

type InfoCardProps = {
  lstring: string
  rstring: string
  description: string
}

function InfoCard({ lstring, rstring, description }: InfoCardProps) {
  return (
    <Card variant="outlined">
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography gutterBottom variant="h5" component="div">
            {lstring}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {rstring}
          </Typography>
        </Stack>
        <Typography color="text.secondary" variant="body2">
          {description}
        </Typography>
      </Box>
      {/* <Divider />
      <Box sx={{ px: 2, pt: 2 }}>
        <Stack direction="row" spacing={1}>
          {chips.map((chip, index) => (
            <Chip key={index} label={chip} size="small" />
          ))}
        </Stack>
      </Box> */}
    </Card>
  )
}

export default PropertyExtremeWind
