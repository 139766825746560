import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography, Tooltip, Card, List, ListItem } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { capitalise } from 'balkerne-fn'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Page from '../components/Page'
import { usePropertySubsidenceProjections } from '../hooks/analytics'
import { HelpRounded } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'

const scoreToColor = {
  '-1': '#dadada',
  '1': '#f5aa84',
  '2': '#e74b0f',
  '3': '#a83318',
}

const PropertySubsidence = () => {
  const { id: propertyId } = useParams()
  const { data: projections, isLoading } = usePropertySubsidenceProjections(propertyId)

  const sortedProjectionsByPeriod = useMemo(() => {
    projections?.sort((a, b) => a.year - b.year)
    return projections
  }, [projections])

  const hasNoData = !isLoading && (sortedProjectionsByPeriod == null || sortedProjectionsByPeriod.length === 0)

  return (
    <Page title="Subsidence" size="md">
      <List
        sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside',
          '& .MuiListItem-root': {
            display: 'list-item',
          },
          pb: 3,
        }}>
        <ListItem>Subsidence projections for the property are based on the RCP8.5 scenario.</ListItem>
        <ListItem>
          This is the worst-case scenario for climate change, and assumes that no action is taken to reduce greenhouse
          gas emissions.
        </ListItem>
      </List>
      {sortedProjectionsByPeriod?.length > 0 ? (
        <Stack justifyContent="center" gap={2}>
          {sortedProjectionsByPeriod?.map((projection, index) => (
            <Card key={index}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">{`${projection.year_start} - ${projection.year_end}`}</Typography>
                <Stack direction="row" alignItems="center">
                  <Box
                    sx={{
                      px: 2,
                      borderRadius: 10,
                      bgcolor: scoreToColor[projection.score],
                    }}>
                    <Typography variant="h6">{projection.text}</Typography>
                  </Box>
                  <Tooltip title={projection.description}>
                    <HelpRounded sx={{ ml: 1, width: '15px' }} />
                  </Tooltip>
                </Stack>
              </Stack>
            </Card>
          ))}
        </Stack>
      ) : (
        <Stack height="100px" justifyContent="center" alignItems="center">
          {hasNoData ? <Typography variant="h6">No data available</Typography> : <CircularProgress />}
        </Stack>
      )}
      <Stack direction="row" gap={1} justifyContent="center" pt={5}>
        <Typography variant="subtitle2">Available classifications</Typography>
        <Box sx={{ px: 1, borderRadius: 10, bgcolor: scoreToColor['-1'] }}>
          <Typography variant="body1">Unavailable</Typography>
        </Box>
        <Box sx={{ px: 1, borderRadius: 10, bgcolor: scoreToColor['1'] }}>
          <Typography variant="body1">Improbable</Typography>
        </Box>
        <Box sx={{ px: 1, borderRadius: 10, bgcolor: scoreToColor['2'] }}>
          <Typography variant="body1">Possible</Typography>
        </Box>
        <Box sx={{ px: 1, borderRadius: 10, bgcolor: scoreToColor['3'] }}>
          <Typography variant="body1">Probable</Typography>
        </Box>
      </Stack>
    </Page>
  )
}

export default PropertySubsidence
