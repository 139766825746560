import { Auth } from '@aws-amplify/auth'
import api from 'balkerne-core/api'

async function getUser() {
  return await api
    .get('/users/me')
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export function signIn(email, password) {
  return new Promise((resolve, reject) => {
    Auth.signIn(email.toLowerCase(), password)
      .then(async data => {
        const token = data.signInUserSession.idToken.jwtToken
        addBearerToken(token)
        const user = await getUser()
        if (!user) {
          reject("Couldn't fetch user information")
        }
        resolve({ token, user })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function signOut() {
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then(() => {
        sessionStorage.clear()
        removeBearerToken(api)
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

function refresh(refreshToken) {
  return new Promise(async (resolve, reject) => {
    try {
      const auth = await Auth.currentAuthenticatedUser()
      auth.refreshSession(refreshToken, (err, session) => {
        if (err) {
          throw err
        }
        const token = session.getIdToken().getJwtToken()
        resolve(token)
      })
    } catch (err) {
      reject(err)
    }
  })
}

export async function rehydrateSession() {
  return await Auth.currentSession()
    .then(async session => {
      const refreshToken = session.getRefreshToken()
      const token = await refresh(refreshToken)
      addBearerToken(token)
      const user = await getUser()
      if (!user) {
        throw "Couldn't fetch user information"
      }
      return { token, user }
    })
    .catch(err => {
      console.error(err)
      throw "Couldn't fetch user information"
    })
}

export function addBearerToken(token) {
  if (!token) {
    console.error("Token can't be undefined or null")
    return
  }
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export function removeBearerToken() {
  delete api.defaults.headers.common['Authorization']
}

export const generatePassword = () => {
  var length = 12
  var string = 'abcdefghijklmnopqrstuvwxyz'
  var numeric = '0123456789'
  var punctuation = '!@#$%^&*'
  var password = ''
  var character = ''
  while (password.length < length) {
    let entity1 = Math.ceil(string.length * Math.random() * Math.random())
    let entity2 = Math.ceil(numeric.length * Math.random() * Math.random())
    let entity3 = Math.ceil(punctuation.length * Math.random() * Math.random())
    let hold = string.charAt(entity1)
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold
    character += hold
    character += numeric.charAt(entity2)
    character += punctuation.charAt(entity3)
    password = character
  }
  password = password
    .split('')
    .sort(function () {
      return 0.5 - Math.random()
    })
    .join('')
  return password.substring(0, length)
}
