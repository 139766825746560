import { useCallback, useEffect, useMemo, useState } from 'react'
import { Divider, useTheme } from '@mui/material'
import { Box, Stack, Typography } from '@mui/material'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import PropertyHeatStress from '../components/climateAnalytics/property/PropertyHeatStress'
import PropertyFlood from '../components/climateAnalytics/property/PropertyFlood'
import PropertyExtremeWind from '../components/climateAnalytics/property/PropertyExtremeWind'
import PropertyFireHazard from '../components/climateAnalytics/property/PropertyFireHazard'
import PropertyDrought from '../components/climateAnalytics/property/PropertyDrought'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import Page from '../components/Page'
import { useClimateHazards, useClimatePropertyHazards, useClimateScenarios } from '../hooks/analytics'
import { useParams } from 'react-router-dom'
import { capitalise } from 'balkerne-fn'
import LoadingIcon from '../components/LoadingIcon'

const PropertyClimateAnalytics = () => {
  const theme = useTheme()
  const history = useHistory()
  const match = useRouteMatch()
  const { id: propertyId } = useParams()

  const { data: hazards, isLoading: isHazardsLoading } = useClimateHazards()
  const { data: availableHazards, isLoading: isAvailableHazardsLoading } = useClimatePropertyHazards(propertyId)
  const [selectedHazard, setSelectedHazard] = useState<string>('flood')

  const { data: _scenarios, isLoading: isScenarioLoading } = useClimateScenarios()
  const [availableScenarios, _setAvailableScenarios] = useState<string[]>([])
  const [selectedScenario, setSelectedScenario] = useState<string>()

  const scenarios = useMemo(() => {
    return availableScenarios.map(scenario => {
      return _scenarios.find(s => s.name === scenario)
    })
  }, [availableScenarios])

  const navigateTo = (hazard: string) => {
    history.push(`${match.url}/${hazard}`)
  }

  const setAvailableScenarios = useCallback((scenarios: string[]) => {
    _setAvailableScenarios(scenarios)
    if (scenarios.length > 0) {
      setSelectedScenario(scenarios[0])
    }
  }, [])

  // Change component based on hazard
  useEffect(() => {
    if (selectedHazard !== null) {
      navigateTo(selectedHazard)
    }
  }, [selectedHazard])

  if (isHazardsLoading || isScenarioLoading || isAvailableHazardsLoading) {
    return (
      <Page size="lg" title="Climate Analytics">
        <Stack
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '200px',
            width: '100%',
          }}>
          <LoadingIcon />
        </Stack>
      </Page>
    )
  }

  return (
    <Page size="lg" title="Climate Analytics">
      <Stack direction="row-reverse" spacing={4}>
        {/* Controls */}
        <Stack
          sx={{
            width: 200,
            minWidth: 200,
          }}>
          {/* Hazards */}
          <Typography variant="overline">Hazards</Typography>
          <ToggleButtonGroup
            color="primary"
            value={selectedHazard}
            exclusive
            size="small"
            onChange={(event: React.MouseEvent<HTMLElement>, newHazard: string) => setSelectedHazard(newHazard)}
            aria-label="Hazard"
            orientation="vertical">
            <ToggleButton key="flood" value="flood" aria-label="flood">
              Flood
            </ToggleButton>
            {hazards.map(value => (
              <ToggleButton
                key={value.name}
                value={value.name}
                aria-label={value.name}
                disabled={!(value.name in availableHazards)}>
                {value.display_name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <Box sx={{ mb: 4 }}></Box>

          {/* Emission Scenarios */}
          <Typography variant="overline">Emission Scenarios</Typography>
          <ToggleButtonGroup
            color="primary"
            value={selectedScenario}
            exclusive
            size="small"
            onChange={(event: React.MouseEvent<HTMLElement>, newScenario: string) => setSelectedScenario(newScenario)}
            aria-label="Scenario"
            orientation="vertical">
            {scenarios.map(value => (
              <ToggleButton key={value.name} value={value.name} aria-label={value.name}>
                {value.display_name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
        <Box sx={{ maxWidth: '1px', flexBasis: '100%', backgroundColor: theme.palette.background.borderColor }} />

        {/* Hazard Component */}
        {selectedHazard == null ? (
          <Stack
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '100px',
              width: '100%',
            }}>
            <Typography variant="h6">Select a hazard to view analytics</Typography>
          </Stack>
        ) : (
          <>
            <Stack direction="column" flexGrow={1}>
              <Stack direction="row" alignItems="flex-end" spacing={2}>
                <Typography variant="h5">{capitalise(selectedHazard.replace('-', ' '))}</Typography>
                <Divider orientation="vertical" />
                <Typography variant="h6">{selectedScenario?.toUpperCase()} Emissions Scenario</Typography>
              </Stack>
              <Divider sx={{ mt: 2, mb: 4 }} />
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  borderRadius: 1,
                  backgroundColor: theme.palette.background.default,
                }}>
                <Switch>
                  <Route exact path={`${match.path}/flood`}>
                    <PropertyFlood selectedScenario={selectedScenario as string} setScenarios={setAvailableScenarios} />
                  </Route>
                  <Route exact path={`${match.path}/heat-stress`}>
                    <PropertyHeatStress
                      selectedScenario={selectedScenario as string}
                      setScenarios={setAvailableScenarios}
                    />
                  </Route>
                  <Route exact path={`${match.path}/wind`}>
                    <PropertyExtremeWind
                      selectedScenario={selectedScenario as string}
                      setScenarios={setAvailableScenarios}
                    />
                  </Route>
                  <Route exact path={`${match.path}/fire`}>
                    <PropertyFireHazard
                      selectedScenario={selectedScenario as string}
                      setScenarios={setAvailableScenarios}
                    />
                  </Route>
                  <Route exact path={`${match.path}/drought`}>
                    <PropertyDrought
                      selectedScenario={selectedScenario as string}
                      setScenarios={setAvailableScenarios}
                    />
                  </Route>
                </Switch>
              </Box>
            </Stack>
          </>
        )}
      </Stack>
    </Page>
  )
}

export default PropertyClimateAnalytics
