import React, { forwardRef } from 'react'
import { TextField, TextFieldProps } from '@mui/material'

type FormTextFieldProps = TextFieldProps & {
  children: React.ReactNode
  errors: any
  name: string
  [key: string]: any
}
export const FormTextField = forwardRef(({ children, errors, ...props }: FormTextFieldProps, ref) => {
  const error = props.name.split('.').reduce((acc, curr) => acc?.[curr], errors)
  return (
    <TextField {...props} inputRef={ref} error={!!error} helperText={error?.message ?? ' '}>
      {children}
    </TextField>
  )
})

export default FormTextField
