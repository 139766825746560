import React from 'react'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import c from '../../pages/GetStarted.module.scss'

type UserGetStartedProps = {
  currentStep: number
  updateUserData: (userData: any) => void
}
type UserGetStartedState = {
  userData: {
    full_name: string
    email: string
    phone: string
    organisation_name: string
    position: string
  }
}
export class UserGetStarted extends React.Component<UserGetStartedProps, UserGetStartedState> {
  constructor(props) {
    super(props)
    this.state = {
      userData: {
        full_name: '',
        email: '',
        phone: '01150000000',
        organisation_name: '',
        position: 'Insurance Self-Service',
      },
    }
  }

  // handles changes made to the user, updates the users array state
  handleChange = event => {
    let newState = {
      userData: {
        // object that we want to update
        ...this.state.userData, // keep all other key-value pairs
        [event.target.id]: event.target.value, // update the value of specific key
      },
    }
    this.setState(newState)
    this.props.updateUserData(newState.userData)
  }

  // conditionally render based on state, add the component for row of fields and set functions
  render() {
    if (this.props.currentStep !== 1) {
      return null
    }

    return (
      <div className={c.page}>
        <Container>
          <Card className={c.chart}>
            <Card.Header>Create User: </Card.Header>
            <Card.Body>
              <form name="my-form" onChange={this.handleChange}>
                <div>
                  <label htmlFor="email">E-Mail Address</label>
                  <input type="text" id="email" className="form-control" name="userData.email" />
                </div>
                <div className="form-group row">
                  <label htmlFor="fullName">Your Name</label>
                  <input type="text" id="full_name" className="form-control" name="full_name" />
                </div>
                <div className="form-group row">
                  <label htmlFor="org_id">Your Company Name</label>
                  <input type="text" id="organisation_name" className="form-control" />
                </div>
              </form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    )
  }
}
export default UserGetStarted
