import { useSnackbar } from 'notistack'
import { useLocation, Redirect } from 'react-router-dom'
import siteMap from '../siteMap'
import { useUsers, useUsersLocationIDs } from '../hooks/users'
import React, { useEffect, useMemo, useState } from 'react'
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import { PortfolioType, PortfolioTypeIcon } from 'balkerne-core/roles'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import api from 'balkerne-core/api'
import { useLocations, usePropertyAttributeCategories } from '../hooks/locations'
import { Alert, CircularProgress, Typography } from '@mui/material'
import capitalise from 'balkerne-fn/capitalise'
import { LoadingButton } from '@mui/lab'
import Page from '../components/Page'

const CustomToolbar = ({ children, sx }: { children: any; sx?: any }) => (
  <GridToolbarContainer sx={{ justifyContent: 'space-between', ...sx }}>
    {children}
    <GridToolbarFilterButton />
  </GridToolbarContainer>
)

const AssignUsers = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { state } = useLocation()
  if (state?.ids === undefined) {
    console.error('[AssignUsers] state.ids must be provided')
    enqueueSnackbar('An error has occurred', { variant: 'error' })
    return <Redirect to={siteMap.ListUsers.path} />
  }
  const ids = state.ids
  const { data: usersLocations, isLoading: usersLocationsLoading, refetch, isRefetching } = useUsersLocationIDs(ids)
  const { data: initUsers, isLoading: usersLoading } = useUsers(ids)
  const [users, setUsers] = useState<any[]>([])

  useEffect(() => {
    if (!usersLoading && !usersLocationsLoading && !isRefetching) {
      const usersWithProperties = initUsers.map(user => ({ ...user, properties: usersLocations[user.id] }))
      setUsers(usersWithProperties)
      setUser(usersWithProperties[0])
    }
  }, [usersLoading, usersLocationsLoading, isRefetching])

  const [user, setUser] = useState<any>()
  const { data: properties, isLoading: propertiesLoading } = useLocations()
  const { data: attributeCategories, isLoading: attributeCategoriesLoading } = usePropertyAttributeCategories()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [availableSelected, setAvailableSelected] = useState<any[]>([])
  const [assignedSelected, setAssignedSelected] = useState<any[]>([])
  const [propertyColumns, setPropertyColumns] = useState<GridColDef[]>([
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'property_customer_reference',
      headerName: 'Ref.',
      width: 150,
    },
  ])

  useEffect(() => {
    if (!attributeCategoriesLoading) {
      const cols: GridColDef[] = [...propertyColumns]
      attributeCategories.forEach(category => {
        cols.push({
          field: category.name,
          headerName: category.name,
          width: 150,
          valueGetter: params => {
            const attribute = params.row.attributes.find(attribute => attribute.category_id == category.id)
            return attribute ? attribute.value : ''
          },
          hide: true,
        })
      })
      setPropertyColumns(cols)
    }
  }, [attributeCategoriesLoading])

  const userColumns = useMemo(
    () => [
      {
        field: 'full_name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 200,
      },
      {
        field: 'role',
        headerName: 'Role',
        width: 170,
        valueGetter: params => params.row.role.name,
      },
      {
        field: 'portoflio_type',
        headerName: 'Portfolio Type',
        width: 170,
        valueGetter: params => capitalise(params.row.role.attributes.portfolio_type),
        renderCell: params => (
          <Stack direction="row" gap={1} alignItems="center">
            <PortfolioTypeIcon type={params.row.role.attributes.portfolio_type} />
            {capitalise(params.row.role.attributes.portfolio_type)}
          </Stack>
        ),
      },
      {
        field: 'position',
        headerName: 'Position',
        width: 180,
      },
      {
        field: 'properties',
        headerName: 'Properties',
        width: 150,
        valueGetter: params =>
          params.row.role.attributes.portfolio_type == PortfolioType.Organisation
            ? properties?.length
            : `${params.row.properties.length}`,
      },
    ],
    [properties],
  )

  const addProperties = () => {
    const newUsers: any[] = [...users]
    const replaceIndex = newUsers.findIndex(_user => _user.email === user.email)
    const newUser = { ...user, properties: [...availableSelected, ...user.properties] }
    newUsers.splice(replaceIndex, 1, newUser)
    setUsers(newUsers)
    setUser(newUser)
    setAvailableSelected([])
  }

  const removeProperties = () => {
    const newUsers = [...users]
    const replaceIndex = newUsers.findIndex(_user => _user.email === user.email)
    const newUser = {
      ...user,
      properties: user.properties.filter(id => !assignedSelected.includes(id)),
    }
    newUsers.splice(replaceIndex, 1, newUser)
    setUsers(newUsers)
    setUser(newUser)
    setAssignedSelected([])
  }

  const CustomToolbarAvailable = useMemo(
    () => () =>
      (
        <CustomToolbar>
          <Button onClick={addProperties} variant="contained" size="small">
            Assign
          </Button>
        </CustomToolbar>
      ),
    [addProperties],
  )

  const CustomToolbarAssigned = useMemo(
    () => () =>
      (
        <CustomToolbar sx={{ ml: 1 }}>
          <Button onClick={removeProperties} variant="outlined" size="small">
            Remove
          </Button>
        </CustomToolbar>
      ),
    [removeProperties],
  )

  const available = useMemo(() => properties.filter(prop => !user?.properties.includes(prop.id)), [user])
  const assigned = useMemo(() => properties.filter(prop => user?.properties.includes(prop.id)), [user])

  if (usersLoading || attributeCategoriesLoading || propertiesLoading) {
    return <CircularProgress />
  }

  const single = user?.role.attributes.portfolio_type == PortfolioType.Property
  const isOrganisationPortfolio = user?.role.attributes.portfolio_type == PortfolioType.Organisation

  // 🚩 Disable select-all checkbox for single property users
  const classes = {
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
      display: single ? 'none' : 'flex',
    },
    height: '800px',
  }

  const handleUserClick = user => {
    setAvailableSelected([])
    setUser(user)
  }

  const isRowSelectable = (selectedId: any) => {
    return (
      (!single || availableSelected.length === 0 || availableSelected.includes(selectedId)) &&
      (!single || user.properties.length === 0)
    )
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    const data = users.reduce((acc, user) => {
      acc[user.id] = user.properties
      return acc
    }, {})
    api
      .put('/users/locations', data)
      .then(res => {
        // Check res.data.errors
        refetch()
        enqueueSnackbar('Properties assigned successfully', { variant: 'success' })
      })
      .catch(err => {
        enqueueSnackbar('An error has occurred', { variant: 'error' })
        console.error(err)
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <Page
      title="Assign Users"
      back
      PageActions={() => (
        <LoadingButton onClick={handleSubmit} variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      )}>
      {/* New users */}
      <Box sx={{ maxHeight: 300 }} mb={2}>
        <DataGrid
          autoHeight
          hideFooterSelectedRowCount
          rowsPerPageOptions={[10]}
          columns={userColumns}
          rows={users ?? []}
          getRowId={row => row.email}
          density="compact"
          onRowClick={params => handleUserClick(params.row)}
        />
      </Box>
      {!isOrganisationPortfolio ? (
        <>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
            <Box sx={{ width: '49%' }}>
              {/* Available Locations */}
              <label>Select properties to assign</label>
              <DataGrid
                sx={classes}
                components={{
                  Toolbar: CustomToolbarAvailable,
                }}
                rows={available ?? []}
                onSelectionModelChange={ids => setAvailableSelected(ids)}
                selectionModel={availableSelected}
                columns={propertyColumns}
                disableColumnSelector
                checkboxSelection
                isRowSelectable={params => isRowSelectable(params.id)}
                density="compact"
              />
            </Box>
            {/* Assigned locations */}
            <Box sx={{ width: '49%' }}>
              <label>Assigned properties</label>
              <DataGrid
                sx={classes}
                components={{
                  Toolbar: CustomToolbarAssigned,
                }}
                rows={assigned ?? []}
                onSelectionModelChange={ids => setAssignedSelected(ids)}
                selectionModel={assignedSelected}
                columns={propertyColumns}
                disableColumnSelector
                checkboxSelection
                density="compact"
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between"></Stack>
        </>
      ) : (
        <Stack direction="row" justifyContent="center" sx={{ mt: 4 }}>
          <Alert severity="info">Organisational users have all properties assigned by default</Alert>
        </Stack>
      )}
    </Page>
  )
}
export default AssignUsers
