import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormLabel,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  getGridSingleSelectOperators,
  GridFilterInputValueProps,
  GridValueGetterParams,
  GridColDef,
  GridSelectionModel,
} from '@mui/x-data-grid'
import api from 'balkerne-core/api'
import { useSnackbar } from 'notistack'
import { forwardRef, useEffect, useMemo, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useLocationGroups, useLocations, usePortfolioRisks, usePropertyAttributeCategories } from '../hooks/locations'
import { useRiskCategories } from '../hooks/analytics'
import Page from '../components/Page'
import { Risk } from 'balkerne-core/risks'
import { RiskLevelTag } from '../components/widgets/RiskLevelTag'
import { TransitionProps } from '@mui/material/transitions'

const groupColumns: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'description', headerName: 'Description', width: 200 },
  { field: 'property_count', headerName: 'Property #', width: 100, type: 'number', align: 'right' },
]

const propertyColumns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'postcode',
    headerName: 'Postcode',
    width: 100,
    valueGetter: (params: GridValueGetterParams) => params.row?.address.postcode,
  },
  {
    field: 'address',
    headerName: 'Address',
    width: 300,
    valueGetter: params => {
      return params.row?.address.street + ', ' + params.row?.address.town
    },
    hide: true,
    flex: 1,
  },
]
const customRiskFilterComponent = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props
  const [value, setValue] = useState('')
  const handleChange = (event, newValue) => {
    applyValue({ ...item, value: newValue.props.children })
    setValue(newValue.props.value)
  }
  return (
    <>
      <InputLabel>Value</InputLabel>
      <Select onChange={handleChange} value={value}>
        <MenuItem key={'riskFilterHigh'} value={Risk.HIGH}>
          High
        </MenuItem>
        <MenuItem key={'riskFilterMedium'} value={Risk.MEDIUM}>
          Medium
        </MenuItem>
        <MenuItem key={'riskFilterLow'} value={Risk.LOW}>
          Low
        </MenuItem>
        <MenuItem key={'riskFilterNegligible'} value={Risk.NEGLIGIBLE}>
          Negligible
        </MenuItem>
        <MenuItem key={'riskFilterNone'} value={Risk.NONE}>
          None
        </MenuItem>
      </Select>
    </>
  )
}
const PropertyGroups = () => {
  const { data: groups, refetch: refetchGroups } = useLocationGroups()
  const [groupId, setGroupId] = useState(groups?.[0]?.id ?? null)
  const { data: locations, refetch: refetchLocations } = useLocations({ group_id: groupId }, (groups?.length ?? 0) > 0)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const onDelete = () => {
    setGroupId(null)
    refetchGroups()
  }

  const group = useMemo(() => {
    return groups?.find(x => x.id === groupId)
  }, [groups, groupId])

  return (
    <>
      <Page
        title="Property Groups"
        back
        PageActions={() => (
          <Button variant="outlined" onClick={() => setIsCreateModalOpen(true)}>
            Create Group
          </Button>
        )}>
        <Stack alignItems="center" direction="row" mb={2}>
          <Box>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h5">{group?.name ?? 'No selection'}</Typography>
              <Chip label={group?.property_count ?? 0} size="small" variant="outlined" />
            </Stack>
            <Typography variant="body1">
              {group?.description?.length > 0 ? group.description : 'No description'}
            </Typography>
          </Box>
          <Stack sx={{ flexGrow: 1 }} gap={1} direction="row" justifyContent="flex-end" flexBasis={1} flexGrow={1}>
            <Button variant="contained" disabled={groupId == null} onClick={() => setIsAssignModalOpen(true)}>
              Assign
            </Button>
            <Button variant="outlined" disabled={groupId == null} onClick={() => setIsDeleteModalOpen(true)}>
              Delete
            </Button>
          </Stack>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DataGrid
              sx={{ height: 500 }}
              columns={groupColumns}
              rows={groups}
              density="compact"
              autoPageSize
              onRowClick={params => setGroupId(params.id)}
            />
          </Grid>
          <Grid item xs={6}>
            <DataGrid
              sx={{ height: 500 }}
              columns={propertyColumns}
              rows={groupId !== null ? locations : []}
              density="compact"
              autoPageSize
            />
          </Grid>
        </Grid>
      </Page>
      <DeleteDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
        groupId={groupId}
      />
      <CreateGroupDialog open={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} onAdd={refetchGroups} />
      <AssignGroupDialog
        open={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        onSubmit={async () => {
          refetchLocations()
          refetchGroups()
        }}
        locations={locations}
        groupId={groupId}
      />
    </>
  )
}
const DialogTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />
})

const DeleteDialog = ({ open, onClose, onDelete, groupId, ...props }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleDelete = async () => {
    setIsSubmitting(true)
    if (groupId == null) return
    await api
      .delete(`/locations/groups/${groupId}`)
      .then(() => {
        enqueueSnackbar('Group deleted', { variant: 'success' })
        onDelete()
        onClose()
      })
      .catch(() => {
        enqueueSnackbar('Failed to delete group', { variant: 'error' })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <Dialog open={open} onClose={onClose} keepMounted TransitionComponent={DialogTransition}>
      <DialogTitle>Delete Group</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this group?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton onClick={handleDelete} variant="contained" color="error" loading={isSubmitting}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const CustomToolbar = ({ children }) => (
  <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
    {children}
    <GridToolbarFilterButton />
  </GridToolbarContainer>
)

const AssignGroupDialog = ({ open, onClose, onSubmit, locations, groupId, ...props }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { data: allLocations } = useLocations()
  const { data: risks } = usePortfolioRisks()
  const { data: riskCategories, isLoading: riskCategoriesLoading } = useRiskCategories()
  const { data: attributeCategories, isLoading: attributeCategoriesLoading } = usePropertyAttributeCategories()
  const [selectedLocations, setSelectedLocations] = useState(locations?.map(x => x.id) ?? [])
  const [availableSelection, setAvailableSelection] = useState<GridSelectionModel>([])
  const [assignedSelection, setAssignedSelection] = useState<GridSelectionModel>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filterModel, setFilterModel] = useState({
    availableLocations: {
      items: [],
    },
    assignedLocations: {
      items: [],
    },
  })
  const columns = useMemo(() => {
    const cols: any[] = [...propertyColumns]

    riskCategories.forEach(category => {
      cols.push({
        field: category.name,
        headerName: category.display_name,
        width: 100,
        valueGetter: params => {
          const risk = params.row?.risks.find(risk => risk.category_id === category.id)
          if (!risk) return ''
          switch (risk.value) {
            case Risk.HIGH:
              return 'High'
            case Risk.MEDIUM:
              return 'Medium'
            case Risk.LOW:
              return 'Low'
            case Risk.NEGLIGIBLE:
              return 'Negligible'
            case Risk.NONE:
              return 'None'
          }
        },
        renderCell: params => {
          const risk = params.row?.risks.find(risk => risk.category_id === category.id)
          if (risk?.value <= 0) return ''
          return <RiskLevelTag value={risk?.value} />
        },
        hide: true,
        filterOperators: getGridSingleSelectOperators()
          .filter(operator => operator.value !== 'isAnyOf')
          .map(operator => ({
            ...operator,
            InputComponent: operator.InputComponent ? customRiskFilterComponent : undefined,
          })),
      })
    })

    attributeCategories.forEach(category => {
      cols.push({
        field: category.name,
        headerName: category.name,
        valueGetter: params => {
          const attribute = params.row.attributes.find(attribute => attribute.category_id == category.id)
          return attribute ? attribute.value : ''
        },
        hide: true,
        flex: 1,
      })
    })
    return cols
  }, [riskCategories, attributeCategories])

  const columnVisibilityModel = useMemo(() => {
    const updatedColumnVisibilityModel = {
      availableLocations: {},
      assignedLocations: {},
    }
    const hideableColumns = columns.filter(col => col.hasOwnProperty('hide')).map(column => column.field)
    // Check if any hideable columns are being filtered
    Object.keys(filterModel).forEach(key => {
      const filteredHideableColumns = filterModel[key].items
        .filter(filterItem => hideableColumns.includes(filterItem.columnField))
        .map(filterItem => filterItem.columnField)

      hideableColumns.forEach(columnField => {
        if (filteredHideableColumns.includes(columnField)) {
          updatedColumnVisibilityModel[key][columnField] = true
        } else {
          updatedColumnVisibilityModel[key][columnField] = false
        }
      })
    })

    return updatedColumnVisibilityModel
  }, [columns, filterModel])

  const handleFilterModelChange = (key, newFilterModel) => {
    setFilterModel({ ...filterModel, [key]: newFilterModel })
  }

  useEffect(() => {
    setSelectedLocations(locations?.map(x => x.id) ?? [])
  }, [locations, allLocations])

  const availableLocations = useMemo(() => {
    const _locations: any[] = []
    for (const location of allLocations) {
      if (!selectedLocations.some(id => id === location.id)) {
        _locations.push({ ...location, risks: risks ? risks[location.id] : [] })
      }
    }
    return _locations
  }, [locations, allLocations, selectedLocations, risks])

  const assignedLocations = useMemo(() => {
    const _locations: any[] = []
    for (const location of allLocations) {
      if (selectedLocations.some(id => id === location.id)) {
        _locations.push({ ...location, risks: risks ? risks[location.id] : [] })
      }
    }
    return _locations
  }, [locations, allLocations, selectedLocations])

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const originalLocations = locations.map(x => x.id)
    const add = selectedLocations.filter(x => !originalLocations.includes(x))
    const remove = originalLocations.filter(x => !selectedLocations.includes(x))

    await api
      .post(`/locations/groups/${groupId}/reassign`, { add, remove })
      .then(() => {
        enqueueSnackbar('Group updated', { variant: 'success' })
        onSubmit()
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar('Failed to update group', { variant: 'error' })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const handleClose = () => {
    onClose()
    handleReset()
  }

  const onAdd = () => {
    setSelectedLocations([...selectedLocations, ...availableSelection])
  }

  const handleReset = () => {
    setSelectedLocations(locations?.map(x => x.id) ?? [])
  }

  const onRemove = () => {
    setSelectedLocations(selectedLocations.filter((x: any) => !assignedSelection.includes(x)))
  }

  const CustomToolbarAvailable = useMemo(
    () => () =>
      (
        <CustomToolbar>
          <Button onClick={onAdd} variant="outlined" size="small">
            Add
          </Button>
        </CustomToolbar>
      ),
    [onAdd],
  )

  const CustomToolbarAssigned = useMemo(
    () => () =>
      (
        <CustomToolbar>
          <Button onClick={onRemove} variant="outlined" size="small">
            Remove
          </Button>
        </CustomToolbar>
      ),
    [onRemove],
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      keepMounted
      TransitionComponent={DialogTransition}>
      <DialogTitle>Assign Properties</DialogTitle>
      <DialogContent>
        <Stack mb={2} sx={{ minHeight: 400 }} direction="row">
          <DataGrid
            sx={{ height: 500 }}
            columns={columns}
            rows={availableLocations}
            onSelectionModelChange={setAvailableSelection}
            density="compact"
            autoPageSize
            checkboxSelection
            components={{
              Toolbar: CustomToolbarAvailable,
            }}
            filterModel={filterModel['availableLocations']}
            onFilterModelChange={newFilterModel => handleFilterModelChange('availableLocations', newFilterModel)}
            columnVisibilityModel={columnVisibilityModel['availableLocations']}
          />
          <Box width={10}></Box>
          <DataGrid
            sx={{ height: 500 }}
            columns={columns}
            rows={assignedLocations}
            onSelectionModelChange={setAssignedSelection}
            density="compact"
            autoPageSize
            checkboxSelection
            components={{
              Toolbar: CustomToolbarAssigned,
            }}
            filterModel={filterModel['assignedLocations']}
            onFilterModelChange={newFilterModel => handleFilterModelChange('assignedLocations', newFilterModel)}
            columnVisibilityModel={columnVisibilityModel['assignedLocations']}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          Reset
        </Button>
        <LoadingButton variant="contained" onClick={handleSubmit} loading={isSubmitting}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const CreateGroupDialog = ({ open, onClose, onAdd, ...props }) => {
  const { enqueueSnackbar } = useSnackbar()

  const schema = yup.object({
    name: yup.string().min(1).required('Name is required'),
    description: yup.string(),
  })

  const {
    register,
    handleSubmit: formSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { name: null, description: null },
  })

  const handleClose = () => {
    reset()
    onClose()
  }

  const handleSubmit = async data => {
    await api
      .post('/locations/groups', {
        name: data.name,
        description: data.description,
      })
      .then(() => {
        enqueueSnackbar('Group created', { variant: 'success' })
        onAdd()
        handleClose()
      })
      .catch(err => {
        const defaultError = 'Error creating group'
        if (err.response.status == 400) {
          enqueueSnackbar(err.response?.data ?? defaultError, { variant: 'warning' })
        } else {
          enqueueSnackbar(defaultError, { variant: 'error' })
        }
      })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      keepMounted
      TransitionComponent={DialogTransition}>
      <DialogTitle>Create Group</DialogTitle>
      <DialogContent>
        <Box sx={{ minHeight: 200 }}>
          <Box mb={1}>
            <FormControl fullWidth>
              <FormLabel htmlFor="name">Name*</FormLabel>
              <TextField id="name" {...register('name')} fullWidth></TextField>
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth>
              <FormLabel htmlFor="description">Description</FormLabel>
              <TextField id="description" {...register('description')} fullWidth />
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={formSubmit(handleSubmit)} loading={isSubmitting}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default PropertyGroups
