import React from 'react'
import { Box, Divider, IconButton, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import { ArrowBackIosNewRounded } from '@mui/icons-material'

type PageProps = {
  title: string
  children: React.ReactNode
  PageActions?: React.FC<any>
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  back?: boolean
}

const Page: React.FC<PageProps> = ({ title, children, PageActions, size, back }) => {
  const theme = useTheme()
  if (PageActions === undefined) {
    PageActions = () => <div></div>
  }
  const maxWidth = size ? theme.breakpoints.values?.[size] : '100%'

  return (
    <Stack alignItems={'center'} pb={6}>
      <Stack sx={{ px: 5, pt: 4, maxWidth: maxWidth, width: '100%' }} direction="column">
        <Stack pb={3} direction="row" justifyContent="space-between" alignItems="center">
          {/* Title & Optional Back Button */}
          <Stack direction="row" alignItems="center" spacing={1}>
            {back && (
              <IconButton onClick={() => history.back()}>
                <ArrowBackIosNewRounded />
              </IconButton>
            )}
            <Typography variant="h5" fontWeight="bold">
              {title === null ? (
                <Skeleton variant="rectangular" width={300} height={42} sx={{ borderRadius: 1 }} />
              ) : (
                title
              )}
            </Typography>
          </Stack>

          {/* Spacer */}
          <Box flexGrow={1} />

          {/* Actions */}
          <PageActions />
        </Stack>
        {/* <Divider sx={{ mb: 1 }} /> */}
        {children}
      </Stack>
    </Stack>
  )
}

export default Page
