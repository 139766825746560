import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { Box, Button, Divider, Grid, Stack, Typography, Card, useTheme, Icon } from '@mui/material'
import { DataGrid, DataGridProps, GridToolbarFilterButton } from '@mui/x-data-grid'
import { NotificationsActiveRounded, NotificationsOffRounded, CheckCircleRounded } from '@mui/icons-material'

const CardDataGrid: React.FC<DataGridProps> = props => {
  const theme: any = useTheme()
  return (
    <DataGrid
      {...props}
      hideFooterSelectedRowCount
      sx={{
        borderRadius: theme.components.MuiCard.styleOverrides.root.borderRadius,
        border: theme.components.MuiCard.styleOverrides.root.border,
        boxShadow: theme.shadows[1],
        // '& .MuiDataGrid-cell': {
        //   borderRight: `1px solid ${theme.palette.divider}`,
        // },
        // '& .MuiDataGrid-cell:last-child': {
        //   borderRight: 'none',
        // },
        ...props.sx,
      }}
      // checkboxSelection
      // columns={propertyColumns}
      // disableColumnMenu
      // disableColumnSelector
      // hideFooter
      // hideFooterPagination
      // hideFooterRowCount
    />
  )
}

export default CardDataGrid
