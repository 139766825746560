import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { useControl } from 'react-map-gl'
import { centroid } from '@turf/turf'
import { useMemo } from 'react'

import type { MapRef, ControlPosition } from 'react-map-gl'

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition,
  initGeometry?: GeoJSON.FeatureCollection,
  initFeatures: (features: { [key: string]: GeoJSON.Feature }) => void,
  onCreate?: (evt: { features: object[] }) => void,
  onUpdate?: (evt: { features: object[], action: string }) => void,
  onDelete?: (evt: { features: object[] }) => void,
}

export default function DrawControl(props: DrawControlProps) {
  const { initGeometry, ...drawOptions } = props
  const draw = useMemo(() => new MapboxDraw(drawOptions), [])
  useControl<MapboxDraw>(
    () => draw,
    ({ map }: { map: MapRef }) => {
      if (initGeometry) {
        const initFeatures: { [key: string]: GeoJSON.Feature } = {}
        initGeometry.features.forEach(feature => {
          const featureId = draw.add(feature)[0]
          feature['id'] = featureId
          initFeatures[featureId] = feature
        })
        props.initFeatures(initFeatures)
      }
      map.on('draw.create', props.onCreate || (() => {}))
      map.on('draw.update', props.onUpdate || (() => {}))
      map.on('draw.delete', props.onDelete || (() => {}))
    },
    ({ map }: { map: MapRef }) => {
      map.off('draw.create', props.onCreate || (() => {}))
      map.off('draw.update', props.onUpdate || (() => {}))
      map.off('draw.delete', props.onDelete || (() => {}))
    },
    {
      position: props.position,
    },
  )

  return null
}

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
  initGeometry: undefined,
  initFeatures: () => {},
}
