import React, { useEffect } from 'react'
import c from './AttachedFile.module.scss'
import { getSignedUrl } from './DynamicForm'

const AttachedFile = ({ ...props }) => {
  var fileSize = props.fileSize / 1024
  fileSize = parseFloat((fileSize / 1024).toFixed(2))
  const getFile = async () => {
    let downloadURL = ''
    if (props.objectURL === null) {
      downloadURL = await getSignedUrl(props.formID, props.fileName, props.inputID)
    } else {
      downloadURL = props.objectURL
    }
    const downloadButton: any = document.getElementById(props.fileKey + '-download')
    if (downloadButton) {
      downloadButton.href = downloadURL
      downloadButton.click()
    }
  }
  useEffect(() => {
    const getImageURL = async () => {
      const imageURL = await getSignedUrl(props.formID, props.fileName, props.inputID)
      const image: any = document.getElementById(props.fileKey + '-image')
      if (image) {
        image.src = imageURL
      }
    }
    getImageURL()
  }, [])
  return (
    <div className={c.containerwidth} id={props.fileKey}>
      <div className={c.containercolour}>
        <a onClick={props.removeFile} className={['close', c.close].join(' ')} title="Remove">
          <span className={c.closeSpan} aria-hidden="true">
            &times;
          </span>
        </a>
        {/* <img className={c.imagestyle} src={props.objectURL ? props.objectURL : null} alt={props.fileName} /> */}
        <img id={`${props.fileKey}-image`} className={c.imagestyle} alt={props.fileName} />

        <a className={c.downloadtag} id={props.fileKey + '-download'} download></a>
        <a className={c.attachName} target="_blank" onClick={getFile}>
          '{props.fileName}'
        </a>
        <p className={c.attachSize}>Size: {fileSize} MB</p>
      </div>
    </div>
  )
}

export default AttachedFile
