import { ListItem, Collapse } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import LightListItemButton from './LightListItemButton'

type CollapseListItemProps = {
  component?: JSX.Element
  render?: () => JSX.Element
  children?: JSX.Element | JSX.Element[]
  open?: boolean
} & React.ComponentProps<typeof ListItem>

const CollapseListItem = ({ component, render, children, open, ...props }: CollapseListItemProps) => {
  const [_open, setOpen] = useState(open)
  useEffect(() => {
    if (open) {
      setOpen(open)
    }
  }, [open])
  let element: JSX.Element | null = null
  if (component) {
    element = component
  } else if (render) {
    element = render()
  }

  return (
    <>
      <ListItem {...props} onClick={() => setOpen(!_open)}>
        <LightListItemButton>
          {element}
          {_open ? <ExpandLess /> : <ExpandMore />}
        </LightListItemButton>
      </ListItem>
      <Collapse sx={props.sx} in={_open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  )
}

export default CollapseListItem
