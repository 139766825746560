import {
  ComposedChart,
  Area,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  Legend,
  ResponsiveContainer,
} from 'recharts'

import moment from 'moment'
import { DisplaySeverity } from 'balkerne-core/alerts'
import { Card, Typography } from '@mui/material'

const SeverityColour = Object.freeze({
  1: '#F43333',
  2: '#F48E33',
  3: '#F4E233',
})

const AreaReadingsChart = props => {
  const { areaReading, chartType } = props

  const renderThresholds = () => {
    return areaReading.configurations.map(configuration => (
      <Line
        dataKey={configuration.id}
        name={DisplaySeverity[configuration.severity]}
        stroke={SeverityColour[configuration.severity]}
        strokeWidth={2}
        type="linear"
        dot={false}
        strokeDasharray="9 3"
      />
    ))
  }
  const getAreaChartLabel = readingType => {
    switch (areaReading.alert_type.name) {
      case 'rain_measurement':
        return `${readingType === 'metric' ? areaReading.alert_type.display_name : 'Forecast'} (${
          areaReading.alert_type.units
        })`
      case 'rain':
        return `${readingType === 'metric' ? areaReading.alert_type.display_name : 'Forecast'} (${
          areaReading.alert_type.units
        })`
      case 'rain_forecast_long_range':
        return `${readingType === 'metric' ? areaReading.alert_type.display_name : 'Forecast'} (${
          areaReading.alert_type.units
        })`
      case 'wind':
        return `${readingType === 'metric' ? 'Wind Speed' : 'Forecast'} (${areaReading.alert_type.units})`
      case 'heat':
        return `${readingType === 'metric' ? 'Temperature' : 'Forecast'} (°${areaReading.alert_type.units})`
      case 'snow':
        return `${readingType === 'metric' ? 'Snow' : 'Forecast'} (${areaReading.alert_type.units})`
      case 'river_gauge':
        return `${readingType === 'metric' ? 'River Gauge' : 'Forecast'} (${areaReading.alert_type.units})`
      default:
        return ''
    }
  }
  const getAreaChartColour = () => {
    switch (areaReading.alert_type.name) {
      case 'rain':
        return '#0077B6'
      case 'wind':
        return '#95C6B1'
      case 'heat':
        return '#FF9233'
      case 'snow':
        return '#d9d9d9'
      case 'river_gauge':
        return '#219EBC'
      default:
        return '#8884d8'
    }
  }
  const renderGraphs = () => {
    const out: any = []
    switch (chartType) {
      case 'area':
        out.push(
          <Area
            type="monotone"
            dataKey="value"
            name={getAreaChartLabel('metric')}
            stroke={getAreaChartColour()}
            strokeWidth={2.5}
            fill={getAreaChartColour()}
            connectNulls
          />,
        )
        break

      case 'bar':
        out.push(<Bar dataKey="value" name={getAreaChartLabel('metric')} fill={getAreaChartColour()} barSize={3.5} />)
        break

      default:
        break
    }
    return out
  }

  return (
    <Card>
      <Typography variant="subtitle1">
        {getAreaChartLabel('metric')} -{' '}
        {areaReading.area.name
          ? areaReading.area.name + ' (' + areaReading.area.reference + ')'
          : areaReading.area.reference}
      </Typography>
      <div className="Weatherchart">
        {areaReading.readings.length !== 0 ? (
          <ResponsiveContainer height={500}>
            <ComposedChart
              data={areaReading.readings}
              margin={{
                top: 25,
                bottom: 25,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="measured_at"
                width={100}
                name="Measured At"
                tickFormatter={unixTime => moment(unixTime).format('DD-MMM HH:mm')}
                scale="point"
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Brush dataKey="name" height={30} stroke={getAreaChartColour()} />
              {renderGraphs()}
              {renderThresholds()}
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          <div style={{ padding: 4, textAlign: 'center' }}>
            <p>No data available</p>
          </div>
        )}
      </div>
    </Card>
  )
}

export default AreaReadingsChart
