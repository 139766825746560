import Box from '@mui/material/Box'
import * as Sentry from '@sentry/react'
import { rehydrateSession } from 'balkerne-core/auth'
import { useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import Footer from './components/Footer'
import MenuDrawer from './components/MenuDrawer'
import Header from './components/HeaderV2'
import { PrivateRoute } from './components/userManagement/PrivateRoute'
import { history } from './redux/_helpers'
import authActions from './store/auth'
import customActions from './store/custom'
import NotificationDrawer from './components/NotificationDrawer'
import siteMap from './siteMap'
import { RootState } from './store'
import './pages/ObsoleteSoon.css'

// 🚧 Legacy pages, re-work into new pages
import GetStarted from './pages/GetStarted'
import ResetPassword from './pages/ResetPassword'
import LoadingIcon from './components/LoadingIcon'

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
})

const LoadingProgress = () => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <LoadingIcon />
  </Box>
)

export const App = () => {
  const [rehydrating, setRehydrating] = useState(true)
  const authenticated = useSelector((state: RootState) => state.auth.authenticated)
  const user = useSelector((state: RootState) => state.auth.user)
  const dispatch = useDispatch()

  const refresh = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      rehydrateSession()
        .then(({ token, user }) => {
          dispatch(customActions.setup(user.organisation.id))
          dispatch(authActions.login({ token, user }))
          resolve()
        })
        .catch(error => {
          console.warn(error)
          reject(error)
        })
    })
  }, [])
  useEffect(() => {
    refresh().finally(() => setRehydrating(false))
  }, [])

  useEffect(() => {
    const milliseconds = 1800000 // 30 minutes
    const intervalId = setInterval(refresh, milliseconds)
    return () => clearInterval(intervalId)
  }, [])

  if (rehydrating) {
    return <LoadingProgress />
  }

  // 🧑 User is authenticated
  if (authenticated && !!user) {
    return (
      <Router history={history}>
        <Box sx={{ display: 'flex' }}>
          <Route path="/" component={MenuDrawer} />
          <NotificationDrawer />
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                minHeight: 'calc(100vh - 64px)', // 96px = 64px (footer) + 32px (margin)
              }}>
              <Route path="/" component={Header} />
              <PrivateRoute {...siteMap.Portfolio} />
              <PrivateRoute {...siteMap.Organisation} />
              <PrivateRoute {...siteMap.PortfolioOperations} />
              <PrivateRoute {...siteMap.PortfolioClimateProjections} />
              <PrivateRoute {...siteMap.PortfolioAlerts} />
              <PrivateRoute {...siteMap.PropertyList} />
              <PrivateRoute {...siteMap.AlertSubscriptions} />
              <PrivateRoute {...siteMap.AssignConfigurations} />
              <PrivateRoute {...siteMap.CreateAlertConfiguration} />
              <PrivateRoute {...siteMap.AlertConfigurations} />
              <PrivateRoute {...siteMap.AlertAreas} />
              <PrivateRoute {...siteMap.CreateUsers} />
              <PrivateRoute {...siteMap.ListUsers} />
              <PrivateRoute {...siteMap.AssignUsers} />
              <PrivateRoute {...siteMap.AddProperties} />
              <PrivateRoute {...siteMap.EditProperty} />
              <PrivateRoute {...siteMap.Property} />
              <PrivateRoute {...siteMap.PropertyClimateMitigations} />
              <PrivateRoute {...siteMap.PropertyMitigations} />
              <PrivateRoute {...siteMap.PropertyIncidents} />
              <PrivateRoute {...siteMap.RiskInsights} />
              <PrivateRoute {...siteMap.ClimateProjection} />
              <PrivateRoute {...siteMap.Alerts} />
              <PrivateRoute {...siteMap.Sensors} />
              <PrivateRoute {...siteMap.PropertyGroups} />
              <PrivateRoute {...siteMap.TemplateManager} />
              <Switch>
                <PrivateRoute {...siteMap.CreateFormTemplateVersion} />
                <PrivateRoute {...siteMap.EditFormTemplateVersion} />
                <PrivateRoute {...siteMap.CreateTemplate} />
                <PrivateRoute {...siteMap.FormTemplate} />
              </Switch>
              <PrivateRoute {...siteMap.CompletedForms} />
              <PrivateRoute {...siteMap.CompleteForm} />
              <PrivateRoute {...siteMap.FormView} />
              <PrivateRoute {...siteMap.FormList} />
              <PrivateRoute {...siteMap.Login} />
              <PrivateRoute {...siteMap.Settings} />
              <PrivateRoute {...siteMap.PortfolioRiskInsights} />
              <PrivateRoute {...siteMap.PropertySubsidence} />
              <PrivateRoute {...siteMap.PropertyFloodDamagePricing} />
              <PrivateRoute {...siteMap.ClimateAnalyticsV2} />
              <PrivateRoute {...siteMap.PropertyClimateAnalytics} />
            </Box>
            <Footer />
          </Box>
        </Box>
      </Router>
    )
  } else {
    // 👻 User is not authenticated
    return (
      <Router history={history}>
        <Switch>
          <Route {...siteMap.Login} path="/" exact />
          <Route {...siteMap.Login} exact />
          <Route path="/GetStarted" component={GetStarted} />
          <Route path="/ResetPassword" component={ResetPassword} />
          <Route>
            <Redirect to={siteMap.Login.path} />
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default Sentry.withProfiler(App)
