import Login from './pages/Login'
import Portfolio from './pages/Portfolio'
import Property from './pages/Property'
import AlertSubscriptions from './pages/AlertSubscriptions'
import AlertConfigurations from './pages/AlertConfigurations'
import AssignConfigurations from './pages/AssignConfigurations'
import CreateUsers from './pages/CreateUsers'
import AssignUsers from './pages/AssignUsers'
import ListUsers from './pages/ListUsers'
import PropertyList from './pages/PropertyList'
import PropertyInsights from './pages/PropertyInsights'
import PropertyAlerts from './pages/PropertyAlerts'
import PropertySensors from './pages/PropertySensors'
import AddProperties from './pages/AddPropertiesV2'
import PropertyGroups from './pages/PropertyGroupsV2'
import TemplateManager from './pages/TemplateManager'
import CompletedForms from './pages/CompletedForms'
import Settings from './pages/Settings'
import FormView from './pages/FormView'
import FormList from './pages/FormList'
import CompleteForm from './pages/CompleteForm'
import PropertyIncidents from './pages/PropertyIncidents'
import AlertAreas from './pages/AlertAreas'
import EditProperty from './pages/EditProperty'
import PortfolioOperations from './pages/PortfolioOperations'
import PortfolioClimateProjections from './pages/PortfolioClimateProjections'
import { RiskInsightTable } from './pages/RiskInsightTable'
import PropertyClimateProjection from './pages/PropertyClimateProjection'
import PropertyClimateMitigations from './pages/PropertyClimateMitigations'
import PropertyMitigations from './pages/PropertyMitigations'
import PropertySubsidence from './pages/PropertySubsidence'
import PropertyFloodDamagePricing from './pages/PropertyFloodDamagePricing'
import PortfolioAlerts from './pages/PortfolioAlerts'
import Organisation from './pages/Organisation'
import CreateAlertConfiguration from './pages/CreateAlertConfiguration'
import ClimateAnalytics from './pages/ClimateAnalytics'
import FormTemplate from './pages/templateManager/FormTemplate'
import CreateTemplate from './pages/templateManager/CreateTemplate'
import EditTemplateVersion from './pages/templateManager/EditTemplateVersion'
import CreateTemplateVersion from './pages/templateManager/CreateTemplateVersion'
import PropertyClimateAnalytics from './pages/PropertyClimateAnalytics'

export default {
  Portfolio: {
    path: '/',
    component: Portfolio,
    exact: true,
  },
  Organisation: {
    path: '/organisation',
    component: Organisation,
  },
  ClimateAnalyticsV2: {
    path: '/climate-analytics',
    component: ClimateAnalytics,
  },
  PortfolioOperations: {
    path: '/operations',
    component: PortfolioOperations,
    exact: true,
  },
  PortfolioClimateProjections: {
    path: '/climate-change-projections',
    component: PortfolioClimateProjections,
    exact: false,
  },
  PortfolioAlerts: {
    path: '/alerts',
    component: PortfolioAlerts,
    exact: true,
  },
  Property: {
    path: '/properties/:id',
    component: Property,
    exact: true,
    getPath: id => `/properties/${id}`,
  },
  PropertyGroups: {
    path: '/property-groups',
    component: PropertyGroups,
    exact: true,
  },
  AddProperties: {
    path: '/add-properties',
    component: AddProperties,
  },
  EditProperty: {
    path: '/properties/:id/edit',
    component: EditProperty,
    getPath: id => `/properties/${id}/edit`,
  },
  RiskInsights: {
    path: '/properties/:id/risk-insights',
    component: PropertyInsights,
    getPath: id => `/properties/${id}/risk-insights`,
  },
  PropertyIncidents: {
    path: '/properties/:id/incidents',
    component: PropertyIncidents,
    getPath: id => `/properties/${id}/incidents`,
  },
  PropertyClimateMitigations: {
    path: '/properties/:id/climate-mitigations',
    component: PropertyClimateMitigations,
    getPath: id => `/properties/${id}/climate-mitigations`,
  },
  PropertyMitigations: {
    path: '/properties/:id/mitigations',
    component: PropertyMitigations,
    getPath: id => `/properties/${id}/mitigations`,
  },
  PropertyClimateAnalytics: {
    path: '/properties/:id/climate-analytics',
    component: PropertyClimateAnalytics,
    getPath: id => `/properties/${id}/climate-analytics`,
  },
  PropertyFloodDamagePricing: {
    path: '/properties/:id/flood-damage-pricing',
    component: PropertyFloodDamagePricing,
    getPath: id => `/properties/${id}/flood-damage-pricing`,
  },
  // PropertyHeatStress: {
  //   path: '/properties/:id/heat-stress',
  //   component: PropertyHeatStress,
  //   getPath: id => `/properties/${id}/heat-stress`,
  // },
  Alerts: {
    path: '/properties/:id/alerts',
    component: PropertyAlerts,
    getPath: id => `/properties/${id}/alerts`,
  },
  Sensors: {
    path: '/properties/:id/sensors',
    component: PropertySensors,
    getPath: id => `/properties/${id}/sensors`,
  },
  ClimateProjection: {
    path: '/properties/:id/climate-projections',
    component: PropertyClimateProjection,
    getPath: id => `/properties/${id}/climate-projections`,
  },
  PropertySubsidence: {
    path: '/properties/:id/subsidence',
    component: PropertySubsidence,
    getPath: id => `/properties/${id}/subsidence`,
  },
  PropertyList: {
    path: '/properties',
    component: PropertyList,
    exact: true,
  },
  CreateUsers: {
    path: '/users/create',
    component: CreateUsers,
  },
  AssignUsers: {
    path: '/users/assign',
    component: AssignUsers,
    getPath: ids => ({ pathname: `/users/assign`, state: { ids } }),
  },
  ListUsers: {
    path: '/users/list',
    component: ListUsers,
  },
  AlertSubscriptions: {
    path: '/alerts/subscriptions',
    component: AlertSubscriptions,
    exact: true,
  },
  AlertAreas: {
    path: '/alerts/subscriptions/:subscriptionId/areas',
    component: AlertAreas,
    getPath: id => `/alerts/subscriptions/${id}/areas`,
  },
  AlertConfigurations: {
    path: '/alerts/configurations',
    component: AlertConfigurations,
    exact: true,
  },
  CreateAlertConfiguration: {
    path: '/alerts/configurations/create',
    component: CreateAlertConfiguration,
    exact: true,
  },
  AssignConfigurations: {
    path: '/alerts/configurations/:id/assign',
    component: AssignConfigurations,
    getPath: id => `/alerts/configurations/${id}/assign`,
    exact: true,
  },
  TemplateManager: {
    path: '/templates',
    component: TemplateManager,
    getPath: state => ({ pathname: '/templates', state }),
    exact: true,
  },
  CreateTemplate: {
    path: '/templates/new',
    component: CreateTemplate,
    exact: true,
  },
  FormTemplate: {
    path: '/templates/:id',
    component: FormTemplate,
    getPath: id => `/templates/${id}`,
    exact: true,
  },
  CreateFormTemplateVersion: {
    path: '/templates/:id/versions/new',
    component: CreateTemplateVersion,
    getPath: id => `/templates/${id}/versions/new`,
    exact: true,
  },
  EditFormTemplateVersion: {
    path: '/templates/:id/versions/:versionId',
    component: EditTemplateVersion,
    getPath: (id, versionId) => `/templates/${id}/versions/${versionId}`,
    exact: true,
  },
  CompletedForms: {
    path: '/forms',
    component: CompletedForms,
    getPath: state => ({ pathname: '/forms', state }),
  },
  CompleteForm: {
    path: '/complete-form',
    component: CompleteForm,
    getPath: state => ({ pathname: '/complete-form', state }),
  },
  FormView: {
    path: '/form-view',
    component: FormView,
    getPath: state => ({ pathname: '/form-view', state }),
  },
  FormList: {
    path: '/form-list',
    component: FormList,
    getPath: state => ({ pathname: '/form-list', state }),
  },
  Login: {
    path: '/login',
    component: Login,
  },
  Settings: {
    path: '/settings',
    component: Settings,
  },
  PortfolioRiskInsights: {
    path: '/risk-insights',
    component: RiskInsightTable,
  },
}
