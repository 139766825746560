import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import capitalise from 'balkerne-fn/capitalise'
import {
  Box,
  Button,
  Grid,
  Stack,
  FormControlLabel,
  Switch,
  Slider,
  InputAdornment,
  Tooltip,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material'
import {
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import CardDataGrid from '../components/CardDataGrid'
import { useAlertAreasWithLatestMetrics, useAlertAreaTypes } from '../hooks/alerts'
import AreaMap, { PinMarker, SelectedAreaSource, fitMapToBounds } from './AreaMap'
import { MapRef } from 'react-map-gl'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { useThresholdRecommendation } from '../hooks/analytics'
import { searchLocation } from './userManagement/DrawMap'
import { useSnackbar } from 'notistack'
import { Area } from '../types/areas'

const AreaSelector = ({ onSelect, alertTypeId, types }) => {
  const { enqueueSnackbar } = useSnackbar()
  const typeId = Number.isInteger(alertTypeId) ? alertTypeId : undefined
  const INIT_LNG = -0.1442
  const INIT_LAT = 51.5072
  const PAGE_SIZE = 25
  const [selectedArea, setSelectedArea] = useState<any>(null)
  const [page, setPage] = useState(0)
  const mapRef = useRef<MapRef | null>(null)
  const [useRadius, setIsUseRadius] = useState(true)
  const [radius, setRadius] = useState(15)
  const [delayedRadius, setDelayedRadius] = useState(radius)
  const { data: areaTypes } = useAlertAreaTypes({ alert_type_id: typeId })
  const [pin, setPin] = useState({
    latitude: INIT_LAT + 0.03,
    longitude: INIT_LNG,
  })
  const searchRef = useRef<any>()
  const { data: riverGaugeThresholdRecommendation, isLoading: thresholdReccLoading } = useThresholdRecommendation(
    selectedArea?.ref,
    {
      enabled: Boolean(selectedArea?.type.name === 'river_gauge' && selectedArea?.ref !== undefined),
    },
  )
  const [accumulatedAreas, setAccumulatedAreas] = useState<Area[]>([])

  useEffect(() => {
    /*
		  This will prevent the client from sending too many requests to the server
			when the user is dragging the slider.
		*/
    const timeout = setTimeout(() => {
      setDelayedRadius(radius)
    }, 500)
    return () => clearTimeout(timeout)
  }, [radius])

  const coordinates = useMemo(() => {
    if (!useRadius) {
      return {}
    }
    return {
      lat: pin.latitude,
      lng: pin.longitude,
      radius: delayedRadius * 1000, // convert to meters
    }
  }, [useRadius, delayedRadius, pin])

  const [args, setArgs] = useState({})
  const { data: areasWithMetrics, isFetching: isAreasWithMetricsFetching } = useAlertAreasWithLatestMetrics(
    {
      limit: PAGE_SIZE,
      offset: page * PAGE_SIZE,
      alert_type_id: typeId,
      include_geometry: true,
      ...coordinates,
      ...args,
    },
    types[typeId],
  )

  useEffect(() => {
    // Reset list of areas, page and selected area
    setAccumulatedAreas([])
    setPage(0)
    setSelectedArea(null)
  }, [typeId, pin, delayedRadius, args])

  useEffect(() => {
    if (isAreasWithMetricsFetching || !areasWithMetrics) return

    setAccumulatedAreas(prevData => {
      if (!areasWithMetrics || areasWithMetrics.length === 0) return prevData

      const existingIds = new Set(prevData.map(area => area.id))
      const newAreas = areasWithMetrics.filter(area => !existingIds.has(area.id))
      return [...prevData, ...newAreas]
    })
  }, [areasWithMetrics, isAreasWithMetricsFetching])

  const columns = useMemo(
    () => [
      {
        field: 'ref',
        headerName: 'Reference',
        hideable: false,
        flex: 1,
        minWidth: 50,
        filterOperators: getGridStringOperators().filter(op => op.value === 'contains'),
      },
      {
        field: 'type',
        headerName: 'Type',
        hideable: false,
        type: 'singleSelect',
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
        valueGetter: params => capitalise(params.row.type.name.split('_').join(' ')),
        valueOptions:
          areaTypes?.map(areaType => ({
            value: areaType.type.name,
            label: capitalise(areaType.type.name.split('_').join(' ')),
          })) ?? [],
      },
      {
        field: 'subtype',
        hideable: false,
        headerName: 'Subtype',
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
        valueGetter: (params: { row: { subtype: { name: string } } }) =>
          capitalise(params.row.subtype.name.split('_').join(' ')),
      },
      {
        field: 'latest_metric',
        hideable: false,
        headerName: 'Latest Metric',
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
        valueGetter: (params: { row: { metric: { value: any } } }) => {
          const { value } = params.row.metric || {}
          if (!value) return ''

          return `${value}  ${types?.[typeId]?.units}`
        },
      },
      {
        field: 'metric_status',
        hideable: false,
        flex: 1,
        headerName: 'Status',
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
        renderCell: (params: { row: { metric: { value: any; stale: boolean } } }) => {
          const { value, stale } = params.row.metric || {}
          if (value) {
            if (stale) {
              return (
                <>
                  <PriorityHighIcon />
                  More than 6 hours ago
                </>
              )
            } else return <CheckIcon />
          } else
            return (
              <>
                <CloseIcon />
              </>
            )
        },
      },
    ],
    [areaTypes],
  )

  const handleFilterModelChange = args => {
    if (args.items.length === 0) {
      setArgs({})
    } else {
      let { columnField, value } = args.items[0]
      if (columnField === 'ref') columnField = 'reference'
      setArgs({ [columnField]: value })
    }
    setPage(0)
  }

  const handleRowSelect = area => {
    setSelectedArea(area)
    const map = mapRef.current
    if (map) {
      fitMapToBounds(map, area.geometry)
    }
    onSelect(area.id)
  }

  const handleSetRadius = radius => {
    setRadius(radius)
    setPage(0)
  }

  const handleSetIsUseRadius = checked => {
    setIsUseRadius(checked)
    setPage(0)
  }

  const goToPin = () => {
    if (mapRef?.current) {
      const map = mapRef.current
      map.flyTo({
        center: [pin.longitude, pin.latitude],
        zoom: 8,
        speed: 2.5,
      })
    }
  }

  const CustomToolbar = useMemo(
    () => () =>
      (
        <GridToolbarContainer>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      ),
    [],
  )

  const onSearchSuccess = (features: any) => {
    if (features.length > 0) {
      const { geometry, center } = features[0]
      setPin({ latitude: geometry.coordinates[1], longitude: geometry.coordinates[0] })
      mapRef?.current?.setCenter(center)
      mapRef?.current?.setZoom(13)
    } else {
      enqueueSnackbar('No matching addresses found', { variant: 'warning' })
    }
  }

  const onSearchError = (error: any) => {
    console.error(error)
    enqueueSnackbar('Error occured when searching for address.', { variant: 'error' })
  }

  const handleSearch = () => {
    const searchValue = searchRef?.current?.value
    searchLocation(searchValue, onSearchSuccess, onSearchError)
  }

  const handleClickMap = useCallback(event => {
    const feature = event.features && event.features[0]

    if (feature) {
      const map = mapRef.current
      fitMapToBounds(map, feature.geometry)
      handleRowSelect({
        id: feature.properties.id,
        ref: feature.properties.ref,
        type: JSON.parse(feature.properties.type),
        subtype: JSON.parse(feature.properties.subtype),
        metric: feature.properties.metric ? JSON.parse(feature.properties.metric) : null,
        geometry: feature.geometry,
      })
    }
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              paddingTop: 1,
              paddingBottom: 2,
            }}>
            {selectedArea ? (
              <>
                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  <strong>Reference:</strong> {selectedArea.ref}
                </Typography>

                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  <strong>Type:</strong> {capitalise(selectedArea.type.name.split('_').join(' '))}
                </Typography>

                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  <strong>Subtype:</strong> {capitalise(selectedArea.subtype.name.split('_').join(' '))}
                </Typography>

                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  <strong>Latest Metric:</strong>{' '}
                  {selectedArea.metric?.value
                    ? `${selectedArea.metric.value} ${types?.[typeId]?.units}`
                    : 'Not available'}
                </Typography>
                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  <strong>Recommended Threshold:</strong>{' '}
                  {typeId && thresholdReccLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <>
                      {riverGaugeThresholdRecommendation
                        ? `${riverGaugeThresholdRecommendation.typical_high.toFixed(2)} ${types?.[typeId]?.units}`
                        : 'Not available'}
                    </>
                  )}
                </Typography>

                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedArea(null)
                    onSelect(null)
                  }}>
                  Clear
                </Button>
              </>
            ) : (
              <Typography variant="body1" fontStyle="italic">
                No Area Selected
              </Typography>
            )}
          </Box>
        </Grid>
        <TextField
          sx={{ width: '100%', marginBottom: 1 }}
          inputRef={searchRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Search">
                  <IconButton aria-label="search" onClick={handleSearch} edge="end">
                    <SearchOutlinedIcon color={'action'} />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          placeholder="Enter a postcode or address"
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <FormControlLabel
            sx={{ m: 0, pr: 2 }}
            label="Only within radius (km)"
            control={<Switch checked={useRadius} onChange={e => handleSetIsUseRadius(e.target.checked)} />}
          />
          <Box width={300}>
            <Slider
              valueLabelDisplay="auto"
              aria-label="default"
              disabled={!useRadius}
              value={radius}
              min={1}
              max={200}
              onChange={(e, value) => handleSetRadius(value)}
            />
          </Box>
          <Button variant="contained" onClick={goToPin} disabled={!useRadius}>
            Focus Pin
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CardDataGrid
          loading={isAreasWithMetricsFetching}
          onCellClick={params => handleRowSelect(params.row)}
          rowCount={Number.MAX_VALUE}
          sx={{
            height: 400,
            '.MuiTablePagination-displayedRows': {
              display: 'none',
            },
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          columns={columns}
          rows={areasWithMetrics}
          filterMode="server"
          density="compact"
          onFilterModelChange={handleFilterModelChange}
          rowsPerPageOptions={[PAGE_SIZE]}
          pagination
          page={page}
          onPageChange={setPage}
          paginationMode="server"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AreaMap
          ref={mapRef}
          onMapClick={handleClickMap}
          initLng={INIT_LNG}
          initLat={INIT_LAT}
          areas={accumulatedAreas}>
          <PinMarker
            lat={pin.latitude}
            lng={pin.longitude}
            radius={radius}
            onDragEnd={(lat, lng) => setPin({ latitude: lat, longitude: lng })}
          />
          {selectedArea && <SelectedAreaSource area={selectedArea} />}
        </AreaMap>
      </Grid>
    </Grid>
  )
}

export default AreaSelector
