import { Container, Grid, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ArrowTopRight from '../../images/icons/ArrowTopRight.svg'
import { SurfaceWaterType } from '../../hooks/mapbox'
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal, Key } from 'react'

const historicalFloods = {
  legends: [
    {
      color: '#8625CB',
      label: 'Historical Floods',
      opacity: 0.75,
    },
  ],
  tooltip: null,
}
const riverAndSea = {
  title: 'Flood risk from rivers or sea',
  legends: [
    {
      color: '#A10202',
      label: 'High',
      opacity: 0.66,
    },
    {
      color: '#C29E00',
      label: 'Medium',
      opacity: 0.66,
    },
    {
      color: '#8625CB',
      label: 'Low',
      opacity: 0.66,
    },
  ],
  tooltip: {
    id: 'riverAndSea',
    title: 'Each year, this area has a chance of flooding according to its risk level',
    content: ['High: greater than 3.3%', 'Medium: between 1% and 3.3%', 'Low: between 0.1% and 1%'],
  },
}

const surfaceWaterExtent = {
  title: 'Flood risk from surface water',
  legends: [
    {
      color: '#C825CB',
      label: 'High',
      opacity: 0.9,
    },
    {
      color: '#C825CB',
      label: 'Medium',
      opacity: 0.5,
    },
    {
      color: '#C825CB',
      label: 'Low',
      opacity: 0.25,
    },
  ],
  tooltip: {
    id: 'surfaceWaterExtent',
    title: 'Each year, this area has a chance of flooding according to its risk level:',
    content: ['High: greater than 3.3%', 'Medium: between 1% and 3.3%', 'Low: between 0.1% and 1%'],
  },
}

const surfaceWaterDepth = level => {
  var obj = {
    title: `Flood risk: water depth in a ${level} risk scenario`,
    legends: [
      {
        color: '#20AAF7',
        label: '+900 mm',
        opacity: 0.75,
      },
      {
        color: '#20AAF7',
        label: '300-900 mm',
        opacity: 0.5,
      },
      {
        color: '#20AAF7',
        label: '>300 mm',
        opacity: 0.25,
      },
    ],
    tooltip: {
      id: 'surfaceWaterDepth',
      title: 'Flood depth measured in millimetres (mm)',
      content: [''],
    },
  }
  return obj
}

const surfaceWaterVelocity = level => {
  var obj = {
    title: `Flood risk: water velocity in a ${level} risk scenario`,
    legends: [
      {
        color: '#DC6C3C',
        label: '+0.25 m/s',
        opacity: 0.75,
      },
      {
        color: '#DC6C3C',
        label: '0.13 m/s',
        opacity: 0.5,
      },
      {
        color: '#DC6C3C',
        label: '>0.09 m/s',
        opacity: 0.25,
      },
      {
        color: '',
        label: 'Direction',
        opacity: 1,
        icon: <img src={ArrowTopRight} />,
      },
    ],
    tooltip: {
      id: 'surfaceWaterVelocity',
      title: 'Flood velocity measured in metres per second (m/s)',
      content: [''],
    },
  }
  return obj
}

export function MapLegendWidget({
  showFloods,
  showRiverSea,
  showSurfaceWater,
  surfaceWaterType,
  overMap = true,
} : {
  showFloods?: boolean,
  showRiverSea?: boolean,
  showSurfaceWater?: boolean,
  surfaceWaterType?: any,
  overMap: boolean
}) {
  var surfaceWater
  if (showSurfaceWater) {
    switch (surfaceWaterType) {
      case SurfaceWaterType.DEPTH_HIGH:
        surfaceWater = surfaceWaterDepth('high')
        break
      case SurfaceWaterType.DEPTH_MEDIUM:
        surfaceWater = surfaceWaterDepth('medium')
        break
      case SurfaceWaterType.DEPTH_LOW:
        surfaceWater = surfaceWaterDepth('low')
        break
      case SurfaceWaterType.VELOCITY_HIGH:
        surfaceWater = surfaceWaterVelocity('high')
        break
      case SurfaceWaterType.VELOCITY_MEDIUM:
        surfaceWater = surfaceWaterVelocity('medium')
        break
      case SurfaceWaterType.VELOCITY_LOW:
        surfaceWater = surfaceWaterVelocity('low')
        break
      default:
        surfaceWater = surfaceWaterExtent
    }
  }
  const legends = [
    { isShowing: showFloods, type: historicalFloods },
    { isShowing: showRiverSea, type: riverAndSea },
    { isShowing: showSurfaceWater, type: surfaceWater },
  ]

  return (
    <Box
      sx={{
        maxWidth: overMap ? '240px' : '400px',
        position: overMap ? 'absolute' : 'relative',
        zInndex: 1,
        left: overMap ? 30 : null,
        bottom: overMap ? 40 : null,
      }}>
      {legends.map((legend, index) => {
        if (legend.isShowing) {
          return Legend(legend.type, index, overMap)
        }
        return
      })}
    </Box>
  )
}

function Legend(legend: { title: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; tooltip: any; legends: any[] }, key: Key | null | undefined, overMap: boolean) {
  return (
    <Container
      key={key}
      sx={{
        padding: 1,
        backgroundColor: 'rgba(255,255,255,0.85)',
        paddingTop: overMap ? 1 : 0,
        borderRadius: '6px',
        marginTop: overMap ? 1 : 0,
      }}>
      {legend.title && (
        <h4>
          {legend.title} <TooltipForMapLegend tooltip={legend.tooltip} />
        </h4>
      )}
      <Grid container rowSpacing={1} direction="row" justifyContent="center" alignItems="center">
        {legend.legends.map(label => {
          return (
            <Grid item xs={overMap ? 12 : 4} sx={{ display: 'flex' }}>
              <div
                style={{
                  width: 30,
                  height: 20,
                  backgroundColor: label.color,
                  opacity: label.opacity,
                  borderRadius: 6,
                }}>
                {label.icon}
              </div>
              <Typography variant="body2" sx={{ marginLeft: 2 }}>
                {label.label}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}

function TooltipForMapLegend({ tooltip }) {
  return (
    <Tooltip title={<CustomTooltipContent tooltip={tooltip} />} placement="top-end">
      <a>
        <i className="fa fa-info-circle" style={{ color: '#283e59', opacity: 0.8 }} />
      </a>
    </Tooltip>
  )
}

function CustomTooltipContent({ tooltip }) {
  return (
    <Box sx={{ color: 'white', fontWeight: 'bold' }}>
      {tooltip.title && <Typography variant="body2">{tooltip.title}</Typography>}
      <Box sx={{ gap: 1, mt: 2, display: 'flex', flexDirection: 'column' }}>
        {tooltip.content.map((item, index) => (
          <Typography variant="body2" key={index}>
            {item}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}
