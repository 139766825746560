import { jsPDF } from 'jspdf'
import FormatDate from './FormatDate'
import Logo from './forms/Logo.png'

const exportToPDF = formInstance => {
  console.log(formInstance)
  if (formInstance === undefined) return
  const property = formInstance.property
  const organisation = formInstance.organisation
  console.log(formInstance.comments)
  const doc = new jsPDF()

  var xStart = 20
  var yStart = 20
  var lineHeight = 10

  doc.text('Logo', xStart, yStart, {
    maxWidth: 180,
  })
  var img = new Image()
  img.src = './forms/Logo.png'
  doc.addImage(Logo, 'PNG', 16, 14, 35, 17)

  doc.setFontSize(18)
  doc.setFont('Roboto-Regular', 'bold')
  yStart += 20

  let formTitle = formInstance.template.template.title
  if (property) `${formTitle} at ${property.name}`
  doc.text(`${formTitle}`, xStart, yStart, {
    maxWidth: 180,
  })

  doc.setFont('Roboto-Regular', 'normal')
  doc.setFontSize(12)
  doc.setTextColor(100)
  yStart += lineHeight
  var subtitle = ''
  if (formInstance.template.subcategory_id !== null) {
    subtitle = formInstance.template.template.title + ' - '
  }
  subtitle = subtitle + formInstance.template.template.type.title
  doc.text(subtitle, xStart, yStart, {
    maxWidth: 180,
  })
  doc.setTextColor(0)

  yStart += lineHeight
  doc.text('Form Title', xStart, yStart, {
    maxWidth: 180,
  })
  doc.text(formInstance.template.template.title, 190, yStart, { align: 'right' })

  yStart += lineHeight
  if (property) {
    doc.text('Property', xStart, yStart, {
      maxWidth: 180,
    })
    doc.text(property.name, 190, yStart, { align: 'right' })
  } else {
    doc.text('Organisation', xStart, yStart, {
      maxWidth: 180,
    })
    doc.text(organisation.organisation_name, 190, yStart, { align: 'right' })
  }

  yStart += lineHeight
  doc.text('Submitted On', xStart, yStart, {
    maxWidth: 180,
  })
  doc.text(FormatDate(formInstance.updated_at), 190, yStart, { align: 'right' })

  yStart += lineHeight
  doc.text('Author', xStart, yStart, {
    maxWidth: 180,
  })
  doc.text(formInstance.assignee?.full_name ?? 'No Assignee', 190, yStart, { align: 'right' })

  yStart += lineHeight
  doc.text('Location', xStart, yStart, {
    maxWidth: 180,
  })
  var location = ''
  if (property) {
    if (property.address_street !== null) {
      location = property.address_street
    }
    if (property.address_town !== null) {
      location = location + ', ' + property.address_town
    }
    if (property.address_county !== null) {
      location = location + ', ' + property.address_county
    }
    if (property.address_postcode !== null) {
      location = location + ', ' + property.address_postcode
    }
    if (property.address_country !== null) {
      location = location + ', ' + property.address_country
    }
  } else {
    if (organisation.address !== null) {
      location = organisation.address
    }
    if (organisation.postcode !== null) {
      location = location + ', ' + organisation.postcode
    }
  }
  doc.text(location, 190, yStart, { align: 'right' })
  // End Form Summary Page

  // Start Form Data Pages
  yStart = 20
  doc.addPage('a4', 'p')
  doc.setDrawColor(230, 230, 230)
  doc.setLineWidth(8)
  doc.line(16, yStart, 190, yStart)
  doc.setFont('Roboto-Regular', 'bold')

  doc.text(formInstance.template.template.title, xStart, 21.2, {
    maxWidth: 180,
  })
  yStart += 15

  var index = 1
  // loop through form data
  Object.keys(formInstance.template.data.properties).map(property_key => {
    doc.setTextColor(0)
    if (formInstance.template.data.properties[property_key].type == 'object') {
      if (yStart >= 260) {
        doc.addPage()
        yStart = 20
      }

      const section = formInstance.template.data.properties[property_key]
      var textWidth = Math.round(doc.getTextWidth(`${index}. ${section.title}`))
      // this is a section
      doc.setFontSize(15)
      doc.text(`${index}. ${section.title}`, xStart, yStart, {
        maxWidth: 180,
      })
      if (textWidth >= 180) {
        var lineCount = textWidth / 180
        lineCount = Math.ceil(lineCount)

        for (var i = 1; i <= lineCount; i++) {
          yStart += 5
        }
      }
      doc.setFontSize(12)
      doc.setDrawColor(230, 230, 230)

      var index2 = 1
      Object.keys(section.properties).map(element_key => {
        if (yStart >= 260) {
          doc.addPage()
          yStart = 20
        }
        doc.setTextColor(0)
        yStart += 8
        const element = section.properties[element_key]
        var textWidth = Math.round(doc.getTextWidth(`${index}.${index2} ${element.title}`))

        doc.text(`${index}.${index2} ${element.title}`, 20, yStart, {
          maxWidth: 180,
        })
        if (textWidth >= 180) {
          var lineCount = textWidth / 180
          lineCount = Math.ceil(lineCount)

          for (var i = 1; i <= lineCount; i++) {
            yStart += 5
          }
        }
        doc.setTextColor(0, 0, 128)
        yStart += 5
        var element_value = formInstance.data[property_key][element_key]
        if (element_value == undefined) element_value = ''

        if ((element.format == 'date-time' || element.format == 'date') && element_value !== '') {
          element_value = FormatDate(element_value)
        }
        if (element.type == 'boolean') {
          console.log(element_value)
          switch (element_value) {
            case true:
              element_value = 'Yes'
              break
            case false:
              element_value = 'No'
              break
          }
        }
        var textWidth = Math.round(doc.getTextWidth(element_value))

        doc.text(element_value, 20, yStart, {
          maxWidth: 180,
        })
        if (textWidth >= 180) {
          var lineCount = textWidth / 180
          lineCount = Math.ceil(lineCount)

          for (var i = 1; i <= lineCount; i++) {
            yStart += 5
          }
        }
        yStart += 6
        doc.setDrawColor(230, 230, 230)
        doc.setLineWidth(0.6)
        doc.line(18, yStart, 190, yStart)
        index2 += 1
      })
    } else {
      if (yStart >= 260) {
        doc.addPage()
        yStart = 20
      }
      // this is a form element without section

      const element = formInstance.template.data.properties[property_key]
      doc.setFontSize(12)
      doc.setTextColor(0)
      doc.text(`${index}. ${element.title}`, 20, yStart, {
        maxWidth: 100,
      })
      doc.setTextColor(0, 0, 128)
      yStart += 5
      var element_value = formInstance.data[property_key]
      console.log(element_value)
      if (element_value == undefined) element_value = ''

      if ((element.format == 'date-time' || element.format == 'date') && element_value !== '') {
        element_value = FormatDate(element_value)
      }
      if (element.type == 'boolean') {
        console.log('test')
        switch (element_value) {
          case true:
            element_value = 'Yes'
            break
          case false:
            element_value = 'No'
            break
        }
      } else if (element.type == 'number') {
        element_value = String(element_value)
      }
      console.log(element)
      doc.text(element_value, 20, yStart, {
        maxWidth: 100,
      })
      yStart += 6
      doc.setDrawColor(230, 230, 230)
      doc.setLineWidth(0.6)
      doc.line(18, yStart, 190, yStart)
    }
    yStart += 15
    index += 1
  })
  const saveFileAs = `${formInstance.template.template.title}-${FormatDate(formInstance.updated_at)}`
  doc.save(`${saveFileAs}.pdf`)
}

export default exportToPDF
