import { useEffect, useState } from 'react'
import Form from '@rjsf/mui'
import Badge from 'react-bootstrap/Badge'
import Button from '@mui/material/Button'
import FormComment from './FormComment'
import { useHistory } from 'react-router-dom'
import FileWidget from '../widgets/FileWidget'
import DateTimeWidget from '../widgets/DateTimeWidget'
import api from 'balkerne-core/api'
import exportToPDF from './exportToPDF'
import { useFormInstance } from '../../hooks/forms'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useLocations } from '../../hooks/locations'
import { useSnackbar } from 'notistack'
import siteMap from '../../siteMap'
import c from './DynamicForm.module.scss'
import './DynamicForm.css'
import { RootState } from '../../store'
import { FieldProps, RegistryFieldsType, RegistryWidgetsType, WidgetProps } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'

export const DynamicForm = props => {
  const propertyID = props.propertyID
  const { data: locations } = useLocations()
  const [assignedProperty, setAssignedProperty] = useState(propertyID ? propertyID : -1)
  const user = useSelector((state: RootState) => state.auth.user)
  const queryClient = useQueryClient()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const { data: formInstance } = useFormInstance(props.formInstanceId)
  const [template, setTemplate] = useState<any>(null)
  const [ui_schema, setUi_Schema] = useState<any>(null)
  const [formData, setFormData] = useState({})
  const [formdisabled, setFormDisabled] = useState(props.readonly ? true : false)
  const [alertDisplay, setAlertDisplay] = useState('none')
  const { enqueueSnackbar } = useSnackbar()
  const [formComments, setFormComments] = useState([])
  const [initFlag, setInitFlag] = useState(true)
  const [allFormPages, setAllFormPages] = useState({})
  const [instanceTags, setInstanceTags] = useState({})
  const [paths, setPaths] = useState({
    1: {
      title: props.previousPathTitle,
      onClick: () =>
        history.push({
          pathname: props.previousPathName,
          state: props.previousPathState,
        }),
    },
  })
  const [discardModalOpen, setDiscardModalOpen] = useState(false)
  let disableform = formdisabled

  useEffect(() => {
    // Adding a margin-right to all radio buttons
    const radioelements = Array.from(document.getElementsByClassName('field-radio-group'))
    const checkboxelements = Array.from(document.getElementsByClassName('field-boolean'))

    radioelements.map(radioelement => {
      Array.from(radioelement.children).map(children => {
        Array.from(children.getElementsByTagName('input')).map((input: any) => {
          input.style = 'margin-right: 5px'
          input.parentElement.style.fontSize = 15 + 'px'
        })
      })
    })
    // Adding a margin-right to all checkboxes
    checkboxelements.map(checkboxelement => {
      Array.from(checkboxelement.children).map(children => {
        Array.from(children.getElementsByTagName('input')).map((input: any) => {
          input.style = 'margin-right: 10px'
          input.parentElement.style.fontSize = 15 + 'px'
        })
      })
    })
    // Map through each select element and set their width and margins

    // Remove margin from field labels

    const labels = document.getElementsByClassName('control-label')

    if (labels.length > 0) {
      Object.values(labels).map((label: any) => {
        label.style.marginLeft = 0
        label.style.fontSize = 15 + 'px'
      })
    }
  })
  useEffect(() => {
    console.log('updating template and ui_schema')
    console.log(template)
    if (template !== null && ui_schema !== null) {
      console.log('template and ui_schema not null')
      if (loading) {
        console.log('setting loading false')
        setLoading(false)
      }
    }
  }, [template, ui_schema])

  // Check saved form instance use effect
  useEffect(() => {
    console.log(formInstance)
    console.log(props.formInstanceId)
    if (formInstance != null) {
      console.log(formInstance)
      let tempPaths = paths
      tempPaths[2] = {
        title: formInstance.template.template.title,
        onClick: null,
      }
      setPaths({ ...tempPaths })
      try {
        var o = formInstance.template.data
        var o2 = formInstance.template.ui_schema

        if (o && typeof o === 'object') {
          console.log('setting template')
          setTemplate(formInstance.template.data)
        }

        if (o2 && typeof o2 === 'object') {
          console.log('setting ui schema')
          setUi_Schema(formInstance.template.ui_schema)
        }
      } catch (e) {
        console.log('Invalid schemas')
        alert('Invalid JSON Form Schemas')
      }

      if (formInstance.data !== '') setFormData(formInstance.data)

      setFormComments(formInstance.comments)
    }
  }, [formInstance])

  // Handle creation of pages using form sections
  useEffect(() => {
    if (initFlag && formInstance != null) {
      setInitFlag(false)
    }
  }, [initFlag])

  const handleSubmit = async ({ formData }: any, e: any) => {
    if (!disableform) {
      await postFormData(formData)
    } else {
      console.log('This form is disabled. To edit it, please press edit!')
    }
  }

  const postFormData = async formData => {
    const inputs = document.getElementsByTagName('input')
    var filesData = {}
    for (const input of inputs) {
      if (input.type == 'file') {
        const inputID = input.id
        let files: any = document.getElementById(inputID + '-attachedFiles')
        if (files) {
          files = files.value
          if (files !== '') {
            files = JSON.parse(files)
            let finalArr: any = []
            Object.values(files[inputID]).map((fileData: any) => {
              let temp: any = fileData
              delete temp['objectURL']
              finalArr.push(temp)
            })
            files[inputID] = finalArr
            filesData[inputID] = [...files[inputID]]
          }
        }
      }
    }
    formData['attached_files'] = filesData

    console.log('Submitting form..')
    const fetchurl = '/forms/instances/' + formInstance.id
    const data = {
      form_instance: {
        form_state: 'CLOSED',
        data: formData,
      },
      form_log: 'User submitted a draft form.',
      form_action: 'SUBMITTED',
    }
    // Parse instance tags and add to request data

    if (Object.keys(instanceTags).length > 0) {
      var tags: any = []
      Object.keys(instanceTags).map((tag_id: any) => {
        const instanceTagData = {
          tag_id: tag_id,
          value: instanceTags[tag_id],
        }
        tags.push({ ...instanceTagData })
      })
      data['tags'] = [...tags]
    }

    let flag = true
    if (requiresProperty()) {
      if (assignedProperty !== -1) {
        if (propertyID === null) {
          console.log('test2')
          data['assigned_property'] = assignedProperty
        } else {
          console.log('Form is already assigned to a property')
        }
      } else {
        flag = false
        console.log('Incident and Checklist forms must be assigned a property')
        enqueueSnackbar('Incidents and Checklists need to be assigned to a property.', { variant: 'error' })
      }
    }
    if (flag) {
      await api
        .patch(fetchurl, data)
        .then(response => {
          console.log(response.data.data)
          enqueueSnackbar('Form has been successfully submitted. You will be redirected in a few seconds.', {
            variant: 'success',
          })
          redirectHome(false)
        })
        .catch(error => {
          console.error('Error submitting form data: ', error)
          enqueueSnackbar('Error submitting form.', { variant: 'error' })
        })
    }
  }
  const handleSave = async e => {
    if (e != undefined) {
      e.preventDefault()
    }
    const inputs = document.getElementsByTagName('input')
    var filesData = {}
    for (const input of inputs) {
      if (input.type == 'file') {
        const inputID = input.id
        let files: any = document.getElementById(inputID + '-attachedFiles')
        if (files) {
          files = files.value
          if (files !== '') {
            files = JSON.parse(files)
            let finalArr: any = []
            Object.values(files[inputID]).map(fileData => {
              let temp: any = fileData
              delete temp['objectURL']
              finalArr.push(temp)
            })
            files[inputID] = finalArr
            filesData[inputID] = [...files[inputID]]
          }
        }
      }
    }
    formData['attached_files'] = filesData

    const fetchurl = '/forms/instances/' + formInstance.id
    const data = {
      form_instance: {
        form_state: 'INPROGRESS',
        data: formData,
      },
      form_log: 'User edited a draft form.',
      form_action: 'EDITED',
    }
    // Parse instance tags and add to request data

    if (Object.keys(instanceTags).length > 0) {
      var tags: any = []
      Object.keys(instanceTags).map((tag_id: any) => {
        const instanceTagData = {
          tag_id: tag_id,
          value: instanceTags[tag_id],
        }
        tags.push({ ...instanceTagData })
      })
      data['tags'] = [...tags]
    }
    console.log('saving draft form')
    let flag = true
    if (requiresProperty()) {
      if (assignedProperty !== -1) {
        if (propertyID === null) {
          console.log('test2')
          data['assigned_property'] = assignedProperty
        } else {
          console.log('Form is already assigned to a property')
        }
      } else {
        flag = false
        console.log('Incident and Checklist forms must be assigned a property')
        enqueueSnackbar('Incidents and Checklists need to be assigned to a property.', { variant: 'error' })
      }
    }
    if (flag) {
      await api
        .patch(fetchurl, data)
        .then(response => {
          enqueueSnackbar('Form has been saved successfully. You will be redirected in a few seconds.', {
            variant: 'success',
          })
          redirectHome(false)
        })
        .catch(error => {
          console.error('Error saving form data: ', error)
          enqueueSnackbar('Error saving form.', { variant: 'error' })
        })
    }
  }
  // TODO test invalid values for instance tags
  const handleChange = ({ formData }: any, ...props) => {
    //savedformdata.data = JSON.stringify(formData)
    setFormData(formData)
    /* draftdata = formData */
    // Adding a margin-right to all radio buttons
    const radioelements = Array.from(document.getElementsByClassName('field-radio-group'))
    const checkboxelements = Array.from(document.getElementsByClassName('field-boolean'))

    radioelements.map(radioelement => {
      Array.from(radioelement.children).map(children => {
        Array.from(children.getElementsByTagName('input')).map((input: any) => {
          input.style = 'margin-right: 5px'
          input.parentElement.style.fontSize = 15 + 'px'
        })
      })
    })
    // Adding a margin-right to all checkboxes
    checkboxelements.map(checkboxelement => {
      Array.from(checkboxelement.children).map(children => {
        Array.from(children.getElementsByTagName('input')).map((input: any) => {
          input.style = 'margin-right: 10px'
          input.parentElement.style.fontSize = 15 + 'px'
        })
      })
    })

    // Remove margin from field labels
    const labels = document.getElementsByClassName('control-label')

    if (labels.length > 0) {
      Object.values(labels).map((label: any) => {
        label.style.marginLeft = 0
        label.style.fontSize = 15 + 'px'
      })
    }

    // update data tags value
    const tempInstanceTags = { ...instanceTags }
    Object.values(formInstance.template.tags).map((template_tag: any) => {
      console.log('processing ' + template_tag.attribute + ' with tag id ' + template_tag.tag_id)
      const paths = template_tag.attribute.split('_')

      var tempValue = null
      Object.values(paths).map((pathname: any) => {
        if (pathname !== 'root') {
          tempValue = formData[pathname]
        }
      })
      if (tempValue != undefined) tempInstanceTags[template_tag.tag_id] = tempValue
    })
    setInstanceTags({ ...tempInstanceTags })
  }
  const redirectHome = async cancel => {
    if (cancel) {
      homeButton()
    } else {
      const cancelButton: any = document.getElementById('cancel-form')
      const discardButton: any = document.getElementById('discard-form')
      const saveButton: any = document.getElementById('save-draft')
      const submitButton: any = document.getElementById('original-submit')
      if (cancelButton) {
        cancelButton.disabled = true
      }
      if (discardButton) {
        discardButton.disabled = true
      }
      if (saveButton) {
        saveButton.disabled = true
      }
      if (submitButton) {
        submitButton.disabled = true
      }
      await delayForXSeconds(1800)
      queryClient.invalidateQueries(`/forms/instances/${formInstance.id}`)
      queryClient.invalidateQueries(`/forms/instances`)
      homeButton()
    }
  }

  const handleDiscardChanges = async () => {
    const fetchurl = '/forms/instances/' + formInstance.id
    await api
      .delete(fetchurl)
      .then(response => {
        console.log(response)
        enqueueSnackbar('Successfully discarded the template.', { variant: 'success' })
        redirectHome(false)
      })
      .catch(error => {
        console.error('Error discarding the form: ', error)
        enqueueSnackbar('Error discarding form.', { variant: 'error' })
      })
  }

  const discardChangesModal = () => {
    const modal: any = document.getElementById('discardChangesModal')
    if (modal) {
      modal.style.display = 'block'
    }
  }

  const onClickClose = () => {
    const modal: any = document.getElementById('discardChangesModal')
    if (modal) {
      modal.style.display = 'none'
    }
  }

  const displayButtons = () => {
    if (formdisabled) {
      if (formInstance != null && formInstance.form_state === 'CLOSED') {
        return (
          <div className={c.buttonsForm}>
            <Button
              variant="contained"
              onClick={() => history.go(-1)}
              className={c.buttonOthers}
              style={{ marginRight: 'auto' }}
              type="button">
              Back
            </Button>
          </div>
        )
      } else if (formInstance != null && formInstance.form_state === 'INPROGRESS') {
        return (
          <div className={c.buttonsForm}>
            <Button
              onClick={() => history.go(-1)}
              className={c.buttonOthers}
              style={{ marginRight: 'auto' }}
              type="button"
              variant="contained">
              Back
            </Button>
            <Button variant="contained" className={c.buttonOthers} type="button" onClick={e => editCompletedForm(e)}>
              Edit
            </Button>
          </div>
        )
      }
    } else {
      return (
        <div className={c.buttonsForm}>
          <div className={c.buttonSmallGroup}>
            <div className={c.button1}>
              <Button
                variant="contained"
                onClick={() => redirectHome(true)}
                type="button"
                id="cancel-form"
                className={c.buttonResponsive}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="error"
                className={c.buttonResponsive}
                onClick={() => setDiscardModalOpen(true)}
                type="button"
                id="discard-form">
                Discard
              </Button>
            </div>
          </div>
          <div className={c.buttonSmallGroup}>
            <div className={c.button1}>
              <Button
                className={c.buttonResponsive}
                color="success"
                variant="contained"
                onClick={e => handleSave(e)}
                id="save-draft">
                Save as Draft
              </Button>
            </div>
            <div>
              <Button
                className={c.buttonResponsive}
                color="success"
                variant="contained"
                id="original-submit"
                type="submit">
                Submit
              </Button>
            </div>
          </div>
        </div>
      )
    }
  }

  // Delay for x seconds function
  async function delayForXSeconds(time) {
    console.log('start timer')
    await new Promise(resolve => setTimeout(resolve, time))
    console.log('after' + time / 1000 + ' second(s)')
  }

  const editCompletedForm = e => {
    e.preventDefault()
    Object.values(document.getElementsByTagName('input')).map(inputelement => {
      if (inputelement.type == 'datetime-local') {
        inputelement.disabled = false
      }
    })

    setFormDisabled(false)
  }
  const dismissNotification = () => {
    setAlertDisplay('none')
  }
  const submitComment = async () => {
    let comment: any = document.getElementById('comment_textbox')
    if (comment) {
      comment = comment.value
      if (comment.length > 0) {
        const fetchurl = '/forms/instances/comments'
        const data = {
          instance_id: formInstance.id,
          author_id: user.id,
          comment: comment,
        }
        await api.post(fetchurl, data).then(response => {
          queryClient.invalidateQueries(`/forms/instances/${formInstance.id}`)
        })
      }
    }
  }

  const onError = errors => {
    let pageDiv: any = document.getElementById(`page-${Object.values(allFormPages).length}`)
    if (pageDiv != undefined || pageDiv != null) {
      pageDiv.style.display = 'block'
    }
    console.error('Form validation failed', errors)
  }
  const homeButton = () => {
    if (propertyID != null) {
      if (user.portfolio_type === 'Single') {
        history.push({
          pathname: '/',
          state: {
            propertyId: propertyID,
          },
        })
      } else {
        history.push(siteMap.Property.getPath(propertyID))
      }
    } else {
      // redirect to portfolio dashboard if no id is provided
      history.push(siteMap.Portfolio.path)
    }
  }
  const requiresProperty = () => {
    const templateTypeID = formInstance.template.template.type.id
    switch (templateTypeID) {
      case 2: // Incident Template Type
        return true
      case 7: // CHecklist Template Type
        return true
      default:
        return false
    }
  }
  useEffect(() => {
    console.log('Assigned Property:', assignedProperty)
  }, [assignedProperty])

  const propertySelection = () => {
    if (requiresProperty()) {
      if (propertyID === null && locations !== null) {
        return (
          <Box className="box-container">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select A Property</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={assignedProperty}
                label="Select A Property"
                onChange={e => setAssignedProperty(e.target.value)}>
                {locations.map(location => {
                  return (
                    <MenuItem key={location.id} value={location.id}>
                      {location.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        )
      }
    }
  }
  if (!loading) {
    const CustomTitleField = ({ title, required }: FieldProps) => {
      const legend = required ? title + '*' : title

      if (title === template.title) {
        return <></>
      } else {
        return (
          <div>
            <h5 style={{ fontSize: 1.25 + 'rem' }}>{legend}</h5>
          </div>
        )
      }
    }

    const CustomDescriptionField = ({ description }: FieldProps) => {
      if (description !== undefined) {
        if (description === template.description) {
          return <></>
        } else {
          return (
            <>
              <div style={{ marginBottom: 7, fontSize: 15 }}>{description}</div>
            </>
          )
        }
      } else {
        return <></>
      }
    }
    const fields: RegistryFieldsType = {
      TitleField: CustomTitleField,
      DescriptionField: CustomDescriptionField,
    }

    const widgets: RegistryWidgetsType = {
      FileWidget: (props: WidgetProps) => (
        <FileWidget
          formInstance={formInstance}
          propertyID={propertyID}
          formID={formInstance.id}
          disabled={formdisabled}
          {...props}
        />
      ),
      // DateTimeWidget: props => <DateTimeWidget disabled={formdisabled} {...props} />,
    }

    return (
      <>
        <div className={c.progressBadge} style={{ display: 'none' }} id="page_progress">
          <Badge pill variant="primary">
            <span id="progress_label"></span>
          </Badge>
        </div>
        {formInstance.form_state === 'CLOSED' ? (
          <Button
            style={{ marginLeft: 'auto', marginBottom: 2 }}
            variant="contained"
            onClick={() => exportToPDF(formInstance)}>
            Export PDF
          </Button>
        ) : (
          <></>
        )}
        <Card>
          <CardContent>
            <div>
              {!formdisabled && propertySelection()}
              <Form
                disabled={formdisabled}
                fields={fields}
                widgets={widgets}
                schema={template}
                uiSchema={ui_schema}
                onSubmit={handleSubmit}
                onChange={handleChange}
                onError={onError}
                noHtml5Validate
                formData={formData}
                showErrorList={false}
                validator={validator}>
                <div style={{ display: 'block' }}>
                  <div style={{ display: alertDisplay }}>
                    <div style={{ textAlign: 'center' }} role="alert">
                      <button type="button" className="close" onClick={dismissNotification} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>

                {displayButtons()}
              </Form>

              <div id="display_attached_files"></div>
            </div>
          </CardContent>
        </Card>
        {formInstance?.form_state === 'CLOSED' && (
          <Card style={{ marginTop: '30px' }}>
            <CardContent>
              <div id="comments_list">
                {formComments.map((comment, index) => (
                  <FormComment key={index} comment={comment} />
                ))}
              </div>

              <TextField multiline rows={4} fullWidth name="comment" label="Comment" id="comment_textbox" />

              <Button sx={{ marginTop: '10px' }} variant="contained" onClick={submitComment}>
                Add Comment
              </Button>
            </CardContent>
          </Card>
        )}

        <DiscardFormModal
          open={discardModalOpen}
          onClose={() => setDiscardModalOpen(false)}
          handleDiscardChanges={handleDiscardChanges}
        />
      </>
    )
  } else {
    return <>Loading Form</>
  }
}

const DiscardFormModal = ({
  open,
  onClose,
  handleDiscardChanges,
}: {
  open: boolean
  onClose: () => void
  handleDiscardChanges: () => void
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Discard Form</DialogTitle>
      <DialogContent>
        <DialogContentText>This action will permanently remove the form and cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDiscardChanges} variant="contained">
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const getSignedUrl = async (formID: number, fileName: string, inputID: string) => {
  const config = { params: { form_id: formID, file: fileName, form_input: inputID } }
  const signedUrl = (await api.get('/forms/media-endpoint', config))?.data
  if (!signedUrl) {
    console.warn('Failed to retrieve pre-signed URL')
    return null
  }
  return signedUrl
}

export const getUploadSignedUrl = async (formID: number, fileName: string, inputID: string) => {
  const config = { params: { form_id: formID, file: fileName, form_input: inputID } }
  const signedUrl = (await api.get('/forms/media-upload-endpoint', config))?.data
  if (!signedUrl) {
    console.warn('Failed to retrieve pre-signed URL')
    return null
  }
  return signedUrl
}

export const readFile = async (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => resolve(reader.result)
    reader.readAsArrayBuffer(file)
  })
}

export default DynamicForm
