import React from 'react'
import { Auth } from '@aws-amplify/auth'
import UserGetStarted from '../components/userManagement/UserGetStarted'
import OrgGetStarted from '../components/userManagement/OrgGetStarted'
import LastPageGetStarted from '../components/userManagement/LastPageGetStarted'
// import Button from 'balkerne-components/Button'
import PageContainer from 'balkerne-components/Container'
import PreTitle from 'balkerne-components/PreTitle'
import { emailAvailable, emailValid } from 'balkerne-core/forms'
import { generatePassword } from 'balkerne-core/auth'
import api from 'balkerne-core/api'
import c from './GetStarted.module.scss'
import { Button } from '@mui/material'
import { SignUpParams } from '@aws-amplify/auth/lib-esm/types'

type GetStartedProps = {}
type GetStartedState = {
  currentStep: number
  userData: {
    email: string
    full_name: string
    position: string
    organisation_name: string
    phone: string
    password: string
  }
  orgData: {
    organisation_name: string
    file: File | null
    filename: string | null
    logo?: string
  }
  secret: string | null
  databaseClear: boolean
  databaseSuccess: boolean
  errorArray: string[]
  errorText: React.ReactNode
  properties: any[]
  logo?: string
  success?: boolean
  mounted?: boolean
}
export class GetStarted extends React.Component<GetStartedProps, GetStartedState> {
  state: GetStartedState
  constructor(props) {
    super(props)
    // setting initial user row
    this.state = {
      currentStep: 1,
      userData: {
        email: '',
        full_name: '',
        position: '',
        organisation_name: '',
        phone: '',
        password: '',
      },
      orgData: {
        organisation_name: '',
        file: null,
        filename: null,
      },
      secret: null,
      databaseClear: true,
      databaseSuccess: false,
      errorArray: [],
      errorText: '',
      properties: [],
    }
    this._next = this._next.bind(this)
    this._prev = this._prev.bind(this)

    this.registerUserWithCognito = this.registerUserWithCognito.bind(this)
    this.handleSubmit2 = this.handleSubmit2.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
    this.validateAddUser = this.validateAddUser.bind(this)
    this.updateUserData = this.updateUserData.bind(this)
    this.updateOrgData = this.updateOrgData.bind(this)
    this.updateOrgLogo = this.updateOrgLogo.bind(this)
    this._next = this._next.bind(this)
  }

  async handleUpload() {
    let userdata = this.state.userData
    if (userdata.email && userdata.email.length > 3) {
      userdata.email = userdata.email.toLowerCase()
    }

    const organisation = this.state.orgData

    const data = {
      organisation: organisation,
      user: userdata,
    }

    if (this.state.logo) {
      organisation.logo = this.state.logo
    }

    let success = false
    await api
      .post('/registration/get-started', data)
      .then(() => {
        success = true
      })
      .catch(err => {
        console.error(err)
        success = false
        this.setState({
          success: false,
          errorText: this.printError(['Internal server error.']),
        })
      })

    return success
  }

  async _next() {
    let currentStep = this.state.currentStep

    if (currentStep === 1) {
      //validate added users
      let answer = await this.validateAddUser()
      if (answer) {
        currentStep = 2

        let orgData = this.state.orgData

        orgData.organisation_name = this.state.userData.organisation_name

        this.setState({ orgData })
        this.setState({ currentStep })
        this.setState({
          errorText: '',
        })
      }
    } else if (currentStep === 2) {
      // If the current step is 1 or 2, then add one on "next" button click
      // currentStep = currentStep >= 2 ? 3 : currentStep + 1;
      let answer = await this.validateOrganisation()
      if (answer) {
        currentStep = 3
        this.setState({ currentStep })
        this.setState({
          errorText: '',
        })
      }
    }
  }

  // function moving the user to the previous form
  _prev() {
    let currentStep = this.state.currentStep
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    this.setState({
      currentStep: currentStep,
    })
  }

  get previousButton() {
    let currentStep = this.state.currentStep
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1 && currentStep < 4) {
      return (
        <Button variant="contained" color="primary" onClick={this._prev}>
          Previous
        </Button>
      )
    }
    // ...else return nothing
    return null
  }

  //render next page button
  get nextButton() {
    let currentStep = this.state.currentStep
    // If the current step is not 3, then render the "next" button
    if (currentStep === 1) {
      return (
        <Button variant="contained" color="primary" onClick={this._next}>

          Let's Get Started
        </Button>
        // <Button id="getstartedbutton" onClick={this._next}>
        // </Button>
      )
    } else if (currentStep < 2) {
      return (
        <Button variant="contained" color="primary" onClick={this._next}>
          Next
        </Button>
      )
    }
    // ...else render nothing
    return null
  }

  async componentDidMount() {
    this.setState({ mounted: true })
  }

  async validateAddUser() {
    let success = true
    let validate = true
    let errorMessage: any[] = []
    let user = this.state.userData

    // test if any fields are undefined or empty (missing) as well as checking if email and phone number are valid
    if (typeof user.full_name === 'undefined' || user.full_name.length < 1) {
      validate = false
      errorMessage.push("Field 'Full Name' cannot be empty.\n")
    }
    if (typeof user.email === 'undefined' || user.email.length < 1) {
      validate = false
      errorMessage.push("Field 'Email' cannot be empty.\n")
    } else if (!emailValid(user.email)) {
      validate = false
      errorMessage.push("Field 'Email' is not valid.\n")
    }

    if (typeof user.organisation_name === 'undefined' || user.organisation_name.length < 1) {
      validate = false
      errorMessage.push("Field 'Organisation' cannot be empty.\n")
    } else if (user.organisation_name.search(/[!$%^&*(){}+|~=`{}\[\]:";'<>?,\/]/) >= 0) {
      validate = false
      errorMessage.push('Invalid symbols in organisation name .\n')
    }

    // if everything valid, move to the next form, if not then print an error message.
    if (validate === true) {
      success = true
      success = await emailAvailable(this.state.userData.email)

      if (success === false) {
        console.log('these credentials already exist in the database')
      }
    } else {
      success = false
      this.setState({
        errorText: this.printError(errorMessage),
      })
    }
    return success
  }

  // formats the error message to be on separate lines
  printError = errorMessage => {
    var lines = errorMessage
    var br = lines.map(function (line) {
      return (
        <div className="alert alert-danger" role="alert">
          <span>
            {line}
            <br />
          </span>
        </div>
      )
    })
    return <div>{br}</div>
  }

  validateOrganisation() {
    let success = true
    let errorMessage: any[] = []

    let orgData = this.state.orgData
    if (!orgData.organisation_name || orgData.organisation_name === '') {
      success = false
      errorMessage.push('Please provide an organisation name .\n')
    } else if (orgData.organisation_name.search(/[!$%^&*(){}+|~=`{}\[\]:";'<>?,\/]/) >= 0) {
      success = false
      errorMessage.push('Invalid symbols in organisation name .\n')
    }
    if (success === false) {
      this.setState({
        errorText: this.printError(errorMessage),
      })
    }
    return success
  }

  validateLastStep = () => {
    let success = true
    let errorMessage = []

    if (!success) {
      success = false
      this.setState({
        errorText: this.printError(errorMessage),
      })
    }
    return success
  }

  async handleSubmit2() {
    let success = false
    let userRegistered = false
    let successDatabase = false

    success = this.validateLastStep()

    if (success) {
      this.state.userData.password = generatePassword()
      userRegistered = await this.registerUserWithCognito(this.state.userData)
      if (userRegistered) {
        successDatabase = await this.handleUpload()
        if (!successDatabase) {
          alert('Application submitted with errors')
        }
        this.setState({ databaseSuccess: true })
        this.setState({ currentStep: 3 })
      } else {
        console.log('cognito fail')
      }
    } else {
      console.log('Error with address form')
    }
  }

  async registerUserWithCognito(details) {
    let success = true

    let email = details.email
    if (email && email.length > 3) {
      email = email.toLowerCase()
    }

    await Auth.signUp({
      username: email,
      password: details.password,
      attributes: {
        email: email,
      },
      // region: 'region eu-west-1',
    })
      .then(data => {
        details.aws_id = data.userSub
        // this.state.secret = data.user.pool.userPoolId
        success = true
      })
      .catch(err => {
        console.log(err)
        success = false

        if (err.code === 'UsernameExistsException') {
          this.setState({
            errorText: this.printError(['User with this email already exists']),
          })
        } else {
          this.setState({
            errorText: this.printError(['Cognito adding error']),
          })
        }
      })

    return success
  }

  updateUserData(userData) {
    this.setState({ userData })
  }

  updateOrgData(orgData) {
    this.setState({ orgData })
  }

  updateOrgLogo(logo) {
    this.setState({ logo: logo })
  }

  render() {
    return (
      <div className={c.page}>
        <PageContainer>
          <PageContainer.Center>
            <PreTitle className={c.chart}>Create an account</PreTitle>
            <h3 className="mainTitle">Get Started with Balkerne</h3>
            <h4> Step {this.state.currentStep} of 3 </h4>
            <hr></hr>

            <UserGetStarted currentStep={this.state.currentStep} updateUserData={this.updateUserData} />

            <OrgGetStarted
              currentStep={this.state.currentStep}
              updateOrgData={this.updateOrgData}
              updateOrgLogo={this.updateOrgLogo}
              name={this.state.userData.organisation_name}
              handleSubmit={this.handleSubmit2}
            />

            <LastPageGetStarted currentStep={this.state.currentStep} databaseSuccess={this.state.databaseSuccess} />

            <div className="d-flex justify-content-between mt-3">
              {this.previousButton}
              {this.nextButton}
            </div>

            <div>{this.state.errorText}</div>
          </PageContainer.Center>
        </PageContainer>
      </div>
    )
  }
}

export default GetStarted
