const _readFile = getReader => file => {
  return new Promise((resolve, reject) => {
    const reader = getReader(file)
    reader.onload = () =>
      resolve({
        name: file.name,
        type: file.type,
        data: reader.result,
      })

    reader.onerror = error => {
      console.error(error)
      reject(null)
    }
  })
}

export const readFileBuffer = _readFile(file => {
  const reader = new FileReader()
  reader.readAsArrayBuffer(file)
  return reader
})

export const readFileText = _readFile(file => {
  const reader = new FileReader()
  reader.readAsText(file)
  return reader
})

export const readFileDataURL = _readFile(file => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return reader
})

export const readFiles = files => {
  // Return multiple promises
}

export default readFileBuffer
