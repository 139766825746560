import React from 'react'
import './Displayrisk.css'
import { RiskMap } from '../maps/MediumMap'
import { Riskrating } from '../maps/riskrating'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import { Card } from '@mui/material'
import { MapLegendWidget } from '../widgets/MapLegendWidget'
import { Typography } from '@mui/material'

type DisplayriskProps = {
  title: string
  riskrating: number
  riskratingdescription: string
  max_score: number
  geoFeature: any
  p: string
}
type DisplayriskState = {
  risk_rating: number
}
export class Displayrisk extends React.Component<DisplayriskProps, DisplayriskState> {
  constructor(props) {
    super(props)

    this.state = {
      risk_rating: this.props.riskrating,
    }
  }

  Riskchartscore() {
    switch (this.props.riskrating) {
      case 1:
        return (1 / this.props.max_score) * 100
      case 2:
        return (2 / this.props.max_score) * 100
      case 3:
        return (3 / this.props.max_score) * 100
      case 4:
        return (6 / this.props.max_score) * 100
      default:
        return (0 / this.props.max_score) * 100
    }
  }

  render() {
    const propertyId = this.props.geoFeature?.id
    if (propertyId == null) {
      return null
    }
    return (
      <>
        <Card>
          <Typography variant="subtitle1">{this.props.title}</Typography>
          <div className="Riskcontent">
            <div className="riskmap">
              {this.props.title === 'Church Metal Theft Risk' ? (
                <></>
              ) : this.props.title === 'Rivers and Seas Flood Risk' ? (
                <RiskMap propertyId={propertyId} showRiverSea />
              ) : (
                <RiskMap propertyId={propertyId} showSurface />
              )}
            </div>
            <div className="riskright">
              <div className="riskdetailsection">
                <Riskrating
                  title={this.props.title}
                  value={this.Riskchartscore()}
                  rating_text={this.props.riskratingdescription}
                />
                <div className="risk_insight_text">
                  <p>
                    <TipsAndUpdatesIcon style={{ color: '#ffcb2e', marginRight: 5 }} />
                    {this.props.p}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {this.props.title == 'Rivers and Seas Flood Risk' && <MapLegendWidget showRiverSea={true} overMap={false} />}
          {this.props.title == 'Surface Water Risk' && <MapLegendWidget showSurfaceWater={true} overMap={false} />}
        </Card>
      </>
    )
  }
}
export default Displayrisk
