export function hiToScore(value: number): number {
  /**
   * Convert HI value to score
   *
   * @param value - HI value
   * @return Score [1-10]
   */
  if (value < 27) {
    return 1
  } else if (value < 28) {
    return 2
  } else if (value < 30) {
    return 3
  } else if (value < 32) {
    return 4
  } else if (value < 33) {
    return 5
  } else if (value < 35) {
    return 6
  } else if (value < 37) {
    return 7
  } else if (value < 40) {
    return 8
  } else if (value < 43) {
    return 9
  } else {
    return 10
  }
}

export function scoreToCategory(score: number): string {
  /**
   * Convert score to category
   *
   * @param score - Score [1-10]
   * @return Category label
   */
  if (score <= 2) {
    return 'Low'
  } else if (score <= 4) {
    return 'Moderate'
  } else if (score <= 6) {
    return 'Moderate to High'
  } else if (score <= 8) {
    return 'High'
  } else {
    return 'Very High'
  }
}

export function scoreToColor(score: number): string {
  let color
  if (score <= 3) {
    color = '#ffff96'
  } else if (score <= 7) {
    color = '#ffc933'
  } else if (score <= 9) {
    color = '#ff5b1f'
  } else {
    color = '#cf0516'
  }
  return color
}

export const scoreColor = {
  0: '#ffff96',
  1: '#ffff96',
  2: '#ffff96',
  3: '#ffff96',

  4: '#ffc933',
  5: '#ffc933',
  6: '#ffc933',
  7: '#ffc933',

  8: '#ff5b1f',
  9: '#ff5b1f',

  10: '#cf0516',
}
