import { useQuery } from 'react-query'
import api from 'balkerne-core/api'
import { useRiskCategories } from './analytics'
import { useMemo } from 'react'

export const useLocationGroups = () => {
  const path = '/locations/groups'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch)
  return { ...rest, data: data ?? [] }
}

export const useLocation = (locationId, args) => {
  const path = `/locations/${locationId}`
  const fetch = async () => await api.get(path, { params: args }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, {
    enabled: locationId !== undefined && locationId !== null,
  })
  return { ...rest, data: data ?? null }
}

export const useLocationSubscription = (id, args) => {
  const path = `/locations/${id}/subscriptions`
  const fetch = async () => await api.get(path, { params: args }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, { enabled: id !== undefined })
  return { ...rest, data: data?.data ?? null }
}

export const useLocationSubscriptionsReadings = (id, args) => {
  const path = `/locations/${id}/subscriptions/readings`
  const fetch = async () => await api.get(path, { params: args }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, { enabled: id !== undefined })
  return { ...rest, data: data ?? null }
}

export const useLocations = (args, enabled = true) => {
  const path = '/locations'
  const fetch = async () => await api.get(path, { params: args }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, { enabled: enabled, refetchOnWindowFocus: false })
  return { ...rest, data: data ?? [] }
}

export const useOrganisationLocations = args => {
  const path = '/organisations/locations'
  const fetch = async () => await api.get(path, { params: args }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch)
  return { ...rest, data: data ?? [] }
}

export const useRiskSummary = locationId => {
  const path = `/locations/${locationId}/risks/summary`
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch, { enabled: locationId !== undefined })
  return { ...rest, data: data ?? {} }
}

export const usePortfolioRiskSummary = () => {
  const path = '/locations/risks/summary'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch)
  return { ...rest, data: data ?? null }
}

export const usePortfolioRisks = () => {
  const path = '/locations/risks'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch)
  return { ...rest, data: data ?? null }
}
export const useLocationsWithRisks = () => {
  const path = '/locations/risks'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data: risks, isLoading: risksLoading, ...rest } = useQuery([path], fetch)
  const { data: locations, isLoading: locationsLoading } = useLocations()
  const { data: riskCategories, isLoading: riskCategoriesLoading } = useRiskCategories()

  const result = useMemo(() => {
    if (!locations || !riskCategories || !risks) return null
    const _riskCategories = {}
    riskCategories?.forEach(riskCategory => {
      _riskCategories[riskCategory.id] = riskCategory
    })
    return locations.map(location => ({
      ...location,
      risks: risks[location.id].map(risk => ({
        ...risk,
        category: _riskCategories[risk.category_id],
      })),
    }))
  }, [locations, risks, riskCategories])

  const isLoading = risksLoading || locationsLoading || riskCategoriesLoading

  return { ...rest, isLoading, data: result ?? [] }
}
export const usePropertyAttributes = (id, args) => {
  const path = `/locations/${id}/attributes`
  const fetch = async () => await api.get(path, { params: args }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, { enabled: id !== undefined })
  return { ...rest, data: data?.data ?? [] }
}

export const usePropertyAttributeCategories = args => {
  const path = `/locations/attributes/categories`
  const fetch = async () => await api.get(path, { params: args }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch)
  return { ...rest, data: data?.data ?? [] }
}
