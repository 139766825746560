import Map, { Source, Layer, MapRef } from 'react-map-gl'
import ConfigProvider from 'balkerne-core/config'
import { Button, Box, TextField, Grid, Typography, Tooltip, IconButton, Paper, InputAdornment } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState, useCallback, useRef, useEffect, Ref } from 'react'
import { geocode } from 'balkerne-fn/geocode'
import DrawControl from './DrawControl'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { AllGeoJSON, centroid } from '@turf/turf'
import axios from 'axios'
import { LayerProps } from 'react-map-gl'

type DrawMapProps = {
  onSubmit: (geometry: GeoJSON.FeatureCollection) => void
  onCancel: () => void
  onReset?: () => void
  onError: (error: string) => void
  getSearchValue?: () => string
  title: string
  initGeometry?: GeoJSON.FeatureCollection
  edit?: boolean
}

export const DrawMap = (props: DrawMapProps) => {
  const { onSubmit, onCancel, onError, initGeometry, edit, onReset, getSearchValue, title } = props
  const mapRef = useRef<any>()
  const postcodeRef = useRef<any>()

  const { enqueueSnackbar } = useSnackbar()
  const [features, setFeatures] = useState({})
  const [geometry, setGeometry] = useState(initGeometry)
  const [editMode, setEditMode] = useState(edit || false)

  const layerStyle: LayerProps = {
    id: 'init-geometry',
    type: 'fill',
    paint: {
      'fill-color': '#00FF00',
      'fill-opacity': 0.5,
    },
    source: 'init-geometry1',
  }
  const initFeatures = features => {
    setFeatures({ ...features })
  }
  const onUpdate = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = { ...currFeatures }
      for (const f of e.features) {
        newFeatures[f.id] = f
      }
      return newFeatures
    })
  }, [])
  const onEditModeCancel = () => {
    if (!edit) {
      setEditMode(false)
    }
    onCancel()
  }
  const onDelete = useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = { ...currFeatures }
      for (const f of e.features) {
        delete newFeatures[f.id]
      }
      return newFeatures
    })
  }, [])

  const prepareSubmit = () => {
    let data: any = null
    if (Object.keys(features).length > 0) {
      data = {
        type: 'FeatureCollection',
        features: Object.values(features),
      }
    }
    if (data) {
      setGeometry(data)
      onSubmit(data)
      if (!edit) {
        setEditMode(false)
      }
    } else {
      onError('No geometry data to submit')
    }
  }
  const onSearchSuccess = (features: any) => {
    if (features.length > 0) {
      const { center } = features[0]
      mapRef?.current?.setCenter(center)
      mapRef?.current?.setZoom(17)
    } else {
      enqueueSnackbar('No matching addresses found', { variant: 'warning' })
    }
  }
  const onSearchError = (error: any) => {
    console.error(error)
    enqueueSnackbar('Error occured when searching for address.', { variant: 'error' })
  }
  const handleSearch = () => {
    const searchValue = getSearchValue ? getSearchValue() : postcodeRef?.current?.value
    searchLocation(searchValue, onSearchSuccess, onSearchError)
  }

  const initMap = () => {
    // Set initial view state to be over London
    const initialViewState = {
      latitude: 51.522,
      longitude: -0.226,
      zoom: 9,
    }
    if (initGeometry && initGeometry.features?.length > 0) {
      const centerCoord = centroid(initGeometry as AllGeoJSON).geometry.coordinates
      initialViewState.longitude = centerCoord[0]
      initialViewState.latitude = centerCoord[1]
      initialViewState.zoom = 15
    }
    return initialViewState
  }
  return (
    <div>
      <Paper elevation={0}>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs>
              <Typography variant="h6" component="h2">
                {title}
              </Typography>
            </Grid>
            {getSearchValue ? (
              <Grid item xs={2}>
                <Box sx={{ textAlign: 'right', marginBottom: 1 }}>
                  <Tooltip title="Search for an address">
                    <Button
                      data-testid="searchPostcodeButton"
                      variant="outlined"
                      onClick={handleSearch}
                      startIcon={<SearchOutlinedIcon />}>
                      Search
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  sx={{ width: '100%', marginBottom: 1 }}
                  inputRef={postcodeRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Search">
                          <IconButton aria-label="search" onClick={handleSearch} edge="end">
                            <SearchOutlinedIcon color={'action'} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter a postcode or address"
                />
              </Grid>
            )}
          </Grid>
          <Map
            ref={mapRef}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            initialViewState={initMap()}
            style={{
              height: '600px',
            }}
            mapboxAccessToken={ConfigProvider.mapboxPublicKey}>
            {!editMode && geometry && (
              <Source key="renderGeometrySource" id="init-geometry1" type="geojson" data={geometry}>
                <Layer key="renderGeometryLayer" {...layerStyle} />
              </Source>
            )}
            {editMode && (
              <DrawControl
                // draw={draw}
                position="top-left"
                displayControlsDefault={false}
                controls={{
                  polygon: true,
                  trash: true,
                }}
                defaultMode={geometry && geometry.features?.length > 0 ? 'simple_select' : 'draw_polygon'}
                onCreate={onUpdate}
                onUpdate={onUpdate}
                onDelete={onDelete}
                initGeometry={geometry}
                initFeatures={initFeatures}
              />
            )}
          </Map>
        </Box>
        <div
          style={{
            marginTop: '8px',
          }}>
          {editMode ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}>
              {onReset && (
                <Button
                  sx={{
                    marginRight: 'auto',
                  }}
                  variant="contained"
                  color="error"
                  onClick={onReset}>
                  Reset
                </Button>
              )}

              <div
                style={{
                  marginLeft: 'auto',
                }}>
                <Button
                  variant="contained"
                  sx={{
                    marginRight: '8px',
                  }}
                  onClick={onEditModeCancel}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={prepareSubmit}>
                  Save changes
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant="contained" onClick={() => setEditMode(true)}>
                Edit
              </Button>
            </div>
          )}
        </div>
      </Paper>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', padding: 0 }}></div>
    </div>
  )
}

export const searchLocation = async (
  address: string,
  onSuccess: (features: any) => void,
  onError: (error: any) => void,
) => {
  try {
    await axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${ConfigProvider.mapboxPublicKey}`,
      )
      .then(response => {
        const { features } = response.data
        onSuccess(features)
      })
  } catch (err) {
    onError(err)
  }
}

export default DrawMap

DrawMap.defaultProps = {
  edit: false,
}
