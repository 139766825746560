import { useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Search from 'balkerne-components/Search'
import { Box, Card } from '@mui/material'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'balkerne-core/api'
import FormsList from '../components/general/forms/FormsList'
import { useSelector } from 'react-redux'
import { useFormTemplates } from '../hooks/forms'
import { useQueryClient } from 'react-query'
import Page from '../components/Page'
import { RootState } from '../store'

export const FormList = props => {
  const queryClient = useQueryClient()
  const typeId = props.location.state.typeID
  const history = useHistory()
  const user = useSelector((state: RootState) => state.auth.user)
  const [categories, setCategories] = useState({})
  const [allTemplates, setAllTemplates] = useState({})
  const [filteredTemplates, setFilteredTemplates] = useState<any>(null)
  const [categoryForms, setCategoryForms] = useState<any>(null)
  const [categorydisplay, setCategoryDisplay] = useState('block')
  const formSelectorTitle = props.location.state.title || 'Complete Form'
  const { data: templates } = useFormTemplates({ type_id: typeId })
  const [paths, setPaths] = useState({
    1: {
      title: props.location.state.title ? props.location.state.title : 'Complete Form',
      onClick: () => {
        setCategoryForms(null)
        setCategoryDisplay('block')
      },
    },
  })

  useEffect(() => {
    if (templates != null) {
      var tempCategories = {}
      var tempTemplates = {}
      Object.values(templates).map((template: any) => {
        if (tempCategories[template.category.id] === undefined) {
          tempCategories[template.category.id] = { ...template.category }
          //tempCategories[template.category_id]['subcategories'] = {}
          tempCategories[template.category.id]['templates'] = []
        }
        tempCategories[template.category.id]['templates'].push(template)
        tempTemplates[template.id] = { ...template }
        // create search string value
        var searchString = template.category.title
        searchString = searchString + ' > ' + template.title
        tempTemplates[template.id]['searchString'] = searchString
      })
      // 🚧 Why {...tempCategories} instead of tempCategories ?
      setCategories({ ...tempCategories })
      setAllTemplates({ ...tempTemplates })
    }
  }, [templates])

  // 🚧 This functionality can be provided by data grid
  const handleSearch = (event, searchkey) => {
    if (event.target.value != '') {
      let searchterm = event.target.value.toUpperCase()
      switch (searchkey) {
        case 'categories':
          let categoryrows: any = document.getElementById('categoryRows')
          if (categoryrows) {
            categoryrows = categoryrows.children
            Object.values(categoryrows).map((categoryrow: any) => {
              let rowterm = categoryrow.children[0].textContent.toUpperCase()
              if (rowterm.includes(searchterm)) {
                if (categoryrow.style.display == 'none') {
                  categoryrow.style.display = 'table-row'
                }
              } else {
                categoryrow.style.display = 'none'
              }
            })
          }
          break
        case 'subcategories':
          let subcategoryrows: any = document.getElementById('subcategoryRows')
          if (subcategoryrows) {
            subcategoryrows = subcategoryrows.children
            Object.values(subcategoryrows).map((subcategoryrow: any) => {
              let rowterm = subcategoryrow.children[0].textContent.toUpperCase()
              if (rowterm.includes(searchterm)) {
                if (subcategoryrow.style.display == 'none') {
                  subcategoryrow.style.display = 'table-row'
                }
              } else {
                subcategoryrow.style.display = 'none'
              }
            })
          }

          break
        case 'forms':
          let formrows: any = document.getElementById('formRows')
          if (formrows) {
            formrows = formrows.children
            Object.values(formrows).map((formrow: any) => {
              let rowterm = formrow.children[1].textContent.toUpperCase()
              if (rowterm.includes(searchterm)) {
                if (formrow.style.display == 'none') {
                  formrow.style.display = 'table-row'
                }
              } else {
                formrow.style.display = 'none'
              }
            })
          }

          break
      }

      // handle searchString for all categories, subcategories and templates
      const searchterms = searchterm.split(' ')
      var tempFilteredTemplates = {}
      Object.values(allTemplates).map((template: any) => {
        var searchflag = false
        searchterms.map(term => {
          if (!searchflag) {
            if (template.searchString.toUpperCase().includes(term)) {
              searchflag = true
            }
          }
        })
        if (searchflag) {
          tempFilteredTemplates[template.id] = { ...template }
        }
      })
      setFilteredTemplates({ ...tempFilteredTemplates })
    }
    // Reset table display to show all rows
    else {
      switch (searchkey) {
        case 'categories':
          let categoryrows: any = document.getElementById('categoryRows')
          if (categoryrows) {
            categoryrows = categoryrows.children
            Object.values(categoryrows).map((categoryrow: any) => {
              categoryrow.style.display = 'table-row'
            })
          }
          break
        case 'subcategories':
          let subcategoryrows: any = document.getElementById('subcategoryRows')
          if (subcategoryrows) {
            subcategoryrows = subcategoryrows.children
            Object.values(subcategoryrows).map((subcategoryrow: any) => {
              subcategoryrow.style.display = 'table-row'
            })
          }
          break
        case 'forms':
          let formrows: any = document.getElementById('formRows')
          if (formrows) {
            formrows = formrows.children
            Object.values(formrows).map((formrow: any) => {
              formrow.style.display = 'table-row'
            })
          }
          break
      }
      setFilteredTemplates(null)
    }
  }

  const selectCategory = async category_id => {
    setCategoryDisplay('none')
    setCategoryForms(categories[category_id])
  }

  // Generate new form on Dynamic Form
  const loadForm = async template => {
    // start a new form instance and redirect to dynamic form
    const versionId = parseInt(template.versions[0].id)
    let data = null
    if (props.location.state.propertyID !== null) {
      data = props.location.state.propertyID
    }
    await api.post(`/forms/templates/versions/${versionId}/start_form`, data).then(response => {
      queryClient.invalidateQueries(`/forms/instances`)
      console.log(response)
      history.push({
        pathname: 'DynamicForm',
        state: {
          propertyID: props.location.state.propertyID,
          formInstanceId: response.data.data,
          previousPathTitle: props.previousPathTitle,
          previousPathName: '/FormSelector',
          previousPathState: {
            typeID: typeId,
            title: props.previousPathTitle,
            propertyID: props.propertyID,
          },
        },
      })
    })
  }
  // 🚧 Bug with search string, enter category name, click on category, one of displayed options fails while selecting
  return (
    <Page title={formSelectorTitle} size="lg" back>
      <Card sx={{ minHeight: 400 }}>
        <Box sx={{ display: categorydisplay }}>
          <Search size="small" placeholder="Search" onChange={e => handleSearch(e, 'categories')} transparent />
          {filteredTemplates !== null ? (
            <Table>
              <tbody id="searchRows">
                {Object.values(filteredTemplates).map((filtered_template: any) => (
                  <tr
                    style={{ cursor: 'pointer' }}
                    key={filtered_template.id}
                    id={filtered_template.id}
                    onClick={() => loadForm(filtered_template)}>
                    <td style={{ width: '70%' }}>{filtered_template.searchString}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <></>
          )}
          <Table>
            <tbody id="categoryRows">
              {Object.values(categories).map((category: any) => (
                <tr
                  style={{ cursor: 'pointer' }}
                  key={category.id}
                  id={category.id}
                  onClick={() => selectCategory(category.id)}>
                  <td style={{ width: '70%' }}>{category.title}</td>
                  <td style={{ color: '#95AAC9', display: category['formType'] == 'Survey' ? 'table-cell' : 'none' }}>
                    {category.formType == 'Survey'
                      ? category.surveys.length > 1
                        ? category.surveys.length + ' surveys'
                        : category.surveys.length + ' survey'
                      : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        <FormsList
          typeID={typeId}
          categoryForms={categoryForms}
          handleSearch={handleSearch}
          paths={paths}
          setPaths={setPaths}
          setCategoryForms={setCategoryForms}
          setCategoryDisplay={setCategoryDisplay}
          filteredTemplates={filteredTemplates}
          previousPathTitle={formSelectorTitle}
          propertyID={props.location.state.propertyID}
          userID={user.id}
        />
      </Card>
    </Page>
  )
}

export default FormList
