import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
} from '@mui/material'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import Page from '../components/Page'
import { useClimateSubsidenceHazardLayer, usePortfolioFloodClimateProjections } from '../hooks/analytics'
import { Line } from 'react-chartjs-2'
import api from 'balkerne-core/api'
import { HelpRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Switch as RouteSwitch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import FloodScore from '../components/portfolioClimateProjections/FloodScore'
import Subsidence from '../components/portfolioClimateProjections/Subsidence'
import FloodPricing from '../components/portfolioClimateProjections/FloodPricing'
import HeatStress from '../components/portfolioClimateProjections/HeatStress'
import { Layer, LayerProps, Map, MapRef, NavigationControl, ScaleControl, Source } from 'react-map-gl'
import ConfigProvider from 'balkerne-core/config'
import { scenarios } from '../types/climate'
import type { Scenario } from '../types/climate'

const indicators = {
  'flood-score': ['Floodability Score', true],
  'flood-pricing': ['Flood Damage Cost', true],
  heat: ['Heat Stress', true],
  subsidence: ['Subsidence', true],
  'sea-level': ['Sea Level', false],
  drought: ['Drought', false],
  wind: ['Wind', false],
} as const

type Indicator = keyof typeof indicators

const SubsidenceSourceLayer = () => {
  const { data, isLoading } = useClimateSubsidenceHazardLayer()

  if (isLoading) return null

  const colors = ['white', 'white', '#f5aa84', '#e74b0f', '#a83318', 'green']

  // Data doesn't exist
  const missing = ['==', ['get', '2030', ['get', 'scores']], null]
  // Data exists, but isn't available for this location
  const unavailable = ['==', ['get', '2030', ['get', 'scores']], -1]

  // Data exists
  const improbable = ['==', ['get', '2030', ['get', 'scores']], 1]
  const possible = ['==', ['get', '2030', ['get', 'scores']], 2]
  const probable = ['==', ['get', '2030', ['get', 'scores']], 3]

  const unclusteredLayer: LayerProps = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'subsidence',
    filter: ['!=', 'cluster', true],
    paint: {
      'circle-color': [
        'case',
        missing,
        colors[0],
        unavailable,
        colors[1],
        improbable,
        colors[2],
        possible,
        colors[3],
        probable,
        colors[4],
        colors[5],
      ],
      'circle-radius': {
        base: 4,
        stops: [
          [0, 2],
          [6, 4],
          [10, 10],
        ],
      },
      'circle-stroke-width': 0.5,
      'circle-stroke-color': 'grey',
    },
  }

  // const clusteredLayer: LayerProps = {
  //   id: 'clustered-point',
  //   type: 'symbol',
  //   source: 'subsidence',
  //   filter: ['!=', 'cluster', true],
  //   layout: {
  //     'text-field': [
  //       'number-format',
  //       ['get', '2030', ['get', 'scores']],
  //       { 'min-fraction-digits': 1, 'max-fraction-digits': 1 },
  //     ],
  //     'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  //     'text-size': 10,
  //   },
  //   paint: {
  //     'text-color': ['case', ['<', ['get', '2030', ['get', 'scores']], 3], 'black', 'white'],
  //   },
  // }

  return (
    <Source
      id="subsidence"
      type="geojson"
      // cluster={true}
      // clusterMaxZoom={10}
      // clusterRadius={30}
      // clusterProperties={{
      //   missing: ['+', ['case', missing, 1, 0]],
      //   unavailable: ['+', ['case', unavailable, 1, 0]],
      //   improbable: ['+', ['case', improbable, 1, 0]],
      //   possible: ['+', ['case', possible, 1, 0]],
      //   probable: ['+', ['case', probable, 1, 0]],
      // }}
      data={data?.['features']}>
      {/* <Layer {...clusteredLayer} /> */}
      <Layer {...unclusteredLayer} />
    </Source>
  )
}

export const PortfolioClimateProjections = () => {
  const mapRef = React.useRef<null | MapRef>(null)
  const history = useHistory()
  const match = useRouteMatch()

  const [indicator, setIndicator] = useState<Indicator>(history.location.pathname.split('/')?.[2] ?? 'flood-score')
  const [scenario, setScenario] = useState<Scenario>('rcp45')
  const [showMap, setShowMap] = useState(false)

  // const { setShow } = useSubsidenceLayer({
  //   map: mapRef?.current?.getMap() ?? null,
  //   initShow: indicator === 'subsidence',
  // })

  // useEffect(() => {
  //   setShow(indicator === 'subsidence')
  // }, [indicator])

  const onChange = useCallback(indicator => {
    setIndicator(indicator)
    history.push(`${match.path}/${indicator}`)
  }, [])

  // Redirect to the default indicator if the path is exact
  if (match.isExact) {
    history.push(`${match.path}/${indicator}`)
  }

  return (
    <Page title="Climate Analytics" size="xl">
      <Stack direction="row">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="indicator-select-label">Peril</InputLabel>
          <Select
            labelId="indicator-select-label"
            id="indicator-select-id"
            value={indicator}
            onChange={e => onChange(e.target.value as Indicator)}>
            {Object.entries(indicators).map(([key, [label, enabled]], _) => (
              <MenuItem key={key} value={key} disabled={!enabled}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="scenario-select-label">Scenario</InputLabel>
          <Select
            labelId="scenario-select-label"
            id="scenario-select-id"
            value={scenario}
            onChange={e => setScenario(e.target.value as Scenario)}>
            {scenarios.map(scenario => (
              <MenuItem key={scenario} value={scenario}>
                {scenario.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Temporarily Disabled */}
        {/* <FormControlLabel
          control={<Switch checked={showMap} onChange={e => setShowMap(e.target.checked)} />}
          label="Map"
          sx={{ userSelect: 'none' }}
        /> */}

        {/* <Tabs value={indicator} onChange={(_, newValue) => onChange(newValue)} aria-label="climate change perils">
          {Object.entries(indicators).map(([key, [label, enabled]], _) => (
            <Tab
              value={key}
              disabled={!enabled}
              label={
                <Stack direction="row" alignItems="center">
                  {label}
                </Stack>
              }
            />
          ))}
        </Tabs> */}
      </Stack>
      {showMap ? (
        <Box sx={{ height: { xs: 600, xl: 900 } }}>
          <Map
            ref={mapRef}
            reuseMaps
            initialViewState={{
              longitude: -2.3,
              latitude: 51.8,
              zoom: 6,
              pitch: 0,
              bearing: 0,
            }}
            style={{ width: '100%', height: '100%', borderRadius: '8px' }}
            mapStyle={`mapbox://styles/balkerne/clmqen49o00bm01ns7ur41hzj`}
            mapboxAccessToken={ConfigProvider.mapboxPublicKey}>
            {indicator === 'subsidence' && <SubsidenceSourceLayer />}
            {/* Selected property */}
            {/* {selectedProperty && (
               <Popup
                 anchor="top"
                 closeOnClick={false}
                 onClose={() => setSelectedProperty(null)}
                 latitude={Number(selectedProperty.coordinates.latitude)}
                 longitude={Number(selectedProperty.coordinates.longitude)}>
                 <Typography variant="h6">{selectedProperty.name}</Typography>
                 <Button fullWidth onClick={() => handleRedirect(selectedProperty.id)} variant="contained">
                   View Property
                 </Button>
               </Popup>
             )} */}

            {/* <Source
               id="assets-source"
               type="geojson"
               data={portfolioGeoJson as any}
               cluster={true}
               clusterMaxZoom={8}
               clusterRadius={30}>
               <Layer {...clusterLayer} />
               <Layer {...clusterCountLayer} />
               <Layer {...unclusteredPointLayer} />
             </Source> */}
            {/* Properties */}
            {/* {entity === 'assets' &&
               portfolio?.map(property => (
                 <Marker
                   key={property.id}
                   style={{ cursor: 'pointer' }}
                   longitude={property.coordinates.longitude}
                   latitude={property.coordinates.latitude}
                   anchor="bottom"
                   onClick={() => openPopup(property)}>
                   {console.log(property.risks?.[riskType]?.value) as any}
                   <Box
                     sx={{
                       backgroundColor: riskColor[property.risks?.[riskType]?.value ?? 0],
                       borderRadius: '100px',
                       p: 0.5,
                       // opacity: 0.8,
                     }}>
                     <HomeWorkOutlinedIcon sx={{ color: 'black' }} />
                   </Box>
                 </Marker>
               ))} */}
            <ScaleControl />
            <NavigationControl />
          </Map>
        </Box>
      ) : (
        <RouteSwitch>
          <Route path={`${match.path}/flood-score`}>
            <FloodScore rcp={scenario} />
          </Route>
          <Route path={`${match.path}/flood-pricing`}>
            <FloodPricing rcp={scenario} />
          </Route>
          <Route path={`${match.path}/subsidence`}>
            <Subsidence />
          </Route>
          <Route path={`${match.path}/heat`}>
            <HeatStress />
          </Route>
        </RouteSwitch>
      )}
    </Page>
  )
}

export default PortfolioClimateProjections
