import { createSlice } from '@reduxjs/toolkit'
import { organisationSetup, organisationDefaultSetup } from 'balkerne-fn/customisation'

const data = organisationDefaultSetup()

let persisted
try {
  persisted = JSON.parse(localStorage.getItem('custom'))
} catch {}

const initialState = persisted || {
  organisationLogoUrl: data.org_url,
  organisationName: data.org_name,
  style: data.customstyle,
  keywords: data.keywords,
}

const customSlice = createSlice({
  name: 'custom',
  initialState,
  reducers: {
    setup: (state, { payload }) => {
      const data = organisationSetup(payload)
      state.organisationLogoUrl = data.org_url
      state.organisationName = data.org_name
      state.style = data.customstyle
      state.keywords = data.keywords
      localStorage.setItem('custom', JSON.stringify(data))
    },
    clear: state => {
      state = initialState
      localStorage.removeItem('custom')
    },
  },
})

export const customReducer = customSlice.reducer
export default customSlice.actions
