import { useQuery } from 'react-query'
import api from 'balkerne-core/api'

export const useRoles = () => {
  return useQuery('/organisations/roles')
}

export const useAccessibleRoles = args => {
  const path = '/organisations/roles'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch)
  return { ...rest, data: data ?? [] }
}
