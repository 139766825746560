import { useParams } from 'react-router-dom'
import CardLoader from 'balkerne-components/dashboard/CardLoader'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'react-redux'
import ViewForm from 'balkerne-components/ViewForm'
import { useFormInstances } from '../hooks/forms'
import FormatDate from 'balkerne-components/FormatDate'
import { useHistory } from 'react-router-dom'
import { FormPermissions, PermissionGate } from 'balkerne-core/roles'
import Page from '../components/Page'
import { Badge, Box, Button, Chip, Divider, Grid, List, ListItem, ListItemText, Stack, useTheme } from '@mui/material'
import * as React from 'react'
import { useCallback } from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import { useClimateMitigations } from '../hooks/analytics'
import api from 'balkerne-core/api'

const PropertyClimateMitigations = () => {
  const { id: propertyId } = useParams()
  const exposureSpacing = 2
  const theme = useTheme()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { data: categories, refetch } = useClimateMitigations(propertyId)

  const addMitigation = useCallback((mitigationId: string) => {
    api.post(`/analytics/locations/${propertyId}/cc/mitigations/${mitigationId}`).then(() => {
      refetch()
      enqueueSnackbar('Mitigation added', { variant: 'success' })
    })
  }, [])

  const removeMitigation = useCallback((mitigationId: string) => {
    api.delete(`/analytics/locations/${propertyId}/cc/mitigations/${mitigationId}`).then(() => {
      refetch()
      enqueueSnackbar('Mitigation removed', { variant: 'success' })
    })
  }, [])

  console.log(categories)

  return (
    <Page title="Climate Mitigations" size="lg">
      <Grid container spacing={6}>
        {/* <Grid item xs={6}>
          <Typography variant="h5" mb={3}>
            Hazard Exposure
          </Typography>

          <Stack direction="row" justifyContent="space-between" alignItems="end">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="body1">Flood</Typography>
            </Stack>
            <Typography variant="body1">
              <strong>Very High</strong>
            </Typography>
          </Stack>
          <Divider sx={{ my: exposureSpacing }} />
          <Stack direction="row" justifyContent="space-between" alignItems="end">
            <Typography variant="body1">Heat Stress</Typography>
            <Typography variant="body1">
              <strong>High</strong>
            </Typography>
          </Stack>
          <Divider sx={{ my: exposureSpacing }} />
          <Stack direction="row" justifyContent="space-between" alignItems="end">
            <Typography variant="body1">Drought</Typography>
            <Typography variant="body1">
              <strong>Moderate</strong>
            </Typography>
          </Stack>
          <Divider sx={{ my: exposureSpacing }} />
          <Stack direction="row" justifyContent="space-between" alignItems="end">
            <Typography variant="body1">Wildfire</Typography>
            <Typography variant="body1">
              <strong>Low</strong>
            </Typography>
          </Stack>
          <Divider sx={{ my: exposureSpacing }} />
          <Stack direction="row" justifyContent="space-between" alignItems="end">
            <Typography variant="body1">Extreme Wind</Typography>
            <Typography variant="body1">
              <strong>Low</strong>
            </Typography>
          </Stack>
        </Grid> */}

        {/* Mitigation Menu */}
        <Grid item xs={12}>
          {categories?.map((category, _) => (
            <Accordion>
              <AccordionSummary>
                <Typography>{category.display_name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {category.mitigations.map((mitigation, index) => (
                    <>
                      <ListItem
                        key={index}
                        secondaryAction={
                          <Stack direction="row" alignItems="center" gap={1}>
                            {mitigation.added ? (
                              <Button
                                variant="outlined"
                                color="info"
                                size="small"
                                onClick={() => removeMitigation(mitigation.id)}>
                                Remove
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="inherit"
                                size="small"
                                onClick={() => addMitigation(mitigation.id)}>
                                Add
                              </Button>
                            )}
                          </Stack>
                        }>
                        <Typography whiteSpace="nowrap">{mitigation.label}</Typography>
                      </ListItem>
                      {index < category.mitigations.length - 1 && <Divider sx={{ my: 1 }} />}
                    </>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Page>
  )
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }),
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default PropertyClimateMitigations
