import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import c from './CompletedForms.module.scss'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import FormatDate from '../FormatDate'
import ViewForm from '../ViewForm'
import CardLoader from '../dashboard/CardLoader'
import { useFormInstances } from '../../../hooks/forms'
import { Stack, Tooltip, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const PortfolioForms = () => {
  const { data: incidents } = useFormInstances({
    type_id: 2,
    form_state: 'CLOSED',
  })
  const { data: checklists } = useFormInstances({
    type_id: 7,
    form_state: 'CLOSED',
  })
  const { data: surveys } = useFormInstances({
    type_id: 3,
    form_state: 'CLOSED',
  })
  const [loading, setLoading] = useState(true)
  const [formInstances, setFormInstances] = useState<any>(null)
  const [filteredForms, setFilteredForms] = useState<any>(null)
  const [filterStates, setFilterStates] = useState({
    incidents: true,
    checklists: true,
    surveys: true,
    fromDate: '',
    toDate: '',
    last7days: true,
  })

  const history = useHistory()

  useEffect(() => {
    if (incidents != null && checklists != null && surveys != null) {
      let forms: any = []
      setLoading(false)
      forms.push(...incidents)
      forms.push(...checklists)
      forms.push(...surveys)
      forms.sort((a, b) => {
        const dateA: any = new Date(a['updated_at'])
        const dateB: any = new Date(b['updated_at'])
        return dateB - dateA
      })
      let temp: any = []
      if (forms.length > 0) {
        forms.map((form: any) => {
          if (form.template.template.type.id == 7) {
            if (filterStates['checklists']) {
              if (filterStates['last7days']) {
                if (isLast7Days(form)) temp.push(form)
              } else temp.push(form)
            }
          } else if (form.template.template.type.id == 2) {
            if (filterStates['incidents']) {
              if (filterStates['last7days']) {
                if (isLast7Days(form)) temp.push(form)
              } else temp.push(form)
            }
          } else if (form.template.template.type.id == 3) {
            if (filterStates['surveys']) {
              if (filterStates['last7days']) {
                if (isLast7Days(form)) temp.push(form)
              } else temp.push(form)
            }
          }
        })
      }
      setFilteredForms(temp)
      setFormInstances(forms)
    }
  }, [incidents, checklists, surveys])
  useEffect(() => {
    if (formInstances != null && formInstances.length > 0) {
      let temp: any = []
      formInstances.map(form => {
        if (form.template.template.type.id == 7) {
          if (filterStates['checklists']) {
            if (filterStates['last7days']) {
              if (isLast7Days(form)) temp.push(form)
            } else temp.push(form)
          }
        } else if (form.template.template.type.id == 2) {
          if (filterStates['incidents']) {
            if (filterStates['last7days']) {
              if (isLast7Days(form)) temp.push(form)
            } else temp.push(form)
          }
        } else if (form.template.template.type.id == 3) {
          if (filterStates['surveys']) {
            if (filterStates['last7days']) {
              if (isLast7Days(form)) temp.push(form)
            } else temp.push(form)
          }
        }
      })

      temp.sort((a, b) => {
        const dateA: any = new Date(a['updated_at'])
        const dateB: any = new Date(b['updated_at'])
        return dateB - dateA
      })
      setFilteredForms(temp)
    }
  }, [filterStates])

  const isLast7Days = form => {
    const todaysDate = new Date()
    const pastDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate() - 7)
    let started_at = new Date(form['started_at'])
    if (pastDate <= started_at && started_at <= todaysDate) {
      return true
    } else {
      return false
    }
  }
  const updateFilter = filter => {
    let temp = filterStates
    switch (filter.type) {
      case 'checkbox':
        temp[filter.name] = filter.checked
        break
      case 'date':
        temp[filter.name] = filter.value
        break
    }
    setFilterStates({ ...temp })
  }

  const previousPath = {
    previousPathTitle: 'Completed Forms',
    previousPathName: '/CompletedForms',
    previousPathState: {
      propertyForms: false,
    },
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Property',
      editable: false,
      width: 140,
      valueGetter: params => {
        if (params.row.property !== null) return params.row.property.name
        else return params.row.organisation.organisation_name
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 215,
      editable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 120,
      editable: false,
      valueGetter: params => {
        return params.row.template.template.type.title
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 120,
      editable: false,
      valueGetter: params => {
        return params.row.template.template.category.title
      },
    },
    {
      field: 'full_name',
      headerName: 'Author',
      width: 120,
      editable: false,
      valueGetter: params => {
        if (params.row.assignee !== null) return params.row.assignee.full_name
        else return 'No Assignee'
      },
    },
    {
      field: 'updated_at',
      headerName: 'Last Updated',
      width: 150,
      editable: false,
      valueGetter: params => {
        return FormatDate(params.row.updated_at)
      },
    },
  ]
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="end" mb={1}>
        <input
          className={['label-popup', c.inputstyle].join(' ')}
          type="checkbox"
          name="last7days"
          onClick={e => updateFilter(e.target)}
          defaultChecked
        />
        <Typography>Last 7 Days</Typography>
        <Tooltip title={'Toggle on/off to display forms submitted within the last 7 days'}>
          <InfoOutlinedIcon className={c.iconStyle} />
        </Tooltip>
      </Stack>
      {filteredForms !== null ? (
        <div style={{ height: '800px' }}>
          <DataGrid
            components={{
              Toolbar: GridToolbar,
            }}
            rows={loading ? [] : filteredForms}
            loading={loading}
            columns={columns}
            onRowDoubleClick={form => ViewForm(form, history, null, previousPath)}
            density="standard"
          />
        </div>
      ) : (
        <CardLoader />
      )}
    </>
  )
}

export default PortfolioForms
