import { ReactNode } from 'react'
import { useLocationAlerts } from '../../../hooks/alerts'
import { Card, Stack } from '@mui/material'
import Badge from '@mui/material/Badge'
import { Box, Typography } from '@mui/material'

export const Notice = ({ icon, children }: { icon: string; children: ReactNode }) => {
  return (
    <div className="notice__box">
      <img className="notice__icon" src={icon} alt="" />
      <Stack direction="column" whiteSpace="pre-wrap">
        {children}
      </Stack>
    </div>
  )
}

const AlertsCard = ({ propertyId, ...props }) => {
  const { data: alerts } = useLocationAlerts(propertyId, {
    only_active: true,
  })
  const getAlertImage = severity_level => {
    switch (severity_level) {
      case 'Flood_1':
        return require('../../../images/Severe_Flood_Warning_small.png')

      case 'Flood_2':
        return require('../../../images/Flood_Warning_small.png')

      case 'Flood_3':
        return require('../../../images/Flood_Alert_small.png')
      case 'Arson_3':
      case 'Metal Theft_3':
      case 'Armed Robbery_3':
      case 8:
      case 9:
        return require('../../../images/crime_alert_small.png')
      case 'Rainfall_3':
      case 'Heavy Rainfall_3':
        return require('../../../images/rainfall_alert_small.png')
      case 'Wind_3':
      case 'High Wind_3':
        return require('../../../images/wind_alert_small.png')
      case 'High Temperature_3':
        return require('../../../images/temp_alert_small.png')
      case 'YELLOW':
        return require('../../../images/yellowwarning.png')
      case 'AMBER':
        return require('../../../images/amberwarning.png')
      case 'RED':
        return require('../../../images/redwarning.png')
      case 'Pandemic_3':
        return require('../../../images/pandemic_alert_small.png')
      case 'River_3':
        return require('../../../images/river_level_warning_small.png')
      case 'River_2':
        return require('../../../images/river_level_alert_small.png')
      default:
        return require('../../../images/alert_icon.png')
    }
  }

  const showAlerts = () => {
    if (alerts && alerts.length) {
      return alerts.map((alerts, index) => (
        <Notice
          key={index}
          children={alerts.message}
          icon={getAlertImage((alerts.type.display_name || 'Flood') + '_' + alerts.severity)}
        />
      ))
    }
  }

  const showWeatherWarnings = () => {
    if (props.activeweatherwarnings && props.activeweatherwarnings.length) {
      return props.activeweatherwarnings.map((warning, index) => (
        <Notice key={index} children={warning.summary} icon={require('../../../images/alert_icon.png')} />
      ))
    }
  }

  const numberOfAlerts: number = [
    alerts?.length ?? 0,
    props.gaugereadings?.length ?? 0,
    props.activeweatherwarnings?.length ?? 0,
  ].reduce((sum, length) => sum + length, 0)

  return (
    <>
      <Card>
        <Badge
          badgeContent={numberOfAlerts}
          color="primary"
          sx={{
            '& .MuiBadge-badge': {
              right: -15,
              top: 12,
              padding: '0 4px',
            },
          }}>
          <Typography>Alerts</Typography>
        </Badge>
        <Box my={1}>
          {numberOfAlerts > 0 ? (
            <>
              <div className="dashboard-card-body">
                {showAlerts()}
                {showWeatherWarnings()}
              </div>
            </>
          ) : null}
        </Box>
      </Card>
    </>
  )
}

export default AlertsCard
