import { DataGrid, getGridSingleSelectOperators, GridToolbar } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { RiskLevelTag } from '../components/widgets/RiskLevelTag'
import { Risk, RiskType, calculatePropertyRiskScore, calculateRiskScore } from 'balkerne-core/risks'
import { useMemo } from 'react'
import { useLocationsWithRisks } from '../hooks/locations'
import Page from '../components/Page'
import siteMap from '../siteMap'
import { useHistory } from 'react-router-dom'
import { usePortfolioRiskBreakdown } from '../hooks/analytics'

type RiskInsightData = {
  propertyId: number
  propertyName: string
  score: number
  [key: string]: any
}

type RiskScores = {
  [key: string]: any
}

const getRiskRenderCell = (riskType: string) => {
  return (data: { row: { [x: string]: any } }) => <RiskLevelTag value={data.row[riskType]} />
}
const columns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
  },
  {
    field: 'flood_score',
    headerName: 'Combined Flood Risk',
    width: 150,
    valueOptions: [
      { value: 0, label: 'None' },
      { value: 2, label: 'Low' },
      { value: 3, label: 'Medium' },
      { value: 4, label: 'High' },
    ],
    valueGetter: params => {
      if (params.row.flood_score >= 4) {
        return Risk.HIGH
      } else if (params.row.flood_score >= 2) {
        return Risk.MEDIUM
      } else if (params.row.flood_score == 1) {
        return Risk.LOW
      } else {
        return Risk.NONE
      }
    },
    filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
    renderCell: params => {
      return <RiskLevelTag value={params.value} />
    },
  },
  {
    field: RiskType.SURFACE_FLOOD,
    headerName: 'Surface Water',
    minWidth: 115,
    renderCell: getRiskRenderCell(RiskType.SURFACE_FLOOD),
    valueOptions: [
      { value: 0, label: 'None' },
      { value: 1, label: 'Negligible' },
      { value: 2, label: 'Low' },
      { value: 3, label: 'Medium' },
      { value: 4, label: 'High' },
    ],
    filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
  },
  {
    field: RiskType.RIVER_SEA,
    headerName: 'River & Sea',
    renderCell: getRiskRenderCell(RiskType.RIVER_SEA),
    valueOptions: [
      { value: 0, label: 'None' },
      { value: 1, label: 'Negligible' },
      { value: 2, label: 'Low' },
      { value: 3, label: 'Medium' },
      { value: 4, label: 'High' },
    ],
    filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
  },
]

export function RiskInsightTable() {
  const history = useHistory()
  const { data, isLoading } = usePortfolioRiskBreakdown()

  const riskBreakdownData = useMemo(() => {
    if (!data) return []
    const properties = Object.keys(data.properties).map(prop_id => {
      const property = data.properties[prop_id]
      const risks = property.risks.reduce((acc, risk) => {
        acc[risk.type] = risk.value
        return acc
      }, {})
      return {
        id: prop_id,
        name: property.name,
        flood_score: property.flood_score,
        determined_risk_level: property.determined_risk_level,
        ...risks,
      }
    })
    properties.sort((a, b) => b.determined_risk_level - a.determined_risk_level)
    return properties
  }, [data])
  return (
    <Page title="Risk Breakdown" back>
      <Box height="700">
        <DataGrid
          columns={columns}
          rows={riskBreakdownData}
          // getRowId={row => row.propertyId}
          components={{ Toolbar: GridToolbar }}
          onRowDoubleClick={row => {
            history.push(siteMap.Property.getPath(row.id))
          }}
        />
      </Box>
    </Page>
  )
}
