import React, { Ref, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Map, { FillLayer, Layer, MapRef, MapboxMap, Marker, Source } from 'react-map-gl'
import ConfigProvider from 'balkerne-core/config'
import PlaceIcon from '@mui/icons-material/Place'
import buildCircle from '@turf/circle'
import bbox from '@turf/bbox'
import { useAlertArea } from '../hooks/alerts'
import axios from 'axios'
import mapboxgl from 'mapbox-gl'
import { FeatureCollection } from '@turf/turf'
import { Area, LatestAreaMetric } from '../types/areas'

type AlertAreaData = {
  data: Area | null
}

type AreaMapProps = {
  areaId?: string
  initLng?: number
  initLat?: number
  areas?: Area[]
  onMapClick?: (e: mapboxgl.MapLayerMouseEvent) => void
  children?: React.ReactNode
}

const AreaMap = forwardRef((props: AreaMapProps, ref: Ref<any>) => {
  const { areaId, onMapClick, initLng, initLat, areas, children } = props
  const mapRef = ref || useRef()
  const INIT_LNG = initLng || -0.1442
  const INIT_LAT = initLat || 51.5072
  const { data: area } = useAlertArea(areaId) as AlertAreaData
  const [hoveredAreaId, setHoveredAreaId] = useState<number | null>(null)

  useEffect(() => {
    if (area && typeof mapRef === 'object' && mapRef.current) {
      const map = mapRef.current
      fitMapToBounds(map, area.geometry)
    }
  }, [area])

  const areaFeatures = useMemo(() => {
    if (!areas) return null
    const circleFeatures: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    }
    const polygonFeatures: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    }
    areas.forEach((area, index) => {
      const { geometry, ...rest } = area
      const collection = area?.type?.point ? circleFeatures : polygonFeatures
      collection.features.push({
        id: index,
        type: 'Feature',
        properties: {
          ...rest,
        },
        geometry: geometry,
      })
    })
    return { circleFeatures, polygonFeatures }
  }, [areas])

  const onHover = useCallback(
    event => {
      const newAreaId = event.features?.[0]?.properties.id
      if (hoveredAreaId !== newAreaId) {
        setHoveredAreaId(newAreaId ?? null)
      }
    },
    [hoveredAreaId],
  )

  return (
    <Map
      ref={mapRef}
      reuseMaps
      initialViewState={{
        longitude: INIT_LNG,
        latitude: INIT_LAT,
        zoom: 8,
      }}
      style={{ width: '100%', height: '100%', zIndex: 0 }}
      mapStyle={'mapbox://styles/mapbox/dark-v9'}
      mapboxAccessToken={ConfigProvider.mapboxPublicKey}
      cursor="auto"
      onClick={onMapClick}
      onMouseMove={onHover}
      interactiveLayerIds={['polygon-layer', 'circle-layer']}>
      {children}
      {areaFeatures && (
        <>
          <PolygonFeatures data={areaFeatures?.polygonFeatures} hoveredAreaId={hoveredAreaId} />
          <CircleFeatures data={areaFeatures?.circleFeatures} hoveredAreaId={hoveredAreaId} />
        </>
      )}
      {area && <SelectedAreaSource area={area} />}
    </Map>
  )
})

const CircleFeatures = React.memo(({ data, hoveredAreaId }: { data: any; hoveredAreaId: any }) => {
  if (data.features.length === 0) return null
  return (
    <Source key={'circle-source'} id="circle-source" type="geojson" data={data}>
      <Layer
        id="circle-layer"
        type="circle"
        paint={{
          'circle-color': ['case', ['==', ['get', 'id'], hoveredAreaId || ''], '#0F0', '#088'],
          'circle-opacity': 0.5,
          'circle-radius': 10,
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff',
        }}
      />
    </Source>
  )
})

const PolygonFeatures = React.memo(({ data, hoveredAreaId }: { data: any; hoveredAreaId: any }) => {
  if (data.features.length === 0) return null
  return (
    <Source key={'polygon-source'} id="polygon-source" type="geojson" data={data}>
      <Layer
        id="polygon-layer"
        type="fill"
        paint={{
          'fill-color': ['case', ['==', ['get', 'id'], hoveredAreaId || ''], '#0F0', '#088'],
          'fill-opacity': 0.5,
        }}
      />
    </Source>
  )
})

export const SelectedAreaSource = ({ area }: { area: Area }) => {
  return (
    <Source id="selected-area-source" type="geojson" data={area.geometry}>
      {area.type?.point ? (
        <Layer
          key={'selected-area-circle'}
          id="selected-area-circle"
          type="circle"
          paint={{
            'circle-color': '#088',
            'circle-radius': 10,
            'circle-stroke-width': 3,
            'circle-stroke-color': '#fff',
          }}
        />
      ) : (
        <Layer
          key={'selected-area-fill'}
          id="selected-area-fill"
          type="fill"
          paint={{
            'fill-color': '#088',
            'fill-opacity': 0.8,
          }}
        />
      )}
    </Source>
  )
}

export const fitMapToBounds = (map: any, geometry: any) => {
  const bounds = bbox(geometry)
  map.fitBounds(bounds, { duration: 2500, padding: 40, maxZoom: 13 })
}

type PinMarkerProps = {
  lat: number
  lng: number
  radius?: number
  onDragEnd?: (lat: number, lng: number) => void
  onBboxChange?: (bbox: number[]) => void
}

export const PinMarker: React.FC<PinMarkerProps> = ({ lat, lng, radius, onDragEnd, onBboxChange }) => {
  const handleDragEnd = useCallback(
    (e: { lngLat: { lat: number; lng: number } }) => {
      onDragEnd && onDragEnd(e.lngLat.lat, e.lngLat.lng)
    },
    [onDragEnd],
  )

  // Circle logic
  const circle = useMemo(() => {
    if (!radius) return null
    const _circle = buildCircle([lng, lat], radius, { units: 'kilometers', steps: 16 })
    return _circle
  }, [lat, lng, radius])

  useEffect(() => {
    onBboxChange && onBboxChange(bbox(circle))
  }, [circle, onBboxChange])

  return (
    <>
      <Marker latitude={lat} longitude={lng} draggable onDragEnd={handleDragEnd} anchor="center">
        <PlaceIcon sx={{ color: 'lightgrey' }} />
      </Marker>

      {circle && (
        <Source id="pin-radius" type="geojson" data={circle}>
          <Layer
            id="pin-radius"
            type="fill"
            paint={{
              'fill-color': '#088',
              'fill-opacity': 0.2,
              'fill-outline-color': '#088',
            }}
          />
        </Source>
      )}
    </>
  )
}

export default AreaMap
