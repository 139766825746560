import { useState, useEffect, useCallback } from 'react'
import { useLocations, usePropertyAttributeCategories } from '../hooks/locations'
import CircularProgress from '@mui/material/CircularProgress'
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridValueGetterParams,
  GridRowId,
  GridToolbarContainer,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import api from 'balkerne-core/api'
import { useHistory } from 'react-router-dom'
import { PermissionGate, PropertyPermissions } from 'balkerne-core/roles'
import siteMap from '../siteMap'
import { Box, Stack, useTheme } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

const PropertyList = () => {
  const { data: locations, isLoading, isError, refetch, isRefetching } = useLocations()
  const { data: attributeCategories, isLoading: attributeCategoriesLoading } = usePropertyAttributeCategories()
  const theme = useTheme()
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 250,
      editable: false,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      minWidth: 250,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => `${params.row?.address.street}, ${params.row?.address.town}`,
    },
    {
      field: 'postcode',
      headerName: 'Postcode',
      width: 100,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row?.address.postcode,
    },
    {
      field: 'property_customer_reference',
      headerName: 'Reference',
      minWidth: 300,
      flex: 1,
      editable: false,
    },
  ])
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    name: true,
    address: true,
    postcode: true,
    property_customer_reference: true,
  })
  const [selectedId, setSelectedId] = useState<GridRowId | undefined>(undefined)
  const history = useHistory()

  useEffect(() => {
    if (!attributeCategoriesLoading) {
      const cols = [...columns]
      const colVisibility = { ...columnVisibilityModel }
      attributeCategories.forEach((category: { name: string; id: number }) => {
        cols.push({
          field: category.name,
          headerName: category.name,
          width: 150,
          valueGetter: params => {
            const attribute = params.row.attributes.find(
              (attribute: { category_id: number }) => attribute.category_id == category.id,
            )
            return attribute ? attribute.value : ''
          },
        })
        if (colVisibility[category.name] === undefined) {
          colVisibility[category.name] = false
        }
      })
      setColumns(cols)
      setColumnVisibilityModel(colVisibility)
    }
  }, [attributeCategoriesLoading])

  const removeProperty = () => {
    const answer = window.confirm('Are you sure you want to delete the property?')
    if (answer) {
      api
        .delete(`/locations/${selectedId}`)
        .then(() => {
          refetch()
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  const handleRedirect = (propertyId: GridRowId) => {
    history.push(siteMap.Property.getPath(propertyId))
  }

  const CustomGridToolbar = useCallback(
    () => (
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" gap={1}>
          <PermissionGate action={PropertyPermissions.Add}>
            <Button size="small" onClick={() => history.push(siteMap.AddProperties.path)} variant="contained">
              <AddRoundedIcon fontSize="small" />
              Add Property
            </Button>
            <Button size="small" onClick={() => history.push(siteMap.PropertyGroups.path)} variant="outlined">
              Groups
            </Button>
          </PermissionGate>
          <PermissionGate action={PropertyPermissions.Delete}>
            <Button
              size="small"
              onClick={removeProperty}
              variant="outlined"
              disabled={selectedId == null || isRefetching}>
              Delete
            </Button>
          </PermissionGate>
        </Stack>
        <GridToolbar />
      </GridToolbarContainer>
    ),
    [selectedId, isRefetching],
  )

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
        }}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box height={800} mb={6}>
      {locations && (
        <DataGrid
          components={{
            Toolbar: CustomGridToolbar,
          }}
          sx={{ backgroundColor: theme.palette.background.default }}
          rows={isRefetching ? [] : locations}
          loading={isRefetching}
          columns={columns}
          disableColumnSelector={false}
          onRowClick={property => setSelectedId(property.id)}
          onRowDoubleClick={property => handleRedirect(property.id)}
          density="compact"
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={model => setColumnVisibilityModel(model)}
        />
      )}
      {isError && <div className="alertbox">Loading error</div>}
    </Box>
  )
}

export default PropertyList
