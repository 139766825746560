import { Box, Button, Tab, Tabs } from '@mui/material'
import { DataGrid, GridColDef, GridRowId, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid'
import { TemplatePermissions, usePermissions } from 'balkerne-core/roles'
import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormTemplates } from '../../hooks/forms'
import { FormTemplatePrincipal } from '../../types/forms'
import { useHistory } from 'react-router-dom'
import siteMap from '../../siteMap'

function TabPanel(props) {
  const { children, selectedTab, value, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== value}
      id={`${value}-templates-panel`}
      aria-labelledby={`${value}-templates-tab`}
      {...other}>
      {selectedTab === value && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
}

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    width: 215,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 215,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.description
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 215,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.type.title
    },
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 215,
    editable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.category.title
    },
  },
]
const NewTemplateManager = () => {
  const { possible } = usePermissions()
  const history = useHistory()
  const [tab, setTab] = useState<'organisation' | 'global'>('organisation')
  const isAdmin = possible(TemplatePermissions.ManageSystem)

  const handleTemplateSelect = (id: number) => {
    history.push(siteMap.FormTemplate.getPath(id))
  }
  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
          <Tab
            id="organisation-templates-tab"
            label="Organisation"
            aria-controls="organisation-templates-panel"
            value="organisation"
          />
          <Tab id="global-templates-tab" label="Global" aria-controls="global-templates-panel" value="global" />
        </Tabs>
        <TabPanel selectedTab={tab} value="organisation">
          <OrganisationTemplates onSelect={handleTemplateSelect} isAdmin={isAdmin} />
        </TabPanel>
        <TabPanel selectedTab={tab} value="global">
          <GlobalTemplates onSelect={handleTemplateSelect} isAdmin={isAdmin} />
        </TabPanel>
      </Box>
    </div>
  )
}
const OrganisationTemplates = ({ onSelect, isAdmin }: { onSelect: (id: number) => void; isAdmin: boolean }) => {
  const { data: templates, isLoading } = useFormTemplates({
    form_manager: isAdmin ? 'all' : 'organisation',
  })
  const filteredTemplates = useMemo(() => {
    if (templates.length > 0 && isAdmin) {
      return templates.filter((template: FormTemplatePrincipal) => template.organisation)
    }
    return []
  }, [templates, isAdmin])

  return (
    <Box>
      <DataGrid
        components={{
          Toolbar: GridToolbar,
        }}
        sx={{ height: '800px' }}
        rows={isAdmin ? filteredTemplates : templates}
        loading={isLoading}
        columns={
          isAdmin
            ? [
                {
                  field: 'organisation',
                  headerName: 'Organisation',
                  width: 215,
                  editable: false,
                  valueGetter: (params: GridValueGetterParams) => {
                    console.log('params', params)
                    return params.row.organisation.organisation_name
                  },
                },
                ...columns,
              ]
            : columns
        }
        disableColumnSelector={true}
        onRowDoubleClick={template => onSelect(template.row.id)}
      />
    </Box>
  )
}

const GlobalTemplates = ({ onSelect, isAdmin }: { onSelect: (id: number) => void; isAdmin: boolean }) => {
  const { data: templates, isLoading } = useFormTemplates({ form_manager: isAdmin ? 'all' : 'global' })

  const filteredTemplates = useMemo(() => {
    if (templates.length > 0 && isAdmin) {
      return templates.filter((template: FormTemplatePrincipal) => template.organisation === null)
    }
    return []
  }, [templates, isAdmin])

  return (
    <Box>
      <DataGrid
        components={{
          Toolbar: GridToolbar,
        }}
        sx={{ height: '800px' }}
        rows={isAdmin ? filteredTemplates : templates}
        columns={columns}
        loading={isLoading}
        disableColumnSelector={true}
        onRowDoubleClick={template => onSelect(template.row.id)}
      />
    </Box>
  )
}
export default NewTemplateManager
