import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Fade from '@mui/material/Fade'
import InputLabel from '@mui/material/InputLabel'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import Timeline from '@mui/icons-material/Timeline'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { useLocationAlerts, useAlertTypes, useEventAlerts } from '../hooks/alerts'
import { DisplaySeverity, Severity } from '../core/alerts'
import { dateComparator } from 'balkerne-fn/sort'
import { ago } from 'balkerne-core/time'

export const AlertHistory = ({ propertyId }) => {
  const [eventId, setEventId] = useState()
  const [severity, setSeverity] = useState('')
  const [type, setType] = useState('')
  const [open, setOpen] = useState(false)

  const { data: eventAlerts } = useEventAlerts(eventId)
  const { data: alertTypes } = useAlertTypes()
  const { data: alerts, isLoading } = useLocationAlerts(propertyId, {
    only_active: false,
  })

  const handleReset = () => {
    setSeverity('')
    setType('')
  }
  const handleAlertClick = eventId => {
    setEventId(eventId)
    setOpen(true)
  }

  const typeSelection = [['', 'All'], ...alertTypes.map(type => [type.name, type.display_name])]
  const severitySelection = [['', 'All'], ...Object.entries(DisplaySeverity).map(([key, value]) => [key, value])]

  const renderAlerts = () => {
    const filteredAlerts = alerts.filter(function (alert) {
      return (type ? alert.type === type : true) && (severity ? alert.severity === Number.parseInt(severity) : true)
    })
    return filteredAlerts.sort(dateComparator('created_at', false)).map(alert => (
      <TableRow hover key={alert.id} onDoubleClick={() => handleAlertClick(alert.event_id)}>
        <TableCell>{alert.type_display}</TableCell>
        <TableCell>{DisplaySeverity[alert.severity]}</TableCell>
        <TableCell>{alert.title}</TableCell>
        <TableCell>{alert.message}</TableCell>
        <TableCell>{ago(alert.created_at)}</TableCell>
        <TableCell>
          <IconButton size="small" onClick={() => handleAlertClick(alert.event_id)}>
            <Timeline />
          </IconButton>
        </TableCell>
      </TableRow>
    ))
  }
  return (
    <>
      <Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1">Alert History</Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '150px 150px auto',
              gap: 2,
            }}>
            <FormControl variant="outlined" size="small">
              <InputLabel id="type">Type</InputLabel>
              <Select labelId="type" label="Type" onChange={e => setType(e.target.value)} value={type}>
                {typeSelection.map(([name, text]) => (
                  <MenuItem key={name} value={name}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
              <InputLabel id="severity">Severity</InputLabel>
              <Select labelId="severity" label="Severity" onChange={e => setSeverity(e.target.value)} value={severity}>
                {severitySelection.map(([severity, text]) => (
                  <MenuItem key={severity} value={severity}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div>
              <IconButton size="small" onClick={() => handleReset()}>
                <RotateLeftIcon />
              </IconButton>
            </div>
          </Box>
        </Box>
        {/* Active Alerts Table */}
        <TableContainer sx={{ width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Severity</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Issued at</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{alerts && renderAlerts()}</TableBody>
          </Table>
        </TableContainer>
        {alerts?.length === 0 && (
          <Box
            sx={{
              textAlign: 'center',
              padding: 20,
              width: '100%',
            }}>
            {isLoading ? <CircularProgress /> : <Typography variant="body1">No alerts</Typography>}
          </Box>
        )}
      </Card>
      {/* Alert Details Modal */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              minHeight: 300,
            }}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2">
                  Progression
                </Typography>
              </CardContent>
              <TableContainer sx={{ width: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Severity</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Issued at</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventAlerts?.sort(dateComparator('created_at', false)).map(alert => (
                      <TableRow hover key={alert.id}>
                        <TableCell>{DisplaySeverity[alert.severity]}</TableCell>
                        <TableCell>{alert.title}</TableCell>
                        <TableCell>{alert.message}</TableCell>
                        <TableCell>{ago(alert.created_at)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <CardActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
              </CardActions>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default AlertHistory
