import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import React, { ReactElement, useCallback, useState } from 'react'
import { usePortfolioFloodClimateProjections } from '../../hooks/analytics'
import { Line } from 'react-chartjs-2'
import api from 'balkerne-core/api'
import { HelpRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import type { Scenario } from '../../types/climate'

type RCPScenario = 'rcp26' | 'rcp45' | 'rcp60' | 'rcp85'
enum RiskSeverity {
  Low = 1,
  Moderate = 2,
  ModeratetoHigh = 3,
  High = 4,
  VeryHigh = 5,
}

const chartOpacity = '9A'
const colorScheme = {
  Low: '#e9f7b0' + chartOpacity,
  Moderate: '#f7af4b' + chartOpacity,
  ModerateToHigh: '#eb6526' + chartOpacity,
  High: '#991e40' + chartOpacity,
  VeryHigh: '#260130' + chartOpacity,
}

const Row = ({ children }: { children: ReactElement[] }) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ my: 1 }}>
    {children}
  </Stack>
)

const RowNumber = ({ children, bgColor, light }: { children: ReactElement[]; bgColor: string; light?: boolean }) => {
  const theme = useTheme()
  const _color = light ? theme.palette.text.light : undefined
  return (
    <Stack
      direction="row"
      justifyContent="end"
      sx={{
        backgroundColor: bgColor,
        px: 1.5,
        borderRadius: 1,
        color: _color,
      }}>
      {children}
    </Stack>
  )
}

const ScenarioDialogTooltip = () => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      <Tooltip
        title={
          <>
            <div>
              <strong>RCP 2.6</strong> envisions a rapid decline in greenhouse gas emissions and a peak in atmospheric
              CO2 before 2050. This best-case scenario requires aggressive climate policies to limit global warming to
              below 2°C above pre-industrial levels, aligning with the Paris Agreement goals.
            </div>
            <br />
            <div>
              <strong>RCP 4.5</strong> assumes a transition to a sustainable global energy system, stabilizing emissions
              by mid-century. Policies promote energy efficiency, decarbonization, and cleaner energy, resulting in a
              global mean temperature increase of approximately 2.4°C above pre-industrial levels.
            </div>
            <br />
            <div>
              <strong>RCP 6.0</strong>: greenhouse gas emissions rise throughout the 21st century but at a slower rate
              due to some mitigation measures. Radiative forcing stabilizes at 6.0 W/m² by 2100, with global mean
              temperature increasing by approximately 3°C above pre-industrial levels.
            </div>
            <br />
            <div>
              <strong>RCP 8.5</strong> is a high-emissions "business-as-usual" scenario. Continual reliance on fossil
              fuels leads to increasing greenhouse gas emissions throughout the 21st century. Global mean temperature is
              projected to increase by 4.3°C or more above pre-industrial levels, causing severe climate consequences.
            </div>
          </>
        }
        placement="bottom">
        <IconButton onClick={() => setOpen(!open)} size="small">
          <HelpRounded sx={{ width: 16, mx: 0.5 }} />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <strong>Representative Concentration Pathways (RCPs)</strong>
        </DialogTitle>
        <DialogContent>
          <div>
            <strong>RCP 2.6</strong> is an emissions scenario that outlines a stringent and ambitious mitigation
            pathway. It envisions a rapid decline in greenhouse gas emissions and a peak in atmospheric CO2
            concentration before 2050, resulting in a stable radiative forcing of 2.6 W/m² by 2100. RCP 2.6 represents a
            best-case scenario in which aggressive climate policies are enacted to limit global warming to well below
            2°C above pre-industrial levels, in line with the goals of the Paris Agreement.
          </div>
          <br />
          <div>
            <strong>RCP 4.5</strong> is a moderate emissions scenario that assumes a transition to a more sustainable
            global energy system. Under this pathway, greenhouse gas emissions are projected to stabilize by mid-century
            due to the implementation of policies that promote energy efficiency, decarbonization, and the use of
            cleaner energy sources. RCP 4.5 corresponds to a radiative forcing of 4.5 W/m² by 2100 and is expected to
            result in a global mean temperature increase of approximately 2.4°C above pre-industrial levels.
          </div>
          <br />
          <div>
            <strong>RCP 6.0</strong> represents a scenario in which greenhouse gas emissions continue to rise throughout
            the 21st century but at a slower rate than in the absence of climate policies. This scenario assumes the
            implementation of some mitigation measures, leading to a stabilization of radiative forcing at 6.0 W/m² by
            the end of the century. However, under RCP 6.0, global mean temperature is projected to increase by
            approximately 3°C above pre-industrial levels, posing significant risks to human societies and ecosystems.
          </div>
          <br />
          <div>
            <strong>RCP 8.5</strong> is a high-emissions scenario often referred to as the "business-as-usual"
            trajectory. This pathway assumes that global society continues to heavily rely on fossil fuels, leading to a
            continuous increase in greenhouse gas emissions throughout the 21st century. Under RCP 8.5, radiative
            forcing reaches 8.5 W/m² by 2100, and global mean temperature is projected to increase by 4.3°C or more
            above pre-industrial levels. This scenario would have severe consequences for the global climate system,
            including extreme weather events, sea-level rise, and widespread ecological disruption.
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const FloodScore = ({ rcp }: { rcp: Scenario }) => {
  const { data: projections, isLoading } = usePortfolioFloodClimateProjections()
  const [periodIndex, setPeriodIndex] = useState<number>(0)
  const [isExporting, setIsExporting] = useState<boolean>(false)

  if (isLoading) {
    return <div>Loading...</div>
  }

  const downloadXLSX = async () => {
    /* Download a XLSX file from the API and save it to the user's computer. 
       On download, a temporary link is created and automatically clicked to trigger a save to the user's computer. */
    setIsExporting(true)
    await api.get('/analytics/climate-projections/flood/export', { responseType: 'blob' }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'climate-projections.xlsx')
      document.body.appendChild(link)
      link.click()
    })
    setIsExporting(false)
  }

  const period = projections['dates'][periodIndex]
  const veryHigh = projections?.[rcp][RiskSeverity.VeryHigh][periodIndex]
  const high = projections?.[rcp][RiskSeverity.High][periodIndex]
  const moderateToHigh = projections?.[rcp][RiskSeverity.ModeratetoHigh][periodIndex]
  const moderate = projections?.[rcp][RiskSeverity.Moderate][periodIndex]
  const low = projections?.[rcp][RiskSeverity.Low][periodIndex]

  const labelVariant = 'body2'
  const valueVariant = 'h6'

  return (
    <>
      <Stack direction="row" gap={2}>
        <Stack minWidth={250} gap={2}>
          {/* Portfolio Card */}
          <Card>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Portfolio</Typography>
              {/* Export  */}
              <LoadingButton
                size="small"
                onClick={downloadXLSX}
                variant="outlined"
                loading={isExporting}
                loadingPosition="start">
                Export
              </LoadingButton>
            </Stack>
            <Row>
              <Typography variant={labelVariant}>Period</Typography>
              <Typography variant={valueVariant}>
                {period[0]} - {period[1]}
              </Typography>
            </Row>
            <Divider />
            <Row>
              <Typography variant={labelVariant}>Very High</Typography>
              <Typography variant={valueVariant}>
                <RowNumber light bgColor={colorScheme.VeryHigh}>
                  {veryHigh}
                </RowNumber>
              </Typography>
            </Row>
            <Divider />
            <Row>
              <Typography variant={labelVariant}>High</Typography>
              <Typography variant={valueVariant}>
                <RowNumber light bgColor={colorScheme.High}>
                  {high}
                </RowNumber>
              </Typography>
            </Row>
            <Divider />
            <Row>
              <Typography variant={labelVariant}>Mod. to High</Typography>
              <Typography variant={valueVariant}>
                <RowNumber bgColor={colorScheme.ModerateToHigh}>{moderateToHigh}</RowNumber>
              </Typography>
            </Row>
            <Divider />
            <Row>
              <Typography variant={labelVariant}>Moderate</Typography>
              <Typography variant={valueVariant}>
                <RowNumber bgColor={colorScheme.Moderate}>{moderate}</RowNumber>
              </Typography>
            </Row>
            <Divider />
            <Row>
              <Typography variant={labelVariant}>Low </Typography>
              <Typography variant={valueVariant}>
                <RowNumber bgColor={colorScheme.Low}>{low}</RowNumber>
              </Typography>
            </Row>
          </Card>
        </Stack>

        {/* Portfolio by Period Stacked Chart */}
        <Box sx={{ width: '100%' }}>
          <Card sx={{ width: '100%' }}>
            <Typography variant="h6">Portfolio by Period</Typography>
            <Box sx={{ mx: 4, py: 0 }}>
              <Slider
                defaultValue={0}
                valueLabelFormat={value => projections['dates'][value][0]}
                valueLabelDisplay="auto"
                onChange={(e, value) => setPeriodIndex(value as number)}
                marks
                min={0}
                max={15}
              />
            </Box>
            <Box sx={{ height: '700px' }}>
              <Line
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: false,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  scales: {
                    x: {
                      ticks: {
                        autoSkip: true,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                      },
                      stacked: true,
                    },
                  },
                }}
                data={{
                  labels: projections['dates'].map(([start, end]) => `${start} - ${end}`),
                  datasets: [
                    {
                      label: 'Low',
                      data: projections[rcp][1],
                      order: 5,
                      fill: 'stack',
                      backgroundColor: colorScheme['Low'],
                      pointRadius: 0,
                      borderColor: 'transparent',
                    },
                    {
                      label: 'Moderate',
                      data: projections[rcp][2],
                      order: 4,
                      fill: 'stack',
                      backgroundColor: colorScheme['Moderate'],
                      pointRadius: 0,
                      borderColor: 'transparent',
                    },
                    {
                      label: 'Moderate to High',
                      data: projections[rcp][3],
                      order: 3,
                      fill: 'stack',
                      backgroundColor: colorScheme['ModerateToHigh'],
                      pointRadius: 0,
                      borderColor: 'transparent',
                    },
                    {
                      label: 'High',
                      data: projections[rcp][4],
                      order: 2,
                      fill: 'stack',
                      backgroundColor: colorScheme['High'],
                      pointRadius: 0,
                      borderColor: 'transparent',
                    },
                    {
                      label: 'Very High',
                      data: projections[rcp][5],
                      order: 1,
                      fill: 'stack',
                      backgroundColor: colorScheme['VeryHigh'],
                      pointRadius: 0,
                      borderColor: 'transparent',
                    },
                  ],
                }}
              />
            </Box>
          </Card>
        </Box>
      </Stack>
    </>
  )
}

export default FloodScore
