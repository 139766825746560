import React, { useEffect, useState } from 'react'
import { Stack, TextField, Typography, MenuItem } from '@mui/material'
import { isInteger } from 'lodash'

const CSVHandler = ({ data, onSelectAll }) => {
  const [name, setName] = useState('')
  const [reference, setReference] = useState('')
  const [address, setAddress] = useState('')

  useEffect(() => {
    if (isInteger(name) && isInteger(reference) && isInteger(address)) {
      const properties = data.slice(1).map(row => ({
        name: row?.[name] ?? '',
        reference: row?.[reference] ?? '',
        address: row?.[address] ?? '',
        manualAddress: null,
        dpa: null,
        dpaSelection: [],
      }))
      onSelectAll(properties)
    } else {
      onSelectAll([])
    }
  }, [name, reference, address])

  const columns = data?.[0] ?? []

  return (
    <Stack gap={2} sx={{ mt: 2 }}>
      {/* Select relevant columns */}
      <Stack gap={1}>
        <Typography variant="body1">Select columns</Typography>
        <Stack gap={1}>
          <TextField select label="Name" value={name} onChange={e => setName(e.target.value)}>
            <MenuItem key="" value="" />
            {columns.map((col, i) => (
              <MenuItem key={i} value={i}>
                {col}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label="Reference" value={reference} onChange={e => setReference(e.target.value)}>
            <MenuItem key="" value="" />
            {columns.map((col, i) => (
              <MenuItem key={i} value={i}>
                {col}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label="Address" value={address} onChange={e => setAddress(e.target.value)}>
            <MenuItem key="" value="" />
            {columns.map((col, i) => (
              <MenuItem key={i} value={i}>
                {col}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CSVHandler
