import { Box, Tab, Tabs } from '@mui/material'
import { useTemplateTags } from '../../hooks/forms'
import { useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import TemplateTags from 'balkerne-components/TemplateTags'
import { createRoot } from 'react-dom/client'
import { FormBuilder, FormInputType } from '@ginkgo-bioworks/react-json-schema-form-builder'
import * as yup from 'yup'
import TemplatePreview from './TemplatePreview'
import PropTypes from 'prop-types'

type Template = {
  id: number
  versionId: number
}
type TemplateBuilderMode = 'create-version' | 'edit-version' | 'create-template' | 'edit-template'
type TemplateBuilderProps = {
  template: Template | null
  mode: TemplateBuilderMode
}
export type TemplateBuilderSubmitData = {
  template: {
    schema: string
    uischema: string
  }
  tags: {
    tag_id: number
    attribute: string
  }[]
}
export const templateSchema = yup.object({
  template: yup.object({
    schema: yup.string().required('Title is required'),
    uischema: yup.string().required('Description is required'),
  }),
  tags: yup.array(
    yup.object({
      tag_id: yup.number(),
      attribute: yup.string(),
    }),
  ),
})
const NewTemplateBuilder = ({ tags }: { tags?: any }) => {
  const { data: templateTags } = useTemplateTags()
  const [tab, setTab] = useState<'builder' | 'preview'>('builder')
  const [templateTagsDict, setTemplateTagsDict] = useState<any>(null)
  const [tagPaths, setTagPaths] = useState<{ [key: string]: any } | null>(null)
  const {
    control,
    watch,
    register,
    setValue,
    handleSubmit: formSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tags',
  })
  const schema = watch('template.schema')
  const uischema = watch('template.uischema')

  useEffect(() => {
    if (tags && templateTagsDict && tagPaths === null) {
      console.log('setting existing tags for version edit')
      const tempTags = {}
      Object.values(tags).forEach((tag: any) => {
        tempTags[tag.attribute] = { ...templateTagsDict[tag.tag_id] }
      })
      setTagPaths({ ...tempTags })
    }
  }, [tags, templateTagsDict])

  useEffect(() => {
    if (templateTags !== null) {
      console.log('setting template tags dict')
      var tagsDict = {}
      templateTags.map(tag => {
        tagsDict[tag.id] = tag
      })
      setTemplateTagsDict({ ...tagsDict })
    }
  }, [templateTags])

  useEffect(() => {
    console.log('tag paths changed')
    console.log(tagPaths)
    if (tagPaths !== null) {
      console.log('settings tags form value')
      // TODO: Fix issue with loading existing tags
      // if (tags) handleChange(schema, uischema)
      const tagsData = Object.keys(tagPaths).map((key: string) => {
        const templateTag = tagPaths[key]
        const templateTagData = {
          tag_id: templateTag['id'],
          attribute: key,
        }
        return templateTagData
      })
      setValue('tags', tagsData)
    }
  }, [tagPaths, tags])

  useEffect(() => {
    if (JSON.parse(schema).properties !== undefined) {
      if (Object.keys(JSON.parse(schema).properties).length > 0) {
        console.log('adding tags dropdown')
        addTagsDropdown()
        // Adding a margin-right to all radio buttons
        const radioelements = Array.from(document.getElementsByClassName('field-radio-group'))
        const checkboxelements = Array.from(document.getElementsByClassName('field-boolean'))
        const selectelements = Array.from(document.getElementsByTagName('select'))

        radioelements.map(radioelement => {
          Array.from(radioelement.children).map(children => {
            Array.from(children.getElementsByTagName('input')).map((input: any) => {
              input.style = 'margin-right: 5px'
            })
          })
        }),
          // Adding a margin-right to all checkboxes
          checkboxelements.map(checkboxelement => {
            Array.from(checkboxelement.children).map(children => {
              Array.from(children.getElementsByTagName('input')).map((input: any) => {
                input.style = 'margin-right: 10px'
              })
            })
          })
        // Map through each select element and set their width and margins
        selectelements.map((selectelement: any) => {
          if (selectelement.id !== 'selectTag') {
            selectelement.style = 'width: 100%;'
          }
        })
        /* disableObjectNameFormGroup() */
        disableExtraCheckboxes()
      }
    }
  }, [schema, uischema, tab])

  const addTagsDropdown = () => {
    const object_h5 = document.getElementsByTagName('h5')
    Array.from(object_h5).map(object => {
      if (object.textContent == 'Object Name ') {
        var parentDiv = object.parentElement?.parentElement?.parentElement
        if (parentDiv?.children[2].children[1] !== undefined) {
          if ((parentDiv?.children[2].children[1] as HTMLInputElement).name === 'Select Data Tag') {
          }
        } else {
          const tag_path =
            parentDiv?.parentElement?.parentElement?.parentElement?.parentElement?.getAttribute('data-rfd-draggable-id')
          if (document.getElementById(tag_path + '-tagcontainer') === null) {
            var tagContainer = document.createElement('div')
            tagContainer.id = tag_path + '-tagcontainer'
            var tagDropdown = (
              <TemplateTags
                templateTags={templateTagsDict}
                tagPaths={tagPaths}
                updateTagPaths={setTagPaths}
                tag_path={tag_path}
              />
            )
            const root = createRoot(tagContainer)
            root.render(tagDropdown)
            parentDiv?.insertBefore(tagContainer, parentDiv.children[2])
          }
        }
      }
    })
  }

  const disableExtraCheckboxes = () => {
    var allLabels = document.getElementsByTagName('label')
    Array.from(allLabels).map(label => {
      if (label.textContent == 'Display label is different from value') {
        if (label.parentElement?.parentElement && label.parentElement.parentElement.style.display != 'none') {
          label.parentElement.parentElement.style.display = 'none'
        }
      }
      if (label.textContent == 'Force number') {
        if (label.parentElement?.parentElement && label.parentElement.parentElement.style.display != 'none') {
          label.parentElement.parentElement.style.display = 'none'
        }
      }
    })
  }

  const customFormInputs: { [key: string]: FormInputType } = {
    fileAttachment: {
      displayName: 'Attach File',
      matchIf: [
        {
          types: ['array'],
        },
      ],
      defaultDataSchema: {
        type: 'array',
        items: {
          type: 'string',
          format: 'data-url',
        },
      },
      type: 'array',
      defaultUiSchema: {},
      cardBody: () => <div>Test</div>,
      modalBody: () => <div>Extra editing options in modal appear hear</div>,
    },
  }

  const handleChange = (newSchema, newUiSchema) => {
    console.log(newSchema)
    /* disableObjectNameFormGroup() */
    disableExtraCheckboxes()
    try {
      var schemaObj = JSON.parse(newSchema)
      var uiSchemaObj = JSON.parse(newUiSchema)

      if (schemaObj && typeof schemaObj === 'object') {
        setValue('template.schema', newSchema)
      }

      if (uiSchemaObj && typeof uiSchemaObj === 'object') {
        setValue('template.uischema', newUiSchema)
      }
    } catch (e) {
      console.log('Invalid schemas')
      setValue('template.schema', '{}')
      setValue('template.uischema', '{}')
    }
  }

  return (
    <Box>
      <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
        <Tab value="builder" label="Form" />
        <Tab value="preview" label="Preview" />
      </Tabs>
      <TabPanel selectedTab={tab} value="builder">
        <FormBuilder
          schema={watch('template.schema')}
          uischema={watch('template.uischema')}
          onChange={(newSchema, newUiSchema) => handleChange(newSchema, newUiSchema)}
          mods={{
            deactivatedFormInputs: ['array'],
            customFormInputs,
          }}
        />
      </TabPanel>
      <TabPanel selectedTab={tab} value="preview">
        <TemplatePreview
          template={{
            schema: watch('template.schema'),
            uischema: watch('template.uischema'),
          }}
        />
      </TabPanel>
    </Box>
  )
}
function TabPanel(props) {
  const { children, selectedTab, value, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== value}
      id={`${value}-templates-panel`}
      aria-labelledby={`${value}-templates-tab`}
      {...other}>
      {selectedTab === value && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
}

export default NewTemplateBuilder
