import { Box, Stack, Typography } from '@mui/material'
import { colorToGradient } from './color'

export const GradientLegend: React.FC<{
  colors: Record<number, string>
  width: number
  getLabel?: (value: number) => string
}> = ({ colors, width, getLabel }) => {
  const values = Object.keys(colors)
    .map(Number)
    .sort((a, b) => a - b)
  const min = values[values.length - 1]
  const max = values[0]
  const offsets = values.map(v => ((v - min) / (max - min)) * 100)
  console.log(offsets)
  console.log(min, max)
  console.log(values)
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      width="fit-content"
      height="100%"
      py={1}
      pr={0.5}>
      {/* Values */}
      <Stack height="100%" width={width + 'px'} position="relative" alignItems="flex-end">
        {offsets.map((offset, i) => (
          <Stack
            key={i}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={0.2}
            sx={{
              position: 'absolute',
              top: offset + '%',
              transform: 'translateY(-50%)',
              ':first-of-type': {
                transform: 'translateY(-50%) translateY(-1px)',
              },
              ':last-of-type': {
                transform: 'translateY(-50%) translateY(1px)',
              },
            }}>
            {/* Gradient Value */}
            <Typography variant="caption" sx={{ color: 'grey.900' }}>
              {getLabel ? getLabel(values[i]) : values[i]}
            </Typography>
            {/* Line */}
            <Box
              sx={{
                width: '6px',
                height: '2px',
                borderRadius: '1px 0 0 1px',
                backgroundColor: colors[values[i]],
              }}
            />
          </Stack>
        ))}
      </Stack>

      {/* Gradient Bar */}
      <Box
        sx={{
          width: '20px',
          minWidth: '20px',
          height: '100%',
          borderRadius: '0 5px 5px 0',
          background: colorToGradient(colors, 0),
        }}
      />
    </Stack>
  )
}

export const CategoryLegend: React.FC<{
  categories: string[]
  colors: Record<string, string>
  width: number
}> = ({ categories, colors, width }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      width="fit-content"
      height="100%"
      py={1}
      pr={0.5}>
      {/* Values */}
      <Stack height="100%" width={width + 'px'} position="relative" alignItems="flex-end">
        {categories.map((category, i) => (
          <Stack
            key={i}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={0.2}
            sx={{
              position: 'absolute',
              top: ((i + 1) / categories.length) * 100 + '%',
              transform: 'translateY(-50%)',
              ':first-of-type': {
                transform: 'translateY(-50%) translateY(-1px)',
              },
              ':last-of-type': {
                transform: 'translateY(-50%) translateY(1px)',
              },
            }}>
            {/* Gradient Value */}
            <Typography variant="caption" sx={{ color: 'grey.900' }}>
              {category}
            </Typography>
            {/* Line */}
            <Box
              sx={{
                width: '6px',
                height: '2px',
                borderRadius: '1px 0 0 1px',
                backgroundColor: colors[category],
              }}
            />
          </Stack>
        ))}
      </Stack>

      {/* Gradient Bar */}
      <Box
        sx={{
          width: '20px',
          minWidth: '20px',
          height: '100%',
          borderRadius: '0 5px 5px 0',
          background: colorToGradient(colors, 0),
        }}
      />
    </Stack>
  )
}
