import React from 'react'
import { connect } from 'react-redux'
import Auth from '@aws-amplify/auth'
import api from 'balkerne-core/api'
import './ResetPassword2.css'
import './ResetPassword.css'

async function getUserDetails() {
  return await api
    .get('/users/me')
    .then(res => {
      return res.data.data
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

type ResetPasswordProps = {
  dispatch: any
  history: any
}
type ResetPasswordState = {
  toPortfolioLanding: boolean
  email: string
  v_code: string
  password_new: string
  password_confirm: string
  user: string
  step: number
  errMessage: string
  [key: string]: any
}
export class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
  constructor(props) {
    super(props)
    this.state = {
      toPortfolioLanding: false,
      email: '',
      v_code: '',
      password_new: '',
      password_confirm: '',

      user: '',
      step: 1,
      errMessage: '',
    }
    this.changePassword = this.changePassword.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
    this.next_form = this.next_form.bind(this)
    this.back_form = this.back_form.bind(this)
  }

  async forgotPassword() {
    let email = this.state.email
    if (email && email.length > 3 && !email.toLowerCase().endsWith('arcusfm.com')) {
      email = email.toLowerCase()
    }

    if (this.validateEmail()) {
      await Auth.forgotPassword(email)
        .then(data => {
          // console.log(data);
          alert('Password reset sent to ' + this.state.email)
          this.setState({ errMessage: '' })
          this.next_form()
        })
        .catch(err => {
          console.log(err)

          this.setState({ errMessage: err.message })
          // alert("User not found in the database.");
        })
    }
  }

  showNotice() {
    if (this.state.errMessage && this.state.errMessage.length) {
      return (
        <div className="alert alert-danger" role="alert">
          {this.state.errMessage}
        </div>
      )
    }
  }

  async changePassword() {
    let changed = false
    let user = null

    let email = this.state.email
    if (email && email.length > 3 && !email.toLowerCase().endsWith('arcusfm.com')) {
      email = email.toLowerCase()
    }

    if (this.state.password_new != this.state.password_confirm) {
      this.setState({ errMessage: 'Passwords do not match, please try again' })
    } else {
      if (this.validateForm() == true) {
        await Auth.forgotPasswordSubmit(email, this.state.v_code, this.state.password_new)
          .then(() => {
            changed = true
          })
          .catch(err => {
            this.setState({ errMessage: err.message })

            changed = false
          })

        if (changed) {
          await Auth.signIn(email, this.state.password_new)
            .then(async user1 => {
              user = user1
            })
            .catch(err => {
              user = null
              if (!err.message) {
                this.setState({ errMessage: err.message })
              } else {
                this.setState({ errMessage: err.message })
              }
            })
        }
        if (changed && user !== null) {
          console.log('Password change WORKED on cognito')
          let userdata = await getUserDetails()
          
          // html form element does not have attribute reset()
          // document.getElementById('forgot-password2').reset()
          alert('Password successfully changed')

          

          // console.log("password change user below")
          // console.log(user)

          // clearCachedTokens() does not exist on type CognitoUser
          // user.clearCachedTokens()

          Object.keys(sessionStorage).forEach(key => {
            if (key.includes('Cognito')) {
              sessionStorage.removeItem(key)
            }
          })

          this.props.history.push({
            pathname: '/',
          })
        }
      } else {
        this.setState({
          errMessage: 'Please ensure the password meets the above criteria',
        })
      }
    }
  }

  validateForm = () => {
    return (
      this.state.v_code &&
      this.state.password_new &&
      this.state.v_code.length >= 3 &&
      this.state.password_new.length > 7
    )
  }

  validateEmail = () => {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return this.state.email.length && emailRegex.test(this.state.email.toLowerCase())
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    })
  }

  next_form() {
    if (this.state.step < 2) {
      this.setState({ step: this.state.step + 1 })
    }
  }
  back_form() {
    if (this.state.step > 1) {
      this.setState({ step: this.state.step - 1 })
    }
  }

  render() {
    if (this.state.toPortfolioLanding === true) {
      {
        console.log('redirecting')
      }
      return this.props.history.push({
        pathname: '/',
      })
    }

    if (this.state.step == 1) {
      return (
        <div id="content">
          <div className="wrapper fadeInDown">
            <div id="formContent">
              <form id="forgot-password1">
                <h1>Password Reset</h1>
                <div className="forgot-password-input1">
                  <div id="emailinput">
                    <label>To reset your password, enter the email address you use to sign in</label>
                    <input
                      autoFocus
                      type="email"
                      id="email"
                      className="form-control"
                      name="login"
                      placeholder="you@example.com"
                      onChange={this.handleChange}
                    />

                    <input
                      type="button"
                      className="form-control"
                      id="sendverification"
                      value="Send verification code to my email"
                      onClick={this.forgotPassword}
                      disabled={!this.validateEmail()}
                    />
                  </div>
                </div>
              </form>
              {this.showNotice()}
            </div>
          </div>
        </div>
      )
    }
    if (this.state.step == 2) {
      return (
        <div id="content">
          <div className="wrapper fadeInDown">
            <div id="formContent">
              <form id="forgot-password2">
                <div id="userpasswordinput">
                  <div id="emailinput">
                    <h1>Email Sent!</h1>
                    <div className="forgot-password-input2">
                      <label>Check your email for verification code</label>

                      <div className="form-group">
                        <label>Verification Code</label>
                        <input
                          type="text"
                          id="v_code"
                          className="form-control"
                          name="v_code"
                          placeholder="verification code"
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <label>New Password </label>
                        <input
                          type="password"
                          id="password_new"
                          className="form-control"
                          name="password_new"
                          placeholder="new password"
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <label>Confirm Password </label>
                        <input
                          type="password"
                          id="password_confirm"
                          className="form-control"
                          name="password_confirm"
                          placeholder="confirm password"
                          onChange={this.handleChange}
                        />
                      </div>

                      <input
                        type="button"
                        id="sendverification"
                        className="form-control"
                        value="Change Password"
                        onClick={this.changePassword}
                      />
                    </div>
                    <ul id="passwordinstruction">
                      In order to protect your account, make sure your password :<li>Is longer than 7 characters</li>
                      <li>Contains uppercase letters</li>
                      <li>Contains lowercase letters</li>
                      <li>Contains numbers</li>
                    </ul>
                  </div>
                </div>
              </form>
              {this.showNotice()}
            </div>
          </div>
        </div>
      )
    }

    return this.props.history.push({
      pathname: '/login',
    })
  }
}

function mapStateToProps(state) {
  const { userLogged, user, userData } = state

  return {
    userLogged,
    user,
    userData,
  }
}

//const connectedHomePage = connect(mapStateToProps)(HomePage);
export default connect(mapStateToProps)(ResetPassword)
