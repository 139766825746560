import React from 'react'
import { useParams } from 'react-router-dom'
import SmartIntegrationTab from '../components/riskReport/SmartIntegrationTab'
import Page from '../components/Page'

const PropertySensors = () => {
  const { id: propertyId } = useParams()
  return (
    <Page title="Sensors" size="lg">
      <SmartIntegrationTab propertyId={propertyId} />
    </Page>
  )
}

export default PropertySensors
