import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackbarProvider } from 'notistack'
import { Amplify } from '@aws-amplify/core'
import amplifyConfig from './amplify-config'
import App from './App'
import { Provider } from 'react-redux'
import { CssBaseline } from '@mui/material'
import theme from './theme'
import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import config from 'balkerne-core/config'
import store from './store'
import './index.scss'
import { unregister, register } from './serviceWorker'
import WebFont from 'webfontloader'

const queryClient = new QueryClient()

Sentry.init({
  dsn: config.sentryDSN,
  environment: config.stage,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [config.backendEndpoint],
    }),
  ],
  tracesSampleRate: 1.0,
})
Amplify.configure(amplifyConfig)

function Root() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Figtree'],
      },
    })
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <App />
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  )
}

const _root: any = document.getElementById('root')
createRoot(_root).render(<Root />)

unregister()
