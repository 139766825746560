import React, { useMemo, useState } from 'react'
import './MediumMap.css'
import Box from '@mui/material/Box'
import { useLocation } from '../../hooks/locations'
import { CircularProgress } from '@mui/material'
import Map, { Layer, Source } from 'react-map-gl'

// special Map component for Irsk Insights page which displays either rofrs or rofsw maps with no UI or toggles
//---------------------------- AT THE MOMENT SHOWS HISTORICAL FLOODING INSTEAD OF ROFSW -------------------------------------------------


function vectorSource(id): any {
  return {
    type: 'vector',
    url: `mapbox://balkerne.${id}`,
  }
}

function layer(sourceLayer, color, visible = true) {
  return {
    id: sourceLayer,
    type: 'fill',
    'source-layer': sourceLayer,
    paint: {
      'fill-color': color,
      'fill-opacity': 0.5,
      'fill-outline-color': '#07062d',
    },
    layout: {
      visibility: visible ? 'visible' : 'none',
    },
  }
}

const colorSurface = {
  low: 'rgba(200,37,203,0.25)',
  medium: 'rgba(200,37,203,0.5)',
  high: 'rgba(200,37,203,0.75)',
}

const colorRiverAndSea = {
  low: '#8625cb',
  medium: '#c29e00',
  high: '#a10202',
}

export const RiskMap = ({ propertyId, showSurface = false, showRiverSea = false }) => {
  const { data: property, isLoading } = useLocation(propertyId, { geojson: true })
  const [style, setStyle] = useState('streets-v11')
  console.log(property)

  const surfaceFloodSource = useMemo(() => vectorSource('uk-rofsw'), [])
  const surfaceFlood: any = useMemo(
    () => ({
      1: layer('rofsw-1', colorSurface.low, showSurface),
      2: layer('rofsw-2', colorSurface.low, showSurface),
      3: layer('rofsw-3', colorSurface.medium, showSurface),
      4: layer('rofsw-4', colorSurface.high, showSurface),
    }),
    [showSurface],
  )

  const riverSeaFloodSource = useMemo(() => vectorSource('uk-rofrs'), [])
  const riverSeaFlood: any = useMemo(
    () => ({
      1: layer('rofrs-1', colorRiverAndSea.low, showRiverSea),
      2: layer('rofrs-2', colorRiverAndSea.low, showRiverSea),
      3: layer('rofrs-3', colorRiverAndSea.medium, showRiverSea),
      4: layer('rofrs-4', colorRiverAndSea.high, showRiverSea),
    }),
    [showRiverSea],
  )

  if (isLoading && !property) {
    return <CircularProgress />
  }

  const propertySource: any = {
    type: 'geojson',
    data: property?.geometry,
  }

  const propertyLayer: any = {
    id: 'property',
    type: 'fill-extrusion',
    paint: {
      'fill-extrusion-color': '#34eb64',
      'fill-extrusion-height': 5,
      'fill-extrusion-opacity': 0.75,
    },
  }
  return (
    <Box
      sx={{
        width: 400,
        height: 350,
      }}>
      <Map
        reuseMaps
        initialViewState={{
          longitude: property?.coordinates.longitude,
          latitude: property?.coordinates.latitude,
          zoom: 15,
          pitch: 0,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle={`mapbox://styles/mapbox/${style}`}
        mapboxAccessToken="pk.eyJ1IjoiYmFsa2VybmUiLCJhIjoiY2wwdGswNDhpMDAzczNrbXY1aHE3dno5OSJ9.gmUFneco76pHw2FmprGpPw">
        <Source {...surfaceFloodSource} minzoom={9}>
          <Layer {...surfaceFlood[1]} />
          <Layer {...surfaceFlood[2]} />
          <Layer {...surfaceFlood[3]} />
          <Layer {...surfaceFlood[4]} />
        </Source>
        <Source {...riverSeaFloodSource} minzoom={9}>
          <Layer {...riverSeaFlood[1]} />
          <Layer {...riverSeaFlood[2]} />
          <Layer {...riverSeaFlood[3]} />
          <Layer {...riverSeaFlood[4]} />
        </Source>
        <Source {...propertySource}>
          <Layer {...propertyLayer} />
        </Source>
      </Map>
    </Box>
  )
}
