import React from 'react'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import CheckBox from 'balkerne-components/CheckBox'
import Dropzone from 'balkerne-components/Dropzone'
import { readFileDataURL } from '../../functions/readFile'
import c from '../../pages/GetStarted.module.scss'
import './OrgGetStarted.css'


type OrgGetStartedProps = {
  currentStep: number
  name: string
  updateOrgData: (userData: any) => void
  updateOrgLogo: (logo: any) => void
  handleSubmit: () => void
}
type OrgGetStartedState = {
  orgData: {
    flooding: boolean
    fire: boolean
    crime: boolean
    weather: boolean
    pandemic: boolean
    metal_theft: boolean
    organisation_name: string
    uploadFile: any
    logo?: any
  }
  agreedTerms: boolean
  mounted: boolean
}
export class OrgGetStarted extends React.Component<OrgGetStartedProps, OrgGetStartedState> {
  constructor(props) {
    super(props)
    this.state = {
      orgData: {
        flooding: true,
        fire: true,
        crime: true,
        weather: true,
        pandemic: false,
        metal_theft: false,
        organisation_name: this.props.name,
        uploadFile: null,
      },
      agreedTerms: false,
      mounted: false,
    }
  }

  async handleFile(e) {
    if (e && e.length > 0) {
      const rawFile = e[0]
      const file = await readFileDataURL(rawFile)

      const logo = {
        data: file.data,
        name: file.name,
      }

      this.setState({
        orgData: {
          ...this.state.orgData,
          logo: logo,
        },
      })
      this.props.updateOrgLogo(logo)
    }
  }

  handleChange = event => {
    let newState = {
      orgData: {
        ...this.state.orgData,
        [event.target.id]: event.target.value,
      },
    }
    this.setState(newState)
    this.props.updateOrgData(newState.orgData)
  }

  initializeOrg = () => {
    let newState = {
      orgData: {
        ...this.state.orgData,
        ['organisation_name']: this.props.name,
      },
    }
    this.setState({ mounted: true })
    this.setState(newState)
    this.props.updateOrgData(newState.orgData)
  }

  handleChangeCheckbox = (name, checked) => {
    let value = checked
    let newState = {
      orgData: {
        ...this.state.orgData,
        [name]: value,
      },
    }
    this.setState(newState)
    this.props.updateOrgData(newState.orgData)
  }

  handleTermAgreement = checked => {
    this.setState({ agreedTerms: checked })
  }

  render() {
    if (this.props.currentStep !== 2) {
      return null
    }

    if (this.state.mounted === false) {
      this.initializeOrg()
    }

    return (
      <div className={c.page}>
        <Container>
          <Card className={c.chart}>
            <Card.Header>Create Organisation: </Card.Header>
            <Card.Body>
              <div id="orgupload">
                <div>
                  <form name="my-form" onChange={this.handleChange}>
                    <h3 className="pb-2">Company</h3>
                    <input
                      type="text"
                      id="organisation_name"
                      className="form-control"
                      name="organisation_name"
                      value={this.state.orgData.organisation_name}
                    />
                  </form>
                </div>
                <div>
                  <h3 className="pb-2">Logo</h3>
                  <Dropzone multiple={false} showCounter onChange={e => this.handleFile(e)} />
                </div>
                <div>
                  <div>
                    <h3>Monitor Risks</h3>
                    <p>Select relevant risks you'd like to monitor</p>
                  </div>
                  <form name="my-form-checkbox">
                    <div className="checkboxes  pb-3 pt-2">
                      <CheckBox
                        size="large"
                        name="flooding"
                        value
                        onChange={e => this.handleChangeCheckbox('flooding', e)}>
                        Flooding
                      </CheckBox>
                      <CheckBox size="large" name="crime" value onChange={e => this.handleChangeCheckbox('crime', e)}>
                        Crime
                      </CheckBox>
                      <CheckBox size="large" name="fire" value onChange={e => this.handleChangeCheckbox('fire', e)}>
                        Fire
                      </CheckBox>
                      <CheckBox
                        size="large"
                        name="weather"
                        value
                        onChange={e => this.handleChangeCheckbox('weather', e)}>
                        Weather
                      </CheckBox>
                      <CheckBox
                        size="large"
                        name="pandemic"
                        value
                        onChange={e => this.handleChangeCheckbox('pandemic', e)}>
                        Pandemic
                      </CheckBox>
                      <CheckBox
                        size="large"
                        name="metal_theft"
                        value
                        onChange={e => this.handleChangeCheckbox('metal_theft', e)}>
                        Metal Theft
                      </CheckBox>
                    </div>
                    <hr></hr>
                    <p>Under Development</p>
                    <div className="checkboxes  pt-3">
                      <CheckBox size="large" value disabled>
                        Air Pollution
                      </CheckBox>
                      <CheckBox size="large" value disabled>
                        Earthquake
                      </CheckBox>
                      <CheckBox size="large" value disabled>
                        Power Failure
                      </CheckBox>
                      <CheckBox size="large" value disabled>
                        Terrorism
                      </CheckBox>
                    </div>
                  </form>
                </div>
              </div>
              <div className="pt-3" id="wrapper">
                <CheckBox
                  size="large"
                  name="hasAgreedTcsCs"
                  value={this.state.agreedTerms}
                  onChange={event => this.handleTermAgreement(event)}>
                  <a href="https://www.balkerne.com/privacypolicy/ " target="_blank">
                    I agree to the Terms and Privacy Policy
                  </a>
                </CheckBox>
              </div>
              <button
                id="getstartedsubmit"
                onClick={() => this.props.handleSubmit()}
                type="button"
                className="btn btn-primary btn-lg float-right">
                Complete
              </button>
            </Card.Body>
          </Card>
        </Container>
      </div>
    )
  }
}
export default OrgGetStarted
