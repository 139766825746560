import React from 'react'
import Box from '@mui/material/Box'
import c from './Container.module.scss'

const Container = ({ children }: any) => {
  return (
    <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
      <div className={c.container}>{children}</div>
    </Box>
  )
}

Container.Center = ({ children }) => (
  <div className={c.centerContainer}>
    <div className={c.center}>{children}</div>
  </div>
)

Container.centerResponsive = ({ children }) => (
  <div className={c.centerContainer}>
    <div className={c.centerResponsive}>{children}</div>
  </div>
)

Container.centerResponsiveNoTop = ({ children }) => (
  <div className={c.centerContainer}>
    <div className={c.centerResponsiveNoTop}>{children}</div>
  </div>
)

// Container.Center.displayName = 'Container.Center'
// Container.centerResponsive.displayName = 'Container.centerResponsive'
// Container.centerResponsiveNoTop.displayName = 'Container.centerResponsiveNoTop'

export default Container
