import Page from '../components/Page'
import { SetStateAction, forwardRef, useCallback, useMemo, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
  Fade,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Tooltip,
  MenuItem,
  Hidden,
  Divider,
  Card,
} from '@mui/material'
import {
  DataGrid,
  getGridSingleSelectOperators,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { useAlertTypes, useConfigurations } from '../hooks/alerts'
import SeverityIcon from '../components/SeverityIcon'
import AlertTypeIcon from '../components/AlertTypeIcon'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AreaSelector from '../components/AreaSelector'
import api from 'balkerne-core/api'
import { LoadingButton } from '@mui/lab'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

const CreateAlertConfiguration = () => {
  const { data: unindexedTypes, isLoading: isTypesLoading } = useAlertTypes()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const types = useMemo(() => {
    if (isTypesLoading) return {}
    return unindexedTypes.reduce((acc, type) => {
      acc[type.id] = type
      return acc
    }, {})
  }, [unindexedTypes, isTypesLoading])

  const schema = yup.object({
    name: yup.string().min(1).required('Name is required'),
    type_id: yup.number().required('Alert type is required').integer(),
    severity: yup.number().required('Severity is required').integer(),
    sensitive: yup.boolean().required('Sensitive is required'),
    notify: yup.boolean().required('Notify is required'),
    threshold: yup.number().when('type_id', {
      is: type_id => types?.[type_id]?.metric ?? false,
      then: yup.number().required('Threshold is required'),
      otherwise: yup
        .number()
        .transform((_, value) => (value === '' ? null : value))
        .nullable(true),
    }),
    area_id: yup.number().nullable().default(null),
  })

  const {
    register,
    handleSubmit: formSubmit,
    formState: { isValid },
    watch,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      name: null,
      type_id: null,
      severity: null,
      notify: null,
      sensitive: null,
      threshold: null,
      area_id: null,
    },
  })
  const typeId: any = watch('type_id', null)
  const type = types?.[typeId]

  const handleSubmit = (data: any) => {
    setIsSubmitting(true)
    api
      .post('/alerts/configurations', data)
      .then(() => {
        enqueueSnackbar('Configuration created', { variant: 'success' })
        queryClient.invalidateQueries('/alerts/configurations')
        history.push('/alerts/configurations')
      })
      .catch(err => {
        console.error(err)
        enqueueSnackbar('Error creating configuration', { variant: 'error' })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }
  return (
    <Page title="Create Alert Configuration" size="lg">
      <Card>
        <Stack sx={{ minHeight: 200 }} spacing={2}>
          <FormControl fullWidth>
            <FormLabel htmlFor="name">Name</FormLabel>
            <TextField id="name" {...register('name')} fullWidth></TextField>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel htmlFor="type_id">Alert Type</FormLabel>
            <TextField id="type_id" {...register('type_id')} fullWidth select defaultValue="">
              {isTypesLoading ? (
                <MenuItem>Loading...</MenuItem>
              ) : (
                unindexedTypes.map(type => (
                  <MenuItem key={type.id} value={type.id}>
                    <AlertTypeIcon type={type.name} height={18} width={18} /> {type.display_name}
                  </MenuItem>
                ))
              )}
            </TextField>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel htmlFor="severity">Severity</FormLabel>
            <TextField id="severity" {...register('severity')} fullWidth select defaultValue="">
              <MenuItem value={1}>
                <SeverityIcon severity={1} height={18} width={18} /> Severe Warning
              </MenuItem>
              <MenuItem value={2}>
                <SeverityIcon severity={2} height={18} width={18} /> Warning
              </MenuItem>
              <MenuItem value={3}>
                <SeverityIcon severity={3} height={18} width={18} /> Alert
              </MenuItem>
            </TextField>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel htmlFor="threshold">Threshold {type?.metric && `(${type.units})`}</FormLabel>
            <TextField
              id="threshold"
              {...register('threshold')}
              fullWidth
              type="number"
              disabled={Boolean(!type?.metric)}></TextField>
          </FormControl>
          <FormGroup>
            <Hidden smUp>
              <Typography variant="caption">Whether to send notifications to users</Typography>
            </Hidden>
            <Tooltip title="Whether to send notifications to users">
              <FormControlLabel control={<Checkbox {...register('notify')} defaultChecked />} label="Notify" />
            </Tooltip>
            <Hidden smUp>
              <Typography variant="caption">
                Whether to issue notifications if configuration is triggered more than once during the same event. Only
                applicable if 'Notify' is checked.
              </Typography>
            </Hidden>
            <Tooltip title="Whether to issue notifications if configuration is triggered more than once during the same event. Only applicable if 'Notify' is checked.">
              <FormControlLabel control={<Checkbox {...register('sensitive')} defaultChecked />} label="Sensitive" />
            </Tooltip>
          </FormGroup>
          <FormControl>
            <FormLabel>Area</FormLabel>
            <AreaSelector onSelect={areaId => setValue('area_id', areaId)} alertTypeId={typeId} types={types} />
          </FormControl>

          <Stack
            gap={1}
            direction="row"
            sx={{
              justifyContent: 'flex-end',
            }}>
            <Button variant="outlined" onClick={() => history.push('/alerts/configurations')}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              onClick={formSubmit(handleSubmit)}
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}>
              Submit
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
    </Page>
  )
}

export default CreateAlertConfiguration
