import { useQuery, UseQueryResult } from 'react-query'
import api from 'balkerne-core/api'
import { useLocations } from './locations'

export const usePortfolioHeatProjections = () => {
  const path = '/analytics/climate-projections/heat-stress'
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export type HurricaneForecast = {
  name: string
  basin_id: string
  year: number
  gov_id: string
  is_active: boolean
  is_subtropical: boolean
  forecasts: {
    latitude: number
    longitude: number
    date: string
    sustained_wind: number
    max_gust_wind: number
    status: string
    max_radius: number
  }[]
  history: {
    latitude: number
    longitude: number
    date: string
    sustained_wind: number
    max_gust_wind: number
    status: string
    movement_degrees: number
    movement_speed: number
  }[]
}

export const useHurricaneForecasts = () => {
  const path = '/analytics/hurricanes/forecasts'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery<HurricaneForecast[]>([path], fetch, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
  return { ...rest, data: data ?? [] }
}

export type HurricaneHistorical = {
  sid: string
  name: string
  last_timestamp: string
  year: number
}

export const useHurricanes = () => {
  const path = '/analytics/hurricanes'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery<HurricaneHistorical[]>([path], fetch, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
  return { ...rest, data: data ?? [] }
}

export type HurricaneHistoricalTrack = {
  lat: number
  lon: number
  direction: number
  velocity: number
  track_type: string
  nature: string
  date: string
  agency: string
  interpolated: boolean
  roci: number | null
  wind_speed: number | null
  pressure: number | null
}

export const useHurricaneTrack = (sid: string | null) => {
  const path = `/analytics/hurricanes/${sid}`
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery<HurricaneHistoricalTrack[]>([path], fetch, {
    enabled: sid !== null,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
  return { ...rest, data: data ?? [] }
}

import { FeatureCollection, Point } from '@turf/turf'
import { distance as turfDistance } from '@turf/turf'

export const useClimateAnalyticsSubsidence = () => {
  const { data: properties, ...rest } = useLocations()
  let propertiesWithFakeSubsidenceData = []
  const highestRiskPoint: Point = {
    type: 'Point',
    coordinates: [-0.118092, 51.509865],
  }

  const highestRiskPoint2: Point = {
    type: 'Point',
    coordinates: [0.90421, 51.88921],
  }
  const lowestRiskPoint: Point = {
    type: 'Point',
    coordinates: [-2.244644, 53.483959],
  }
  const range = turfDistance(highestRiskPoint, lowestRiskPoint, { units: 'kilometers' })

  if (properties.length > 0) {
    propertiesWithFakeSubsidenceData = properties.map(property => {
      const propertyPoint: Point = {
        type: 'Point',
        coordinates: [property.coordinates.longitude, property.coordinates.latitude],
      }
      const distance = Math.min(
        range,
        turfDistance(highestRiskPoint, propertyPoint, { units: 'kilometers' }),
        turfDistance(highestRiskPoint2, propertyPoint, { units: 'kilometers' }),
      )
      const subsidenceScore = 5 - Math.round((distance / range) * 5)
      return {
        ...property,
        '2030': {
          drier: Math.max(0, subsidenceScore - 1),
          normal: Math.max(0, subsidenceScore - 2),
          wetter: Math.max(0, subsidenceScore - 3),
        },
        '2070': {
          drier: subsidenceScore,
          normal: Math.max(0, subsidenceScore - 1),
          wetter: Math.max(0, subsidenceScore - 2),
        },
      }
    })
  }
  console.log(propertiesWithFakeSubsidenceData)
  return {
    ...rest,
    data: propertiesWithFakeSubsidenceData,
  }
}

export const useClimateAnalyticsSPI = () => {
  const path = '/analytics/cc/spi'
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery(path, fetch, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60 * 24,
    refetchOnMount: false,
  })
}

export const usePortfolioFloodClimateProjections = () => {
  const path = '/analytics/climate-projections/flood'
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useClimateProjections = propertyId => {
  const path = `/analytics/locations/${propertyId}/climate-projections`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch, { enabled: propertyId !== undefined })
}

export const usePortfolioRiskBreakdown = () => {
  const path = '/analytics/risks/summary'
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useRiskCategories = () => {
  const path = `/analytics/risks/categories`
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch)
  return { ...rest, data: data ?? [] }
}

export const usePropertyPricingProjections = propertyId => {
  const path = `/analytics/locations/${propertyId}/flood-pricing`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch, { enabled: propertyId !== undefined })
}

export const usePortfolioPricingProjections = () => {
  const path = '/analytics/climate-projections/flood-pricing'
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const usePropertySubsidenceProjections = propertyId => {
  const path = `/analytics/locations/${propertyId}/climate-subsidence-scores/`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch, { enabled: propertyId !== undefined })
}

export const usePortfolioSubsidenceProjections = () => {
  const path = `/analytics/climate-projections/subsidence/`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useClimateProjectionScore = propertyId => {
  const path = `/analytics/locations/${propertyId}/climate-projection-score`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch, { enabled: propertyId !== undefined })
}

export const useClimateSubsidenceHazardLayer = () => {
  const path = `/analytics/features/subsidence`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useClimateFloodabilityScoreLayer = () => {
  const path = '/analytics/features/floodability-score'
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useThresholdRecommendation = (stationId, config = {}) => {
  const path = `/analytics/get_typical_high/${stationId}`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch, {
    ...config,
  })
}

export const useClimateAnalyticsWeather = (variable: 'hot_days' | 'tropical_nights') => {
  const path = `/analytics/cc/mo`
  const params = {
    variable,
  }
  const fetch = async () => await api.get(path, { params: params }).then(res => res.data)
  return useQuery([path, params], fetch)
}

export const useClimateAnalyticsWeatherBreakdown = (
  variable: 'hot_days' | 'tropical_nights',
  rcp: 'rcp26' | 'rcp45' | 'rcp85' | string,
  sortBy: 'near' | 'mid' | 'long',
  byPercentage: boolean,
) => {
  const path = `/analytics/cc/mo/breakdown`
  const params = {
    variable,
    rcp,
    sort_by: sortBy,
    by_percentage: byPercentage,
  }
  const fetch = async () =>
    await api
      .get(path, {
        params: params,
      })
      .then(res => res.data)
  return useQuery([path, params], fetch)
}

import temp_meadow from '../temp/meadow_output2.json'

export const usePropertyClimateAnalyticsExtremeWind = (propertyId: number) => {
  const rcp26 = temp_meadow['wg_rcp26']
  const rcp85 = temp_meadow['wg_rcp85']

  if (propertyId in rcp26 && propertyId in rcp85) {
    return {
      data: {
        rcp26: rcp26[propertyId],
        rcp85: rcp85[propertyId],
      },
    }
  } else {
    return { data: null }
  }
}

export const usePropertyClimateAnalyticsHeatStress = (propertyId: number) => {
  const rcp26 = temp_meadow['hshd_rcp26']
  const rcp85 = temp_meadow['hshd_rcp85']

  if (propertyId in rcp26 && propertyId in rcp85) {
    return {
      data: {
        rcp26: rcp26[propertyId],
        rcp85: rcp85[propertyId],
      },
    }
  } else {
    return { data: null }
  }
}

const temp_fire_hazard = {
  rcp26: {
    years: [
      2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
      2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042,
      2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060, 2061,
      2062, 2063, 2064, 2065, 2066, 2067, 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079, 2080,
      2081, 2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2091, 2092, 2093, 2094, 2095, 2096, 2097,
    ],
    fwi_nods_gt_15: {
      percentiles: {
        '10th': [
          127.7, 100.1, 80.4, 132.2, 103.8, 91.7, 94.3, 110.2, 81.1, 117.0, 98.8, 122.2, 104.9, 122.3, 112.5, 113.8,
          118.1, 88.3, 81.10000000000001, 102.9, 99.3, 106.3, 124.6, 119.1, 120.9, 118.5, 125.0, 115.9, 137.3, 93.3,
          87.5, 130.1, 114.6, 116.8, 96.9, 113.1, 130.9, 128.6, 106.2, 122.1, 102.1, 123.2, 128.5, 123.6, 103.5, 122.2,
          131.8, 115.4, 115.2, 116.9, 135.9, 119.6, 123.3, 114.2, 130.6, 110.2, 109.0, 112.2, 117.4, 107.0, 129.6, 94.2,
          99.3, 117.9, 103.9, 113.8, 121.3, 103.0, 109.7, 103.0, 101.7, 137.0, 93.9, 128.6, 105.30000000000001, 104.2,
          111.2, 113.8, 122.9, 94.0, 100.8, 117.3, 116.5, 123.0, 126.8, 121.6, 110.9, 114.4, 121.2, 57.400000000000006,
          95.1, 105.2, 143.1,
        ],
        '50th': [
          141.5, 113.5, 115.0, 136.0, 136.5, 119.5, 136.5, 132.5, 87.0, 129.5, 116.5, 153.5, 158.0, 138.0, 120.5, 132.5,
          141.0, 112.0, 151.5, 141.5, 116.5, 116.5, 132.0, 126.0, 127.5, 131.5, 134.0, 124.5, 146.0, 126.0, 121.0,
          154.0, 133.5, 144.0, 138.5, 131.5, 154.0, 131.0, 144.0, 145.0, 119.0, 144.0, 171.0, 127.5, 111.5, 163.0,
          151.0, 133.0, 121.5, 119.5, 157.0, 122.5, 152.0, 146.5, 146.5, 141.0, 116.0, 132.0, 140.0, 130.5, 134.5,
          118.0, 140.0, 132.5, 125.5, 125.0, 169.0, 127.5, 132.5, 124.5, 113.0, 145.0, 119.0, 132.0, 150.5, 141.0,
          131.0, 119.0, 140.0, 102.0, 130.5, 153.0, 123.5, 132.5, 131.0, 140.5, 137.0, 123.0, 132.5, 102.0, 122.5,
          125.5, 154.0,
        ],
        '90th': [
          156.0, 129.0, 124.4, 158.70000000000002, 160.8, 163.4, 150.7, 145.7, 120.2, 158.1, 142.6, 163.8, 185.9, 156.5,
          135.5, 154.0, 161.8, 130.8, 170.1, 151.4, 129.5, 141.4, 145.0, 131.5, 135.5, 202.60000000000002, 153.5, 139.4,
          162.4, 175.5, 156.6, 180.0, 146.8, 146.7, 164.70000000000002, 146.4, 163.8, 166.3, 178.3, 163.0, 128.9, 169.0,
          184.8, 142.6, 159.4, 168.8, 163.2, 148.5, 140.4, 128.4, 163.4, 131.0, 175.8, 169.0, 172.2, 166.9, 140.5,
          139.2, 163.3, 149.1, 152.0, 130.6, 196.10000000000002, 161.1, 140.1, 138.3, 191.5, 137.3, 172.8, 166.3, 170.5,
          162.1, 163.0, 142.4, 155.1, 194.60000000000002, 143.8, 141.70000000000002, 152.2, 124.0, 160.20000000000002,
          153.0, 145.20000000000002, 144.1, 140.8, 176.9, 154.7, 144.20000000000002, 137.5, 128.4, 138.70000000000002,
          135.3, 167.7,
        ],
      },
      mean: [
        141.75, 114.25, 106.0, 142.75, 133.5, 125.25, 126.5, 129.25, 96.75, 135.25, 119.5, 146.0, 149.0, 139.0, 123.0,
        133.5, 140.25, 110.25, 133.0, 131.25, 115.0, 121.75, 134.0, 125.5, 128.0, 152.25, 137.75, 126.75, 148.75, 132.0,
        121.75, 154.75, 131.5, 135.25, 133.0, 130.25, 149.25, 142.75, 142.75, 143.25, 116.5, 145.5, 160.75, 131.5,
        125.75, 150.5, 148.5, 132.25, 126.0, 121.75, 151.75, 124.5, 150.25, 143.0, 150.0, 139.25, 122.25, 127.5, 140.25,
        128.75, 139.0, 114.0, 145.5, 137.5, 123.0, 125.75, 160.0, 122.25, 138.75, 131.75, 129.5, 148.25, 125.75, 134.5,
        136.0, 147.0, 128.5, 125.25, 138.25, 107.0, 130.5, 140.25, 128.75, 133.25, 133.0, 146.75, 134.0, 127.5, 130.25,
        95.5, 118.5, 121.75, 155.0,
      ],
    },
    fwi_nods_gt_30: {
      percentiles: {
        '10th': [
          84.0, 60.4, 46.8, 81.6, 66.3, 55.3, 56.1, 67.2, 52.8, 73.1, 71.3, 80.6, 72.0, 79.3, 69.0, 85.3, 90.2, 59.9,
          41.1, 56.3, 70.3, 63.3, 69.0, 77.8, 74.4, 74.3, 78.9, 54.2, 99.8, 66.8, 37.3, 80.8, 79.9, 76.0,
          55.400000000000006, 69.1, 95.0, 74.2, 70.0, 82.6, 55.7, 68.6, 84.3, 81.3, 61.0, 74.7, 83.0, 67.9, 84.9, 66.3,
          80.4, 73.9, 70.3, 58.3, 95.9, 64.4, 46.3, 60.900000000000006, 68.4, 65.2, 81.3, 48.8, 55.4, 61.4, 69.9, 63.3,
          87.0, 61.1, 69.9, 55.9, 73.0, 89.1, 59.9, 65.2, 63.5, 61.800000000000004, 81.0, 72.5, 84.4, 53.1,
          60.400000000000006, 80.3, 59.4, 84.4, 89.3, 75.2, 63.8, 83.0, 64.5, 25.1, 55.5, 53.300000000000004, 90.0,
        ],
        '50th': [
          93.0, 78.0, 68.0, 92.0, 100.5, 79.0, 91.5, 93.0, 61.5, 98.5, 83.0, 105.5, 117.0, 102.0, 76.5, 91.5, 96.5,
          82.5, 98.0, 80.0, 75.0, 80.0, 91.0, 88.5, 86.0, 93.0, 93.5, 76.0, 104.0, 85.5, 65.0, 106.5, 82.5, 86.0, 87.5,
          99.5, 102.0, 87.5, 94.5, 95.0, 74.0, 92.0, 112.5, 84.0, 65.5, 102.5, 110.0, 79.0, 92.5, 88.5, 109.0, 88.0,
          91.5, 97.0, 102.5, 102.5, 54.5, 95.0, 90.0, 81.0, 90.0, 67.5, 74.0, 79.5, 87.5, 86.5, 111.5, 84.0, 95.5, 81.5,
          80.5, 102.0, 87.0, 83.5, 116.5, 106.5, 93.0, 83.0, 93.5, 66.5, 83.5, 101.0, 75.0, 92.5, 96.0, 97.5, 80.0,
          87.5, 74.5, 69.0, 84.5, 83.5, 105.0,
        ],
        '90th': [
          111.80000000000001, 92.10000000000001, 96.9, 101.0, 117.9, 110.4, 97.5, 104.8, 77.2, 119.0,
          113.60000000000001, 124.10000000000001, 137.5, 117.0, 88.9, 104.0, 105.6, 89.7, 124.8, 105.8, 83.2, 91.8,
          102.5, 96.4, 94.1, 139.0, 110.9, 94.3, 104.0, 122.4, 102.5, 123.80000000000001, 85.8, 108.60000000000001,
          111.9, 115.2, 125.80000000000001, 116.9, 117.60000000000001, 111.6, 88.10000000000001, 101.4, 142.8, 88.8,
          108.50000000000001, 115.6, 119.5, 90.10000000000001, 98.7, 91.1, 115.2, 95.1, 118.30000000000001, 120.3,
          108.4, 122.4, 73.2, 103.2, 125.60000000000001, 104.5, 93.1, 89.7, 129.70000000000002, 110.2, 103.0, 113.2,
          137.4, 89.4, 107.1, 114.80000000000001, 109.7, 103.0, 112.7, 96.2, 127.5, 136.5, 105.7, 96.3, 101.2, 83.4,
          101.7, 111.2, 95.5, 100.6, 113.9, 125.4, 113.7, 108.10000000000001, 91.5, 96.80000000000001, 93.9, 93.4,
          118.60000000000001,
        ],
      },
      mean: [
        96.5, 76.75, 70.75, 91.5, 94.5, 81.75, 81.0, 88.0, 64.0, 96.75, 89.75, 103.25, 108.25, 99.25, 78.25, 93.75,
        97.5, 77.0, 87.25, 80.75, 76.25, 78.25, 87.25, 87.5, 84.75, 102.75, 94.5, 74.75, 102.5, 92.0, 68.5, 103.5,
        82.75, 90.5, 84.75, 94.25, 108.0, 93.25, 94.0, 96.5, 72.5, 87.0, 113.25, 84.75, 79.25, 97.25, 103.75, 79.0,
        92.0, 81.5, 101.0, 85.5, 93.5, 91.5, 102.25, 96.0, 58.25, 85.75, 95.0, 83.75, 88.0, 68.75, 87.25, 84.0, 86.75,
        87.75, 112.0, 77.75, 90.5, 84.25, 88.25, 97.75, 86.5, 81.5, 101.5, 101.25, 93.25, 84.0, 93.0, 67.75, 81.75,
        97.25, 76.75, 92.5, 100.0, 99.5, 86.25, 93.25, 77.0, 63.25, 77.5, 76.25, 104.5,
      ],
    },
    fwi_nods_gt_45: {
      percentiles: {
        '10th': [
          46.3, 41.9, 29.3, 42.1, 32.0, 20.3, 25.8, 40.4, 27.9, 48.9, 47.0, 49.1, 44.9, 27.900000000000002, 42.7, 40.8,
          57.3, 34.5, 26.400000000000002, 24.8, 37.6, 38.8, 32.6, 34.1, 32.7, 36.8, 48.8, 21.200000000000003, 57.6,
          35.2, 15.100000000000001, 40.7, 44.1, 49.8, 27.7, 35.3, 55.1, 44.3, 36.7, 48.3, 24.9, 40.400000000000006,
          50.6, 49.1, 31.9, 41.1, 49.7, 31.6, 48.1, 37.3, 43.9, 40.3, 41.8, 23.5, 55.3, 34.900000000000006, 16.6, 35.0,
          37.8, 26.8, 39.1, 22.200000000000003, 24.8, 22.0, 44.9, 41.4, 41.5, 28.9, 41.8, 30.6, 41.0, 40.3, 35.2, 34.4,
          38.1, 37.8, 51.2, 40.3, 42.0, 30.1, 29.700000000000003, 47.9, 27.4, 54.3, 56.5, 38.2, 28.700000000000003,
          46.3, 35.0, 9.3, 32.0, 22.700000000000003, 52.8,
        ],
        '50th': [
          64.5, 46.0, 36.5, 53.0, 69.5, 53.0, 47.0, 53.5, 40.5, 66.0, 54.5, 67.5, 71.5, 64.5, 56.5, 62.0, 58.0, 53.0,
          66.0, 50.5, 41.0, 52.5, 53.5, 51.5, 46.0, 62.0, 59.0, 31.5, 68.5, 52.5, 36.5, 57.0, 51.0, 56.0, 45.0, 62.5,
          66.5, 57.5, 57.0, 54.0, 38.0, 60.5, 64.5, 55.0, 44.5, 65.0, 74.0, 48.0, 62.5, 63.5, 66.5, 50.5, 54.0, 52.0,
          65.5, 61.0, 26.0, 56.5, 51.0, 49.5, 52.0, 42.0, 39.5, 44.5, 51.0, 61.5, 61.5, 44.0, 53.5, 44.0, 49.5, 56.0,
          57.0, 46.5, 77.5, 63.5, 55.5, 61.0, 44.5, 39.0, 52.0, 62.5, 42.0, 56.0, 70.0, 60.0, 53.5, 50.0, 42.5, 36.5,
          47.5, 52.0, 60.0,
        ],
        '90th': [
          76.4, 57.800000000000004, 56.300000000000004, 62.5, 81.10000000000001, 73.1, 59.800000000000004, 69.4, 45.4,
          75.4, 71.10000000000001, 85.2, 85.5, 88.5, 57.0, 74.8, 60.8, 55.4, 90.9, 77.6, 54.900000000000006, 57.8, 66.7,
          66.10000000000001, 60.0, 83.7, 75.5, 58.60000000000001, 78.7, 62.800000000000004, 65.60000000000001, 74.0,
          55.1, 77.60000000000001, 74.9, 75.7, 77.9, 70.0, 73.80000000000001, 65.3, 49.7, 62.4, 80.5,
          62.300000000000004, 74.60000000000001, 81.9, 82.9, 68.60000000000001, 78.3, 77.8, 82.1, 53.0, 71.8, 77.0,
          70.8, 79.4, 44.5, 58.4, 78.2, 74.3, 62.8, 59.7, 78.0, 60.7, 59.2, 83.7, 82.9, 55.6, 65.9, 66.5, 65.7, 69.6,
          72.5, 56.5, 94.5, 80.1, 71.0, 74.7, 61.0, 56.300000000000004, 62.4, 76.4, 66.4, 57.7, 75.8, 98.60000000000001,
          60.1, 64.9, 55.6, 58.800000000000004, 58.1, 58.9, 63.7,
        ],
      },
      mean: [
        62.25, 48.75, 41.0, 52.5, 60.25, 48.5, 44.0, 54.5, 37.75, 63.25, 57.75, 67.25, 67.0, 60.0, 51.75, 59.0, 58.75,
        47.25, 60.75, 51.0, 44.75, 49.5, 50.75, 50.5, 46.25, 60.75, 61.25, 37.5, 68.25, 50.0, 39.25, 57.25, 50.0, 61.5,
        49.5, 57.5, 66.5, 57.25, 55.75, 56.0, 37.5, 54.0, 65.25, 55.5, 50.75, 62.5, 68.5, 49.5, 63.0, 59.25, 64.0,
        47.75, 56.0, 50.75, 63.75, 58.25, 29.25, 49.5, 56.0, 50.25, 51.25, 41.25, 48.0, 42.25, 51.75, 62.25, 62.0,
        42.75, 53.75, 47.25, 52.25, 55.25, 54.75, 45.75, 69.5, 60.25, 59.5, 58.5, 49.5, 42.0, 47.75, 62.25, 45.5, 56.0,
        67.25, 66.0, 47.0, 54.0, 44.5, 34.75, 45.75, 44.0, 58.75,
      ],
    },
  },
  rcp85: {
    years: [
      2005.0, 2006.0, 2007.0, 2008.0, 2009.0, 2010.0, 2011.0, 2012.0, 2013.0, 2014.0, 2015.0, 2016.0, 2017.0, 2018.0,
      2019.0, 2020.0, 2021.0, 2022.0, 2023.0, 2024.0, 2025.0, 2026.0, 2027.0, 2028.0, 2029.0, 2030.0, 2031.0, 2032.0,
      2033.0, 2034.0, 2035.0, 2036.0, 2037.0, 2038.0, 2039.0, 2040.0, 2041.0, 2042.0, 2043.0, 2044.0, 2045.0, 2046.0,
      2047.0, 2048.0, 2049.0, 2050.0, 2051.0, 2052.0, 2053.0, 2054.0, 2055.0, 2056.0, 2057.0, 2058.0, 2059.0, 2060.0,
      2061.0, 2062.0, 2063.0, 2064.0, 2065.0, 2066.0, 2067.0, 2068.0, 2069.0, 2070.0, 2071.0, 2072.0, 2073.0, 2074.0,
      2075.0, 2076.0, 2077.0, 2078.0, 2079.0, 2080.0, 2081.0, 2082.0, 2083.0, 2084.0, 2085.0, 2086.0, 2087.0, 2088.0,
      2089.0, 2090.0, 2091.0, 2092.0, 2093.0, 2094.0, 2095.0, 2096.0, 2097.0,
    ],
    fwi_nods_gt_15: {
      percentiles: {
        '10th': [
          97.8, 107.8, 96.8, 120.8, 94.8, 105.6, 96.4, 90.4, 101.4, 103.0, 118.8, 101.4, 103.4, 129.0, 118.6, 119.2,
          102.6, 120.6, 119.2, 114.2, 104.0, 95.4, 126.4, 129.8, 118.0, 127.2, 106.6, 101.4, 117.0, 120.2, 133.2, 118.4,
          127.8, 149.2, 115.8, 108.0, 134.8, 108.8, 153.4, 116.8, 123.0, 140.6, 118.2, 135.0, 123.8, 119.2, 129.0,
          144.2, 151.6, 125.0, 136.8, 123.0, 144.6, 129.6, 116.4, 141.6, 124.8, 142.6, 147.0, 155.4, 150.4, 121.8,
          143.0, 156.0, 138.2, 145.4, 136.8, 125.8, 168.4, 163.2, 153.6, 141.2, 175.0, 158.2, 174.8, 168.6, 197.8,
          123.6, 160.8, 193.2, 156.6, 143.4, 147.6, 159.2, 147.4, 181.6, 120.2, 159.8, 174.4, 173.2, 167.8, 152.6,
          166.6,
        ],
        '50th': [
          112.0, 141.0, 144.0, 138.0, 110.0, 151.0, 142.0, 128.0, 116.0, 150.0, 129.0, 139.0, 130.0, 148.0, 132.0,
          136.0, 122.0, 137.0, 160.0, 156.0, 130.0, 125.0, 140.0, 141.0, 164.0, 151.0, 118.0, 123.0, 152.0, 147.0,
          158.0, 164.0, 134.0, 158.0, 167.0, 131.0, 148.0, 161.0, 159.0, 170.0, 171.0, 170.0, 137.0, 152.0, 146.0,
          146.0, 173.0, 153.0, 173.0, 157.0, 176.0, 168.0, 147.0, 183.0, 128.0, 149.0, 175.0, 170.0, 174.0, 178.0,
          166.0, 180.0, 169.0, 165.0, 177.0, 193.0, 168.0, 165.0, 186.0, 187.0, 178.0, 170.0, 201.0, 177.0, 184.0,
          174.0, 224.0, 170.0, 176.0, 209.0, 162.0, 187.0, 157.0, 169.0, 183.0, 204.0, 159.0, 192.0, 226.0, 214.0,
          197.0, 183.0, 190.0,
        ],
        '90th': [
          149.6, 161.2, 170.6, 149.8, 184.8, 200.6, 192.0, 172.6, 164.6, 192.8, 134.0, 181.8, 173.6, 165.6, 156.0,
          188.2, 168.8, 174.2, 204.0, 194.6, 183.20000000000002, 213.4, 183.8, 192.2, 188.6, 211.4, 196.4, 193.0,
          188.20000000000002, 181.4, 204.6, 192.4, 180.0, 193.2, 202.4, 196.2, 183.0, 198.2, 182.0, 202.4, 240.4, 221.0,
          197.6, 184.8, 183.4, 174.6, 187.8, 208.60000000000002, 208.8, 208.8, 180.0, 231.4, 198.6, 202.6, 188.4, 212.6,
          240.4, 222.20000000000002, 210.8, 207.4, 195.0, 201.8, 193.6, 188.2, 237.6, 238.0, 212.0, 212.0, 214.4, 197.2,
          214.8, 223.0, 232.4, 213.6, 201.4, 234.6, 230.4, 226.4, 220.2, 247.2, 204.0, 203.4, 198.2, 234.8, 220.0,
          230.4, 186.2, 220.6, 250.6, 220.6, 235.8, 226.2, 247.20000000000002,
        ],
      },
      mean: [
        120.6, 135.6, 134.8, 136.8, 129.8, 153.0, 143.6, 129.2, 128.4, 147.8, 127.4, 140.0, 136.0, 147.6, 135.8, 149.0,
        132.4, 145.2, 162.2, 154.2, 140.0, 145.4, 150.8, 156.2, 154.8, 162.6, 140.4, 141.0, 152.0, 149.6, 165.8, 158.4,
        147.2, 166.8, 159.4, 146.2, 155.8, 154.4, 164.6, 161.2, 177.8, 180.0, 152.2, 157.2, 150.8, 146.4, 161.2, 168.2,
        178.6, 162.4, 164.2, 174.0, 164.2, 171.0, 145.8, 171.6, 182.4, 178.2, 177.4, 179.0, 171.8, 165.8, 168.0, 169.4,
        184.8, 190.8, 173.0, 168.8, 189.4, 182.0, 183.0, 178.0, 202.2, 183.2, 187.2, 194.0, 216.0, 172.8, 186.0, 217.0,
        176.6, 177.4, 170.0, 190.4, 183.6, 206.4, 154.6, 190.2, 214.6, 201.6, 199.4, 188.0, 200.2,
      ],
    },
    fwi_nods_gt_30: {
      percentiles: {
        '10th': [
          61.0, 81.2, 69.4, 64.4, 61.0, 62.0, 48.6, 65.6, 64.2, 69.6, 66.2, 58.0, 64.0, 91.0, 76.6, 71.0, 64.0, 86.0,
          72.4, 58.8, 56.2, 62.6, 80.0, 74.6, 69.4, 80.2, 62.2, 73.2, 85.4, 76.0, 78.0, 84.8, 71.0, 97.0, 77.8, 67.6,
          79.8, 73.4, 97.8, 80.8, 85.8, 104.6, 80.6, 95.2, 77.0, 80.4, 92.4, 96.6, 115.4, 85.4, 93.6, 88.2, 107.0, 83.8,
          79.8, 94.6, 85.8, 96.6, 92.4, 91.8, 113.2, 79.8, 94.2, 102.2, 100.8, 89.2, 88.8, 75.0, 100.6, 105.4, 108.2,
          88.8, 132.0, 127.4, 112.8, 108.6, 147.4, 80.4, 115.6, 142.0, 124.0, 95.0, 104.2, 104.0, 95.0, 118.6, 90.4,
          107.6, 132.8, 134.0, 124.8, 115.4, 126.2,
        ],
        '50th': [
          76.0, 101.0, 86.0, 102.0, 78.0, 103.0, 110.0, 83.0, 75.0, 94.0, 71.0, 99.0, 72.0, 108.0, 106.0, 86.0, 82.0,
          103.0, 111.0, 114.0, 104.0, 98.0, 97.0, 103.0, 79.0, 108.0, 79.0, 90.0, 106.0, 90.0, 104.0, 105.0, 82.0,
          106.0, 117.0, 79.0, 88.0, 107.0, 116.0, 104.0, 125.0, 136.0, 100.0, 115.0, 92.0, 84.0, 114.0, 104.0, 138.0,
          99.0, 134.0, 117.0, 115.0, 120.0, 96.0, 103.0, 135.0, 107.0, 122.0, 127.0, 128.0, 149.0, 128.0, 116.0, 125.0,
          133.0, 102.0, 116.0, 110.0, 142.0, 129.0, 97.0, 157.0, 136.0, 119.0, 113.0, 161.0, 139.0, 140.0, 172.0, 130.0,
          127.0, 121.0, 127.0, 152.0, 136.0, 104.0, 128.0, 146.0, 156.0, 136.0, 129.0, 148.0,
        ],
        '90th': [
          98.8, 110.8, 113.6, 107.0, 130.0, 136.0, 154.2, 123.0, 119.4, 148.4, 90.0, 135.6, 117.4, 116.0, 110.4,
          127.60000000000001, 109.2, 123.0, 139.4, 140.0, 134.6, 155.8, 140.8, 134.4, 134.2, 147.8, 136.60000000000002,
          145.4, 139.8, 132.6, 146.8, 134.6, 141.20000000000002, 135.2, 154.20000000000002, 151.6, 123.4, 142.4, 143.8,
          150.4, 171.2, 166.0, 157.8, 152.20000000000002, 140.20000000000002, 129.0, 139.8, 150.4, 151.0, 133.4, 139.8,
          158.0, 151.0, 162.0, 153.4, 146.8, 168.2, 169.6, 168.60000000000002, 166.2, 144.0, 153.6, 147.0, 145.0, 181.2,
          177.6, 174.2, 170.8, 171.0, 149.0, 167.8, 167.20000000000002, 171.2, 167.4, 168.6, 184.20000000000002, 186.2,
          182.20000000000002, 172.4, 214.6, 164.0, 146.8, 161.0, 186.8, 160.0, 179.0, 145.4, 160.8, 206.2, 187.8,
          185.20000000000002, 175.2, 204.6,
        ],
      },
      mean: [
        78.6, 97.4, 90.0, 91.4, 89.8, 100.4, 101.8, 90.4, 85.8, 105.8, 76.2, 96.2, 86.4, 104.8, 96.2, 95.4, 85.0, 103.4,
        106.0, 101.6, 98.4, 104.4, 105.6, 102.6, 95.6, 111.2, 92.0, 105.8, 110.0, 101.4, 110.0, 109.4, 99.2, 112.4,
        115.0, 101.0, 98.4, 106.6, 119.4, 113.6, 127.4, 137.0, 113.8, 120.6, 103.8, 100.0, 116.4, 117.2, 133.6, 106.6,
        122.4, 120.6, 124.4, 122.8, 110.8, 116.4, 129.0, 125.6, 127.6, 127.4, 127.8, 123.8, 122.2, 122.4, 137.0, 132.2,
        125.4, 119.8, 128.6, 131.2, 135.2, 119.4, 153.0, 145.2, 136.2, 136.6, 165.2, 131.2, 141.4, 175.4, 139.6, 121.4,
        128.8, 139.8, 134.0, 146.2, 114.6, 132.6, 163.2, 160.8, 149.2, 140.4, 159.6,
      ],
    },
    fwi_nods_gt_45: {
      percentiles: {
        '10th': [
          30.8, 48.8, 46.0, 32.0, 35.6, 30.4, 20.6, 33.2, 35.0, 30.2, 32.8, 34.0, 34.4, 43.6, 42.8, 33.2, 35.2, 50.4,
          33.0, 18.8, 30.8, 35.4, 44.6, 42.0, 24.2, 34.4, 30.2, 38.4, 56.8, 32.0, 40.6, 53.4, 31.0, 44.0, 53.2, 30.2,
          47.0, 45.6, 54.4, 48.0, 59.6, 62.400000000000006, 52.0, 63.2, 48.2, 38.8, 67.2, 65.2, 72.0, 33.6, 62.4, 49.6,
          52.8, 45.8, 45.0, 47.4, 51.2, 66.8, 59.0, 46.4, 75.4, 43.8, 53.2, 64.2, 73.4, 55.8, 53.4, 35.2, 63.2, 70.6,
          65.4, 48.4, 76.8, 92.2, 73.0, 54.0, 82.2, 57.8, 70.8, 100.4, 80.8, 60.2, 67.2, 71.6, 55.6, 87.4, 57.2,
          59.400000000000006, 88.8, 92.2, 68.8, 79.2, 83.2,
        ],
        '50th': [
          37.0, 66.0, 52.0, 60.0, 42.0, 62.0, 75.0, 47.0, 43.0, 59.0, 45.0, 62.0, 37.0, 68.0, 61.0, 58.0, 39.0, 54.0,
          66.0, 75.0, 66.0, 52.0, 58.0, 57.0, 34.0, 63.0, 43.0, 52.0, 67.0, 47.0, 56.0, 54.0, 61.0, 65.0, 78.0, 42.0,
          61.0, 66.0, 88.0, 70.0, 76.0, 89.0, 63.0, 78.0, 60.0, 62.0, 86.0, 73.0, 80.0, 68.0, 88.0, 77.0, 76.0, 79.0,
          62.0, 63.0, 93.0, 76.0, 75.0, 78.0, 79.0, 103.0, 85.0, 80.0, 84.0, 91.0, 64.0, 64.0, 92.0, 102.0, 81.0, 73.0,
          95.0, 109.0, 94.0, 68.0, 126.0, 93.0, 86.0, 112.0, 92.0, 70.0, 76.0, 94.0, 97.0, 96.0, 82.0, 98.0, 106.0,
          100.0, 95.0, 92.0, 104.0,
        ],
        '90th': [
          68.4, 68.6, 58.4, 64.6, 78.8, 91.0, 110.6, 67.2, 77.80000000000001, 98.2, 60.0, 97.2, 67.2, 77.2, 84.4, 73.4,
          67.0, 71.2, 86.2, 98.6, 82.4, 102.0, 107.0, 89.2, 74.8, 95.2, 87.2, 98.8, 104.0, 82.8, 94.0,
          92.60000000000001, 89.80000000000001, 95.0, 95.2, 101.2, 75.8, 101.2, 104.8, 97.0, 103.80000000000001, 105.0,
          104.4, 117.80000000000001, 99.2, 93.2, 97.4, 96.60000000000001, 98.2, 78.4, 103.2, 99.4, 111.60000000000001,
          110.60000000000001, 111.80000000000001, 98.80000000000001, 110.4, 122.0, 119.80000000000001, 123.0, 102.2,
          119.0, 103.4, 107.4, 136.0, 119.2, 119.8, 132.4, 129.6, 109.0, 130.0, 114.2, 117.2, 119.8, 132.2, 130.6,
          147.4, 139.6, 134.20000000000002, 161.4, 114.2, 116.80000000000001, 130.6, 137.6, 116.6, 124.0, 112.0, 119.8,
          147.8, 137.2, 129.0, 128.4, 150.2,
        ],
      },
      mean: [
        46.8, 60.2, 52.0, 52.4, 53.0, 60.8, 67.2, 49.2, 51.8, 62.2, 45.4, 64.6, 46.8, 62.8, 62.0, 54.6, 48.6, 58.6,
        60.2, 61.2, 59.6, 63.4, 70.6, 63.4, 45.2, 64.4, 54.4, 65.6, 75.8, 55.2, 63.6, 67.8, 59.4, 68.0, 75.6, 58.6,
        61.0, 71.4, 81.0, 72.2, 79.8, 86.2, 73.6, 86.8, 69.2, 64.6, 83.0, 78.2, 83.4, 59.4, 84.2, 75.2, 80.4, 78.4,
        73.4, 69.0, 84.2, 89.0, 85.0, 81.2, 87.0, 86.0, 79.4, 85.0, 99.6, 86.6, 81.8, 78.4, 93.6, 92.8, 93.0, 77.4,
        96.0, 106.4, 101.2, 85.2, 117.8, 95.6, 96.8, 124.6, 95.6, 83.6, 92.6, 100.8, 90.2, 102.4, 84.6, 92.6, 114.0,
        111.0, 97.0, 99.8, 113.6,
      ],
    },
  },
}

export const usePropertyClimateAnalyticsFireHazard = (propertyId: number) => {
  const rcp26 = temp_fire_hazard['rcp26']
  const rcp85 = temp_fire_hazard['rcp85']

  return {
    data: {
      rcp26: rcp26,
      rcp85: rcp85,
    },
  }
}

const temp_dry_days = {
  rcp26: {
    years: [
      2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
      2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043,
      2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060, 2061, 2062,
      2063, 2064, 2065, 2066, 2067, 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079, 2080, 2081,
      2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2091, 2092, 2093, 2094, 2095, 2096, 2097, 2098, 2099,
    ],
    dd: {
      percentiles: {
        '10th': [
          242.4, 233.2, 223.9, 246.4, 215.5, 229.1, 234.6, 236.8, 222.0, 241.4, 232.6, 230.1, 258.3, 242.8, 253.1,
          254.0, 258.6, 248.5, 243.4, 254.8, 255.0, 248.0, 250.0, 247.6, 266.9, 257.8, 249.5, 240.9, 255.7, 244.1,
          245.7, 253.3, 244.3, 263.3, 266.0, 229.9, 267.6, 257.0, 277.6, 258.3, 233.3, 255.7, 265.2, 255.5, 253.0,
          212.0, 252.2, 250.4, 239.9, 250.9, 277.4, 246.7, 257.8, 248.5, 253.0, 248.5, 237.9, 249.6, 253.5, 245.3,
          249.4, 229.2, 242.6, 251.4, 256.0, 244.7, 253.4, 246.9, 224.7, 239.9, 250.3, 269.5, 232.3, 245.0, 229.6,
          210.3, 259.6, 232.7, 256.8, 239.5, 232.6, 248.2, 252.2, 251.6, 247.5, 254.0, 241.9, 236.2, 232.3, 221.3,
          232.6, 236.3, 261.9, 238.9,
        ],
        '50th': [
          261.5, 266.0, 252.0, 289.0, 270.0, 261.0, 269.5, 250.5, 248.5, 268.5, 249.0, 284.5, 278.5, 264.0, 271.0,
          274.0, 268.5, 271.5, 271.5, 276.0, 265.5, 266.0, 274.5, 257.0, 276.5, 278.0, 259.5, 265.5, 269.0, 270.0,
          270.0, 275.0, 269.5, 283.0, 282.0, 267.0, 278.5, 265.5, 290.0, 280.5, 252.5, 279.5, 282.0, 264.0, 266.0,
          297.5, 267.5, 275.5, 252.0, 267.5, 287.0, 257.5, 279.5, 288.5, 273.0, 277.0, 262.5, 271.5, 274.5, 252.5,
          266.5, 264.5, 267.0, 260.5, 276.0, 261.5, 279.5, 267.0, 253.5, 255.0, 268.0, 282.5, 285.0, 267.5, 269.5,
          261.0, 271.0, 274.0, 271.0, 251.0, 268.5, 276.5, 264.5, 261.0, 265.0, 277.5, 271.0, 271.0, 264.5, 258.5,
          260.0, 260.0, 272.0, 259.0,
        ],
        '90th': [
          277.6, 290.8, 281.0, 312.4, 292.5, 280.7, 288.7, 279.2, 261.9, 291.7, 292.1, 291.3, 315.4, 291.4, 288.4,
          285.6, 294.0, 291.1, 297.0, 304.7, 300.4, 294.2, 297.09999999999997, 274.2, 288.3, 301.0, 279.6, 278.7, 293.7,
          295.8, 286.0, 304.7, 275.3, 298.7, 290.9, 289.8, 291.9, 282.9, 306.9, 305.7, 280.1, 290.0, 303.3, 292.2,
          304.6, 307.1, 278.9, 289.7, 265.3, 284.4, 308.5, 277.8, 308.2, 303.3, 288.4, 296.8, 278.5, 287.6, 286.4,
          281.9, 296.3, 297.0, 290.6, 278.6, 291.09999999999997, 284.8, 310.8, 279.5, 287.1, 267.0, 301.3, 296.3, 305.2,
          292.4, 284.2, 312.0, 280.2, 288.2, 289.0, 279.3, 291.6, 287.09999999999997, 282.5, 277.9, 284.3, 307.5, 280.5,
          282.8, 279.2, 286.5, 295.1, 276.8, 288.1, 274.2,
        ],
      },
      mean: [
        262.3, 265.1, 253.2, 280.2, 260.9, 257.1, 267.8, 255.2, 246.2, 265.1, 257.6, 267.1, 280.7, 266.1, 270.9, 272.1,
        274.1, 272.0, 272.2, 277.1, 272.5, 267.3, 272.8, 259.4, 278.2, 277.0, 262.3, 260.3, 273.4, 268.8, 267.6, 277.9,
        262.3, 282.8, 279.4, 262.9, 279.2, 268.6, 289.4, 280.1, 254.0, 274.0, 283.3, 269.3, 274.3, 273.8, 266.9, 271.5,
        251.7, 267.1, 290.1, 261.3, 283.2, 276.9, 270.5, 275.1, 260.3, 269.3, 271.4, 258.4, 269.1, 260.1, 268.0, 263.5,
        272.3, 262.6, 280.9, 265.1, 255.4, 254.5, 272.5, 281.1, 274.3, 269.5, 262.1, 262.2, 269.9, 263.0, 271.4, 257.1,
        264.2, 272.4, 267.1, 262.7, 265.5, 279.5, 264.9, 265.3, 260.2, 257.2, 263.4, 256.2, 272.4, 257.3,
      ],
    },
  },
  rcp85: {
    years: [
      2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
      2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043,
      2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060, 2061, 2062,
      2063, 2064, 2065, 2066, 2067, 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079, 2080, 2081,
      2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2091, 2092, 2093, 2094, 2095, 2096, 2097, 2098, 2099,
    ],
    dd: {
      percentiles: {
        '10th': [
          264.6, 265.6, 259.4, 274.0, 279.4, 259.6, 257.4, 257.6, 276.0, 269.2, 277.6, 262.6, 276.0, 271.8, 288.6,
          271.6, 269.6, 263.0, 262.0, 254.6, 270.8, 271.6, 291.8, 276.8, 271.8, 285.8, 253.2, 282.8, 284.6, 300.8,
          287.4, 281.0, 290.8, 278.0, 277.8, 270.6, 301.0, 264.0, 281.4, 263.6, 270.8, 283.6, 277.0, 295.0, 276.0,
          278.8, 272.8, 302.2, 292.2, 294.2, 287.8, 277.4, 279.2, 293.8, 260.2, 279.8, 272.6, 270.0, 287.0, 297.8,
          291.2, 275.2, 259.8, 285.2, 308.8, 286.8, 298.6, 288.2, 297.2, 279.6, 275.6, 296.6, 314.0, 308.4, 304.6,
          294.8, 316.0, 270.2, 282.2, 315.2, 312.6, 272.6, 293.6, 297.4, 277.2, 283.0, 266.8, 298.6, 302.0, 301.6,
          297.4, 299.6, 304.2, 298.7,
        ],
        '50th': [
          292.0, 282.0, 286.0, 286.0, 291.0, 292.0, 282.0, 277.0, 299.0, 295.0, 279.0, 287.0, 287.0, 289.0, 300.0,
          287.0, 282.0, 286.0, 292.0, 294.0, 280.0, 281.0, 308.0, 293.0, 288.0, 295.0, 269.0, 295.0, 299.0, 309.0,
          297.0, 296.0, 298.0, 295.0, 306.0, 290.0, 305.0, 305.0, 298.0, 292.0, 301.0, 307.0, 307.0, 309.0, 302.0,
          294.0, 295.0, 317.0, 316.0, 302.0, 292.0, 287.0, 297.0, 302.0, 282.0, 294.0, 315.0, 314.0, 307.0, 309.0,
          310.0, 297.0, 301.0, 312.0, 313.0, 296.0, 308.0, 307.0, 304.0, 307.0, 305.0, 309.0, 319.0, 321.0, 316.0,
          311.0, 326.0, 301.0, 318.0, 319.0, 317.0, 305.0, 306.0, 327.0, 306.0, 320.0, 302.0, 304.0, 323.0, 316.0,
          307.0, 305.0, 318.0, 311.0,
        ],
        '90th': [
          301.2, 309.2, 304.4, 311.0, 304.2, 319.2, 302.2, 291.2, 323.4, 305.8, 299.2, 311.6, 297.4, 309.2, 310.2,
          300.6, 289.6, 321.4, 313.6, 306.0, 305.4, 296.8, 322.0, 314.0, 304.2, 311.6, 300.4, 302.0, 306.4, 315.4,
          328.2, 314.0, 306.2, 309.6, 322.4, 305.8, 314.4, 315.4, 310.6, 317.0, 325.4, 317.2, 316.2, 316.8, 311.2,
          303.0, 328.0, 322.2, 323.0, 310.6, 309.4, 324.0, 315.4, 307.4, 299.8, 329.8, 323.2, 333.2, 313.0, 317.2,
          321.2, 333.6, 319.0, 327.8, 320.0, 327.6, 325.4, 326.6, 314.4, 329.4, 314.6, 326.6, 335.6, 335.8, 325.8,
          320.6, 342.2, 316.8, 328.0, 329.4, 337.2, 334.2, 326.0, 335.8, 339.2, 337.4, 324.2, 329.2, 338.6, 323.6,
          318.2, 331.6, 328.0, 326.5,
        ],
      },
      mean: [
        284.8888888888889, 283.8888888888889, 283.22222222222223, 289.22222222222223, 291.0, 289.55555555555554,
        283.22222222222223, 275.22222222222223, 297.44444444444446, 289.1111111111111, 284.55555555555554,
        287.44444444444446, 287.0, 289.22222222222223, 300.55555555555554, 287.0, 279.8888888888889, 291.0,
        287.22222222222223, 288.55555555555554, 287.8888888888889, 282.3333333333333, 307.77777777777777,
        294.3333333333333, 286.77777777777777, 296.1111111111111, 273.22222222222223, 294.44444444444446,
        296.22222222222223, 308.6666666666667, 304.55555555555554, 297.22222222222223, 299.44444444444446,
        294.55555555555554, 301.22222222222223, 288.55555555555554, 306.8888888888889, 296.22222222222223,
        297.1111111111111, 292.77777777777777, 299.55555555555554, 302.55555555555554, 298.1111111111111,
        306.1111111111111, 295.44444444444446, 292.55555555555554, 299.77777777777777, 313.0, 310.22222222222223,
        302.6666666666667, 296.55555555555554, 294.8888888888889, 297.44444444444446, 300.8888888888889,
        280.3333333333333, 299.55555555555554, 302.77777777777777, 306.8888888888889, 302.77777777777777,
        306.3333333333333, 307.8888888888889, 303.77777777777777, 293.3333333333333, 307.55555555555554,
        314.3333333333333, 306.22222222222223, 309.77777777777777, 305.6666666666667, 305.1111111111111,
        306.44444444444446, 299.1111111111111, 310.6666666666667, 323.55555555555554, 320.55555555555554,
        316.1111111111111, 309.55555555555554, 326.44444444444446, 294.77777777777777, 308.55555555555554,
        321.55555555555554, 322.55555555555554, 305.0, 308.22222222222223, 320.8888888888889, 307.3333333333333,
        313.6666666666667, 297.44444444444446, 311.44444444444446, 322.0, 315.22222222222223, 307.77777777777777,
        310.77777777777777, 316.44444444444446, 312.75,
      ],
    },
  },
}

export const usePropertyClimateAnalyticsDryDays = (propertyId: number) => {
  const rcp26 = temp_dry_days['rcp26']
  const rcp85 = temp_dry_days['rcp85']

  return {
    data: {
      rcp26: rcp26,
      rcp85: rcp85,
    },
  }
}

function getMitigation(categories: any[], category: string, reference: string) {
  const cat = categories.find(cat => cat.name === category)
  if (cat) {
    return cat.mitigations.find(mit => mit.reference === reference)
  }
  return null
}

export const useClimateMitigations = (propertyId: number) => {
  const path = `/analytics/locations/${propertyId}/cc/mitigations`
  const fetch = async () => await api.get(path).then(res => res.data)
  const data = useQuery([path], fetch)
  return {
    ...data,
    getMitigation: (category: string, reference: string) => getMitigation(data?.data ?? [], category, reference),
  }
}

export const useClimatePropertyMetadata = (propertyId: number) => {
  const path = `/analytics/locations/${propertyId}/cc/metadata`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useClimatePropertyData = (propertyId: number, hazardName: string) => {
  const path = `/analytics/locations/${propertyId}/cc/hazard/${hazardName}`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useClimatePropertyHazards = (propertyId: number) => {
  const path = `/analytics/locations/${propertyId}/cc/hazards`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useClimateHazards = () => {
  const path = `/analytics/cc/hazards`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useClimateScenarios = () => {
  const path = `/analytics/cc/scenarios`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}

export const useClimatePortfolioData = (hazardName: string) => {
  const path = `/analytics/cc/hazard/${hazardName}`
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery([path], fetch)
}
