import { useLocation } from 'react-router-dom'
import { DynamicForm } from 'balkerne-components/DynamicForm'
import Page from '../components/Page'

const FormView = () => {
  const location = useLocation()
  return (
    <Page title="Completed Form" size="lg" back>
      <DynamicForm {...location.state} />
    </Page>
  )
}

export default FormView
