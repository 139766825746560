const standardstyle = {
  background: '#0652dd',
}
const islamstyle = {
  background: '#009000',
}
const church = {
  background: '#804FB3',
}
const bandqorange = {
  background: '#FF6600',
}
const screwfixblue = {
  background: '#00486E',
}
const aiusgreen = {
  background: '#336D79',
}
const marshblue = {
  background: '#00A8C7',
}
const coopblue = {
  background: '#418CFC',
}

export const organisationDefaultSetup = () => {
  return {
    org_url: require('../images/Logo.png'),
    org_name: 'Balkerne Ltd',
    customstyle: standardstyle,
    keywords: 'flooding rain uk,arson,robbery,criminal damage,theft',
  }
}

export const organisationSetup = organisationId => {
  let customstyle = {}
  if (organisationId === 101) {
    customstyle = islamstyle
  } else if (organisationId === 2) {
    customstyle = church
  } else {
    customstyle = standardstyle
  }

  const data = {
    org_url: require('../images/Logo.png'),
    org_name: '',
    customstyle: customstyle,
    keywords: '',
  }

  switch (organisationId) {
    case 0:
      data.org_url = require('../images/sainsburys.jpg')
      data.org_name = 'Sainsburys Supermarkets Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 1:
      data.org_name = 'Argos Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk'
      break

    case 2:
      data.org_name = 'Trinitas Insurance Services'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,stolen lead church uk'
      break

    case 4:
      data.org_name = 'Commercial MGA Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break
    case 4:
      data.org_url = require('../images/Logo.png')
      data.org_name = 'Marks and Spencers Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 3:
      data.org_name = 'Waitrose Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 7:
      data.org_name = 'Screwfix Ltd'
      data.customstyle = screwfixblue
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break
    case 8:
      data.org_name = 'Kingfisher UK Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,criminal damage'
      break

    case 14:
      data.org_name = 'Asda Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 30:
      data.org_name = 'Aldi Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 11:
      data.org_name = 'Lidl Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

      break
    case 35:
      data.org_name = 'Morrisons Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'

      break

    case 41:
      data.org_url = require('../images/tesco_icon.png')
      data.org_name = 'Tesco Plc'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 42:
      data.org_url = require('../images/coop_icon.png')
      data.org_name = 'The Co-operative Group'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 43:
      data.org_name = 'Iceland'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 44:
      data.org_url = require('../images/boots_logo.png')
      data.org_name = 'Boots'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 101:
      data.org_name = 'Himaya'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 160:
    case 161:
    case 162:
      data.customstyle = aiusgreen
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 167:
      data.customstyle = marshblue
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      break

    case 169:
      data.customstyle = coopblue
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
      data.customstyle.dashboards = [
        {
          Dashboard_Name: 'Incident History',
          Dashboard_ID: 'f60eb941-836d-4abf-bccf-2499363f0f3c',
        },
        {
          Dashboard_Name: 'Co-op Property Risk',
          Dashboard_ID: '05f7314d-8fb4-4b1a-9fe0-d582a19582a6',
        },
        {
          Dashboard_Name: 'Alerts History',
          Dashboard_ID: '238f5905-f0da-43a8-9be5-9b478a83ca51',
        },
      ]
      break

    default:
      data.org_url = require('../images/Logo.png')
      data.org_name = 'Balkerne Ltd'
      data.customstyle = customstyle
      data.keywords = 'flooding rain uk,arson,robbery,criminal damage,theft'
  }
  return data
}
