import { createApi } from 'balkerne-core/api'
import { setupCache } from 'axios-cache-interceptor'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const cachedApi = setupCache(createApi(), {
  ttl: 60 * 1000,
})

export async function emailExists(email) {
  return new Promise((resolve, reject) => {
    cachedApi
      .get('/registration/email-exists', { params: { email } })
      .then(res => {
        resolve(res.data.exists)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}

export async function emailAvailable(email) {
  return new Promise((resolve, reject) => {
    if (email.length < 3) {
      resolve(true)
      return
    }
    cachedApi
      .get('/registration/email-exists', { params: { email } })
      .then(res => {
        resolve(!res.data)
      })
      .catch(err => {
        console.error(err)
        reject(false)
      })
  })
}

export function emailValid(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(email.toLowerCase())
}

export function validateUniqueArray(message, mapper = item => item) {
  return this.test('unique', message, (list, { createError }) => {
    const isUnique = list.length === new Set(list.map(mapper)).size
    if (!isUnique) {
      return createError({ path: 'unique', message: message })
    }
    return true
  })
}

export function validateInSequence(list) {
  return this.test(async (value, context) => {
    try {
      for (const func of list) {
        await func().validate(value)
      }
    } catch ({ message }) {
      return context.createError({ message })
    }
    return true
  })
}

export function validatePhoneNumber() {
  return this.test('phone-number-valid', 'Please provide a valid phone number', value => {
    if (!value) return true
    const phoneNumber = parsePhoneNumberFromString(value)
    return phoneNumber ? phoneNumber.isValid() : false
  })
}
