import React, { useState, useMemo, useCallback } from 'react'
import { useUsers } from '../hooks/users'
import Modal from '@mui/material/Modal'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { useSnackbar } from 'notistack'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import HouseRoundedIcon from '@mui/icons-material/HouseRounded'
import api from 'balkerne-core/api'
import {
  PortfolioType,
  UserPermissions,
  usePermissions,
  PropertyPermissions,
  PermissionGate,
} from 'balkerne-core/roles'
import { useHistory } from 'react-router-dom'
import siteMap from '../siteMap'
import { Stack, useTheme } from '@mui/material'

export const UsersList = () => {
  const { data: users, isLoading, refetch } = useUsers()
  const { possible } = usePermissions()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState(-1)
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  function handleDelete(userId) {
    setIsModalOpen(true)
    setDeleteUserId(userId)
  }

  function deleteUser() {
    if (deleteUserId !== -1) {
      api
        .delete(`/users/${deleteUserId}`)
        .then(async () => {
          await refetch()
          enqueueSnackbar('User deleted', { variant: 'success' })
        })
        .catch(err => {
          enqueueSnackbar('Error while deleting user', { variant: 'error' })
          console.error(err)
        })
        .finally(() => setIsModalOpen(false))
    }
  }

  function handleDoubleClick(user) {
    history.push(siteMap.AssignUsers.getPath([user.id]))
  }

  function canAssignProperties(role) {
    return role.attributes.portfolio_type !== PortfolioType.Organisation && possible(PropertyPermissions.Assign)
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'full_name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 280,
      },
      {
        field: 'role',
        headerName: 'Role',
        width: 180,
        valueGetter: params => params.row.role.name,
      },
      {
        field: 'position',
        headerName: 'Position',
        width: 180,
      },
      {
        field: 'phone',
        headerName: 'Phone number',
        width: 150,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: args => [
          <GridActionsCellItem
            icon={<HouseRoundedIcon />}
            onClick={() => handleDoubleClick(args.row)}
            label="Assign properties"
            disabled={!canAssignProperties(args.row.role)}
          />,
          <GridActionsCellItem
            icon={<DeleteForeverIcon />}
            onClick={() => handleDelete(args.id)}
            label="Delete"
            disabled={!possible(UserPermissions.Delete)}
          />,
        ],
      },
    ],
    [],
  )

  const CustomGridToolbar = useCallback(
    () => (
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <Stack>
          <PermissionGate action={UserPermissions.Add}>
            <Button size="small" variant="contained" onClick={() => history.push(siteMap.CreateUsers.path)}>
              Create User
            </Button>
          </PermissionGate>
        </Stack>
        <GridToolbar />
      </GridToolbarContainer>
    ),
    [],
  )

  return (
    <>
      <Box sx={{ height: 700, mb: 6 }}>
        <DataGrid
          components={{
            Toolbar: CustomGridToolbar,
          }}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
          density="compact"
          columns={columns}
          rows={users}
          loading={isLoading}
        />
      </Box>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Grid container direction="column" alignItems="center" style={{ minHeight: '100vh' }}>
          <Grid item xs={8}>
            <Card style={{ width: '300px' }}>
              <Box sx={{ padding: '16px' }}>
                <Typography sx={{ marginBottom: '16px' }} variant="h5">
                  Delete user?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'flex-end' }}>
                  <Button variant="contained" onClick={() => setIsModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" onClick={deleteUser}>
                    Delete
                  </Button>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default UsersList
