import { useTheme, ListItemButton } from '@mui/material'
import { lighten, alpha } from '@mui/material'

const LightListItemButton = ({ children, ...props }) => {
  const theme = useTheme()
  const color = theme.palette.primary.main
  const selectedColor = lighten(color, 0.6)

  return (
    <ListItemButton
      {...props}
      divider
      sx={{
        '&.Mui-selected': {
          '& .MuiSvgIcon-root': {
            color: theme.palette.text.lighter,
          },
          color: theme.palette.text.primary,
          backgroundColor: alpha(selectedColor, 0.3),
          '&:hover': {
            backgroundColor: alpha(selectedColor, 0.5),
          },
        },
        '&:hover': {
          backgroundColor: alpha(selectedColor, 0.5),
        },
        '& .MuiSvgIcon-root': {
          color: theme.palette.text.lighter,
        },
        color: theme.palette.text.primary,
        borderBottom: 'none',
        borderRadius: theme.shape,
      }}>
      {children}
    </ListItemButton>
  )
}

export default LightListItemButton
