import { useTheme } from '@mui/material'

export const Risk = Object.freeze({
  HIGH: 4,
  MEDIUM: 3,
  LOW: 2,
  NEGLIGIBLE: 1,
  NONE: 0,
  INAPPLICABLE: -1,
})

export const JBARisk = Object.freeze({
  0: 'No Risk',
  1: 'Low',
  2: 'Moderate',
  3: 'Moderate to High',
  4: 'High',
  5: 'Very High',
})

export const RiskType = Object.freeze({
  RIVER_SEA: 'river_sea',
  SURFACE_FLOOD: 'surface_flood',
})

export const RiskDetails = Object.freeze({
  [RiskType.RIVER_SEA]: {
    label: 'Rivers and Coastal Flood',
    maxScore: 4,
  },
  [RiskType.SURFACE_FLOOD]: {
    label: 'Surface Water Flood',
    maxScore: 4,
  },
  jba_floodability: {
    label: 'JBA Floodability',
    maxScore: 5,
  },
})

export function calculateRiskScore(riskName, riskScore) {
  return (riskScore / RiskDetails[riskName].maxScore) * 100
}

export const calculatePropertyRiskScore = (risks, includeRiskScores = false) => {
  const maxTotalRiskScore = RiskDetails[RiskType.RIVER_SEA].maxScore + RiskDetails[RiskType.SURFACE_FLOOD].maxScore
  let totalRiskScore = risks.reduce(
    // Exclude inapplicable risks from total risk score calculation
    (total, risk) => (risk.value === Risk.INAPPLICABLE ? total : total + risk.value),
    0,
  )
  totalRiskScore = (totalRiskScore / maxTotalRiskScore) * 100

  let riskScoresData = {
    score: totalRiskScore,
  }

  if (includeRiskScores) {
    const riskScores = {}
    risks.forEach(item => {
      riskScores[item.category.name] = item.value
    })
    riskScoresData = {
      ...riskScoresData,
      ...riskScores,
    }
  }
  return riskScoresData
}

export const getRiskScoreColor = score => {
  const theme = useTheme()
  if (score > 66) {
    return theme.palette.risk.high
  } else if (score > 33) {
    return theme.palette.risk.medium
  } else {
    return theme.palette.risk.low
  }
}

export const getRiskToolTipMessage = riskRating => {
  switch (riskRating) {
    case 'RiverFlood_0':
      return 'Negligible risk from flooding'
    case 'RiverFlood_1':
      return 'Very Low - this area has a chance of flooding each year of less than 1 in 1000 (0.1%)'
    case 'RiverFlood_2':
      return 'Low : each year, this area has a chance of flooding of between 1 in 1000 (0.1%) 1 in 100 (1%)'
    case 'RiverFlood_3':
      return 'Medium : each year, this area has a chance of flooding of between 1 in 100 (1%) and 1 in 30 (3.3%)'
    case 'RiverFlood_4':
      return 'High : High : each year, this area has a chance of flooding of greater than 1 in 30 (3.3%)'
    case 'SurfaceWater_0':
      return 'Negligible risk from flooding'
    case 'SurfaceWater_2':
      return 'Low : each year, this area has a chance of flooding of between 1 in 1000 (0.1%) 1 in 100 (1%)'
    case 'SurfaceWater_3':
      return 'Medium : each year, this area has a chance of flooding of between 1 in 100 (1%) and 1 in 30 (3.3%)'
    case 'SurfaceWater_4':
      return 'High : High : each year, this area has a chance of flooding of greater than 1 in 30 (3.3%)'
  }
}
