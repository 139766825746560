import React from 'react'
import ContentLoader from 'react-content-loader'

const CardLoader = props => (
  <ContentLoader
    speed={1}
    width={200}
    height={75}
    viewBox="0 0 200 75"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <circle cx="28" cy="20" r="8" />
    <rect x="50" y="15" rx="5" ry="5" width="600" height="10" />
    <circle cx="28" cy="50" r="8" />
    <rect x="50" y="45" rx="5" ry="5" width="600" height="10" />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="110" r="8" />
    <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
)

export default CardLoader
