import React from 'react'
import c from './PreTitle.module.scss'

const PreTitle = props => {
  return (
    <div className={c.pretitle}>
      {props.children}
    </div>
  )
}

export default PreTitle
