export const scoreColor = {
  1: '#a0ce63',
  2: '#eaa443',
  3: '#ea3323',
  4: '#757171',
  5: '#494949',
}

export const scoreTextColor = {
  1: 'black',
  2: 'black',
  3: 'white',
  4: 'white',
  5: 'white',
}

export function getRating(score: number): string | null {
  return _scoreToRating?.[score] ?? null
}

const _scoreToRating: { [key: number]: string } = {
  1: 'Low',
  2: 'Moderate',
  3: 'Moderate to High',
  4: 'High',
  5: 'Very High',
}

export default scoreColor
