import {
  useTheme,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Hidden,
  SwipeableDrawer,
  Skeleton,
} from '@mui/material'
import {
  ExpandLess,
  ExpandMore,
  LogoutRounded,
  SpaceDashboardOutlined,
  PublicOutlined,
  HouseboatOutlined,
  MapsHomeWorkOutlined,
  CrisisAlertOutlined,
  HomeOutlined,
  TsunamiOutlined,
  NotificationImportantOutlined,
  FenceOutlined,
  HouseSidingOutlined,
  GppMaybeOutlined,
  QueryStatsOutlined,
  SettingsOutlined,
  ArticleOutlined,
  BalconyOutlined,
} from '@mui/icons-material'
import { signOut } from 'balkerne-core/auth'
import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import webLogo from '../images/logo_web_v2.png'
import siteMap from '../siteMap'
import { useHistory } from 'react-router-dom'
import authActions from '../store/auth'
import systemActions from '../store/system'
import { useDispatch, useSelector } from 'react-redux'
import { lighten, alpha } from '@mui/material'
import { PermissionGate, ViewPermissions, FormPermissions } from 'balkerne-core/roles'
import { useQueryClient } from 'react-query'
import { RootState } from '../store'
import { useCurrentUser } from '../hooks/users'

const menuItems = [
  {
    text: 'Dashboard',
    icon: <SpaceDashboardOutlined />,
    path: siteMap.Portfolio.path,
    permission: ViewPermissions.Portfolio,
  },
  {
    text: 'Organisation',
    icon: <MapsHomeWorkOutlined />,
    path: siteMap.Organisation.path,
  },
  {
    text: 'Alerts',
    icon: <CrisisAlertOutlined />,
    path: siteMap.PortfolioAlerts.path,
    permission: ViewPermissions.Portfolio,
    feature: 'alerts',
  },
  {
    text: 'Operations',
    icon: <PublicOutlined />,
    path: siteMap.PortfolioOperations.path,
    permission: ViewPermissions.Portfolio,
    feature: 'operations',
  },
  {
    text: 'Climate Analytics',
    icon: <TsunamiOutlined />,
    hideMobile: true,
    path: siteMap.ClimateAnalyticsV2.path,
    permission: ViewPermissions.Portfolio,
    feature: 'projections',
  },
  {
    text: 'Forms',
    icon: <ArticleOutlined />,
    path: siteMap.CompletedForms.path,
    permission: FormPermissions.ListCompleted,
  },
]

const CollapseListItem = ({ component, render, children, open, ...props }) => {
  const theme = useTheme()
  const [_open, setOpen] = useState(open)
  useEffect(() => {
    if (open) {
      setOpen(open)
    }
  }, [open])

  let element = null
  if (component) {
    element = component
  } else if (render) {
    element = render()
  }
  const sx = {
    '&.MuiSvgIcon-root': {
      color: theme.palette.text.lighter,
      opacity: 0.5,
    },
  }
  const renderCollapse = children && (_open || !_open)
  return (
    <>
      <ListItem {...props} onClick={() => setOpen(!_open)}>
        <LightListItemButton>
          {element}
          {_open ? <ExpandLess sx={sx} /> : <ExpandMore sx={sx} />}
        </LightListItemButton>
      </ListItem>
      {renderCollapse && (
        <Collapse sx={props.sx} in={_open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  )
}

const LightListItemButton = ({ children, ...props }) => {
  const theme = useTheme()
  const color = theme.palette.primary.main
  const selectedColor = lighten(color, 0.6)

  return (
    <ListItemButton
      {...props}
      divider
      sx={{
        '&.Mui-selected': {
          '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
          },
          fontWeight: 600,
          color: theme.palette.text.primary,
          backgroundColor: alpha(color, 0.3),
          '&:hover': {
            backgroundColor: alpha(selectedColor, 0.5),
          },
        },
        '&:hover': {
          backgroundColor: alpha(selectedColor, 0.5),
        },
        '& .MuiSvgIcon-root': {
          color: theme.palette.text.lighter,
        },
        color: theme.palette.text.lighter,
        borderBottom: 'none',
        borderRadius: theme.shape,
      }}>
      {children}
    </ListItemButton>
  )
}

const DrawerContent = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentPath = history.location.pathname
  const { propertyId } = useSelector((state: RootState) => state.system)
  const { data: user, isLoading: isUserLoading } = useCurrentUser()
  const isPropertySelected = propertyId !== null && propertyId !== undefined
  const queryClient = useQueryClient()

  const featureIsEnabled = feature => {
    const organisationFeatures = user?.organisation?.features
    // Enable by default, if feature is not set
    if (!organisationFeatures || !(feature in organisationFeatures)) {
      return true
    }
    return organisationFeatures[feature]
  }

  const handleLogout = async () => {
    await signOut()
    queryClient.removeQueries()
    dispatch(authActions.logout())
    dispatch(systemActions.setPropertyId(null))
    history.push(siteMap.Login.path)
  }

  const handleClick = func => {
    props?.onClose?.()
    func()
  }

  const propertyMenuItems = [
    {
      text: 'Property Dashboard',
      icon: <HomeOutlined />,
      path: siteMap.Property.getPath(propertyId),
    },
    {
      text: 'Climate Analytics',
      icon: <HouseboatOutlined />,
      path: siteMap.PropertyClimateAnalytics.getPath(propertyId),
      feature: 'projections',
    },
    {
      text: 'Climate Mitigations',
      icon: <BalconyOutlined />,
      path: siteMap.PropertyClimateMitigations.getPath(propertyId),
      feature: 'projections',
    },
    {
      text: 'Incidents',
      icon: <NotificationImportantOutlined />,
      path: siteMap.PropertyIncidents.getPath(propertyId),
    },
    {
      text: 'General Mitigations',
      icon: <FenceOutlined />,
      path: siteMap.PropertyMitigations.getPath(propertyId),
    },
    {
      text: 'Alert History',
      icon: <GppMaybeOutlined />,
      feature: 'alerts',
      path: siteMap.Alerts.getPath(propertyId),
    },
    {
      text: 'Smart Sensors',
      icon: <QueryStatsOutlined />,
      path: siteMap.Sensors.getPath(propertyId),
      hideMobile: true,
    },
  ]

  return (
    <>
      {/* Logo */}
      <Toolbar sx={{ justifyContent: 'center' }}>
        {!isUserLoading ? (
          <img
            style={{ height: 'auto', paddingTop: 10, maxHeight: '50px', width: '90%', objectFit: 'cover' }}
            src={webLogo}
            loading="lazy"
            alt="Organisation logo"
          />
        ) : (
          <Skeleton variant="rectangular" width="70%" height="30px" />
        )}
      </Toolbar>

      {/* Portfolio Menu Items */}
      <List>
        {menuItems.map((item: any, index: any) =>
          item?.children?.length > 0 ? (
            // Menu item with children
            <PermissionGate action={item?.permission} all={false} key={index}>
              {item?.feature == undefined || featureIsEnabled(item.feature) ? (
                <CollapseListItem
                  open={item.children.map(child => child?.path).includes(currentPath)}
                  sx={{ display: { xs: item?.hideMobile ? 'none' : 'block', md: 'block' } }}
                  dense
                  key={index}
                  render={() => (
                    <>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>{item.text}</ListItemText>
                    </>
                  )}
                  component={null}>
                  {item.children.map((child, child_index) => (
                    <PermissionGate action={child?.permission} key={child_index}>
                      <ListItem dense sx={{ display: { xs: child?.hideMobile ? 'none' : 'block', md: 'block' } }}>
                        <LightListItemButton
                          key={child_index}
                          onClick={() => handleClick(() => history.push(child?.path))}
                          selected={child?.path === currentPath || child?.path?.pathname == currentPath}>
                          <ListItemIcon>{child?.icon}</ListItemIcon>
                          <ListItemText>{child.text}</ListItemText>
                        </LightListItemButton>
                      </ListItem>
                    </PermissionGate>
                  ))}
                </CollapseListItem>
              ) : (
                <></>
              )}
            </PermissionGate>
          ) : (
            // Menu item
            <PermissionGate action={item?.permission} key={index}>
              {item?.feature == undefined || featureIsEnabled(item.feature) ? (
                <ListItem dense sx={{ display: { xs: item?.hideMobile ? 'none' : 'block', md: 'block' } }}>
                  <LightListItemButton
                    selected={item?.path === currentPath || (currentPath.startsWith(item?.path) && item?.path !== '/')}
                    key={index}
                    onClick={() => handleClick(() => history.push(item?.path))}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{item.text}</ListItemText>
                  </LightListItemButton>
                </ListItem>
              ) : (
                <></>
              )}
            </PermissionGate>
          ),
        )}

        {/* Property Menu Items */}
        {isPropertySelected && <Divider sx={{ my: 2 }} />}
        {isPropertySelected &&
          propertyMenuItems.map((item: any, index: any) =>
            item?.children?.length > 0 ? (
              <PermissionGate action={item?.permission} key={index}>
                {item?.feature == undefined || featureIsEnabled(item.feature) ? (
                  <CollapseListItem
                    open={item.children.map(child => child?.path).includes(currentPath)}
                    sx={{ display: { xs: item?.hideMobile ? 'none' : 'block', md: 'block' } }}
                    dense
                    key={index}
                    render={() => (
                      <>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText>{item.text}</ListItemText>
                      </>
                    )}
                    component={null}>
                    {item.children.map((child, child_index) => (
                      <PermissionGate action={item?.permission} key={child_index}>
                        <ListItem dense sx={{ display: { xs: child?.hideMobile ? 'none' : 'block', md: 'block' } }}>
                          <LightListItemButton
                            key={child_index}
                            onClick={() => handleClick(() => history.push(child?.path))}
                            selected={child?.path === currentPath || child?.path?.pathname == currentPath}>
                            <ListItemIcon>{child?.icon}</ListItemIcon>
                            <ListItemText>{child.text}</ListItemText>
                          </LightListItemButton>
                        </ListItem>
                      </PermissionGate>
                    ))}
                  </CollapseListItem>
                ) : (
                  <></>
                )}
              </PermissionGate>
            ) : (
              <PermissionGate action={item?.permission} key={index}>
                {item?.feature == undefined || featureIsEnabled(item.feature) ? (
                  <ListItem dense sx={{ display: { xs: item?.hideMobile ? 'none' : 'block', md: 'block' } }}>
                    <LightListItemButton
                      selected={item?.path === currentPath}
                      key={index}
                      onClick={() => handleClick(() => history.push(item?.path))}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>{item.text}</ListItemText>
                    </LightListItemButton>
                  </ListItem>
                ) : (
                  <></>
                )}
              </PermissionGate>
            ),
          )}
      </List>

      {/* Bottom Menu */}
      <Box sx={{ marginTop: 'auto' }}>
        <List>
          <ListItem dense>
            <LightListItemButton onClick={() => history.push(siteMap.Settings.path)}>
              <ListItemIcon>
                <SettingsOutlined />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </LightListItemButton>
          </ListItem>
          <ListItem dense>
            <LightListItemButton onClick={() => handleClick(handleLogout)}>
              <ListItemIcon>
                <LogoutRounded />
              </ListItemIcon>
              <ListItemText>Log out</ListItemText>
            </LightListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  )
}

const MenuDrawer = () => {
  const { menuDrawerOpen } = useSelector((state: RootState) => state.system)
  const dispatch = useDispatch()
  const handleOpen = () => dispatch(systemActions.setMenuDrawerOpen(true))
  const handleClose = () => dispatch(systemActions.setMenuDrawerOpen(false))
  const theme = useTheme()
  const width = '240px'

  const sx = {
    width: width,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      borderColor: theme.palette.background.borderColor,
      backgroundColor: 'background.paper',
      width: width,
      boxSizing: 'border-box',
      '&::-webkit-scrollbar': {
        width: '0.4em',
        '&-track': {
          backgroundColor: 'transparent',
        },
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'inherit',
      },
      '&:hover': {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, 0.10)',
        },
      },
    },
  }

  return (
    <>
      <Hidden smDown>
        <Drawer sx={sx} variant="permanent" anchor="left">
          <DrawerContent />
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <SwipeableDrawer
          sx={sx}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          open={menuDrawerOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          variant="temporary"
          anchor="left">
          <DrawerContent onClose={handleClose} />
        </SwipeableDrawer>
      </Hidden>
    </>
  )
}

export default MenuDrawer
