import { getUploadSignedUrl, getSignedUrl, readFile } from 'balkerne-components/DynamicForm'
import React, { useState, useEffect } from 'react'
import AttachedFile from '../general/AttachedFile'
import axios from 'axios'
import api from 'balkerne-core/api'
import c from './FileWidget.module.scss'
import extensionToMime from 'balkerne-components/extensionToMime'
import FileIcon from 'balkerne-components/FileIcon'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Box, Button, IconButton } from '@mui/material'

const FileWidget = ({ ...props }) => {
  const [attachedFilesData, setAttachedFilesData] = useState({})
  const [uploading, setUploading] = useState<number | null>(null)
  const [status, setStatus] = useState({
    upload: {
      successful: {},
      failed: {},
    },
    delete: {
      successful: {},
      failed: {},
    },
  })

  useEffect(() => {
    var tempFiles = {}
    try {
      var o = props.formInstance.data

      if (o && typeof o === 'object') {
        const attachedFiles = o['attached_files']
        if (props.id in attachedFiles) {
          tempFiles[props.id] = []
          Object.values(attachedFiles[props.id]).map((savedFile: any) => {
            tempFiles[props.id].push({
              inputID: props.id,
              fileName: savedFile.fileName,
              fileKey: `${props.id}-${savedFile.fileName}`,
              fileSize: savedFile.size ? savedFile.size : 0,
              dbID: savedFile.dbID,
              fieldTitle: savedFile.fieldTitle,
            })
          })
          setAttachedFilesData({ ...tempFiles })
          const _fileInput: any = document.getElementById(props.id + '-attachedFiles')
          _fileInput.value = JSON.stringify(tempFiles)
        }
      }
    } catch (e) {
      console.log('Invalid form data schema')
    }
  }, [props.formInstance])

  useEffect(() => {
    if (uploading !== null) {
      const cancelForm = document.getElementById('cancel-form')
      const discardForm = document.getElementById('discard-form')
      const saveDraft = document.getElementById('save-draft')
      const originalSubmit = document.getElementById('original-submit')

      cancelForm?.setAttribute('disabled', 'true')
      discardForm?.setAttribute('disabled', 'true')
      saveDraft?.setAttribute('disabled', 'true')
      originalSubmit?.setAttribute('disabled', 'true')

      if (uploading === 100) {
        cancelForm?.removeAttribute('disabled')
        discardForm?.removeAttribute('disabled')
        saveDraft?.removeAttribute('disabled')
        originalSubmit?.removeAttribute('disabled')
        setUploading(null)
      }
    }
  }, [uploading])

  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    var tempFilesData = { ...attachedFilesData }
    const _files = e.target.files
    let numOfFiles = _files?.length
    let tempState = { ...status }
    tempState['upload']['failed'] = {}
    const input = e.target
    const inputID = e.target.id
    if (tempFilesData[inputID] !== undefined) {
      if (numOfFiles && numOfFiles > 0) setUploading(0)
      if (_files && numOfFiles) {
        for (const file of _files) {
          const fileID = `${inputID}-${file.name}`
          let uploadFlag = false
          for (const processedFile of tempFilesData[inputID]) {
            if (fileID == processedFile.fileKey) {
              console.log('Already uploaded file with this file name')
              alert(`Error, file with name ${file.name} has already been attached to this field.`)
              uploadFlag = true
              setUploading(Math.round(100 / numOfFiles))
              numOfFiles -= 1
            }
          }
          if (!uploadFlag) {
            const data = await readFile(file)
            const signedURL = await getUploadSignedUrl(props.formID, file.name, inputID)
            await axios
              .put(signedURL, data)
              .then(async () => {
                let fileSize = file.size / 1024
                fileSize = parseFloat((fileSize / 1024).toFixed(2))
                let fileType = file.name.split('.').pop()

                const fetchurl = `/forms/instances/${props.formID}/attachments`
                const fileData = {
                  name: file.name,
                  size: `${fileSize} MB`,
                  file_type: fileType,
                }
                await api
                  .post(fetchurl, fileData)
                  .then(response => {
                    console.log('Successfully uploaded file.')
                    tempState['upload']['successful'][file.name] = {
                      message: 'Successfully uploaded file.',
                    }
                    tempFilesData[inputID].push({
                      inputID: props.id,
                      fileName: file.name,
                      fileKey: fileID,
                      fileSize: file.size,
                      objectURL: URL.createObjectURL(file),
                      dbID: response.data.data.id,
                      fieldTitle: props.title,
                    })
                  })
                  .catch(error => {
                    console.log(error)
                    tempState['upload']['failed'][file.name] = {
                      message: 'Failed to add file details to database.',
                    }
                  })
              })
              .catch(error => {
                console.log(error)
                tempState['upload']['failed'][file.name] = {
                  message: 'Failed to add file details to database.',
                }
              })
            setUploading(Math.round(100 / numOfFiles))
            numOfFiles -= 1
          }
        }
      }
    } else {
      if (numOfFiles && numOfFiles > 0) setUploading(0)
      tempFilesData[inputID] = []
      if (_files && numOfFiles) {
        for (const file of _files) {
          const fileID = `${inputID}-${file.name}`

          const data = await readFile(file)
          const signedURL = await getUploadSignedUrl(props.formID, file.name, inputID)
          await axios
            .put(signedURL, data)
            .then(async () => {
              let fileSize = file.size / 1024
              fileSize = parseFloat((fileSize / 1024).toFixed(2))
              let fileType = file.name.split('.').pop()

              const fetchurl = `/forms/instances/${props.formID}/attachments`
              const fileData = {
                name: file.name,
                size: `${fileSize} MB`,
                file_type: fileType,
              }
              await api
                .post(fetchurl, fileData)
                .then(response => {
                  console.log('Successfully uploaded file.')
                  tempState['upload']['successful'][file.name] = {
                    message: 'Successfully uploaded file.',
                  }

                  tempFilesData[inputID].push({
                    inputID: props.id,
                    fileName: file.name,
                    fileKey: fileID,
                    fileSize: file.size,
                    objectURL: URL.createObjectURL(file),
                    dbID: response.data.data.id,
                    fieldTitle: props.title,
                  })
                })
                .catch(error => {
                  console.log(error)
                  tempState['upload']['failed'][file.name] = {
                    message: 'Failed to add file details to database.',
                  }
                })
            })
            .catch(error => {
              console.log(error)
              tempState['upload']['failed'][file.name] = {
                message: 'Failed to add file details to database.',
              }
            })
          setUploading(Math.round(100 / numOfFiles))
          numOfFiles -= 1
        }
      }
    }
    if (tempFilesData[inputID].length === 0) delete tempFilesData[inputID]
    setStatus({ ...tempState })
    setAttachedFilesData({ ...tempFilesData })
    input.value = ''
    const _fileInput: any = document.getElementById(inputID + '-attachedFiles')
    _fileInput.value = JSON.stringify(tempFilesData)
  }
  const removeFile = async (inputID, fileKey, dbID, fileName) => {
    if (!props.disabled) {
      let tempFilesData = { ...attachedFilesData }
      let tempState = { ...status }
      // delete file attachment from db
      await api
        .delete(`/forms/instances/${props.formInstance.id}/attachments/${dbID}`)
        .then(response => {
          console.log('Successfully deleted file with ID: ' + dbID)
          tempState['delete']['successful'][fileName] = {
            message: 'Successfully deleted file.',
          }
          delete tempState['upload']['successful'][fileName]
          if (inputID in tempFilesData) {
            let newFilesData: any[] = []
            Object.values(tempFilesData[inputID]).map((fileData: any) => {
              if (fileData['fileKey'] !== fileKey) {
                newFilesData.push(fileData)
              }
            })
            tempFilesData[inputID] = newFilesData
            if (Object.keys(tempFilesData[inputID]).length == 0) {
              delete tempFilesData[inputID]
              const _fileInput: any = document.getElementById(inputID + '-attachedFiles')
              _fileInput.value = ''
            } else {
              const _fileInput: any = document.getElementById(inputID + '-attachedFiles')
              _fileInput.value = JSON.stringify(tempFilesData)
            }
          }
        })
        .catch(error => {
          console.warn('Error deleting form attachment from database', error)

          tempState['delete']['failed'][fileName] = {
            message: 'Failed to  deleted file details from database.',
          }
        })
      setStatus({ ...tempState })
      setAttachedFilesData({ ...tempFilesData })
    }
  }

  const getFile = async file => {
    let downloadURL = ''
    if (file.objectURL === undefined) {
      downloadURL = await getSignedUrl(props.formInstance.id, file.fileName, file.inputID)
    } else {
      downloadURL = file.objectURL
    }
    const downloadButton: any = document.getElementById(file.fileKey + '-download')
    if (downloadButton) {
      downloadButton.href = downloadURL
      downloadButton.click()
    }
    console.log(downloadURL)
  }

  const showAttachedFiles = () => {
    const mediaFiles: any[] = []
    const otherFiles: any[] = []

    Object.keys(attachedFilesData).forEach(key => {
      Object.values(attachedFilesData[key]).forEach((file: any) => {
        let extension
        try {
          extension = file.fileName.split('.').slice(-1)[0].toLowerCase()
          console.log(file.fileName)
          console.log(extension)
        } catch {
          console.warn('Failed to parse file extension')
          extension = ''
        }
        var fileSize = file.fileSize / 1024
        fileSize = parseFloat((fileSize / 1024).toFixed(2))
        const mimeType = extensionToMime(extension)
        if ('image' === mimeType.split('/')[0]) {
          mediaFiles.push(
            <div className={c.containerAttachedFile}>
              <AttachedFile
                fileName={file.fileName}
                fileSize={file.fileSize}
                fileKey={file.fileKey}
                formID={props.formID}
                inputID={file.inputID}
                objectURL={file.objectURL ? file.objectURL : null}
                removeFile={() => removeFile(file.inputID, file.fileKey, file.dbID, file.fileName)}
                disabled={props.disabled}
              />
            </div>,
          )
        } else {
          otherFiles.push(
            <Box className={c['other-container']} style={{ display: 'flex' }}>
              <a className={c.downloadtag} id={file.fileKey + '-download'} download></a>
              <Button onClick={() => getFile(file)} className={c['file-title']} sx={{ marginRight: 3 }}>
                <FileIcon inferFromFileName="" mimeType={mimeType} size="medium" />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '4px' }}>
                  {file.fileName}
                  <p className={c.attachSizes}>Size: {fileSize} MB</p>
                </div>
              </Button>
              <IconButton
                sx={{ marginY: 'auto' }}
                aria-label="delete"
                onClick={() => removeFile(file.inputID, file.fileKey, file.dbID, file.fileName)}>
                <DeleteForeverIcon color="error" />
              </IconButton>
            </Box>,
          )
        }
      })
    })
    return (
      <div id={`${props.id}-container`}>
        <div>
          <div className={c.mediaTitle}>Media Files</div>
          <div className={c.containerGridAttachFile}>{mediaFiles}</div>
        </div>
        <div>
          <div className={c.mediaTitle}>Other Files</div>
          <div className={c.containerGridOtherFiles}>{otherFiles}</div>
        </div>
      </div>
    )
  }
  return (
    <div id={props.id}>
      <input type="hidden" id={props.id + '-attachedFiles'} />
      <input
        type="file"
        id={props.id}
        multiple={true}
        onChange={e => onFileSelect(e)}
        onClick={props.clearFileUpload}
        disabled={props.disabled}
      />
      <div>
        <div style={{ marginTop: 4 }}>{uploading !== null && `Uploading.. ${uploading}%`}</div>
        <p style={{ marginTop: 8, marginBottom: '-4px' }}>
          {Object.keys(status['upload']['successful']).length > 0 &&
            `${Object.keys(status['upload']['successful']).length} file(s) uploaded successfully.`}
        </p>
        {Object.keys(status['upload']['failed']).length > 0 &&
          Object.keys(status['upload']['failed']).map(fileName => (
            <p style={{ marginTop: 8, color: 'red' }}>{`Failed to upload ${fileName}`}</p>
          ))}
      </div>
      {showAttachedFiles()}
    </div>
  )
}

export default FileWidget
