import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography, Tooltip, useTheme, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { capitalise } from 'balkerne-fn'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Page from '../components/Page'
import { useClimateProjections } from '../hooks/analytics'
import { HelpRounded } from '@mui/icons-material'
import { Line } from 'react-chartjs-2'
import { Chart, Filler } from 'chart.js'
import { useHistory } from 'react-router-dom'
import siteMap from '../siteMap'
import { useSelector } from 'react-redux'
import { RootState } from '../store'

Chart.register(Filler)

const PropertyClimateProjection = () => {
  const { id: propertyId } = useParams()
  const { data: projections, isLoading } = useClimateProjections(propertyId)
  const [scenario, setScenario] = useState('')
  const [type, setType] = useState('')
  const [chance, setChance] = useState<number | undefined>()
  const [display, setDisplay] = useState('table')
  const history = useHistory()

  const scenarioOptions = Object.keys(projections ?? [])
  const typeOptions = Object.keys(projections?.[scenario] ?? [])
  const chanceOptions: any[] = useMemo(() => {
    const chances = new Set()
    if (projections?.[scenario]?.[type]) {
      for (const row of projections[scenario][type]) {
        if (!chances.has(row['one_in_n_prob'])) {
          chances.add(row['one_in_n_prob'])
        }
      }
    }
    return Array.from(chances)
  }, [projections, scenario, type])

  useEffect(() => {
    if (scenarioOptions.length > 0) {
      const scenario = scenarioOptions[0]
      const type = Object.keys(projections[scenario])[0]
      const chance = projections[scenario][type][0]['one_in_n_prob']
      setScenario(scenario)
      setType(type)
      setChance(chance)
    }
  }, [projections])

  useEffect(() => {
    setChance(chanceOptions[0])
  }, [type])

  const columns = useMemo(
    () => [
      {
        field: 'year_start',
        headerName: 'Year',
        flex: 1,
        minWidth: 150,
        renderCell: params => `${params.row.year_start} - ${params.row.year_end}`,
      },
      {
        field: 'min',
        type: 'number',
        width: 150,
        headerName: 'Min. Depth',
        renderCell: params => `${params.row.min}m`,
      },
      {
        field: 'mean',
        headerName: 'Mean Depth',
        width: 150,
        type: 'number',
        renderCell: params => `${params.row.mean}m`,
      },
      {
        field: 'max',
        headerName: 'Max. Depth',
        width: 150,
        type: 'number',
        renderCell: params => `${params.row.max}m`,
      },
      {
        field: 'paa',
        headerName: '% of Area Affected',
        type: 'number',
        width: 150,
        renderCell: params => `${(params.row.paa * 100).toFixed(2)}%`,
      },
    ],
    [],
  )

  const rows = useMemo(() => {
    if (projections?.[scenario]?.[type]) {
      return projections?.[scenario]?.[type].filter(row => row.one_in_n_prob === chance)
    }
    return []
  }, [scenario, type, chance])

  const tooltip = (
    <>
      <div>
        <strong>RCP 4.5</strong> is described by the Intergovernmental Panel on Climate Change (IPCC) as a moderate
        scenario in which emissions peak around 2040 and then decline.
      </div>
      <br />
      <div>
        <strong>RCP 8.5</strong> is the highest baseline emissions scenario in which emissions continue to rise
        throughout the twenty-first century.
      </div>
    </>
  )

  const isChart = display === 'chart'
  const chartOpacity = '5A'
  return (
    <Page
      title="Flood Depth"
      size="lg"
      PageActions={() => (
        <Stack direction="row" alignItems="center" gap={1} sx={{ display: scenario ? 'flex' : 'none' }}>
          <Tooltip title={tooltip} placement="bottom">
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography variant="body1">IPCC Emission Scenario</Typography>
              <HelpRounded sx={{ width: 16 }} />
            </Stack>
          </Tooltip>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={scenario}
            exclusive
            onChange={(e: any) => setScenario(e.target.value)}>
            {scenarioOptions.map(value => (
              <ToggleButton key={value} value={value}>
                {value.toUpperCase()}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
      )}>
      {/* Toggle Buttons */}
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2} mb={2} sx={{ display: scenario ? 'flex' : 'none' }}>
          <Stack gap={0.5}>
            <Typography variant="body1">Display</Typography>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={display}
              exclusive
              onChange={(e: any) => setDisplay(e.target.value)}>
              <ToggleButton value={'table'}>Table</ToggleButton>
              <ToggleButton value={'chart'}>Chart</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack gap={0.5}>
            <Typography variant="body1">Flood Type</Typography>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={type}
              exclusive
              onChange={(e: any) => setType(e.target.value)}>
              {typeOptions.map(value => (
                <ToggleButton key={value} value={value}>
                  {capitalise(value.split('_').join(' '), true)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
          <Stack gap={0.5}>
            <Typography variant="body1">Probability (years)</Typography>
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={chance}
              exclusive
              onChange={(e: any) => setChance(Number.parseInt(e.target.value))}>
              {chanceOptions?.map((value: any) => (
                <ToggleButton key={value} value={value}>
                  {`1-in-${value}`}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
        </Stack>
      </Stack>
      {isChart ? (
        <Box>
          <Line
            data={{
              labels: rows.map(row => `${row.year_start}-${row.year_end}`),
              datasets: [
                {
                  label: 'Min. Depth',
                  data: rows.map(row => row.min),
                  fill: 'origin',
                  backgroundColor: '#e8eefc' + chartOpacity,
                  borderColor: '#9cb5f5',
                },
                {
                  label: 'Mean Depth',
                  data: rows.map(row => row.mean),
                  fill: 0,
                  backgroundColor: '#d1dbf5' + chartOpacity,
                  borderColor: '#9cb5f5',
                },
                {
                  label: 'Max. Depth',
                  data: rows.map(row => row.max),
                  fill: 1,
                  backgroundColor: '#9cb5f5' + chartOpacity,
                  borderColor: '#9cb5f5',
                },
                {
                  label: '% of Area Affected',
                  data: rows.map(row => row.paa * 100),
                  fill: 'origin',
                  backgroundColor: '#FFFFFF' + '00',
                  borderColor: '#EE4B2B',
                  yAxisID: 'percentageYAxis',
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    filter: function (item, chart) {
                      return item.text != undefined
                    },
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: function (value, index, values) {
                      return value + 'm'
                    },
                  },
                  beginAtZero: true,
                  suggestedMax: 1.0,
                },
                percentageYAxis: {
                  position: 'right',
                  ticks: {
                    callback: function (value, index, values) {
                      return value + '%'
                    },
                  },
                  beginAtZero: true,
                  max: 100,
                  min: 0,
                },
              },
            }}
          />
        </Box>
      ) : (
        <Box height="700">
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            components={{
              NoRowsOverlay: () => (
                <Stack height={'100%'} alignItems="center" justifyContent="center">
                  <Typography variant="body1">No data available for this property</Typography>
                </Stack>
              ),
            }}
            sx={{ height: 700 }}
            density="compact"
            getRowId={row => 'river_sea' + row.year_start + row.one_in_n_prob}
          />
        </Box>
      )}
    </Page>
  )
}

export default PropertyClimateProjection
