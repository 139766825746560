export const scoreColor = {
  1: '#44dd2e', // 1
  2: '#c5fb48', // 2
  3: '#fefd94', // 3
  4: '#f8b72b', // 4
  5: '#c32d13', // 5
}

export function beaufortScale(windSpeed: number): number {
  /**
   * Convert wind speed to Beaufort scale
   *
   * @param windSpeed - Wind speed in m/s
   * @return Beaufort scale [0-12]
   */
  if (windSpeed < 0.3) {
    return 0
  } else if (windSpeed < 1.6) {
    return 1
  } else if (windSpeed < 3.4) {
    return 2
  } else if (windSpeed < 5.5) {
    return 3
  } else if (windSpeed < 8.0) {
    return 4
  } else if (windSpeed < 10.8) {
    return 5
  } else if (windSpeed < 13.9) {
    return 6
  } else if (windSpeed < 17.2) {
    return 7
  } else if (windSpeed < 20.8) {
    return 8
  } else if (windSpeed < 24.5) {
    return 9
  } else if (windSpeed < 28.5) {
    return 10
  } else if (windSpeed < 32.7) {
    return 11
  } else {
    return 12
  }
}

export function beaufortToCategory(beaufort: number): number {
  /**
   * Convert Beaufort scale to risk category
   *
   * Risk categories by Beaufort scale:
   * -1: 0
   * 1: 1-3
   * 2: 4-5
   * 3: 6-7
   * 4: 8-9
   * 5: 10-12
   *
   * @param beaufort - Beaufort scale [0-12]
   * @return Risk category [1-5]
   */
  if (beaufort === 0) {
    return -1
  } else if (beaufort < 4) {
    return 1
  } else if (beaufort < 6) {
    return 2
  } else if (beaufort < 8) {
    return 3
  } else if (beaufort < 10) {
    return 4
  } else if (beaufort < 13) {
    return 5
  } else {
    throw new Error('Beaufort scale is not defined for values greater than 12')
  }
}

export function categoryToLabel(category: number): string {
  /**
   * Convert category integer to label
   *
   * @param category - int
   * @return Category label
   */
  if (category === -1) {
    return 'Inapplicable'
  } else if (category === 1) {
    return 'Negligible'
  } else if (category === 2) {
    return 'Low'
  } else if (category === 3) {
    return 'Moderate'
  } else if (category === 4) {
    return 'High'
  } else if (category === 5) {
    return 'Extreme'
  } else {
    throw new Error('Category not defined')
  }
}

export default scoreColor
