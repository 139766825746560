import moment from 'moment'

const FormatDate = (unformatted_date, formatStr?) => {
  var date = new Date(unformatted_date)
  if (isNaN(date.getTime()) || unformatted_date == null) {
    return `Could not format date.`
  } else {
    if (formatStr !== undefined) {
      return moment(unformatted_date).format(formatStr)
    } else {
      return moment(unformatted_date).format('DD-MM-YYYY HH:mm:ss')
    }
  }
}

export default FormatDate
