import { useQuery } from 'react-query'
import api from 'balkerne-core/api'

export const useFormInstances = args => {
  const path = `/forms/instances`
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch)
  return { ...rest, data: data?.data ?? [] }
}

export const useFormInstance = (id, args) => {
  const path = `/forms/instances/${id}`
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, {
    enabled: id !== undefined,
  })
  return { ...rest, data: data?.data }
}

export const useFormTemplates = args => {
  const path = `/forms/templates`
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch)
  return { ...rest, data: data?.data ?? [] }
}

export const useFormTemplate = (id, args) => {
  const path = `/forms/templates/${id}`
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, {
    enabled: id !== undefined,
  })
  return { ...rest, data: data ?? null }
}
export const useTemplateVersion = (id, args) => {
  const path = `/forms/templates/versions/${id}`
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, {
    enabled: id !== undefined && id !== null,
  })
  return { ...rest, data: data?.data ?? null }
}

export const useFormTypes = () => {
  const path = `/forms/types`
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch)
  return { ...rest, data: data?.data ?? [] }
}

export const useTemplateTags = () => {
  const path = `/forms/tags`
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery([path], fetch)
  return { ...rest, data: data?.data ?? null }
}

export const useDocumentCategories = args => {
  const path = '/forms/categories'
  const fetch = async () => await api.get(path, { params: { ...args } }).then(res => res.data)
  const { data, ...rest } = useQuery([path, { ...args }], fetch, {
    enabled: args['form_type'] !== undefined,
  })
  return { ...rest, data: data?.data ?? null }
}
