import React from 'react'
import { useParams } from 'react-router-dom'
import InsightsTab from '../components/riskReport/InsightsTab'
import Page from '../components/Page'

const PropertyInsights = () => {
  const { id: propertyId } = useParams()
  return (
    <Page title="Insights" size="lg">
      <InsightsTab propertyId={propertyId} />
    </Page>
  )
}

export default PropertyInsights
