import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Divider,
} from '@mui/material'
import { DataGrid, GridSelectionModel, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import { useConfigurationSubscriptions } from '../hooks/alerts'
import { useHistory, useParams } from 'react-router-dom'
import { useLocations } from '../hooks/locations'
import api from 'balkerne-core/api'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import siteMap from '../siteMap'
import Page from '../components/Page'

const propertyColumns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'postcode', headerName: 'Postcode', width: 100, valueGetter: params => params.row?.address.postcode },
  {
    field: 'groups',
    headerName: 'Groups',
    minWidth: 200,
    valueGetter: params => params.row?.groups.map(x => x.name).join(', '),
    renderCell: params => (
      <Stack direction="row" gap={1}>
        {params.row?.groups.map(x => (
          <Chip label={x.name} size="small" variant="outlined" />
        ))}
      </Stack>
    ),
  },
]

const AssignConfigurations = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { id: configurationId } = useParams()
  const { data: locations, isLoading: isLocationsLoading } = useLocations()
  const { data: initSelectedLocationsId, isLoading: isSubscriptionsLoading } =
    useConfigurationSubscriptions(configurationId)
  const [selectedLocations, setSelectedLocations] = useState(initSelectedLocationsId)
  const [assignedSelection, setAssignedSelection] = useState<GridSelectionModel>([])
  const [availableSelection, setAvailableSelection] = useState<GridSelectionModel>([])
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const history = useHistory()

  const isLoading = isLocationsLoading || isSubscriptionsLoading

  const addLocations = useMemo(
    () => selectedLocations.filter(x => !initSelectedLocationsId.includes(x)),
    [selectedLocations],
  )
  const removeLocations = useMemo(
    () => initSelectedLocationsId.filter(x => !selectedLocations.includes(x)),
    [selectedLocations],
  )

  useEffect(() => {
    if (initSelectedLocationsId.length > 0) {
      setSelectedLocations(initSelectedLocationsId)
    }
  }, [initSelectedLocationsId])

  const availableLocations = useMemo(() => {
    const _locations: any[] = []
    for (const location of locations) {
      if (!selectedLocations.some(id => id === location.id)) {
        _locations.push(location)
      }
    }
    return _locations
  }, [locations, selectedLocations])

  const assignedLocations = useMemo(() => {
    const _locations: any[] = []
    for (const location of locations) {
      if (selectedLocations.some(id => id === location.id)) {
        _locations.push(location)
      }
    }
    return _locations
  }, [locations, selectedLocations])

  const onAdd = () => {
    setSelectedLocations([...selectedLocations, ...availableSelection])
  }

  const handleReset = () => {
    setSelectedLocations(initSelectedLocationsId)
  }

  const onRemove = () => {
    setSelectedLocations(selectedLocations.filter(x => !assignedSelection.includes(x)))
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    api
      .post(`/alerts/configurations/${configurationId}/reassign`, {
        add: addLocations,
        remove: removeLocations,
      })
      .then(() => {
        enqueueSnackbar('Configuration reassigned successfully', { variant: 'success' })
        setIsConfirmOpen(false)
        history.push(siteMap.AlertConfigurations.path)
      })
      .catch(err => {
        console.error(err)
        enqueueSnackbar('Failed to reassign configuration', { variant: 'error' })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const CustomToolbarAvailable = useMemo(
    () => () =>
      (
        <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
          <Button onClick={onAdd} variant="outlined" size="small">
            Add
          </Button>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      ),
    [availableSelection, selectedLocations],
  )

  const CustomToolbarAssigned = useMemo(
    () => () =>
      (
        <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
          <Button onClick={onRemove} variant="outlined" size="small">
            Remove
          </Button>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      ),
    [selectedLocations, assignedSelection],
  )

  const isChanged = useMemo(() => {
    return selectedLocations.length !== initSelectedLocationsId.length
  }, [selectedLocations, initSelectedLocationsId])

  return (
    <>
      <Page title="Assign Configuration" back>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack mb={2} direction="row">
              <DataGrid
                loading={isLoading}
                sx={{ height: 600 }}
                columns={propertyColumns}
                rows={availableLocations}
                onSelectionModelChange={setAvailableSelection}
                density="compact"
                autoPageSize
                checkboxSelection
                components={{
                  Toolbar: CustomToolbarAvailable,
                }}
              />
              <Box width={10}></Box>
              <DataGrid
                loading={isLoading}
                sx={{ height: 600 }}
                columns={propertyColumns}
                rows={assignedLocations}
                onSelectionModelChange={setAssignedSelection}
                density="compact"
                autoPageSize
                checkboxSelection
                components={{
                  Toolbar: CustomToolbarAssigned,
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between">
          <Button onClick={handleReset} variant="outlined">
            Reset
          </Button>
          <Button variant="contained" onClick={() => setIsConfirmOpen(true)} disabled={!isChanged}>
            Confirm
          </Button>
        </Stack>
      </Page>
      <Dialog open={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to assign {addLocations?.length ? <strong>{addLocations.length}</strong> : 0} and
            remove {removeLocations?.length ? <strong>{removeLocations.length}</strong> : 0} locations?
          </Typography>
          <br />
          <Typography variant="subtitle2">
            {removeLocations?.length > 0
              ? `Any active alerts associated with this configuration will be deactivated for removed locations.`
              : ''}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmOpen(false)}>Cancel</Button>
          <LoadingButton variant="contained" onClick={handleSubmit} loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AssignConfigurations
