import axios from 'axios'
import { Auth } from '@aws-amplify/auth'
import ConfigProvider from 'balkerne-core/config'
import { signOut } from './auth'
import { userActions } from '../redux/_actions'

export const createApi = () => {
  return axios.create({
    baseURL: ConfigProvider.backendEndpoint,
    responseType: 'json',
  })
}

// This is the main API instance
export const api = createApi()

export const refreshToken = async props => {
  await api.interceptors.request.use(config => {
    // 💡 Token is expired - get and set new token
    if (props.user?.user?.signInUserSession?.idToken?.payload?.exp < Date.now() / 1000) {
      return new Promise((resolve, reject) => {
        Auth.currentSession()
          .then(session => {
            const refreshToken = session.getRefreshToken()
            Auth.currentAuthenticatedUser()
              .then(res => {
                res.refreshSession(refreshToken, (err, sessionData) => {
                  if (err) {
                    // 🔴
                    console.error('Failure refreshing user session tokens.')
                    console.error(err)
                    api.defaults.headers.common['Authorization'] = ''
                    alert('Unexpected Error, Please try again.')
                    signOut()
                    resolve(config)
                  } else {
                    // ✅
                    const jwtToken = sessionData.getIdToken().getJwtToken()
                    if (jwtToken !== null) {
                      api.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken
                      props.dispatch(userActions.update_jwt_token(props.user.user, jwtToken))
                    }
                    console.log('?. 🚧 Refreshed token - got new token and set')
                    resolve(config)
                  }
                })
              })
              .catch(err => {
                console.error(err)
                api.defaults.headers.common['Authorization'] = ''
                alert('Unexpected Error, Please try again.')
                signOut()
                resolve(config)
              })
          })
          .catch(() => {
            // No logged-in user: don't set auth header
            console.log('No logged in user')
            resolve(config)
          })
      })
    } else if (
      typeof api.defaults.headers.common['Authorization'] === 'undefined' &&
      props.user?.user?.signInUserSession?.idToken?.payload?.exp > Date.now() / 1000
    ) {
      // 💡 If not set - set current token
      api.defaults.headers.common['Authorization'] = 'Bearer ' + props.user.user.signInUserSession.idToken.jwtToken
      return new Promise((resolve, reject) => {
        config.headers.Authorization = 'Bearer ' + props.user.user.signInUserSession.idToken.jwtToken
        console.log('?. 🚧 Refreshed token - was not set - set from redux')
        resolve(config)
      })
    } else {
      // 💡 No change - set as itself
      return new Promise((resolve, reject) => {
        config.headers.Authorization = api.defaults.headers.common['Authorization']
        console.log('?. 🚧 Refreshed token - no change - set as itself')
        resolve(config)
      })
    }
  })
}

export default api
