import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, Grid, Stack, Tab, Tabs } from '@mui/material'
import Page from '../../components/Page'
import { FormProvider, useForm } from 'react-hook-form'
import NewTemplateBuilder, {
  TemplateBuilderSubmitData,
  templateSchema,
} from '../../components/templateManager/NewTemplateBuilder'
import TemplatePreview from '../../components/templateManager/TemplatePreview'
import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormTemplate } from '../../hooks/forms'
import { TemplatePrincipalDetails } from './FormTemplate'
import { useMutation } from 'react-query'
import api from 'balkerne-core/api'
import siteMap from '../../siteMap'
import { useSnackbar } from 'notistack'

type CreateTemplateVersionData = {
  form_template: {
    version: number
    template_id: number
    description: string
    data: { [key: string]: any }
    ui_schema: { [key: string]: any }
    expired: boolean
    published: boolean
  }
}
type CreateTemplateVersionMutation = {
  data: TemplateBuilderSubmitData
  publish: boolean
}

const CreateTemplateVersion = () => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { id: templateId } = useParams()
  const { data: template } = useFormTemplate(templateId)
  const form = useForm({
    defaultValues: {
      template: {
        schema: '{}',
        uischema: '{}',
      },
    },
    resolver: yupResolver(templateSchema),
  })
  const { watch, handleSubmit: formSubmit } = form

  const createTemplateVersion = useMutation({
    mutationFn: (data: CreateTemplateVersionMutation) => {
      const _data = {
        form_template: {
          version: template.template.versions.length + 1,
          template_id: template.template.id,
          description: template.template.description,
          data: JSON.parse(data.data.template.schema),
          ui_schema: JSON.parse(data.data.template.uischema),
          expired: false,
          published: data.publish,
        },
        tags: data.data.tags,
      }
      if (data.publish) {
        console.log('create and publish template')
        return api.post('/forms/templates/versions/create_and_publish', _data)
      } else {
        console.log('create template')
        return api.post('/forms/templates/versions/create', _data)
      }
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully created new template version', { variant: 'success' })
      history.push(siteMap.FormTemplate.getPath(template.template.id))
    },
    onError: () => {
      enqueueSnackbar('Error creating new template version', { variant: 'error' })
    },
  })

  const handlePublish = data => {
    console.log('handlePublish', data)
    createTemplateVersion.mutate({
      data: data,
      publish: true,
    })
  }

  const handleSave = data => {
    console.log('handleSave', data)
    createTemplateVersion.mutate({
      data: data,
      publish: false,
    })
  }

  return (
    <Page
      title="Create Template Version"
      size="lg"
      back
      PageActions={() => (
        <Stack direction="row" gap={1}>
          <Button onClick={formSubmit(handleSave)} variant="outlined">
            Save
          </Button>
          <Button onClick={formSubmit(handlePublish)} variant="outlined">
            Publish
          </Button>
        </Stack>
      )}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TemplatePrincipalDetails template={template} />
          </Grid>
        </Grid>
      </Card>
      <FormProvider {...form}>
        <NewTemplateBuilder />
      </FormProvider>
    </Page>
  )
}

export default CreateTemplateVersion
