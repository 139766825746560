import { useCallback, useMemo, useState } from 'react'
import { usePortfolioHeatProjections } from '../../hooks/analytics'
import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography, Tooltip, Card, Slider, Grid } from '@mui/material'
import { capitalise } from 'balkerne-fn'
import { HelpRounded } from '@mui/icons-material'
import { Line } from 'react-chartjs-2'
import { CircularProgress } from '@mui/material'
import { useLocations } from '../../hooks/locations'

const returnPeriods = ['rp20', 'rp50', 'rp100'] as const
const scenarios = ['rcp26', 'rcp45', 'rcp60', 'rcp85'] as const

type ReturnPeriod = (typeof returnPeriods)[number]
type Scenario = (typeof scenarios)[number]

const HeatStress = () => {
  const { data: locations, isLoading: isLocationsLoading } = useLocations()
  const { data, isLoading } = usePortfolioHeatProjections()
  const [returnPeriod, setReturnPeriod] = useState<ReturnPeriod>('rp20')
  const [scenario, setScenario] = useState<Scenario>('rcp60')
  const [yearIndex, setYearIndex] = useState(25)

  const propertyPercentage = useMemo(() => {
    if (data == null || data == undefined || locations.length == 0) return null
    return Math.round((data['property_count'] / locations.length) * 100)
  }, [data, locations])

  const tooltip = (
    <>
      <div>
        <strong>RCP2.6</strong> is defined by the IPCC as a scenario where emissions peak by 2020 and then rapidly
        decline, targeting a 2°C warming limit.
      </div>
      <br />
      <div>
        <strong>RCP 4.5</strong> is described by the Intergovernmental Panel on Climate Change (IPCC) as a moderate
        scenario in which emissions peak around 2040 and then decline.
      </div>
      <br />
      <div>
        <strong>RCP6.0</strong> is characterized by the IPCC as an intermediate pathway with emissions peaking around
        2080, then stabilizing without requiring negative emissions.
      </div>
      <br />
      <div>
        <strong>RCP 8.5</strong> is the highest baseline emissions scenario in which emissions continue to rise
        throughout the twenty-first century.
      </div>
    </>
  )

  const chartLabels = useMemo(() => {
    if (!data) return []
    return data['rcps'][scenario][returnPeriod]['count_means'].map(
      (_, i) => data['year_start'] + i * data['year_increment'],
    )
  }, [data, scenario, returnPeriod])

  const chartMeans = useMemo(() => {
    if (!data) return []
    return data['rcps'][scenario][returnPeriod]['count_means']
  }, [data, scenario, returnPeriod])

  const chartPercentiles = useMemo(() => {
    if (data == null || data == undefined) return null
    const retval = {
      lower: {
        1: [] as number[],
        2: [] as number[],
        3: [] as number[],
      },
      upper: {
        1: [] as number[],
        2: [] as number[],
        3: [] as number[],
      },
    }
    const stats = data['rcps'][scenario][returnPeriod]
    for (let i = 0, len = stats['count_means'].length; i < len; i++) {
      retval['upper'][1].push(stats['count_means'][i] + stats['count_sds'][i] * 1)
      retval['upper'][2].push(stats['count_means'][i] + stats['count_sds'][i] * 2)
      retval['upper'][3].push(stats['count_means'][i] + stats['count_sds'][i] * 3)

      retval['lower'][1].push(stats['count_means'][i] - stats['count_sds'][i] * 1)
      retval['lower'][2].push(stats['count_means'][i] - stats['count_sds'][i] * 2)
      retval['lower'][3].push(stats['count_means'][i] - stats['count_sds'][i] * 3)
    }

    return retval
  }, [data, scenario, returnPeriod])

  const chartPercentileOpacity = '66'
  const chartPercentile997Color = '#e6da5a'
  const chartPercentile95Color = '#e89551'
  const chartPercentile68Color = '#de4c3c'

  const averageHeatRange = useMemo(() => {
    if (!chartPercentiles) return null
    return `${Math.floor(chartPercentiles['lower'][2][yearIndex])} - ${Math.floor(
      chartPercentiles['upper'][2][yearIndex],
    )}`
  }, [chartPercentiles, yearIndex])

  const averageHotDays = useMemo(() => {
    if (!chartMeans) return null
    return Math.floor(chartMeans[yearIndex])
  }, [chartMeans, yearIndex])

  return (
    <>
      <Stack direction="column" justifyContent="space-between" minWidth={100} mb={3}>
        <Typography variant="h4">Annual Average Heat Stress Exposure </Typography>
        <Typography variant="body1">Hot days are defined as days with a maximum temperature above 30°C</Typography>
      </Stack>
      {!isLoading ? (
        <>
          {data !== null && data !== undefined ? (
            <>
              {/* Source & Scenario Toggles */}
              <Stack gap={3} mb={4} direction="row">
                <Stack direction="row" alignItems="center" gap={1} sx={{ display: scenario ? 'flex' : 'none' }}>
                  <Typography variant="body1">Return Period</Typography>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={returnPeriod}
                    exclusive
                    onChange={(e: any) => setReturnPeriod(e.target.value)}>
                    {returnPeriods.map(value => (
                      <ToggleButton key={value} value={value}>
                        {value.replace('rp', '1-in-')}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1} sx={{ display: scenario ? 'flex' : 'none' }}>
                  <Tooltip title={tooltip} placement="bottom">
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="body1">IPCC Emission Scenario</Typography>
                      <HelpRounded sx={{ width: 16 }} />
                    </Stack>
                  </Tooltip>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={scenario}
                    exclusive
                    onChange={(e: any) => setScenario(e.target.value)}>
                    {scenarios.map(value => (
                      <ToggleButton key={value} value={value}>
                        {value.toUpperCase()}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
              </Stack>

              {/* Cards */}
              <Grid container gap={3} wrap="nowrap">
                <Grid item xs={6} lg={3}>
                  <Card>
                    <Typography variant="subtitle1">Properties w/ data</Typography>
                    <Typography variant="h4">{propertyPercentage ?? 0}%</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card>
                    <Typography variant="subtitle1">Average Hot Days</Typography>
                    <Typography variant="h4">{averageHotDays}</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card>
                    <Typography variant="subtitle1">Hot Day Variance (68%)</Typography>
                    <Typography variant="h4">{averageHeatRange}</Typography>
                  </Card>
                </Grid>
              </Grid>

              {/* Slider */}
              <Box sx={{ mx: 2, py: 3 }}>
                {scenario && (
                  <Slider
                    color="primary"
                    defaultValue={25}
                    valueLabelFormat={value => chartLabels[value]}
                    valueLabelDisplay="auto"
                    onChange={(e, value) => setYearIndex(value as number)}
                    marks
                    min={0}
                    max={chartLabels.length - 1}
                  />
                )}
              </Box>

              {/* Chart */}
              <Stack>
                <Line
                  data={{
                    labels: chartLabels,
                    datasets: [
                      {
                        label: 'Upper Bound | 99.7%',
                        data: chartPercentiles?.['upper'][3] ?? [],
                        backgroundColor: chartPercentile997Color + chartPercentileOpacity,
                        fill: '+1',
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: false,
                      },
                      {
                        label: 'Upper Bound | 95%',
                        data: chartPercentiles?.['upper'][2] ?? [],
                        backgroundColor: chartPercentile95Color + chartPercentileOpacity,
                        fill: '+1',
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                      },
                      {
                        label: 'Upper Bound | 68%',
                        data: chartPercentiles?.['upper'][1] ?? [],
                        backgroundColor: chartPercentile68Color + chartPercentileOpacity,
                        fill: '+1',
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                      },
                      {
                        label: 'Mean Annual Days Above 30°C',
                        data: chartMeans,
                        fill: false,
                        borderColor: '#fa6a55',
                        backgroundColor: '#fa6a55',
                        tension: 0.1,
                        pointRadius: 3,
                        // showLine: true,
                      },
                      {
                        label: 'Lower Bound | 68%',
                        data: chartPercentiles?.['lower'][1] ?? [],
                        backgroundColor: chartPercentile68Color + chartPercentileOpacity,
                        fill: '-1',
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                      },
                      {
                        label: 'Lower Bound | 95%',
                        data: chartPercentiles?.['lower'][2] ?? [],
                        backgroundColor: chartPercentile95Color + chartPercentileOpacity,
                        fill: '-1',
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                      },
                      {
                        label: 'Lower Bound | 99.7%',
                        data: chartPercentiles?.['lower'][3] ?? [],
                        backgroundColor: chartPercentile997Color + chartPercentileOpacity,
                        fill: '-1',
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: false,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        labels: {
                          filter: function (item, chart) {
                            return !item.text.includes(' | ')
                          },
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      y: {
                        min: 0,
                        max: 400,
                        ticks: {
                          callback: function (value, index, values) {
                            return value + ' days'
                          },
                        },
                        beginAtZero: false,
                      },
                    },
                  }}
                />
              </Stack>
            </>
          ) : (
            <Stack justifyContent="center" alignItems="center" height="500">
              <Typography variant="h5">No data available</Typography>
            </Stack>
          )}
        </>
      ) : (
        <Stack justifyContent="center" alignItems="center" height="500">
          <CircularProgress />
        </Stack>
      )}
    </>
  )
}

export default HeatStress
