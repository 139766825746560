import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, Grid, Stack, Tab, Tabs } from '@mui/material'
import Page from '../../components/Page'
import { FormProvider, useForm } from 'react-hook-form'
import NewTemplateBuilder, {
  TemplateBuilderSubmitData,
  templateSchema,
} from '../../components/templateManager/NewTemplateBuilder'
import TemplatePreview from '../../components/templateManager/TemplatePreview'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormTemplate, useTemplateVersion } from '../../hooks/forms'
import { TemplatePrincipalDetails } from './FormTemplate'
import { useMutation } from 'react-query'
import api from 'balkerne-core/api'
import siteMap from '../../siteMap'
import { useSnackbar } from 'notistack'
import LoadingIcon from '../../components/LoadingIcon'

type EditTemplateVersionData = {
  form_template: {
    description: string
    data: { [key: string]: any }
    ui_schema: { [key: string]: any }
  }
  form_builder: boolean
}
type EditTemplateVersionMutation = {
  data: TemplateBuilderSubmitData
  publish: boolean
}

const EditTemplateVersion = () => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { id: templateId, versionId } = useParams()
  const { data: template } = useFormTemplate(templateId)
  const { data: version } = useTemplateVersion(versionId)
  const form = useForm({
    defaultValues: {
      template: {
        schema: '{}',
        uischema: '{}',
      },
    },
    resolver: yupResolver(templateSchema),
  })
  const {
    watch,
    setValue,
    handleSubmit: formSubmit,
    formState: { errors },
  } = form
  console.log('form errors', errors)
  useEffect(() => {
    console.log('version', version)
    if (version) {
      setValue('template.schema', JSON.stringify(version.data))
      setValue('template.uischema', JSON.stringify(version.ui_schema))
    }
  }, [version])

  const editTemplateVersion = useMutation({
    mutationFn: (data: EditTemplateVersionMutation) => {
      const _data = {
        form_template: {
          description: template.template.description,
          data: JSON.parse(data.data.template.schema),
          ui_schema: JSON.parse(data.data.template.uischema),
        },
        form_builder: true,
        tags: data.data.tags,
      }
      if (data.publish) {
        console.log('edit and publish template')
        return api.post(`/forms/templates/versions/${versionId}/save_and_publish`, _data)
      } else {
        console.log('edit template')
        return api.post(`/forms/templates/versions/${versionId}/save`, _data)
      }
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully updated template version', { variant: 'success' })
      history.push(siteMap.FormTemplate.getPath(template.template.id))
    },
    onError: () => {
      enqueueSnackbar('Error editing template version', { variant: 'error' })
    },
  })

  const handlePublish = data => {
    console.log('handlePublish', data)
    editTemplateVersion.mutate({
      data: data,
      publish: true,
    })
  }

  const handleSave = data => {
    console.log('handleSave', data)
    editTemplateVersion.mutate({
      data: data,
      publish: false,
    })
  }

  return (
    <Page
      title="Edit Template"
      size="lg"
      back
      PageActions={() => (
        <Stack direction="row" gap={1}>
          <Button onClick={formSubmit(handleSave)} variant="outlined">
            Save
          </Button>
          <Button onClick={formSubmit(handlePublish)} variant="outlined">
            Publish
          </Button>
        </Stack>
      )}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TemplatePrincipalDetails template={template} />
          </Grid>
        </Grid>
      </Card>
      <FormProvider {...form}>
        <NewTemplateBuilder tags={version ? version.tags : null} />
      </FormProvider>
    </Page>
  )
}

export default EditTemplateVersion
