import { useLocationRisks } from '../../hooks/risks'
import { useLocation } from '../../hooks/locations'
import { RiskType } from 'balkerne-core/risks'
import Displayrisk from './displayrisk'
import { Stack } from '@mui/material'

function getFloodRiskText(riskRating) {
  switch (riskRating) {
    case 1:
      return 'Very Low'
    case 2:
      return 'Low'
    case 3:
      return 'Medium'
    case 4:
      return 'High'
    default:
      return 'Negligible'
  }
}

const InsightsTab = ({ propertyId }) => {
  const { data: property } = useLocation(propertyId, { geojson: true })
  const { data: risks } = useLocationRisks(propertyId)
  const riverSeaRisk = risks
    .filter(risk => risk.category?.name == RiskType.RIVER_SEA)
    .map(({ category_id, value, description }) => ({ category_id, value, description }))

  const surfaceWaterRisk = risks
    .filter(risk => risk.category?.name == RiskType.SURFACE_FLOOD)
    .map(({ category_id, value, description }) => ({ category_id, value, description }))

  return (
    <Stack spacing={3} mb={6}>
      {true ? ( // 🚧 Check if org. subscribed to flooding (?)
        <Displayrisk
          title="Rivers and Seas Flood Risk"
          riskratingdescription={getFloodRiskText(riverSeaRisk?.[0]?.value ?? 0)}
          riskrating={riverSeaRisk?.[0]?.value ?? 0}
          max_score={6}
          geoFeature={property}
          p={riverSeaRisk?.[0]?.description || 'No Further Detail'}
        />
      ) : (
        <></>
      )}

      {true ? ( // 🚧 Check if org. subscribed to flooding (?)
        <Displayrisk
          title="Surface Water Risk"
          riskratingdescription={getFloodRiskText(surfaceWaterRisk?.[0]?.value || 0)}
          riskrating={surfaceWaterRisk?.[0]?.value || 0}
          geoFeature={property}
          max_score={6}
          p={surfaceWaterRisk?.[0]?.description || 'No Further Detail'}
        />
      ) : (
        ''
      )}
    </Stack>
  )
}

export default InsightsTab
