import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { TextField } from '@mui/material'


type DebounceTextFieldProps = {
  onChange: (value: string) => void
  debounceMs: number
  [key: string]: any
}
const DebounceTextField = forwardRef(({ onChange, debounceMs, ...props } : DebounceTextFieldProps, ref: any) => {
  const [value, setValue] = useState('')

  const handleChange = useCallback(e => {
    setValue(e.target.value)
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounceMs)
    return () => clearTimeout(timeout)
  }, [value])

  return <TextField ref={ref} {...props} onChange={handleChange} />
})

export default DebounceTextField
