import style from './LineChartWidget.module.scss'
import { fromValueToColor } from '../riskReport/displaycrimerisk'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, LineController, Title, Tooltip, Legend)

function formatDateTime(string) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const date = new Date(string)

  return monthNames[date.getMonth()] + ' ' + date.getFullYear()
}

function LineChartWidget({ data, value, title }) {
  // TODO: Add chart subtitle showing the period the data-set

  if (data?.length === 0) {
    return <div className={style.ChartWidget}>No statistics avaliable</div>
  }
  let colorForLocalData = '#000000'
  let colorForNationalData = 'blue'
  colorForLocalData = fromValueToColor(value)

  let chartData = {
    labels: data.map(item => formatDateTime(item.date)),
    datasets: [
      {
        label: 'Local',
        data: data.map(item => item.local),
        borderColor: colorForLocalData,
        backgroundColor: colorForLocalData,
        pointRadius: 3,
        pointHoverRadius: 3,
      },
      {
        label: 'National',
        data: data.map(item => item.national),
        borderColor: colorForNationalData,
        backgroundColor: colorForNationalData,
        pointRadius: 3,
        pointHoverRadius: 3,
      },
    ],
  }
  const chartOptions: any = {
    tension: 0.3,
    pointStyle: 'dot',

    labels: { display: true },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        title: {
          display: true,
          text: `${title} Rate`,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: title,
        font: {
          size: 20,
        },
      },
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        padding: 10,
      },
    },
  }
  return (
    <div style={{ padding: '10px' }} className={style.ChartWidget}>
      <Chart
        type="line"
        data={chartData}
        options={chartOptions}
      />
    </div>
  )
}

export { LineChartWidget }
