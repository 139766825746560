import * as yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import { useFormTemplate, useTemplateVersion } from '../../hooks/forms'
import Page from '../../components/Page'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { DataGrid, GridRowId, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid'
import FormatDate from 'balkerne-components/FormatDate'
import TemplatePreview from '../../components/templateManager/TemplatePreview'
import api from 'balkerne-core/api'
import { useSnackbar } from 'notistack'
import { useMutation, useQueryClient } from 'react-query'
import siteMap from '../../siteMap'
import { principalSchema, TemplateDetailsForm } from './CreateTemplate'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TemplatePermissions, usePermissions } from 'balkerne-core/roles'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

type SelectedVersion = {
  id: number
  expired: boolean
  version_number: number
}
const FormTemplate = () => {
  const { possible } = usePermissions()
  const history = useHistory()
  const isAdmin = possible(TemplatePermissions.ManageSystem)
  const user = useSelector((state: RootState) => state.auth.user)
  const { id } = useParams()
  const { data: template } = useFormTemplate(id)
  const [selectedVersion, setSelectedVersion] = useState<SelectedVersion | null>(null)
  const { data: version } = useTemplateVersion(selectedVersion?.id ?? null)
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (template !== null) {
      setSelectedVersion({
        id: template.active_version.id,
        expired: template.active_version.expired,
        version_number: template.active_version.version,
      })
    }
  }, [template])

  const handlePublish = async () => {
    const principalId = template['template']['id']
    const versionId = template['active_version']['id']
    const isPublished = template['active_version']['published']
    if (isPublished) {
      await api
        .post(`/forms/templates/${principalId}/unpublish`)
        .then(() => {
          enqueueSnackbar('Successfully unpublished template', { variant: 'success' })
          queryClient.invalidateQueries(`/forms/templates/versions/${versionId}`)
          queryClient.invalidateQueries('/forms/templates')
          queryClient.invalidateQueries(`/forms/templates/${principalId}`)
        })
        .catch(error => {
          enqueueSnackbar('Error unpublishing template', { variant: 'error' })
          console.error(error)
        })
    } else {
      // publish
      await api
        .post(`/forms/templates/${principalId}/publish`)
        .then(() => {
          enqueueSnackbar('Successfully published template', { variant: 'success' })
          queryClient.invalidateQueries(`/forms/templates/versions/${versionId}`)
          queryClient.invalidateQueries('/forms/templates')
          queryClient.invalidateQueries(`/forms/templates/${principalId}`)
        })
        .catch(error => {
          enqueueSnackbar('Error publishing template', { variant: 'error' })
          console.error(error)
        })
    }
  }

  const handleDelete = async () => {
    const templateId = template.template.id

    await api
      .delete(`/forms/templates/${templateId}`)
      .then(async () => {
        enqueueSnackbar('Template has been deleted successfully', { variant: 'success' })
        queryClient.invalidateQueries('/forms/templates')
        history.push(siteMap.TemplateManager.path)
      })
      .catch(error => {
        enqueueSnackbar('Error deleting template', { variant: 'error' })
        console.error(error)
      })
  }

  const handleSetGlobal = async () => {
    const data = {
      principal_data: {
        category_id: template.template.category.id,
        description: template.template.description,
        title: template.template.title,
        type_id: template.template.type.id,
        latest_version: 1,
        property_id: null,
        is_active: true,
        highlight: false,
        default: true,
      },
      template_data: {
        version: 1,
        description: template.template.description,
        data: template.active_version.data,
        ui_schema: template.active_version.ui_schema,
        expired: false,
        published: false,
      },
      tags: [...template.active_version.tags],
      is_global: true,
    }

    await api
      .post('/forms/templates', data)
      .then(response => {
        const templateId = response.data.data
        queryClient.invalidateQueries('/forms/templates')
        enqueueSnackbar('Template converted to global successfully', { variant: 'success' })
        // Redirect to Template Manager
        history.push(siteMap.TemplateManager.path)
      })
      .catch(error => {
        console.error('Error submitting form template: ', error.stack)
        enqueueSnackbar('Error converting organisation template to global', { variant: 'error' })
      })
  }

  const isOrganisationTemplate = () => {
    if (template && template.template.organisation?.id) return true
    else return false
  }

  const hasPermission = () => {
    // display for normal users viewing their own templates
    // display for admin users viewing any template
    let permission = isAdmin
    if (isOrganisationTemplate()) permission = true

    return permission
  }

  const handleUseGlobal = async () => {
    const fetchurl = '/forms/templates'
    const data = {
      principal_data: {
        category_id: template.template.category.id,
        description: template.template.description,
        title: template.template.title,
        type_id: template.template.type.id,
        latest_version: 1,
        property_id: null,
        is_active: true,
        highlight: false,
        default: true,
        organisation_id: user.organisation.id,
      },
      template_data: {
        version: 1,
        description: template.template.description,
        data: template.active_version.data,
        ui_schema: template.active_version.ui_schema,
        expired: false,
        published: false,
      },
      tags: [...template.active_version.tags],
    }

    await api.post(fetchurl, data).then(async response => {
      queryClient.invalidateQueries('/forms/templates')
      history.push(siteMap.FormTemplate.getPath(response.data.data))
    })
  }

  return (
    <Page
      title="View Template"
      size="lg"
      back
      PageActions={() => (
        <Stack direction="row" gap={1}>
          {hasPermission() ? (
            <>
              <Button onClick={handleDelete} variant="outlined" color="error">
                Delete
              </Button>
              {/* {template && template.template.organisation?.id !== null && isAdmin && ( */}
              {isAdmin && isOrganisationTemplate() && (
                <Button onClick={handleSetGlobal} variant="outlined">
                  Set Global
                </Button>
              )}
              {/* )} */}
              <Button onClick={handlePublish} variant="outlined">
                {template && template.active_version.published ? 'Unpublish' : 'Publish'}
              </Button>
            </>
          ) : (
            <Button onClick={handleUseGlobal} variant="outlined">
              Use Template
            </Button>
          )}
        </Stack>
      )}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TemplatePrincipalDetails template={template} />
          </Grid>
          {hasPermission() && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TemplateVersions
                  template={template}
                  selectedVersion={selectedVersion}
                  onSelect={(version: SelectedVersion | null) => setSelectedVersion(version)}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Preview</Typography>
          </Grid>
          <Grid item xs={12}>
            {version ? (
              <TemplatePreview
                template={{
                  schema: JSON.stringify(version.data),
                  uischema: JSON.stringify(version.ui_schema),
                }}
              />
            ) : (
              <>Failed to load template version data</>
            )}
          </Grid>
        </Grid>
      </Card>
    </Page>
  )
}

export const TemplatePrincipalDetails = ({ template }: { template: any }) => {
  const { possible } = usePermissions()
  const isAdmin = possible(TemplatePermissions.ManageSystem)
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [showModal, setShowModal] = useState<boolean>(false)
  const schema = yup.object({
    principal: principalSchema,
  })
  const form = useForm({
    defaultValues: {
      principal: {
        title: '',
        description: '',
        type: null,
        category: null,
      },
    },
    resolver: yupResolver(schema),
  })
  const { handleSubmit: formSubmit, setValue } = form

  useEffect(() => {
    if (template) {
      setValue('principal.title', template.template.title)
      setValue('principal.description', template.template.description)
    }
  }, [template])

  const editTemplateDetails = useMutation({
    mutationFn: (data: any) => {
      return api.patch(`/forms/templates/${template.template.id}`, data)
    },
    onSuccess: () => {
      enqueueSnackbar('Successfully updated template details', { variant: 'success' })
      queryClient.invalidateQueries(`/forms/templates/${template.template.id}`)
      setShowModal(false)
    },
    onError: () => {
      enqueueSnackbar('Error editing template details', { variant: 'error' })
    },
  })

  const handleSubmit = data => {
    console.log('submitting template principal details', data)
    editTemplateDetails.mutate({
      title: data.principal.title,
      description: data.principal.description,
      type_id: data.principal.type,
      category_id: data.principal.category,
    })
  }

  const isOrganisationTemplate = () => {
    if (template && template.template.organisation?.id) return true
    else return false
  }

  const hasPermission = () => {
    // display for normal users viewing their own templates
    // display for admin users viewing any template
    let permission = isAdmin
    if (isOrganisationTemplate()) permission = true

    return permission
  }

  return (
    <Box>
      <FormProvider {...form}>
        <Dialog open={showModal} onClose={() => setShowModal(false)} maxWidth="md" fullWidth>
          <DialogContent>
            <Typography variant="h6" component="h2">
              Details
            </Typography>
            <Box>
              <TemplateDetailsForm />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={formSubmit(handleSubmit)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </FormProvider>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Details</Typography>
            <Stack direction="row" spacing={2}>
              {template ? (
                template.active_version.published === true ? (
                  <Typography variant="h6" sx={{ color: '#43b31f' }}>
                    Published
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ color: '#FFBF00' }}>
                    Unpublished
                  </Typography>
                )
              ) : (
                <></>
              )}
              {hasPermission() && (
                <Button variant="outlined" onClick={() => setShowModal(true)} size="small">
                  Edit
                </Button>
              )}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <Typography variant="subtitle2">Title</Typography>
            <Typography variant="body1">{template ? template.template.title : 'Loading'}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body1">{template ? template.template.description : 'Loading'}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="subtitle2">Type</Typography>
            <Typography variant="body1">{template ? template.template.type.title : 'Loading'}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="subtitle2">Category</Typography>
            <Typography variant="body1">{template ? template.template.category.title : 'Loading'}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
const TemplateVersions = ({
  template,
  selectedVersion,
  onSelect,
}: {
  template: any
  selectedVersion: SelectedVersion | null
  onSelect: (version: SelectedVersion | null) => void
}) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [showArchive, setShowArchive] = useState<boolean>(false)

  const versions = useMemo(() => {
    if (template) {
      return template.template.versions.filter((version: any) => !version.expired)
    }
    return []
  }, [template])

  const archivedVersions = useMemo(() => {
    if (template) {
      return template.template.versions.filter((version: any) => version.expired)
    } else return []
  }, [template])

  const selectionModel = useMemo(() => {
    if (selectedVersion) {
      return [selectedVersion.id as GridRowId]
    }
    return []
  }, [selectedVersion])

  const handleCreateVersion = () => {
    history.push(siteMap.CreateFormTemplateVersion.getPath(template.template.id))
  }

  const handleEditVersion = () => {
    if (selectedVersion) {
      history.push(siteMap.EditFormTemplateVersion.getPath(template.template.id, selectedVersion.id))
    }
  }

  const handleShowArchive = () => {
    setShowArchive(!showArchive)
  }

  const handleSetActive = async () => {
    if (selectedVersion) {
      console.log('setting active', selectedVersion)
      await api
        .post(`/forms/templates/versions/${selectedVersion.id}/set_active`)
        .then(() => {
          enqueueSnackbar('Successfully set active version', { variant: 'success' })
          queryClient.invalidateQueries('/forms/templates')
          queryClient.invalidateQueries(`/forms/templates/${template.template.id}`)
        })
        .catch(error => {
          enqueueSnackbar('Error updating template latest version', { variant: 'error' })
          console.error(error)
        })
    }
  }

  const handleArchiveVersion = async () => {
    if (selectedVersion) {
      if (selectedVersion.version_number !== template['active_version']['version']) {
        await api
          .post(`/forms/templates/versions/${selectedVersion.id}/archive`)
          .then(() => {
            enqueueSnackbar('Successfully archived version', { variant: 'success' })
            onSelect(null)
            // refetch()
            queryClient.invalidateQueries('/forms/templates')
            queryClient.invalidateQueries(`/forms/templates/${template.template.id}`)
          })
          .catch(error => {
            console.error('Error saving template version changes: ', error, error.stack)
            enqueueSnackbar('Error while archiving version', { variant: 'error' })
          })
        onSelect(null)
      } else {
        enqueueSnackbar('You cannot archive an active template.', {
          variant: 'error',
        })
      }
    }
  }

  const handleUnarchiveVersion = async versionID => {
    console.log('Un Archiving template version : ')
    await api
      .post(`/forms/templates/versions/${versionID}/unarchive`)
      .then(() => {
        enqueueSnackbar('Successfully unarchived template', { variant: 'success' })
        // onUnarchive()
        queryClient.invalidateQueries('/forms/templates')
        queryClient.invalidateQueries(`/forms/templates/${template.template.id}`)
      })
      .catch(error => {
        enqueueSnackbar('Failed to unarchived version', { variant: 'error' })
        console.error('Error saving template version changes: ', error, error.stack)
      })
  }

  const handleRowClick = (params: GridRowParams) => {
    onSelect({
      id: params.row.id,
      expired: params.row.expired,
      version_number: params.row.version,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography variant="h6">Versions</Typography>
          <Stack direction="row" gap={1}>
            <Button variant="outlined" size="small" onClick={handleCreateVersion}>
              New Version
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleEditVersion}
              disabled={Boolean(selectedVersion === null || selectedVersion.expired)}>
              Edit
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleSetActive}
              disabled={Boolean(
                selectedVersion === null ||
                  selectedVersion.expired ||
                  selectedVersion.version_number === template.active_version.version,
              )}>
              Set Active
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleArchiveVersion}
              disabled={Boolean(
                selectedVersion === null ||
                  selectedVersion.expired ||
                  selectedVersion.version_number === template.active_version.version,
              )}>
              Archive
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          sx={{ height: '250px' }}
          onSelectionModelChange={(selection, details) => {
            console.log('selection changed', selection)
            console.log('selection changed details', details)
            if (selection.length === 0) {
              onSelect(null)
            }
          }}
          selectionModel={selectionModel}
          onRowClick={handleRowClick}
          columns={[
            {
              headerName: 'Version',
              field: 'version',
            },
            { headerName: 'Description', field: 'description', flex: 1 },
            {
              headerName: 'Created At',
              field: 'created_at',
              flex: 1,
              valueGetter: (params: GridValueGetterParams) => {
                return FormatDate(params.row.created_at)
              },
            },
            {
              headerName: 'Updated At',
              field: 'updated_at',
              flex: 1,
              valueGetter: (params: GridValueGetterParams) => {
                return FormatDate(params.row.updated_at)
              },
            },
            {
              headerName: 'Status',
              field: 'status',
              valueGetter: (params: GridValueGetterParams) => {
                if (params.row.version === template['template']['latest_version']) {
                  return 'Active'
                } else {
                  return 'Inactive'
                }
              },
            },
          ]}
          rows={versions}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Typography variant="subtitle1">{`Archived Versions (${archivedVersions.length})`}</Typography>
          <Button variant="outlined" size="small" onClick={handleShowArchive}>
            {showArchive ? 'Hide Archive' : 'Show Archive'}
          </Button>
        </Stack>
      </Grid>
      {showArchive && (
        <>
          <Grid item xs={12}>
            <DataGrid
              sx={{ height: '250px' }}
              onSelectionModelChange={(selection, details) => {
                console.log('selection changed', selection)
                console.log('selection changed details', details)
                if (selection.length === 0) {
                  onSelect(null)
                }
              }}
              selectionModel={selectionModel}
              onRowClick={handleRowClick}
              columns={[
                {
                  headerName: 'Version',
                  field: 'version',
                },
                { headerName: 'Description', field: 'description', flex: 1 },
                {
                  headerName: 'Created At',
                  field: 'created_at',
                  flex: 1,
                  valueGetter: (params: GridValueGetterParams) => {
                    return FormatDate(params.row.created_at)
                  },
                },
                {
                  headerName: 'Updated At',
                  field: 'updated_at',
                  flex: 1,
                  valueGetter: (params: GridValueGetterParams) => {
                    return FormatDate(params.row.updated_at)
                  },
                },
                {
                  headerName: 'Status',
                  field: 'status',
                  renderCell: params => (
                    <Button variant="contained" onClick={() => handleUnarchiveVersion(params.row.id)}>
                      Unarchive
                    </Button>
                  ),
                },
              ]}
              rows={archivedVersions}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
export default FormTemplate
