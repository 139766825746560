import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  menuDrawerOpen: false,
  notificationDrawerOpen: false,
  propertyId: null,
}

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    toggleMenuDrawer: state => {
      state.menuDrawerOpen = !state.menuDrawerOpen
    },
    setMenuDrawerOpen: (state, { payload }) => {
      state.menuDrawerOpen = payload
    },
    toggleNotificationDrawer: state => {
      state.notificationDrawerOpen = !state.notificationDrawerOpen
    },
    setNotificationDrawerOpen: (state, { payload }) => {
      state.notificationDrawerOpen = payload
    },
    setPropertyId: (state, { payload }) => {
      state.propertyId = payload
    },
  },
})

export const systemReducer = systemSlice.reducer
export default systemSlice.actions
