export const Severity = Object.freeze({
  SEVERE: 1,
  WARNING: 2,
  ALERT: 3,
  NOT_IN_FORCE: 4,
})

export const DisplaySeverity = Object.freeze({
  1: 'Severe Warning',
  2: 'Warning',
  3: 'Alert',
  4: 'No longer active',
})

export const getAlertImage = severity => {
  switch (severity) {
    case 'Flood_1':
      return require('../images/Severe_Flood_Warning_small.png')
    case 'Flood_2':
      return require('../images/Flood_Warning_small.png')
    case 'Flood_3':
      return require('../images/Flood_Alert_small.png')
    case 'Arson_3':
    case 'Armed Robbery_3':
    case 8:
    case 9:
      return require('../images/crime_alert_small.png')
    case 'Rainfall_3':
      return require('../images/rainfall_alert.png')
    case 'Wind_3':
    case 'High Wind_3':
      return require('../images/wind_alert.png')
    case 'High Temperature_3':
      return require('../images/temp_alert.png')
    case 'YELLOW':
      return require('../images/yellowwarning.png')
    case 'AMBER':
      return require('../images/amberwarning.png')
    case 'RED':
      return require('../images/redwarning.png')
    case 'YELLOW':
      return require('../images/yellowwarning.png')
    case 'AMBER':
      return require('../images/amberwarning.png')
    case 'RED':
      return require('../images/redwarning.png')
    case 'Pandemic_3':
      return require('../images/pandemic_alert.png')
  }
  return '0'
}
