import siteMap from '../../siteMap'

const ViewForm = async (forminstance, history, propertyID, previousPath) => {
  if (previousPath !== null) {
    history.push(
      siteMap.FormView.getPath({
        propertyID: propertyID,
        readonly: true,
        formInstanceId: forminstance.id,
        previousPathTitle: previousPath.previousPathTitle,
        previousPathName: previousPath.previousPathName,
        previousPathState: previousPath.previousPathState,
      }),
    )
  } else {
    history.push(
      siteMap.FormView.getPath({
        propertyID: propertyID,
        readonly: true,
        formInstanceId: forminstance.id,
      }),
    )
  }
}
export default ViewForm
