import { SetStateAction, forwardRef, useCallback, useMemo, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Button, Grid, Stack, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Fade } from '@mui/material'
import {
  DataGrid,
  getGridSingleSelectOperators,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import {
  useAlertTypes,
  useConfigurations,
  useDisableAlertConfiguration,
  useEnableAlertConfiguration,
} from '../hooks/alerts'
import SeverityIcon from '../components/SeverityIcon'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import api from 'balkerne-core/api'
import DebounceTextField from '../components/DebounceTextField'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import siteMap from '../siteMap'
import { useHistory } from 'react-router-dom'
import { TransitionProps } from '@mui/material/transitions'
import Page from '../components/Page'
import Switch from '@mui/material/Switch'

const AlertConfigurations = () => {
  const PAGE_SIZE = 25
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteConfiguration, setDeleteConfiguration] = useState<any>(null)
  const [searchText, setSearchText] = useState('')
  const [page, setPage] = useState(0)
  const { data: types, isFetching: isTypesLoading, isError } = useAlertTypes()
  const enableConfiguration = useEnableAlertConfiguration()
  const disableConfiguration = useDisableAlertConfiguration()
  const [configArgs, setConfigArgs] = useState({})
  const {
    data: configurations,
    isFetching: isConfigLoading,
    refetch: refetchConfigs,
  } = useConfigurations({
    offset: page * PAGE_SIZE,
    limit: PAGE_SIZE,
    name: searchText,
    ...configArgs,
  })

  const handleFilterModelChange = useCallback(args => {
    if (args.items.length === 0) {
      setConfigArgs({})
    } else {
      let { columnField, value } = args.items[0]
      if (columnField === 'alert') {
        columnField = 'type_id'
      }
      setConfigArgs({ [columnField]: value })
    }
    setPage(0)
  }, [])

  const handlePauseConfiguration = (event: React.ChangeEvent<HTMLInputElement>, configurationId: number) => {
    if (event.target.checked) {
      enableConfiguration.mutate(configurationId)
    } else {
      disableConfiguration.mutate(configurationId)
    }
  }

  const configurationColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: false,
        flex: 1,
        minWidth: 150,
        filterable: false,
      },
      {
        field: 'alert',
        headerName: 'Alert',
        minWidth: 150,
        maxWidth: 250,
        flex: 1,
        sortable: false,
        valueOptions: types.map(type => ({ value: type.id, label: type.display_name })),
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
        renderCell: params => (
          <>
            <Typography>{params.row.type.display_name}</Typography>
          </>
        ),
      },
      {
        field: 'isMetric',
        headerName: 'Type',
        sortable: false,
        width: 70,
        filterable: false,
        valueGetter: (params: { row: { type: { metric: any } } }) => (params.row.type.metric ? 'Metric' : 'Notice'),
      },
      {
        field: 'severity',
        headerName: 'Severity',
        sortable: false,
        valueOptions: [
          { value: 1, label: 'Severe' },
          { value: 2, label: 'Warning' },
          { value: 3, label: 'Alert' },
        ],
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
        renderCell: params => <SeverityIcon height={25} severity={params.row.severity} />,
      },
      {
        field: 'threshold',
        type: 'number',
        filterable: false,
        sortable: false,
        headerName: 'Threshold',
        renderCell: params => {
          if (params.row.type.metric) {
            return `${params.row.threshold}${params.row.type.units}`
          } else {
            return (
              <Typography color="text.disabled" variant="body2">
                <em>N/A</em>
              </Typography>
            )
          }
        },
      },
      { field: 'notify', type: 'boolean', headerName: 'Notify', filterable: false, sortable: false, width: 70 },
      { field: 'sensitive', type: 'boolean', headerName: 'Sensitive', filterable: false, sortable: false, width: 80 },
      { field: 'subscriber_count', type: 'number', headerName: 'Subscribers', filterable: false, sortable: false },
      {
        field: 'cooldown',
        type: 'number',
        filterable: false,
        headerName: 'Cooldown',
        valueGetter: params => `${params.row.type.cooldown_period}min`,
        sortable: false,
      },
      {
        field: 'created_at',
        type: 'dateTime',
        headerName: 'Created at',
        valueGetter: params =>
          new Date(params.row.created_at).toLocaleDateString('en-gb', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        filterable: false,
        sortable: false,
      },
      {
        field: 'status',
        type: 'boolean',
        headerName: 'Status',
        filterable: false,
        sortable: false,
        width: 70,
        renderCell: params => (
          <Switch
            size="small"
            onChange={e => handlePauseConfiguration(e, params.row.id)}
            checked={params.row.is_active}
          />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: args => [
          <GridActionsCellItem
            icon={<AddBusinessIcon />}
            onClick={() => history.push(siteMap.AssignConfigurations.getPath(args.row.id))}
            label="Assign properties"
          />,
          <GridActionsCellItem
            icon={<DeleteForeverIcon />}
            onClick={() => handleOpenDeleteDialog(args.row)}
            label="Delete"
            disabled={args.row.source === 'SYSTEM'}
          />,
        ],
      },
    ],
    [types],
  )

  const handleOpenDeleteDialog = useCallback(config => {
    setDeleteConfiguration(config)
    setIsDeleteDialogOpen(true)
  }, [])

  const handleCloseDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(false)
  }, [])

  const indexedTypes = useMemo(() => {
    if (isTypesLoading) return {}
    return types.reduce((acc, type) => {
      acc[type.id] = type
      return acc
    }, {})
  }, [types, isTypesLoading])

  const isLoading = isConfigLoading || isTypesLoading

  const configurationsWithAlertTypes = useMemo(() => {
    if (configurations == null || isTypesLoading) {
      return []
    }
    return configurations.items.map((config: { type_id: string | number }) => {
      const type = indexedTypes[config.type_id]
      return { ...config, type }
    })
  }, [configurations, isLoading, page])

  const handleSearch = useCallback((e: SetStateAction<string>) => setSearchText(e), [])
  const SubscriptionsCustomToolbar = useMemo(
    () => () =>
      (
        <GridToolbarContainer>
          <Stack direction="row" spacing={2} alignItems="flex-end" mx={1} width="100%">
            <GridToolbarColumnsButton variant="outlined" size="small" />
            <GridToolbarFilterButton />
            <DebounceTextField
              variant="standard"
              label="Search"
              size="small"
              onChange={handleSearch}
              debounceMs={500}
            />
          </Stack>
        </GridToolbarContainer>
      ),
    [],
  )

  const handleDelete = useCallback(id => {
    setIsDeleting(true)
    api
      .delete(`/alerts/configurations/${id}`)
      .then(() => enqueueSnackbar('Deleted configuration', { variant: 'success' }))
      .catch(() => enqueueSnackbar('Failed to delete configuration', { variant: 'error' }))
      .finally(() => {
        refetchConfigs()
        setIsDeleteDialogOpen(false)
        setDeleteConfiguration(null)
        setIsDeleting(false)
      })
  }, [])

  return (
    <>
      <Page
        title="Configurations"
        size="lg"
        back
        PageActions={() => (
          <Stack direction="row" gap={1}>
            <Button onClick={() => history.push('/alerts/configurations/create')} variant="contained">
              Create Configuration
            </Button>
          </Stack>
        )}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    isMetric: false,
                    cooldown: false,
                    created_at: false,
                  },
                },
              }}
              disableSelectionOnClick
              error={isError || null}
              onFilterModelChange={handleFilterModelChange}
              filterMode="server"
              columns={configurationColumns}
              rows={configurationsWithAlertTypes}
              density="compact"
              rowsPerPageOptions={[PAGE_SIZE]}
              onPageChange={setPage}
              paginationMode="server"
              rowCount={Number.MAX_VALUE}
              sx={{
                height: 600,
                '.MuiTablePagination-displayedRows': {
                  display: 'none',
                },
              }}
              loading={isLoading}
              components={{
                Toolbar: SubscriptionsCustomToolbar,
              }}
            />
          </Grid>
        </Grid>
      </Page>

      {/* Delete Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        maxWidth="xs"
        fullWidth
        keepMounted
        TransitionComponent={DialogTransition}>
        <DialogTitle>Delete configuration</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this configuration? This action cannot be undone.</Typography>
          {deleteConfiguration?.subscriber_count > 0 && (
            <>
              <br />
              <Typography color="error">
                There {deleteConfiguration.subscriber_count == 1 ? 'is' : 'are'} {deleteConfiguration.subscriber_count}{' '}
                subscriber{deleteConfiguration.subscriber_count == 1 ? '' : 's'} to this configuration. They will be
                unsubscribed.
              </Typography>
              <br />
              <Typography variant="caption">Any alerts associated with this configuration will be deleted.</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <LoadingButton
            loading={isDeleting}
            onClick={() => handleDelete(deleteConfiguration.id)}
            variant="contained"
            color="error">
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const DialogTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />
})

export default AlertConfigurations
