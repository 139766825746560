import { Box, useTheme } from '@mui/material'
import { darken, lighten } from '@mui/material'

const LoadingIcon = () => {
  const theme = useTheme()
  const ballSize = 10
  const ballCount = 5
  const gap = 10
  const width = ballSize * ballCount + gap * (ballCount - 1)
  const height = ballSize

  const speed = '0.6s'
  return (
    <Box
      sx={{
        display: 'inline-block',
        position: 'relative',
        boxSizing: 'border-box',
        height: `${height}px`,
        width: `${width}px`,
        '& div': {
          position: 'absolute',
          width: `${ballSize}px`,
          height: `${ballSize}px`,
          borderRadius: '50%',
          backgroundColor: theme.palette.primary.main,
          animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
        },
        '@keyframes scaleIn': {
          '0%': {
            transform: 'scale(0)',
          },
          '100%': {
            transform: 'scale(1)',
          },
        },
        '@keyframes slideRight': {
          '0%': {
            transform: 'translate(0, 0)',
          },
          '100%': {
            transform: `translate(${ballSize + gap}px, 0)`,
          },
        },
        '@keyframes scaleOut': {
          '0%': {
            transform: 'scale(1)',
          },
          '100%': {
            transform: 'scale(0)',
          },
        },
        '& > div:first-child': {
          animation: `scaleIn ${speed} infinite`,
        },
        '& > div:last-child': {
          animation: `scaleOut ${speed} infinite`,
        },
        '& > div:not(:first-child):not(:last-child)': {
          animation: `slideRight ${speed} infinite`,
        },
      }}>
      {[...Array(ballCount)].map((_, i) => (
        <div
          key={i}
          style={{
            left: i > 1 ? `${(ballSize + gap) * (i - 1)}px` : 0,
          }}
        />
      ))}
    </Box>
  )
}

export default LoadingIcon
