class ConfigProvider {
  constructor() {
    const stage = process.env.REACT_APP_STAGE || 'dev'
    const { config } = require(`../environment-${stage}`)
    const { config: staticConfig } = require(`../environment-static`)
    this._staticConfig = staticConfig
    this._config = config
    this._config.Stage = stage
  }
  get backendEndpoint() {
    // if (this._config.Stage == 'prod') {
    //   return this._staticConfig.BackendEndpointStatic
    // } else {
    //   return this._config.BackendEndpoint
    // }
    return this._config.BackendEndpoint
  }
  get cognitoRegion() {
    return this._config.CognitoRegion
  }
  get cognitoUserPoolId() {
    return this._config.CognitoUserPoolId
  }
  get cognitoWebClientId() {
    return this._config.CognitoWebClientId
  }
  get documentsURL() {
    return this._config.DocumentsURL
  }
  get lossIncidentsURL() {
    return this._config.LossIncidentsURL
  }
  get mitigationsURL() {
    return this._config.MitigationsURL
  }
  get mapboxPublicKey() {
    return this._staticConfig.MapboxPublicKey
  }
  get googleMapsKey() {
    return this._staticConfig.GoogleMapsKey
  }
  get sentryDSN() {
    return this._staticConfig.SentryDSN
  }
  get stage() {
    return this._config.Stage
  }
  get samplingRate() {
    return this._config.SamplingRate
  }
}

export default new ConfigProvider()
