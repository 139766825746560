import React, { useMemo, useState } from 'react'
import Dropzone from 'balkerne-components/Dropzone'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useFieldArray, FormProvider, useFormContext, Controller } from 'react-hook-form'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {
  Stack,
  Button,
  Box,
  TextField,
  Typography,
  Grid,
  MenuItem,
  Pagination,
  IconButton,
  Tooltip,
  Card,
  Autocomplete,
  TextFieldProps,
} from '@mui/material'
import { readFileBuffer } from 'balkerne-fn/readFile'
import SpreadSheetHandler from '../components/columnSelector/SpreadSheetHandler'
import CSVHandler from '../components/columnSelector/CSVHandler'
import api from 'balkerne-core/api'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'
import * as xlsx from 'xlsx' // Spreadsheet parser
import Papa from 'papaparse' // CSV parser
import { CSVLink } from 'react-csv'
import { geocode } from 'balkerne-fn/geocode'
import Page from '../components/Page'
import { Architecture } from '@mui/icons-material'
import DrawMap from '../components/userManagement/DrawMap'
import { useCountryList } from '../hooks/utils'
// TODO: Limit list to 1000 properties

const ImportType = Object.freeze({
  CSV: 'text/csv',
  XSLX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XSL: 'application/vnd.ms-excel',
})

const schema = yup.object({
  region: yup.string().required('Region is required'),
  properties: yup
    .array(
      yup.object({
        name: yup.string().required('Name is required'),
        reference: yup.string().required('Reference number is required'),
        address: yup.string().min(3).required('Address is required'),
        dpa: yup.object().optional().nullable(),
        dpaSelection: yup.array().optional(),
        manualAddress: yup
          .object({
            street: yup
              .string()
              .nullable()
              .when('$manualAddressRequired', {
                is: true,
                then: yup
                  .string()
                  .nullable()
                  .test(
                    'notEmpty',
                    'Street is required',
                    value => value !== null && value !== undefined && value.trim() !== '',
                  ),
                otherwise: yup.string().optional().nullable(),
              }),
            town: yup
              .string()
              .nullable()
              .when('$manualAddressRequired', {
                is: true,
                then: yup
                  .string()
                  .nullable()
                  .test(
                    'notEmpty',
                    'Town is required',
                    value => value !== null && value !== undefined && value.trim() !== '',
                  ),
                otherwise: yup.string().optional().nullable(),
              }),
            postcode: yup
              .string()
              .nullable()
              .when('$manualAddressRequired', {
                is: true,
                then: yup
                  .string()
                  .nullable()
                  .test(
                    'notEmpty',
                    'Postcode is required',
                    value => value !== null && value !== undefined && value.trim() !== '',
                  ),
                otherwise: yup.string().optional().nullable(),
              }),
            country: yup
              .string()
              .nullable()
              .when('$manualAddressRequired', {
                is: true,
                then: yup
                  .string()
                  .nullable()
                  .test(
                    'notEmpty',
                    'Country is required',
                    value => value !== null && value !== undefined && value.trim() !== '',
                  ),
                otherwise: yup.string().optional().nullable(),
              }),
            geometry: yup
              .mixed()
              .nullable()
              .when('$manualAddressRequired', {
                is: true,
                then: yup
                  .mixed()
                  .nullable()
                  .test('notEmpty', 'Geometry is required', value => value !== null && value !== undefined),
                otherwise: yup.mixed().optional().nullable(),
              }),
          })
          .optional()
          .nullable(),
      }),
    )
    .min(1, 'At least one property is required'),
})

type Address = {
  town: string
  street: string
  postcode: string
  country: string
  geometry: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
}
type SelectedProperty = {
  index: number
  prevAddress: Address | null
}
type Property = {
  name: string
  reference: string
  address: string
  dpa: any
  dpaSelection: any[]
  manualAddress: Address | null
}

const defaultProperty: Property = {
  name: '',
  reference: '',
  address: '',
  dpa: null,
  dpaSelection: [],
  manualAddress: null,
}

export const AddProperties = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: { region: 'United Kingdom', properties: [{ ...defaultProperty }] },
    resolver: yupResolver(schema),
  })
  const {
    control,
    register,
    setValue,
    handleSubmit: formSubmit,
    getFieldState,
    getValues,
    watch,
    formState: { isValid, isSubmitting },
  } = formMethods

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'properties',
  })

  const [page, setPage] = useState(0)
  const pageSize = 10
  const pageCount = Math.ceil(fields.length / pageSize)

  const isFieldInCurrentPage = index => {
    const startIndex = page * pageSize
    const endIndex = page * pageSize + pageSize - 1
    return index >= startIndex && index <= endIndex
  }
  const removeProperty = index => remove(index)
  const appendProperty = () => prepend({ ...defaultProperty })
  const openModal = () => setIsImportModalOpen(true)
  const closeModal = () => setIsImportModalOpen(false)
  const handleImport = properties => prepend(properties)
  const handleReset = () => remove()
  const [isMapOpen, setIsMapOpen] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState<SelectedProperty | null>(null)

  const handleSubmit = async data => {
    const currentDate = new Date().toISOString()
    const fileName = `SmartResilience - Failed Property List - ${currentDate}.csv`
    const headers = [
      { label: 'Name', key: 'name' },
      { label: 'CRN', key: 'reference' },
      { label: 'Address', key: 'address' },
    ]
    await api
      .post('/locations/bulkv2', data)
      .then(res => {
        const { successCount, failureCount, failures } = res.data
        const totalCount = successCount + failureCount
        handleReset()
        if (successCount > 0) {
          enqueueSnackbar(`Successfully submitted ${successCount} of ${totalCount} property(s)`, { variant: 'success' })
        }
        if (failureCount > 0) {
          prepend(failures)
          enqueueSnackbar(`Couldn't find information for ${failureCount} properties`, {
            variant: 'warning',
            persist: true,
            action: key => (
              <Stack direction="row">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => console.log(key)}
                  sx={{ mr: 1 }}>
                  <CSVLink data={failures} filename={fileName} headers={headers} target="_blank">
                    Download
                  </CSVLink>
                </Button>
                <Button variant="outlined" size="small" onClick={() => closeSnackbar(key)}>
                  Dismiss
                </Button>
              </Stack>
            ),
          })
        }
      })
      .catch(err => {
        console.error(err)
        enqueueSnackbar('Error has occurred, try again later', { variant: 'error' })
      })
  }
  type FormTextFieldProps = TextFieldProps & {
    name: keyof Property
    index: number
    select?: boolean
  }
  const FormTextField = useMemo(
    () =>
      ({ name, index, ...props }: FormTextFieldProps) =>
        (
          <TextField select={props.select} {...register(`properties.${index}.${name}`)} {...props}>
            {props.children}
          </TextField>
        ),
    [],
  )

  const geocodeAddress = async (index: number) => {
    const address = getValues(`properties.${index}.address`)
    const region = getValues('region')
    const addresses = await geocode(address, region)
    if (addresses.length > 0) {
      setValue(`properties.${index}.dpaSelection`, addresses)
    } else {
      enqueueSnackbar('No matching addresses found', { variant: 'warning' })
    }
  }

  const addGeometry = (index: number) => {
    const address: Address | null = getValues(`properties.${index}.manualAddress`)
    setSelectedProperty({
      index: index,
      prevAddress: address ? { ...address } : null,
    })
    setIsMapOpen(true)
  }
  const closeMap = () => {
    setIsMapOpen(false)
    setSelectedProperty(null)
  }

  const availableRegions = useMemo(() => ['United Kingdom', 'World'], [])

  return (
    <>
      <Page
        title="Add Properties"
        size="lg"
        back
        PageActions={() => (
          <Stack gap={1} direction="row">
            <Controller
              name="region"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  value={field.value}
                  options={availableRegions}
                  onChange={(e, value) => field.onChange(value ?? '')}
                  autoHighlight
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Region"
                      variant="outlined"
                      sx={{ minWidth: 200 }}
                      inputRef={field.ref}
                    />
                  )}
                />
              )}
            />
            <Button disabled={isSubmitting} variant="outlined" onClick={handleReset}>
              Reset All
            </Button>
            <Button disabled={isSubmitting} variant="outlined" onClick={appendProperty}>
              Add Row
            </Button>
            <Button disabled={isSubmitting} variant="outlined" onClick={openModal}>
              Import
            </Button>
            <Button onClick={formSubmit(handleSubmit)} disabled={!isValid || isSubmitting} variant="contained">
              Submit
            </Button>
          </Stack>
        )}>
        {/* Property List */}
        <Card elevation={0} sx={{ height: 650 }}>
          {/* Header */}
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>Name</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Reference</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Address</Typography>
            </Grid>
          </Grid>
          {/* Input Fields */}
          {fields.map((property, index) => {
            if (!isFieldInCurrentPage(index)) return null
            return (
              <Grid container key={property.id} spacing={2} py={1}>
                {/* Name */}
                <Grid item xs={3}>
                  <FormTextField index={index} name="name" fullWidth />
                </Grid>
                {/* Reference */}
                <Grid item xs={3}>
                  <FormTextField index={index} name="reference" fullWidth />
                </Grid>
                {/* Address */}
                <Grid item xs>
                  <Stack flexDirection="row">
                    <FormTextField
                      fullWidth
                      index={index}
                      name="address"
                      disabled={getValues(`properties.${index}.manualAddress`) !== null}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={
                        // Returns "Entered Manually" if manual address is set
                        watch(`properties.${index}.manualAddress`) !== null ? (
                          <AddressLabel index={index} setValue={setValue} getValues={getValues} />
                        ) : undefined
                      }
                    />
                    {watch(`properties.${index}.dpaSelection`)?.length > 0 && (
                      <FormTextField
                        select
                        index={index}
                        name="dpa"
                        sx={{ width: 100, ml: 1 }}
                        onChange={(e: any) =>
                          setValue(`properties.${index}.address`, getFullAddress(e.target.value) ?? '')
                        }>
                        {watch(`properties.${index}.dpaSelection`).map(address => (
                          <MenuItem key={address.feature_id} value={address}>
                            {getFullAddress(address)}
                          </MenuItem>
                        ))}
                      </FormTextField>
                    )}
                  </Stack>
                </Grid>
                {/* Actions */}
                <Grid item xs={0}>
                  <Stack direction="row" alignItems="end" gap={1}>
                    <Tooltip title="Find Address">
                      <IconButton
                        aria-label="find address"
                        onClick={() => geocodeAddress(index)}
                        disabled={
                          getFieldState(`properties.${index}.address`).invalid ||
                          getValues(`properties.${index}.manualAddress`) !== null ||
                          watch('region') === null ||
                          watch('region') === ''
                        }>
                        <SearchOutlinedIcon
                          color={
                            getFieldState(`properties.${index}.address`).invalid ||
                            getValues(`properties.${index}.manualAddress`) !== null ||
                            watch('region') === null ||
                            watch('region') === ''
                              ? 'disabled'
                              : 'action'
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Add Manually">
                      <IconButton aria-label="add manually" onClick={() => addGeometry(index)}>
                        <Architecture color="action" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton aria-label="delete" onClick={() => removeProperty(index)}>
                        <DeleteOutlineIcon color="action" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
            )
          })}
          <PropertyImportDialog open={isImportModalOpen} onClose={closeModal} onAdd={handleImport} />

          {selectedProperty !== null && (
            <FormProvider {...formMethods}>
              <ManualAddressDialog open={isMapOpen} onClose={closeMap} selectedProperty={selectedProperty} />
            </FormProvider>
          )}
        </Card>

        <Stack justifyContent="center" mt={2} alignItems="center">
          <Pagination onChange={(e, p) => setPage(p - 1)} count={pageCount} variant="outlined" color="primary" />
        </Stack>
      </Page>
    </>
  )
}

const EmptyHandler = () => <></>
const colummHandler = {
  [CSVHandler.name]: CSVHandler,
  [SpreadSheetHandler.name]: SpreadSheetHandler,
  [EmptyHandler.name]: EmptyHandler,
}

const PropertyImportDialog = ({ open, onClose, onAdd }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [rawData, setRawData] = useState<xlsx.WorkBook | null | undefined>()
  const [handlerName, setHandlerName] = useState(EmptyHandler.name)
  const [properties, setProperties] = useState<any[]>([])

  const importFile = async rawFile => {
    setRawData(undefined)
    setHandlerName(EmptyHandler.name)

    if (!rawFile) {
      console.error('No file provided')
      return
    }
    switch (rawFile?.type) {
      case ImportType.CSV: {
        await Papa.parse(rawFile, {
          complete: res => {
            const data = res.data
            setRawData(data)
            setHandlerName(CSVHandler.name)
          },
        })
        break
      }
      case ImportType.XSL:
      case ImportType.XSLX: {
        const file = await readFileBuffer(rawFile)
        const data = xlsx.read(file.data)
        setRawData(data)
        setHandlerName(SpreadSheetHandler.name)
        break
      }
      default:
        console.warn(`Unsupported file type: ${rawFile?.type}`)
        enqueueSnackbar('Unsupported file type', { variant: 'warning' })
        break
    }
  }

  const onSelect = properties => setProperties(properties)
  const canAdd = properties?.length > 0
  const handleAdd = () => {
    onAdd([...properties])
    setProperties([])
    handleClose()
  }
  const handleClose = () => {
    setRawData(null)
    setHandlerName(EmptyHandler.name)
    onClose()
  }

  const ColumnSelector = useMemo(() => colummHandler[handlerName], [handlerName])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>Import</DialogTitle>
      <DialogContent>
        <Box sx={{ minHeight: 300 }}>
          <Dropzone onChange={files => importFile(files?.[0])} />
          <ColumnSelector data={rawData} onSelectAll={onSelect} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button disabled={!canAdd} onClick={handleAdd} variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type ManualAddressDialogProps = {
  open: boolean
  onClose: () => void
  selectedProperty: SelectedProperty
}

const ManualAddressDialog: React.FC<ManualAddressDialogProps> = ({ open, onClose, selectedProperty }) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext()
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)
  const countryList = useCountryList()

  const initGeometry = useMemo(() => {
    const address: Address | null = getValues(`properties.${selectedProperty.index}.manualAddress`)
    if (address) {
      return address.geometry
    }
  }, [selectedProperty, getValues])

  const onMapSubmit = (geometry: GeoJSON.FeatureCollection) => {
    const manualAddress: Address | null = getValues(`properties.${selectedProperty.index}.manualAddress`)
    if (!manualAddress) {
      console.error('Invalid manual address. Manual address is null or undefined.')
      return
    }
    setValue(`properties.${selectedProperty.index}.manualAddress.geometry`, geometry)
    let isValid = true
    for (const key of Object.keys(manualAddress)) {
      try {
        schema.validateSyncAt(
          `properties.${selectedProperty.index}.manualAddress.${key}`,
          { properties: getValues(`properties`) },
          { context: { manualAddressRequired: true } },
        )
      } catch (error: any) {
        setError(error?.path, {
          type: 'required',
          message: `${error?.message}`,
        })
        isValid = false
      }
    }
    if (!isValid) {
      console.error('Invalid manual address. Validation failed.')
      enqueueSnackbar('One or more fields are invalid', { variant: 'error' })
      return
    }

    setValue(
      `properties.${selectedProperty.index}.address`,
      `${manualAddress.street}, ${manualAddress.town}, ${manualAddress.postcode}, ${manualAddress.country}`,
      { shouldValidate: true },
    )
    setValue(`properties.${selectedProperty.index}.dpaSelection`, [])
    setValue(`properties.${selectedProperty.index}.dpa`, null)
    onClose()
  }
  const onMapCancel = () => {
    setValue(`properties.${selectedProperty.index}.manualAddress`, selectedProperty.prevAddress)
    clearErrors([
      `properties.${selectedProperty.index}.manualAddress.street`,
      `properties.${selectedProperty.index}.manualAddress.town`,
      `properties.${selectedProperty.index}.manualAddress.postcode`,
      `properties.${selectedProperty.index}.manualAddress.country`,
    ])

    onClose()
  }

  const searchMap = (): string => {
    // combine street, town, postcode and country
    const manualAddress: Address | null = getValues(`properties.${selectedProperty.index}.manualAddress`)
    if (!manualAddress) {
      console.error('Invalid manual address. Manual address is null or undefined.')
      return ''
    }
    let searchValues: any[] = []
    for (const key of Object.keys(manualAddress)) {
      try {
        schema.validateSyncAt(
          `properties.${selectedProperty.index}.manualAddress.${key}`,
          { properties: getValues(`properties`) },
          { context: { manualAddressRequired: true } },
        )
        searchValues.push(manualAddress[key])
      } catch (error: any) {
        continue
      }
    }
    return searchValues.join(',').replace(/,/g, ' ')
  }
  return (
    <>
      <Dialog open={open} onClose={onMapCancel} maxWidth="md" fullWidth>
        <DialogContent>
          <Typography variant="h6" component="h2">
            Address
          </Typography>
          <Box sx={{ width: '100%', marginBottom: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography>Street</Typography>
                <TextField
                  fullWidth
                  {...register(`properties.${selectedProperty.index}.manualAddress.street`)}
                  error={Boolean(errors?.properties?.[selectedProperty.index]?.manualAddress?.street)}
                  helperText={errors?.properties?.[selectedProperty.index]?.manualAddress?.street?.message}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>Town</Typography>
                <TextField
                  {...register(`properties.${selectedProperty.index}.manualAddress.town`)}
                  error={Boolean(errors?.properties?.[selectedProperty.index]?.manualAddress?.town)}
                  helperText={errors?.properties?.[selectedProperty.index]?.manualAddress?.town?.message}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography>Postcode</Typography>
                <TextField
                  {...register(`properties.${selectedProperty.index}.manualAddress.postcode`)}
                  error={Boolean(errors?.properties?.[selectedProperty.index]?.manualAddress?.postcode)}
                  helperText={errors?.properties?.[selectedProperty.index]?.manualAddress?.postcode?.message}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography>Country</Typography>
                <Autocomplete
                  options={countryList}
                  autoHighlight
                  renderInput={params => (
                    <TextField
                      {...params}
                      {...register(`properties.${selectedProperty.index}.manualAddress.country`)}
                      autoComplete="off"
                      error={Boolean(errors?.properties?.[selectedProperty.index]?.manualAddress?.country)}
                      helperText={errors?.properties?.[selectedProperty.index]?.manualAddress?.country?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <DrawMap
            title="Draw Property Boundary"
            onSubmit={geometry => onMapSubmit(geometry)}
            onCancel={onMapCancel}
            onReset={() => setIsResetModalOpen(true)}
            initGeometry={initGeometry}
            edit={true}
            onError={error => {
              enqueueSnackbar(error, { variant: 'error' })
            }}
            getSearchValue={searchMap}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={isResetModalOpen} onClose={() => setIsResetModalOpen(false)}>
        <DialogTitle>Confirm Reset Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will clear the street, town, postcode, and country fields as well as any geometry drawn on the
            map. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsResetModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setValue(`properties.${selectedProperty.index}.address`, '', { shouldValidate: true })
              setValue(`properties.${selectedProperty.index}.manualAddress`, null)
              onClose()
              setIsResetModalOpen(false)
            }}
            color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const AddressLabel = ({ index, getValues, setValue }) => {
  const address: Address | null = getValues(`properties.${index}.manualAddress`)
  if (address) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Entered Manually</span>
      </div>
    )
  } else {
    return <></>
  }
}

function getFullAddress(address: any | null): string | null {
  if (!address) return null
  return address.text
}

export default AddProperties
