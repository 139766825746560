import React from 'react'
import { useParams } from 'react-router-dom'
import Page from '../components/Page'
import IncidentsList from '../components/riskReport/IncidentsList'

const PropertyIncidents = () => {
  const { id: propertyId } = useParams()
  return (
    <Page title="Incidents" size="lg">
      <IncidentsList propertyId={propertyId} />
    </Page>
  )
}

export default PropertyIncidents
