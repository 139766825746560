import { Stack, Tab, Tabs } from '@mui/material'
import { useCallback, useState } from 'react'
import PropertyList from './PropertyList'
import Page from '../components/Page'
import UsersList from './ListUsers'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined'

const Organisation = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const [tab, setTab] = useState<'users' | 'properties'>(history.location.pathname.split('/')?.[2] ?? 'properties')

  const onChange = useCallback(tab => {
    setTab(tab)
    history.push(`${match.path}/${tab}`)
  }, [])

  // Redirect to the default indicator if the path is exact
  if (match.isExact) {
    history.push(`${match.path}/properties`)
  }

  return (
    <Page title={'Organisation'} size="xl">
      <Tabs value={tab} onChange={(_, newValue) => onChange(newValue)} aria-label="organisation navigation">
        <Tab
          value="properties"
          label={
            <Stack direction="row" alignItems="center">
              <DomainOutlinedIcon sx={{ mr: 1 }} /> Properties
            </Stack>
          }
        />
        <Tab
          value="users"
          label={
            <Stack direction="row" alignItems="center">
              <PeopleOutlineOutlinedIcon sx={{ mr: 1 }} /> Users
            </Stack>
          }
        />
      </Tabs>
      <Switch>
        <Route path={`${match.path}/properties`}>
          <PropertyList />
        </Route>
        <Route path={`${match.path}/users`}>
          <UsersList />
        </Route>
      </Switch>
    </Page>
  )
}

export default Organisation
