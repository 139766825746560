import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import { usePortfolioSubsidenceProjections } from '../../hooks/analytics'
import { useCallback } from 'react'

const scoreToColor = {
  '-1': '#dadada',
  '1': '#f5aa84',
  '2': '#e74b0f',
  '3': '#a83318',
}

const rowBorderRadius = 1

const Subsidence = () => {
  const { data: projections, isLoading } = usePortfolioSubsidenceProjections()

  const StatisticRow = useCallback(
    ({ title, year, score }) => (
      <Stack
        direction="row-reverse"
        justifyContent="space-between"
        sx={{ px: 2, py: 1, borderRadius: rowBorderRadius, bgcolor: scoreToColor[score] }}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="h5">{projections[year][score]}</Typography>
      </Stack>
    ),
    [projections],
  )

  return (
    <>
      {/* <Stack direction="row" justifyContent="space-between" alignItems="center" minWidth={100} mb={3}>
        <Typography variant="h4">Subsidence</Typography>
      </Stack> */}
      {projections != null && (
        <>
          {projections?.no_data > 0 && (
            <Box pb={2}>
              <Typography variant="subtitle1">
                No subsidence data is available for <strong>{projections.no_data}</strong> properties
              </Typography>
            </Box>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Card>
                <Typography variant="h5">
                  <strong>2030</strong>
                </Typography>
                <Stack gap={1} justifyContent="center" pt={2}>
                  <StatisticRow title="Unavailable" year="2030" score="-1" />
                  <StatisticRow title="Improbable" year="2030" score="1" />
                  <StatisticRow title="Possible" year="2030" score="2" />
                  <StatisticRow title="Probable" year="2030" score="3" />
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Typography variant="h5">
                  <strong>2070</strong>
                </Typography>
                <Stack gap={1} justifyContent="center" pt={2}>
                  <StatisticRow title="Unavailable" year="2070" score="-1" />
                  <StatisticRow title="Improbable" year="2070" score="1" />
                  <StatisticRow title="Possible" year="2070" score="2" />
                  <StatisticRow title="Probable" year="2070" score="3" />
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default Subsidence
