import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Menu,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import { useClimateMitigations, useClimatePropertyData } from '../../../hooks/analytics'
import { useMemo, useState } from 'react'
import { EditNotifications, HelpRounded, InfoOutlined } from '@mui/icons-material'
import {
  scoreColor as floodScoreColor,
  getRating as getFloodRating,
} from '../../climateAnalytics/portfolio/flood/utils'
import {
  scoreColor as windScoreColor,
  beaufortToCategory,
  categoryToLabel,
} from '../../climateAnalytics/portfolio/extremeWind/utils'
import {
  scoreToColor as heatScoreToColor,
  scoreToCategory as heatScoreToCategory,
} from '../../climateAnalytics/portfolio/heatStress/utils'

function getFloodScore(data: any, term: 'short' | 'mid' | 'long'): number | null {
  if (!data) {
    return null
  }
  const termIdx = data?.metadata?.[`${term}_term_idx`] ?? null
  if (termIdx == null) {
    console.error(`No ${term} term index found in flood data`)
    return null
  }
  console.log(data)
  return data.scores?.[termIdx] ?? null
}

function getWindScore(data: any, term: 'short' | 'mid' | 'long'): number | null {
  if (!data) {
    return null
  }
  const termIdx = data?.wg?.metadata?.[`${term}_term_idx`] ?? null
  if (termIdx == null) {
    console.error(`No ${term} term index found in wind gust data`)
    return null
  }
  return data?.wg?.main_data?.wg?.[termIdx] ?? null
}

function getHeatScore(data: any, term: 'short' | 'mid' | 'long'): number | null {
  if (!data) {
    return null
  }
  const termIdx = data?.hshd?.metadata?.[`${term}_term_idx`] ?? null
  if (termIdx == null) {
    console.error(`No ${term} term index found in wind gust data`)
    return null
  }
  return data?.hshd?.main_data?.['hot_days']?.[termIdx] ?? null
}

function getDryDays(data: any, term: 'short' | 'mid' | 'long'): number | null {
  if (!data) {
    return null
  }
  const termIdx = data?.dd?.metadata?.[`${term}_term_idx`] ?? null
  if (termIdx == null) {
    console.error(`No ${term} term index found in drought data`)
    return null
  }
  return data?.dd?.main_data?.dd?.[termIdx] ?? null
}

const FloodScore = ({ value }: { value: number | null }) => {
  const color = value != null ? floodScoreColor?.[value] ?? null : null
  const normalValue = ((value ?? 0) / 5) * 100
  return <ScoreLinearProgress value={normalValue} color={color} />
}

const WindScore = ({ value }: { value: number | null }) => {
  const color = value != null ? windScoreColor?.[value] ?? null : null
  const normalValue = ((value ?? 0) / 5) * 100
  return <ScoreLinearProgress value={normalValue} color={color} />
}

const HeatScore = ({ value }: { value: number | null }) => {
  // const color = value != null ? heatScoreToColor(value) : null
  const color = '#f3b59c'
  const normalValue = Math.min(((value ?? 0) / 90) * 100, 100)
  return <ScoreLinearProgress value={normalValue} color={color} />
}

const DryDays = ({ value }: { value: number | null }) => {
  const color = '#d1b8a7'
  const normalValue = ((value ?? 0) / 365) * 100
  return <ScoreLinearProgress value={normalValue} color={color} />
}

const ScoreLinearProgress = ({ value, color }: { value: number; color: string | null }) => {
  if (color == null) {
    color = 'lightgrey'
  }
  return (
    <>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          height: '4px',
          borderRadius: 5,
          backgroundColor: '#f0f0f0',
          '& .MuiLinearProgress-bar': { backgroundColor: color },
        }}
      />
    </>
  )
}

const hazardTooltip = {
  flood:
    'Flood score reflects the comprehensive flood risk from coastal, river, and surface water sources, using high-resolution data and projections to assess future flood hazards under various scenarios and periods.',
  wind: 'Extreme wind score reflects the intensity of wind hazards by averaging the top 30th day of daily maximum wind gusts for each year over a 20-year period, and then classifying the result according to the Beaufort scale.',
  'heat-stress':
    'Heat score is calculated by averaging the maximum daily heat index values during the hottest month each year over a 20-year period, which incorporates temperature and humidity to assess perceived heat.',
  drought:
    'Dry days show the number of days per year with less than 1mm of precipitation, highlighting the frequency of dry conditions.',
}

const PropertyClimateHazardsCard = ({ propertyId }) => {
  const { data: heatStress, isLoading: isHeatStressLoading } = useClimatePropertyData(propertyId, 'heat-stress')
  const { data: windGust, isLoading: isWindGustLoading } = useClimatePropertyData(propertyId, 'wind')
  const { data: floodScores, isLoading: isFloodScoreLoading } = useClimatePropertyData(propertyId, 'flood-scores')
  const { data: drought, isLoading: isDroughtLoading } = useClimatePropertyData(propertyId, 'drought')
  const { data: mitigationCategories } = useClimateMitigations(propertyId)
  const [emissions, setEmissions] = useState('rcp85')
  const [term, setTerm] = useState<'long' | 'mid' | 'short'>('long')

  const countsByCategory: Record<string, number> = useMemo(() => {
    const counts: Record<string, number> = {}
    if (mitigationCategories && Array.isArray(mitigationCategories)) {
      for (const category of mitigationCategories) {
        for (const mitigation of category?.mitigations ?? []) {
          if (mitigation?.added) {
            counts[category.name] = (counts[category.name] ?? 0) + 1
          }
        }
      }
    }
    return counts
  }, [mitigationCategories])

  const mitigationCount: number = Object.values(countsByCategory).reduce((acc: number, count: number) => acc + count, 0)

  const heatScore = getHeatScore(heatStress?.indicators?.[emissions], term)
  const windScore = getWindScore(windGust?.indicators?.[emissions], term)
  const floodScore = getFloodScore(floodScores?.[emissions]?.[propertyId], term)
  const dryDays = getDryDays(drought?.indicators?.[emissions], term)

  return (
    <Card>
      <CardHeader
        title={
          <>
            Climate Risk Exposure{' '}
            {mitigationCount > 0 ? (
              <Chip size="small" variant="outlined" label={`${mitigationCount} mitigation(s)`} color="info" />
            ) : (
              <></>
            )}
          </>
        }
      />
      <CardContent
        sx={{
          paddingX: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}>
        <Stack minWidth={200} spacing={2}>
          {/* Hazards w/ Scores */}
          <List>
            <ListItem>
              <Stack flexGrow={1} direction="row" justifyContent="space-between" alignItems="center">
                <Tooltip title={hazardTooltip['flood']} arrow>
                  <Stack direction="row" spacing={0.5}>
                    <Badge color="info" variant="standard" badgeContent={countsByCategory['flood'] ?? 0}>
                      <Typography>Flood</Typography>
                    </Badge>
                    <InfoOutlined sx={{ width: 16 }} color="disabled" />
                  </Stack>
                </Tooltip>
                <Box
                  sx={{
                    width: 200,
                  }}>
                  {isFloodScoreLoading ? (
                    <Skeleton variant="rounded" height={8} />
                  ) : (
                    <>
                      <Typography variant="body1">
                        {floodScore ? `${getFloodRating(floodScore as any)} (${floodScore})` : 'N/A'}
                      </Typography>
                      <FloodScore value={floodScore} />
                    </>
                  )}
                </Box>
              </Stack>
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <Stack flexGrow={1} direction="row" justifyContent="space-between" alignItems="center">
                <Tooltip title={hazardTooltip['wind']} arrow>
                  <Stack direction="row" spacing={0.5}>
                    <Badge color="info" variant="standard" badgeContent={countsByCategory['wind'] ?? 0}>
                      <Typography>Extreme Wind</Typography>
                    </Badge>
                    <InfoOutlined sx={{ width: 16 }} color="disabled" />
                  </Stack>
                </Tooltip>
                <Box
                  sx={{
                    width: 200,
                  }}>
                  {isWindGustLoading ? (
                    <Skeleton variant="rounded" height={8} />
                  ) : (
                    <>
                      <Typography variant="body1">
                        {windScore ? `${categoryToLabel(windScore)} (${windScore})` : 'N/A'}
                      </Typography>
                      <WindScore value={windScore} />
                    </>
                  )}
                </Box>
              </Stack>
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <Stack flexGrow={1} direction="row" justifyContent="space-between" alignItems="center">
                <Tooltip title={hazardTooltip['heat-stress']} arrow>
                  <Stack direction="row" spacing={0.5}>
                    <Badge color="info" variant="standard" badgeContent={countsByCategory['heat-stress'] ?? 0}>
                      <Typography>Heat Stress</Typography>
                    </Badge>
                    <InfoOutlined sx={{ width: 16 }} color="disabled" />
                  </Stack>
                </Tooltip>
                <Box
                  sx={{
                    width: 200,
                  }}>
                  {isHeatStressLoading ? (
                    <Skeleton variant="rounded" height={8} />
                  ) : (
                    <>
                      <Typography variant="body1">
                        {heatScore ? `${heatScore.toFixed(0)} local hot days/year` : 'N/A'}
                      </Typography>
                      <HeatScore value={heatScore} />
                    </>
                  )}
                </Box>
              </Stack>
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
              <Stack flexGrow={1} direction="row" justifyContent="space-between" alignItems="center">
                <Tooltip title={hazardTooltip['drought']} arrow>
                  <Stack direction="row" spacing={0.5}>
                    <Badge color="info" variant="standard" badgeContent={countsByCategory['drought'] ?? 0}>
                      <Typography>Drought</Typography>
                    </Badge>
                    <InfoOutlined sx={{ width: 16 }} color="disabled" />
                  </Stack>
                </Tooltip>
                <Box
                  sx={{
                    width: 200,
                  }}>
                  {isDroughtLoading ? (
                    <Skeleton variant="rounded" height={8} />
                  ) : (
                    <>
                      <Typography variant="body1">{dryDays ? `${dryDays.toFixed(0)} dry days/year` : 'N/A'}</Typography>
                      <DryDays value={dryDays} />
                    </>
                  )}
                </Box>
              </Stack>
            </ListItem>
            <Divider sx={{ my: 1 }} />
          </List>
          {/* <Divider /> */}
          {/* Options */}
          <Stack direction="row" alignItems="center" rowGap={2} columnGap={2} flexWrap="wrap">
            <Box>
              <Typography variant="subtitle2">Climate Scenario</Typography>
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  label="Optimistic"
                  onClick={() => setEmissions('rcp26')}
                  color={emissions === 'rcp26' ? 'primary' : 'default'}
                />
                <Chip
                  size="small"
                  label="Moderate"
                  onClick={() => setEmissions('rcp45')}
                  color={emissions === 'rcp45' ? 'primary' : 'default'}
                />
                <Chip
                  size="small"
                  label="Pessimistic"
                  onClick={() => setEmissions('rcp85')}
                  color={emissions === 'rcp85' ? 'primary' : 'default'}
                />
              </Stack>
            </Box>

            <Box>
              <Typography variant="subtitle2">Time Horizon</Typography>
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  label="Near"
                  onClick={() => setTerm('short')}
                  color={term === 'short' ? 'primary' : 'default'}
                />
                <Chip
                  size="small"
                  label="Mid"
                  onClick={() => setTerm('mid')}
                  color={term === 'mid' ? 'primary' : 'default'}
                />
                <Chip
                  size="small"
                  label="Long"
                  onClick={() => setTerm('long')}
                  color={term === 'long' ? 'primary' : 'default'}
                />
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default PropertyClimateHazardsCard
