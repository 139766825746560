import React, { useState, useMemo, useEffect } from 'react'
import { Layer, Source } from 'react-map-gl'

function vectorSource(id) {
  return {
    type: 'vector',
    url: `mapbox://balkerne.${id}`,
  }
}

function directionSymbolLayer(sourceLayer) {
  return {
    id: sourceLayer,
    type: 'symbol',
    'source-layer': sourceLayer,
    layout: {
      'icon-image': 'arrow-right-white2',
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-rotate': [
        'match',
        ['get', 'FLOWDIR'],
        'N',
        -90,
        'NE',
        -45,
        'E',
        0,
        'SE',
        45,
        'S',
        90,
        'SW',
        135,
        'W',
        180,
        'NW',
        225,
        0,
      ],
      'icon-size': 1.5,
    },
  }
}
function layer(
  sourceLayer,
  color,
  type = 'fill',
  paintOptions = {
    'fill-opacity': 0.9,
    'fill-outline-color': '#999999',
  },
) {
  return {
    id: sourceLayer,
    type: type,
    'source-layer': sourceLayer,
    paint: {
      'fill-color': color,
      ...paintOptions,
    },
  }
}

export const color = {
  undetermined: '#d1cbd1',
  low: '#cca9c9',
  medium: '#b773b7',
  high: '#a13ca5',
  depth: [
    'match',
    ['get', 'DEPTH'],
    '0.00 - 0.15',
    '#5ad0e2',
    '0.15 - 0.30',
    '#54b4d0',
    '0.30 - 0.60',
    '#4f98bf',
    '0.60 - 0.90',
    '#497cad',
    '0.90 - 1.20',
    '#4568a0',
    '> 1.20',
    '#3d458a',
    '#FFFFFF',
  ],
  velocity: [
    'match',
    ['get', 'VELOCITY'],
    '0.00 - 0.25',
    '#e1c8bb',
    '0.25 - 0.50',
    '#dda99a',
    '0.50 - 1.00',
    '#d88877',
    '1.00 - 2.00',
    '#d36654',
    '> 2.00',
    '#ce4732',
    '#FFFFFF',
  ],
  riverSea: {
    low: '#8625cb',
    medium: '#c29e00',
    high: '#a10202',
  },
  historicalFlood: {
    low: '#8625cb',
    medium: '#c29e00',
    high: '#a10202',
  },
  protectedNature: 'green',
}
const riverSeaSource = vectorSource('uk-rofrs-2023')
const riverSea = [
  layer(
    'england-rofrs',
    [
      'match',
      ['get', 'prob_4band'],
      'Very Low',
      color.riverSea.low,
      'Low',
      color.riverSea.low,
      'Medium',
      color.riverSea.medium,
      color.riverSea.high,
    ],
    'fill',
    {
      'fill-opacity': 0.5,
      'fill-outline-color': '#07062d',
    },
  ),
  layer(
    'wales-rivers',
    [
      'match',
      ['get', 'risk'],
      'Very Low',
      color.riverSea.low,
      'Low',
      color.riverSea.low,
      'Medium',
      color.riverSea.medium,
      color.riverSea.high,
    ],
    'fill',
    {
      'fill-opacity': 0.5,
      'fill-outline-color': '#07062d',
    },
  ),
  layer(
    'wales-sea',
    [
      'match',
      ['get', 'risk'],
      'Very Low',
      color.riverSea.low,
      'Low',
      color.riverSea.low,
      'Medium',
      color.riverSea.medium,
      color.riverSea.high,
    ],
    'fill',
    {
      'fill-opacity': 0.5,
      'fill-outline-color': '#07062d',
    },
  ),
]
const historicalFloodSource = vectorSource('uk-historic-flood-map-2023-04')
const historicalFlood = [
  layer('england-historic-flood-map', color.historicalFlood.low, 'fill', {
    'fill-opacity': 0.5,
    'fill-outline-color': '#07062d',
  }),
  layer('wales-historic-flood-map', color.historicalFlood.low, 'fill', {
    'fill-opacity': 0.5,
    'fill-outline-color': '#07062d',
  }),
]
const extentSources = {
  'rofsw-extent-2022-10': vectorSource('rofsw-extent-2022-10'),
  'wales-surface-water-2023-04-22': vectorSource('wales-surface-water-2023-04-22'),
  'florida-flood': vectorSource('florida-flood'),
}
const extent = {
  'rofsw-extent-2022-10': [layer('rofsw-3', color.low), layer('rofsw-2', color.medium), layer('rofsw-1', color.high)],
  'wales-surface-water-2023-04-22': [
    layer('wales-surface-water', [
      'match',
      ['get', 'risk'],
      'Very Low',
      color.low,
      'Low',
      color.low,
      'Medium',
      color.medium,
      color.high,
    ]),
  ],
  'florida-flood': [
    layer('florida-flood-risk--1', color.undetermined),
    layer('florida-flood-risk-2', color.low),
    layer('florida-flood-risk-3', color.medium),
    layer('florida-flood-risk-4', color.high),
  ],
}
const depthHighSource = vectorSource('rofsw-depth-1-2022')
const depthHigh = [
  layer('rofsw-1-depth-aa', color.depth),
  layer('rofsw-1-depth-ab', color.depth),
  layer('rofsw-1-depth-ac', color.depth),
]
const depthMediumSource = vectorSource('rofsw-depth-2-2022')
const depthMedium = [
  layer('rofsw-2-depth-aa', color.depth),
  layer('rofsw-2-depth-ab', color.depth),
  layer('rofsw-2-depth-ac', color.depth),
  layer('rofsw-2-depth-ad', color.depth),
]
const depthLowSource = vectorSource('rofsw-depth-3-2022')
const depthLow = [
  layer('rofsw-3-depth-aa', color.depth),
  layer('rofsw-3-depth-ab', color.depth),
  layer('rofsw-3-depth-ac', color.depth),
  layer('rofsw-3-depth-ad', color.depth),
  layer('rofsw-3-depth-ae', color.depth),
  layer('rofsw-3-depth-af', color.depth),
  layer('rofsw-3-depth-ag', color.depth),
]
const velocityHighSource = vectorSource('rofsw-veloc-1-2022')
const velocityHigh = [
  layer('rofsw-1-veloc-aa', color.velocity),
  layer('rofsw-1-veloc-ab', color.velocity),
  directionSymbolLayer('rofsw-1-direction'),
]
const velocityMediumSource = vectorSource('rofsw-veloc-2-2022')
const velocityMedium = [
  layer('rofsw-2-veloc-aa', color.velocity),
  layer('rofsw-2-veloc-ab', color.velocity),
  layer('rofsw-2-veloc-ac', color.velocity),
  directionSymbolLayer('rofsw-2-direction'),
]
const velocityLowSource = vectorSource('rofsw-veloc-3-2022')
const velocityLow = [
  layer('rofsw-3-veloc-aa', color.velocity),
  layer('rofsw-3-veloc-ab', color.velocity),
  layer('rofsw-3-veloc-ac', color.velocity),
  layer('rofsw-3-veloc-ad', color.velocity),
  layer('rofsw-3-veloc-ae', color.velocity),
  layer('rofsw-3-veloc-af', color.velocity),
  layer('rofsw-3-veloc-ag', color.velocity),
  directionSymbolLayer('rofsw-3-direction'),
]
const protectedNatureSource = vectorSource('uk-protected-nature-Jun2024')
const protectedNature = [
  layer('uk-protected-nature', color.protectedNature, 'fill', {
    'fill-opacity': 0.5,
    'fill-outline-color': '#07062d',
  }),
]
const mapLayers = arr => arr.map(layer => <Layer key={layer.id} {...layer}></Layer>)

const sources = {
  extent: props =>
    Object.keys(extentSources).map(sourceKey => (
      <Source key={sourceKey} {...props} {...extentSources[sourceKey]}>
        {mapLayers(extent[sourceKey])}
      </Source>
    )),
  depthHigh: props => (
    <Source {...props} {...depthHighSource}>
      {mapLayers(depthHigh)}
    </Source>
  ),
  depthMedium: props => (
    <Source {...props} {...depthMediumSource}>
      {mapLayers(depthMedium)}
    </Source>
  ),
  depthLow: props => (
    <Source {...props} {...depthLowSource}>
      {mapLayers(depthLow)}
    </Source>
  ),
  velocityHigh: props => (
    <Source {...props} {...velocityHighSource}>
      {mapLayers(velocityHigh)}
    </Source>
  ),
  velocityMedium: props => (
    <Source {...props} {...velocityMediumSource}>
      {mapLayers(velocityMedium)}
    </Source>
  ),
  velocityLow: props => (
    <Source {...props} {...velocityLowSource}>
      {mapLayers(velocityLow)}
    </Source>
  ),
  riverSea: props => (
    <Source {...props} {...riverSeaSource}>
      {mapLayers(riverSea)}
    </Source>
  ),
  historicalFlood: props => (
    <Source {...props} {...historicalFloodSource}>
      {mapLayers(historicalFlood)}
    </Source>
  ),
  protectedNature: props => (
    <Source {...props} {...protectedNatureSource}>
      {mapLayers(protectedNature)}
    </Source>
  ),
}

export const SurfaceWaterType = Object.freeze({
  EXTENT: 'extent',
  DEPTH_HIGH: 'depthHigh',
  DEPTH_MEDIUM: 'depthMedium',
  DEPTH_LOW: 'depthLow',
  VELOCITY_HIGH: 'velocityHigh',
  VELOCITY_MEDIUM: 'velocityMedium',
  VELOCITY_LOW: 'velocityLow',
})

export const useSurfaceWater = defaultType => {
  const [type, setType] = useState(defaultType ?? 'extent')
  const Source = sources[type]
  return { Source, setType, type }
}

export const useRiverSea = () => {
  const Source = sources['riverSea']
  return { Source }
}

export const useHistoricalFlood = () => {
  const Source = sources['historicalFlood']
  return { Source }
}

export const useProtectedNature = () => {
  const Source = sources['protectedNature']
  return { Source }
}
