import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormInstances } from '../../../hooks/forms'
import FormatDate from '../FormatDate'
import CardLoader from './CardLoader'
import ViewForm from '../ViewForm'
import { StickyNote2Rounded } from '@mui/icons-material'
import { Box, IconButton, Link, Stack, Typography } from '@mui/material'

const SavedDrafts = ({ property_id: propertyId }) => {
  const history = useHistory()
  const { data: drafts } = useFormInstances({
    property_id: propertyId,
    form_state: 'INPROGRESS',
  })

  const goToForm = form => {
    ViewForm(form, history, propertyId, null)
  }

  return (
    <>
      {drafts == null ? (
        <CardLoader />
      ) : (
        <div style={{ display: 'block', marginBottom: 15 }}>
          {drafts.length > 0 ? (
            drafts.map((draft, index) => (
              <Stack key={index} direction="row" spacing={2} pl={2} pt={2} alignItems="center">
                <IconButton onClick={() => goToForm(draft)}>
                  <StickyNote2Rounded />
                </IconButton>
                <Box>
                  <Typography variant="caption">{draft.template.template.type.title}</Typography>
                  <Stack direction="row">
                    <span>
                      <Link
                        display="inline"
                        color="secondary"
                        variant="body2"
                        component="button"
                        onClick={() => goToForm(draft)}
                        underline="none"
                        sx={{ pr: 0.5, color: '#0072e5' }}>
                        {draft.title}
                      </Link>
                      <Typography sx={{ display: 'inline' }} variant="body2">
                        {`draft by ${draft.assignee.full_name} at ${new Date(draft.updated_at).toLocaleString()}`}
                      </Typography>
                    </span>
                  </Stack>
                </Box>
              </Stack>
            ))
          ) : (
            <Stack direction="row" spacing={2} pl={2} pt={2}>
              <StickyNote2Rounded />
              <Typography>There are no saved drafts</Typography>
            </Stack>
          )}
        </div>
      )}
    </>
  )
}

export default SavedDrafts
