import axios from 'axios'
import api from 'balkerne-core/api'

export const geocode = (address, region = 'United Kingdom') => {
  return api
    .get(`locations/geocode?address=${address}&region=${region}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.error(error)
      return []
    })
}
