import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import './Riskrating.css'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { fromValueToColor } from '../riskReport/displaycrimerisk'


function CircularProgressWithLabel(props: any) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', marginBottom: '10px' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant="caption" component="div" color="text.secondary">
          {`${props.rating_text}`}
        </Typography>
      </Box>
    </Box>
  )
}


type RiskratingProps = {
  title: string
  value: number
  rating_text: number | string
  risk_rating?: number
  max_score?: number
}
export function Riskrating({ title, value, rating_text }: RiskratingProps) {
  let color = '#000000'
  color = fromValueToColor(value)
  return (
    <div className="riskratingitem">
      <p className="rating_text">{title}</p>
      <CircularProgressWithLabel
        style={{ color: color }}
        variant="determinate"
        size="5rem"
        value={Math.round(value)}
        rating_text={rating_text}
      />
    </div>
  )
}
