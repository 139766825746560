import { formatPhoneNumber } from 'balkerne-fn/phone'
import FormTextField from './FormTextField'
import { Controller } from 'react-hook-form'

const FormPhoneInput = ({ control, name, errors, ...props }) => {
  const handleInputChange = onChange => event => {
    const { value } = event.target
    const onlyDigitsAndPlus = value.replace(/[^0-9+]/g, '')
    if (onlyDigitsAndPlus.length === 0) {
      onChange(onlyDigitsAndPlus)
    } else {
      const withPlus = onlyDigitsAndPlus.startsWith('+') ? onlyDigitsAndPlus : '+' + onlyDigitsAndPlus
      const formatted = formatPhoneNumber(withPlus)
      onChange(formatted)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormTextField
          {...field}
          value={field.value}
          errors={errors}
          onChange={handleInputChange(field.onChange)}
          {...props}
        />
      )}
    />
  )
}

export default FormPhoneInput
