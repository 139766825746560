export function ago(date: string): string {
  const MS_PER_HOUR = 1000 * 60 * 60
  const MS_PER_DAY = MS_PER_HOUR * 24
  const MS_PER_MONTH = MS_PER_DAY * 30
  const MS_PER_YEAR = MS_PER_DAY * 365

  const targetDate = new Date(date)
  const currentDate = new Date()
  const elapsedMs = currentDate.getTime() - targetDate.getTime()

  if (elapsedMs < MS_PER_HOUR) {
    return '<1 hour ago'
  } else if (elapsedMs < MS_PER_DAY) {
    const hours = Math.floor(elapsedMs / MS_PER_HOUR)
    return formatTime(hours, 'hour')
  } else if (elapsedMs < MS_PER_MONTH) {
    const days = Math.floor(elapsedMs / MS_PER_DAY)
    return formatTime(days, 'day')
  } else if (elapsedMs < MS_PER_YEAR) {
    const months = Math.floor(elapsedMs / MS_PER_MONTH)
    return formatTime(months, 'month')
  } else {
    const years = Math.floor(elapsedMs / MS_PER_YEAR)
    return formatTime(years, 'year')
  }
}

function formatTime(num: number, timeUnit: string): string {
  const roundedNum = Math.floor(num)
  const pluralSuffix = roundedNum === 1 ? '' : 's'
  return `${roundedNum} ${timeUnit}${pluralSuffix} ago`
}
