import { Box, Stack, Typography, useTheme } from '@mui/material'
import { Risk } from 'balkerne-core/risks'

export function RiskLevelTag({ value }) {
  const theme = useTheme()
  let tagText = 'Negligible'
  let color = '#000000'

  if (value <= 0 || value == null) {
    return <Box></Box>
  }

  switch (value) {
    case Risk.HIGH:
      tagText = 'High'
      color = theme.palette.risk.high
      break
    case Risk.MEDIUM:
      tagText = 'Medium'
      color = theme.palette.risk.medium
      break
    case Risk.LOW:
      tagText = 'Low'
      color = theme.palette.risk.low
      break
    default:
      tagText = 'Negligible'
      color = theme.palette.risk.negligible
  }
  return (
    <Stack
      mx="auto"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'relative',
        width: 100,
        height: 30,
      }}>
      <Box
        p={1}
        sx={{
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          position: 'absolute',
          zIndex: 0,
          background: color,
          opacity: 0.1,
          borderRadius: 1,
        }}></Box>
      <Typography
        variant="body2"
        sx={{
          zIndex: 1,
          fontWeight: 'bold',
          textAlign: 'center',
          color: color,
        }}>
        {tagText}
      </Typography>
    </Stack>
  )
}
