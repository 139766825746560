import { Box } from '@mui/material'
import { Form } from '@rjsf/mui'
import { FieldProps, RegistryFieldsType, RegistryWidgetsType } from '@rjsf/utils'
import FileWidget from '../widgets/FileWidget'
import validator from '@rjsf/validator-ajv8'

type TemplateVersion = {
  schema: any
  uischema: any
}
const TemplatePreview = ({ template }: { template: TemplateVersion }) => {
  const CustomTitleField = ({ title, required }: FieldProps) => {
    // This is a section title
    const legend = required ? title + '*' : title
    if (title === JSON.parse(template.schema).title) {
      return <></>
    } else {
      return (
        <div className="my-1">
          <h5 style={{ fontSize: 1.25 + 'rem' }}>{legend}</h5>
        </div>
      )
    }
  }
  const CustomDescriptionField = ({ description }: FieldProps) => {
    if (description !== undefined) {
      if (description === JSON.parse(template.schema).description) {
        return <></>
      } else {
        return (
          <>
            <div style={{ marginBottom: 7, fontSize: 15 }}>{description}</div>
          </>
        )
      }
    } else {
      return <></>
    }
  }

  const fields: RegistryFieldsType = {
    TitleField: CustomTitleField,
    DescriptionField: CustomDescriptionField,
  }

  const widgets: RegistryWidgetsType = {
    FileWidget: props => <FileWidget {...props} />,
    // DateTimeWidget: DateTimeWidget,
  }
  return (
    <Box>
      <Form
        fields={fields}
        widgets={widgets}
        schema={JSON.parse(template.schema)}
        uiSchema={JSON.parse(template.schema)}
        validator={validator}>
        <button style={{ display: 'none' }} type="submit"></button>
      </Form>
    </Box>
  )
}

export default TemplatePreview
