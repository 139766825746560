import React from 'react'
import { Button, Stack, MenuItem, IconButton, Card } from '@mui/material'
import api from 'balkerne-core/api'
import { useAccessibleRoles } from '../hooks/useRoles'
import { emailAvailable, validateUniqueArray, validateInSequence, validatePhoneNumber } from 'balkerne-core/forms'
import { FormTextField } from '../components/forms/FormTextField'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useFieldArray } from 'react-hook-form'
import { VariantType, useSnackbar } from 'notistack'
import { groupBy } from 'lodash'
import { CloseRounded } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import siteMap from '../siteMap'
import Page from '../components/Page'
import parsePhoneNumberFromString from 'libphonenumber-js'
import FormPhoneInput from '../components/forms/FormPhoneInput'

yup.addMethod(yup.array, 'unique', validateUniqueArray)
yup.addMethod(yup.string, 'sequence', validateInSequence)
yup.addMethod(yup.string, 'phone', validatePhoneNumber)

const schema = yup.object({
  users: yup
    .array(
      yup.object({
        full_name: yup.string().min(3, 'At least 3 characters').required('Name is required'),
        email: yup
          .string()
          .sequence([
            () => yup.string().email('Enter a valid email').required('Email is required'),
            () => yup.string().test('ifExists', 'Email already exists', email => emailAvailable(email)),
          ]),
        role_id: yup.string().required('Role is required'),
        phone: yup.string().phone().optional(),
        position: yup.string().optional(),
      }),
    )
    .unique('Emails must be unique', item => item.email)
    .min(1, 'At least one user is required'),
})

const defaultUser = { full_name: '', email: '', role_id: '', phone: '', position: '' }

const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} size="small">
      <CloseRounded sx={{ color: 'white' }} />
    </IconButton>
  )
}

const CreateUsers = () => {
  const history = useHistory()
  const { data: roles } = useAccessibleRoles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    control,
    register,
    handleSubmit: formSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { users: [{ ...defaultUser }] },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  })

  const handleSubmit = data => {
    const preparedUsers = data.users.map(user => {
      if (user.phone) {
        const phoneNumber = parsePhoneNumberFromString(user.phone)
        if (phoneNumber) {
          return { ...user, phone: phoneNumber.number }
        }
      }
      return user
    })
    api
      .post('/users', preparedUsers)
      .then(res => {
        if (res.data.errors?.length > 0) {
          const groupedErrors = groupBy(res.data.errors, err => err.level)
          for (const [level, errors] of Object.entries(groupedErrors)) {
            const messages = errors.map(err => `${err.email}: ${err.reason}`).join('\n')
            enqueueSnackbar(messages, {
              variant: level as VariantType,
              persist: true,
              style: {
                whiteSpace: 'pre-line',
              },
              action: key => <SnackbarCloseButton snackbarKey={key} />,
            })
          }
        }
        if (res.data.success?.length > 0) {
          const userIds = res.data.success.map(user => user.id)
          enqueueSnackbar(`${res.data.success.length} users created successfully`, {
            variant: 'success',
            persist: true,
            action: key => (
              <Stack alignItems="center" justifyContent="center" direction="row" gap={1}>
                <Button
                  color="success"
                  variant="contained"
                  size="small"
                  onClick={() => history.push(siteMap.AssignUsers.getPath(userIds))}>
                  Map to properties
                </Button>
                <SnackbarCloseButton snackbarKey={key} />
              </Stack>
            ),
          })
          for (const user of res.data.success) {
            const index = preparedUsers.findIndex(row => row.email === user.email)
            if (index > -1) {
              remove(index)
            }
          }
        }
      })
      .catch(err => {
        enqueueSnackbar('Error creating users', { variant: 'error' })
        console.error(err)
      })
  }

  return (
    <Page
      title="Create Users"
      back
      size="lg"
      PageActions={() => (
        <Stack alignItems="center" direction="row" gap={2}>
          <Button variant="outlined" color="primary" onClick={() => append({ ...defaultUser })}>
            Add User
          </Button>
          <Button variant="contained" color="primary" onClick={formSubmit(handleSubmit)}>
            Submit
          </Button>
        </Stack>
      )}>
      <Card elevation={0}>
        <Stack direction="column" mt={1} p={1}>
          {Array.isArray(fields) ? (
            fields.map((field: any, index) => (
              <Stack key={field.id} direction="row" spacing={2} alignItems="start">
                <FormTextField {...register(`users.${index}.full_name`)} label="Name" errors={errors} />
                <FormTextField {...register(`users.${index}.email`)} label="Email" errors={errors} />
                <FormTextField
                  {...register(`users.${index}.role_id`)}
                  select
                  errors={errors}
                  label="Role"
                  defaultValue={field?.role?.id ?? ''}
                  sx={{ width: 223 }}>
                  {roles?.map(role => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </FormTextField>
                <FormPhoneInput control={control} name={`users.${index}.phone`} label="Phone" errors={errors} />
                <FormTextField {...register(`users.${index}.position`)} label="Position" errors={errors} />
                <Button variant="outlined" onClick={() => remove(index)}>
                  Remove
                </Button>
              </Stack>
            ))
          ) : (
            <></>
          )}
        </Stack>
      </Card>
    </Page>
  )
}

export default CreateUsers
