import PortfolioForms from 'balkerne-components/forms/PortfolioForms'
import PropertyForms from 'balkerne-components/forms/PropertyForms'
import { useLocation } from 'react-router-dom'
import Page from '../components/Page'
import { Button, Stack } from '@mui/material'
import { useHistory } from 'react-router-dom'
import siteMap from '../siteMap'

const CompletedForms = () => {
  const location = useLocation()
  const history = useHistory()
  return (
    <Page
      title="Completed Forms"
      size="lg"
      back={location.state?.back}
      PageActions={() => (
        <Stack direction="row" justifyContent="end" gap={1}>
          <Button variant="contained" onClick={() => history.push(siteMap.TemplateManager.path)}>
            Templates
          </Button>
          <Button variant="outlined" onClick={() => history.push(siteMap.CreateTemplate.path)}>
            Create Template
          </Button>
        </Stack>
      )}>
      {location.state?.propertyForms ? <PropertyForms propertyID={location.state.propertyID} /> : <PortfolioForms />}
    </Page>
  )
}

export default CompletedForms
