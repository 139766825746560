import { useMutation, useQuery, useQueryClient } from 'react-query'
import api from 'balkerne-core/api'
import { MutationProps } from '../types/hooks'
import { EditUser } from '../types/organisations'

export const useCurrentUser = (args = {}, opts = {}) => {
  const path = '/users/me'
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery(path, fetch, { ...opts })
  return { ...rest, data: data ? data : null }
}

export const useUsers = (ids: any = undefined) => {
  const path = '/users' + (ids?.length > 0 ? '?' + ids.map(id => `ids=${id}`).join('&') : '')
  const fetch = async () => await api.get(path).then(res => res.data)
  const { data, ...rest } = useQuery(path, fetch)
  return { ...rest, data: data ?? [] }
}

export const useUsersLocationIDs = (ids: any = undefined) => {
  const path = '/users/locations' + (ids?.length > 0 ? '?' + ids.map(id => `ids=${id}`).join('&') : '')
  const fetch = async () => await api.get(path).then(res => res.data)
  return useQuery(path, fetch)
}

export const useEditCurrentUser = (props: MutationProps = {}) => {
  const { onSuccess, onError } = props
  const queryClient = useQueryClient()
  return useMutation(
    async (user: EditUser) => {
      return await api.patch('/users/me', user)
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries(['/users/me'])
        if (onSuccess) onSuccess(response)
      },
      onError: error => {
        console.error('error', error)
        if (onError) onError(error)
      },
    },
  )
}
