import { useEffect, useMemo, useRef } from 'react'
import { useClimatePortfolioData } from '../../hooks/analytics'
import { Box, Card, FormControl, InputLabel, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material'
import { CardTitle } from './common/Card'
import { useLocations } from '../../hooks/locations'
import PortfolioHeatStressMap from './portfolio/heatStress/PortfolioHeatStressMap'
import PortfolioHeatStressBreakdown from './portfolio/heatStress/PortfolioHeatStressBreakdown'
import LoadingIcon from '../LoadingIcon'

const PortfolioHeatStress = ({ selectedScenario, setScenarios }) => {
  const { data, isLoading } = useClimatePortfolioData('heat-stress')
  const { data: locations, isLoading: isLocationsLoading } = useLocations()
  const theme = useTheme()

  const availableScenarios = useMemo(() => {
    return Object.keys(data?.['data'] ?? {})
  }, [data])

  useEffect(() => {
    setScenarios(availableScenarios)
  }, [availableScenarios])

  const locationById = useMemo(() => {
    return locations.reduce((acc, location) => {
      acc[location.id] = location
      return acc
    }, {})
  }, [locations])

  const scenarioData = useMemo(() => {
    return data?.['data'][selectedScenario] ?? null
  }, [data, selectedScenario])

  if (isLoading || isLocationsLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="200px">
        <LoadingIcon />
      </Stack>
    )
  }

  const gridSpacing = '16px'
  return (
    <Stack alignItems={'center'} pb={6}>
      <Stack sx={{ maxWidth: theme.breakpoints.values?.['lg'], width: '100%' }} direction="column">
        <Box
          sx={{
            display: 'grid',
            gridGap: gridSpacing,
            gridTemplateRows: 'repeat(10, 180px)',
            gridTemplateColumns: 'repeat(8, 1fr)',
            gridTemplateAreas: `"breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
           "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
           "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
           "map map map map map map map map"
           "map map map map map map map map"
           "map map map map map map map map"
           `,
            //   gridTemplateAreas: `"chart chart chart chart card1 card1 card2 card2"
            //  "chart chart chart chart card3 card3 card4 card4"
            //  "comp comp comp comp comp dist dist dist"
            //  "comp comp comp comp comp dist dist dist"
            //  "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
            //  "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
            //  "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
            //  "map map map map map map map map"
            //  "map map map map map map map map"
            //  "map map map map map map map map"
            //  `,
          }}>
          {/* <Card
            sx={{
              gridArea: 'chart',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              background: 'linear-gradient(90deg, #4D5C8D 0%, #6D67A4 100%);',
              p: 4,
            }}>
            <CardTitle title="Hot Summer Days" subtitle="Days with Max Temperature > 30°C per Year" />
            <Box height={250}>
              <Line
                data={{
                  labels: ['2020', '2030', '2050', '2080'],
                  datasets: scenarios.map(scenario => ({
                    data: summaryDatasets[scenario],
                    label: scenario.toUpperCase(),
                    borderColor: selectedScenario === scenario ? '#F58080' : theme.palette.grey[300],
                    backgroundColor: selectedScenario === scenario ? '#F58080' : theme.palette.grey[300],
                    order: selectedScenario === scenario ? 1 : 0,
                    tension: 0.3,
                    pointRadius: 5,
                    borderDash: selectedScenario === scenario ? undefined : [5, 5],
                  })),
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  scales: {
                    x: {
                      ticks: {
                        color: 'white',
                      },
                      border: {
                        color: 'grey',
                      },
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      ticks: {
                        color: 'white',
                        maxTicksLimit: 6,
                      },
                      border: {
                        color: 'grey',
                      },
                      grid: {
                        display: true,
                      },
                    },
                  },
                }}
              />
            </Box>
          </Card>
          <Card sx={{ gridArea: 'card1' }}>
            <CardTitle title="Near-term" subtitle={`Increase from baseline`} />
            <Typography variant="h4">
              {pctChange(hotDays['baseline'][1], hotDays[selectedScenario].near[1]).toFixed(0)}%
            </Typography>
            <Typography variant="subtitle2">
              {hotDays['baseline'][1].toFixed(1)} days › {hotDays[selectedScenario].near[1].toFixed(1)} days
            </Typography>
          </Card>
          <Card sx={{ gridArea: 'card2' }}>
            <CardTitle title="Comparison" subtitle="Against other climates" />
            <Typography variant="body1">London ~3 days (near)</Typography>
            <Typography variant="body1">Marseille ~8 days (mid)</Typography>
            <Typography variant="body1">Madrid ~19 days (long)</Typography>
          </Card>
          <Card sx={{ gridArea: 'card3' }}>
            <CardTitle title="Mid-term" subtitle={`Increase from baseline`} />
            <Typography variant="h4">
              {pctChange(hotDays['baseline'][1], hotDays[selectedScenario].mid[1]).toFixed(0)}%
            </Typography>
            <Typography variant="subtitle2">
              {hotDays['baseline'][1].toFixed(1)} days › {hotDays[selectedScenario].mid[1].toFixed(1)} days
            </Typography>
          </Card>
          <Card sx={{ gridArea: 'card4' }}>
            <CardTitle title="Long-term" subtitle="Increase from baseline" />
            <Typography variant="h4">
              {pctChange(hotDays['baseline'][1], hotDays[selectedScenario].long[1]).toFixed(0)}%
            </Typography>
            <Typography variant="subtitle2">
              {hotDays['baseline'][1].toFixed(1)} days › {hotDays[selectedScenario].long[1].toFixed(1)} days
            </Typography>
          </Card>
          <Card sx={{ gridArea: 'comp', display: 'flex', flexDirection: 'column' }}>
            <CardTitle
              title="Distribution of Hot Summer Days"
              subtitle="Yearly portfolio average of expected hot summer days for colder, normal and hotter years"
            />
            <Box
              sx={{
                flexGrow: 1,
              }}>
              <Line
                ref={compChartRef as any}
                data={{
                  labels: ['baseline', '2030', '2050', '2080'],
                  datasets: compDatasets,
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        title: (context: any) => {
                          return context[0].dataset.label
                        },
                        label: (context: any) => {
                          return Number.parseFloat(context.parsed.y).toFixed(1) + ' average days per year'
                        },
                      },
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  animation: false,
                  scales: {
                    x: {
                      ticks: {
                        color: 'grey',
                      },
                      border: {
                        color: 'lightgrey',
                      },
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      max: chartMax,
                      ticks: {
                        color: 'grey',
                        maxTicksLimit: 6,
                      },
                      border: {
                        color: 'lightgrey',
                      },
                      grid: {
                        display: true,
                      },
                    },
                  },
                }}
              />
            </Box>
          </Card>
          <Card sx={{ gridArea: 'dist', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <CardTitle
                title="Histogram"
                subtitle={`Portfolio distribution of hot summer days in ${term}-term for ${pctToEasyText[breakdownPct]} years`}
              />
              <Stack direction="row" gap={1} alignItems="center">
                <Select labelId="dist-term" onChange={e => setTerm(e.target.value as Term)} size="tiny" value={term}>
                  {terms.map(term => (
                    <MenuItem key={term} value={term}>
                      {capitalize(term)} term
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  labelId="dist-pct"
                  onChange={e => setBreakdownPct(e.target.value as Percentile)}
                  size="tiny"
                  value={breakdownPct}>
                  {percentiles.map(pct => (
                    <MenuItem key={pct} value={pct}>
                      {capitalize(pctToEasyText[pct])} year
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Stack>
            <Box
              sx={{
                flexGrow: 1,
              }}>
              <Chart
                type="scatter"
                data={{
                  datasets:
                    histogramDataset && histogramBaselineDataset
                      ? [
                          {
                            type: 'bar',
                            data: histogramDataset,
                            label: 'projected',
                            backgroundColor: histogramColor + '99',
                            barThickness: 3,
                            order: 2,
                          },
                          {
                            type: 'bar',
                            data: histogramBaselineDataset,
                            label: 'baseline',
                            backgroundColor: '#A9A9A9' + '55',
                            barThickness: 3,
                            order: 1,
                            hidden: true,
                          },
                        ]
                      : [],
                }}
                options={{
                  indexAxis: 'y',
                  plugins: {
                    legend: {
                      display: true,
                    },
                    tooltip: {
                      callbacks: {
                        title: (context: any) => {
                          return '' + Number.parseFloat(context[0].label).toFixed(1) + ' hot summer days per year'
                        },
                        label: (context: any) => {
                          return context.parsed.x + ' properties'
                        },
                      },
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  hover: {
                    mode: 'nearest',
                    axis: 'y',
                  },
                  scales: {
                    x: {
                      ticks: {
                        color: 'grey',
                      },
                      border: {
                        color: 'lightgrey',
                      },
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      type: 'linear',
                      ticks: {
                        color: 'grey',
                      },
                      border: {
                        color: 'lightgrey',
                      },
                      grid: {
                        display: true,
                      },
                    },
                  },
                }}
              />
            </Box>
          </Card> */}

          {/* --------- */}
          {/* Breakdown */}
          {/* --------- */}
          <PortfolioHeatStressBreakdown scenario={selectedScenario} />

          {/* --- */}
          {/* Map */}
          {/* --- */}
          <Card sx={{ gridArea: 'map', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <CardTitle title="Map" subtitle="Analyse geospatial distribution of risk exposure" />
            </Stack>
            <PortfolioHeatStressMap scenario={selectedScenario} />
          </Card>
        </Box>
      </Stack>
    </Stack>
  )
}

export default PortfolioHeatStress
