import React from 'react'
import { useParams } from 'react-router-dom'
import AlertHistory from '../components/AlertHistory'
import Page from '../components/Page'

const PropertyAlerts = () => {
  const { id: propertyId } = useParams()
  return (
    <Page title="Alert History" size="lg">
      <AlertHistory propertyId={propertyId} />
    </Page>
  )
}

export default PropertyAlerts
