import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { MapsHomeWorkRounded, LocationCityRounded, HouseRounded } from '@mui/icons-material'
import { useCurrentUser } from '../hooks/users'

export const PortfolioType = {
  Organisation: 'organisation',
  Region: 'region',
  Property: 'property',
} as const

export type PortfolioType = keyof typeof PortfolioType

const PortfolioIcon = {
  organisation: LocationCityRounded,
  region: MapsHomeWorkRounded,
  property: HouseRounded,
} as const

export const PortfolioTypeIcon = ({ type, ...props }: { type: PortfolioType }) => {
  const IconComponent = PortfolioIcon[type]
  return <IconComponent {...props} />
}

export const ViewPermissions = Object.freeze({
  AlertManagement: 'view:alert-management',
  Portfolio: 'view:portfolio',
  RiskReport: 'view:risk-report',
})

export const OrganisationPermissions = Object.freeze({
  Edit: 'organisations:edit',
})

export const UserPermissions = Object.freeze({
  List: 'users:list',
  Add: 'users:add',
  Delete: 'users:delete',
})

export const PropertyPermissions = Object.freeze({
  List: 'locations:list',
  Add: 'locations:add',
  AddV2: 'locations:add-v2',
  Edit: 'locations:edit',
  Delete: 'locations:delete',
  Assign: 'locations:assign',
})

export const TemplatePermissions = Object.freeze({
  Manage: 'templates:manage',
  ManageSystem: 'templates:manage-system',
})

export const FormPermissions = Object.freeze({
  List: 'forms:list',
  ListCompleted: 'forms:list-completed',
  Complete: 'forms:complete',
  Delete: 'forms:delete',
})

export type Feature = 'alerts' | 'operations' | 'projections'

export type FeatureGateProps = {
  feature: Feature
  children: React.ReactNode
}

export const FeatureGate = ({ feature, children }: FeatureGateProps) => {
  const { data: user } = useCurrentUser()
  const organisation = user?.organisation
  if (organisation?.features?.[feature] ?? true) {
    return <>{children}</>
  }
  return <></>
}

export const PermissionGate = ({ action, all = true, children }) => {
  const role = useSelector<any>(state => state.auth.user.role) as any
  const policies = useMemo(() => [...role.permissions, ...role.views], [role.id])
  if (!Array.isArray(action)) {
    action = [action]
  }
  const numPassed = action.map(_action => check(_action, policies)).filter(Boolean).length
  if (all) {
    return numPassed === action.length ? children : null
  }
  return numPassed > 0 ? children : null
}

const check = (action, policies) => {
  if (action == null) {
    return true
  }
  return policies.includes(action)
}

export const usePermissions = () => {
  const role = useSelector<any>(state => state.auth.user.role) as any
  const policies = useMemo(() => [...role.permissions, ...role.views], [role.id])

  return {
    possible: action => check(action, policies),
    attributes: role.attributes,
    views: role.views,
  }
}
