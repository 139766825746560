import { useCallback, useMemo, useState } from 'react'
import { usePortfolioPricingProjections } from '../../hooks/analytics'
import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography, Tooltip, Card, Slider, Grid } from '@mui/material'
import { capitalise } from 'balkerne-fn'
import { HelpRounded } from '@mui/icons-material'
import { Line } from 'react-chartjs-2'
import { CircularProgress } from '@mui/material'
import { Scenario } from '../../types/climate'

const floodSources = ['river', 'surface', 'coastal', 'coastal_undefended'] as const
type FloodSource = (typeof floodSources)[number]

type FloodPricingProps = {
  rcp: Scenario
}

const FloodPricing = (props: FloodPricingProps) => {
  const { data, isLoading } = usePortfolioPricingProjections()
  const scenario = props.rcp
  const pricing = useMemo(() => data?.portfolio ?? null, [data])
  const [floodSource, setFloodSource] = useState<FloodSource>('river')
  const [yearIndex, setYearIndex] = useState<number>(0)

  const [isHidingBuilding, setIsHidingBuilding] = useState<boolean>(false)
  const [isHidingContents, setIsHidingContents] = useState<boolean>(false)
  const [isHidingBusinessInterruption, setIsHidingBusinessInterruption] = useState<boolean>(false)

  const tooltip = (
    <>
      <div>
        <strong>RCP2.6</strong> is defined by the IPCC as a scenario where emissions peak by 2020 and then rapidly
        decline, targeting a 2°C warming limit.
      </div>
      <br />
      <div>
        <strong>RCP 4.5</strong> is described by the Intergovernmental Panel on Climate Change (IPCC) as a moderate
        scenario in which emissions peak around 2040 and then decline.
      </div>
      <br />
      <div>
        <strong>RCP6.0</strong> is characterized by the IPCC as an intermediate pathway with emissions peaking around
        2080, then stabilizing without requiring negative emissions.
      </div>
      <br />
      <div>
        <strong>RCP 8.5</strong> is the highest baseline emissions scenario in which emissions continue to rise
        throughout the twenty-first century.
      </div>
    </>
  )

  const getPercentiles = (mean: number, sd: number) => {
    const p5 = mean - sd * 1.96
    const p95 = mean + sd * 1.96
    return { p5, p95 }
  }

  const getAnnualDamage = useCallback(
    (
      scenario: Scenario,
      floodSource: FloodSource,
      yearIndex: number,
      damageType: 'building' | 'business_interruption' | 'contents',
    ) => {
      if (pricing !== null) {
        const stats = pricing[scenario][yearIndex][floodSource][damageType]
        if (stats == null) return null
        const p = getPercentiles(stats.ad, stats.ad_sd)
        return { mean: Math.ceil(stats.ad), p5: Math.ceil(p.p5), p95: Math.ceil(p.p95) }
      }
      return null
    },
    [pricing],
  )

  const getAnnualDamageArray = useCallback(
    (scenario: Scenario, floodSource: FloodSource, damageType: 'building' | 'business_interruption' | 'contents') => {
      if (pricing !== null) {
        const retval: { mean: number; p5: number; p95: number }[] = []
        for (const period of pricing[scenario]) {
          const stats = period[floodSource][damageType]
          if (stats == null) return []
          const p = getPercentiles(stats.ad, stats.ad_sd)
          retval.push({ mean: Math.ceil(stats.ad), p5: Math.ceil(p.p5), p95: Math.ceil(p.p95) })
        }
        return retval
      }
      return []
    },
    [pricing],
  )

  return (
    <>
      {/* <Stack direction="row" justifyContent="space-between" alignItems="center" minWidth={100} mb={3}>
        <Typography variant="h4">Annual Average Flood Costs</Typography>
      </Stack> */}
      {!isLoading ? (
        <>
          {pricing !== null && pricing !== undefined ? (
            <>
              {/* Source & Scenario Toggles */}
              <Stack gap={3} mb={4} mt={2} direction="row">
                <Stack direction="row" alignItems="center" gap={1} sx={{ display: scenario ? 'flex' : 'none' }}>
                  <Typography variant="body1">Flood Source</Typography>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={floodSource}
                    exclusive
                    onChange={(e: any) => setFloodSource(e.target.value)}>
                    {floodSources.map(value => (
                      <ToggleButton key={value} value={value}>
                        {capitalise(value.split('_').join(' '), true)}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
                {/* <Stack direction="row" alignItems="center" gap={1} sx={{ display: scenario ? 'flex' : 'none' }}>
                  <Tooltip title={tooltip} placement="bottom">
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="body1">IPCC Emission Scenario</Typography>
                      <HelpRounded sx={{ width: 16 }} />
                    </Stack>
                  </Tooltip>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={scenario}
                    exclusive
                    onChange={(e: any) => setScenario(e.target.value)}>
                    {scenarios.map(value => (
                      <ToggleButton key={value} value={value}>
                        {value.toUpperCase()}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack> */}
              </Stack>

              {/* Cards */}
              <Grid container gap={3} wrap="nowrap">
                <Grid item xs={6} lg={3}>
                  <Card>
                    <Typography variant="subtitle1">Site Valuation</Typography>
                    <Typography variant="h4">
                      {'£'}
                      {addSeparators(data.total_insurance_value ?? 0)}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card>
                    <Typography variant="subtitle1">Building Damage Costs</Typography>
                    <Typography variant="h4">
                      {'£'}
                      {addSeparators(getAnnualDamage(scenario, floodSource, yearIndex, 'building')?.mean ?? 0)}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card>
                    <Typography variant="subtitle1">Business Interruption Costs</Typography>
                    {getAnnualDamage(scenario, floodSource, yearIndex, 'business_interruption') !== null ? (
                      <Typography variant="h4">
                        {'£' +
                          addSeparators(
                            getAnnualDamage(scenario, floodSource, yearIndex, 'business_interruption')?.mean ?? 0,
                          )}
                      </Typography>
                    ) : (
                      <Typography variant="h4">-</Typography>
                    )}
                  </Card>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card>
                    <Typography variant="subtitle1">Content Damage Costs</Typography>
                    <Typography variant="h4">
                      {'£'}
                      {addSeparators(getAnnualDamage(scenario, floodSource, yearIndex, 'contents')?.mean ?? 0)}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>

              {/* Slider */}
              <Box sx={{ mx: 2, py: 3 }}>
                {scenario && (
                  <Slider
                    color="primary"
                    defaultValue={0}
                    valueLabelFormat={value =>
                      `${pricing?.[scenario][value]['year_begin']}-${pricing?.[scenario][value]['year_end']}`
                    }
                    valueLabelDisplay="auto"
                    onChange={(e, value) => setYearIndex(value as number)}
                    marks
                    min={0}
                    max={15}
                  />
                )}
              </Box>

              {/* Chart */}
              <Stack>
                <Line
                  data={{
                    labels: pricing?.[scenario]?.map(row => `${row.year_begin}-${row.year_end}`) ?? [],
                    datasets: [
                      {
                        label: 'Building Damage | p97.5',
                        data: getAnnualDamageArray(scenario, floodSource, 'building').map(period => period.p95),
                        fill: false,
                        borderColor: '#2196f3',
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingBuilding,
                      },
                      {
                        label: 'Building Damage',
                        data: getAnnualDamageArray(scenario, floodSource, 'building').map(period => period.mean),
                        fill: 0,
                        borderColor: '#2196f3',
                        backgroundColor: '#2196f322',
                        tension: 0.1,
                        hidden: isHidingBuilding,
                      },
                      {
                        label: 'Building Damage | p2.5',
                        data: getAnnualDamageArray(scenario, floodSource, 'building').map(period => period.p5),
                        borderColor: '#2196f3',
                        backgroundColor: '#2196f322',
                        fill: 1,
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingBuilding,
                      },
                      {
                        label: 'Contents Damage | p97.5',
                        data: getAnnualDamageArray(scenario, floodSource, 'contents').map(period => period.p95),
                        borderColor: '#f5c84c',
                        backgroundColor: '#f5c84c22',
                        fill: false,
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingContents,
                      },
                      {
                        label: 'Contents Damage',
                        data: getAnnualDamageArray(scenario, floodSource, 'contents').map(period => period.mean),
                        fill: 3,
                        borderColor: '#f5c84c',
                        backgroundColor: '#f5c84c22',
                        tension: 0.1,
                        hidden: isHidingContents,
                      },
                      {
                        label: 'Contents Damage | p2.5',
                        data: getAnnualDamageArray(scenario, floodSource, 'contents').map(period => period.p5),
                        borderColor: '#f5c84c',
                        backgroundColor: '#f5c84c22',
                        fill: 4,
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingContents,
                      },
                      {
                        label: 'Business Interruption | p97.5',
                        data: getAnnualDamageArray(scenario, floodSource, 'business_interruption').map(
                          period => period.p95,
                        ),
                        borderColor: '#f28f7c',
                        backgroundColor: '#f28f7c22',
                        fill: false,
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingBusinessInterruption,
                      },
                      {
                        label: 'Business Interruption',
                        data: getAnnualDamageArray(scenario, floodSource, 'business_interruption').map(
                          period => period.mean,
                        ),
                        fill: 6,
                        borderColor: '#f28f7c',
                        tension: 0.1,
                        hidden: isHidingBusinessInterruption,
                      },
                      {
                        label: 'Business Interruption | p2.5',
                        data: getAnnualDamageArray(scenario, floodSource, 'business_interruption').map(
                          period => period.p5,
                        ),
                        borderColor: '#f28f7c',
                        backgroundColor: '#f28f7c22',
                        fill: 7,
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingBusinessInterruption,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          filter: function (item, chart) {
                            return !item.text.includes(' | ')
                          },
                        },
                        onClick: (event, legendItem, legend) => {
                          switch (legendItem.text) {
                            case 'Building Damage':
                              setIsHidingBuilding(!isHidingBuilding)
                              break
                            case 'Contents Damage':
                              setIsHidingContents(!isHidingContents)
                              break
                            case 'Business Interruption':
                              setIsHidingBusinessInterruption(!isHidingBusinessInterruption)
                              break
                            default:
                              break
                          }
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      y: {
                        min: 0,
                        ticks: {
                          callback: function (value, index, values) {
                            return value + '£'
                          },
                        },
                        beginAtZero: false,
                      },
                    },
                  }}
                />
              </Stack>
            </>
          ) : (
            <Stack justifyContent="center" alignItems="center" height="500">
              <Typography variant="h5">No data available</Typography>
            </Stack>
          )}
        </>
      ) : (
        <Stack justifyContent="center" alignItems="center" height="500">
          <CircularProgress />
        </Stack>
      )}
    </>
  )
}

function addSeparators(num: number, separator: string = ',', gap: number = 3): string {
  const numStr = num.toString()
  const reversedStr = numStr.split('').reverse().join('')
  let formattedStr = ''
  for (let i = 0; i < reversedStr.length; i++) {
    formattedStr += reversedStr[i]
    if ((i + 1) % gap === 0 && i !== 0 && i !== reversedStr.length - 1) {
      formattedStr += separator
    }
  }
  return formattedStr.split('').reverse().join('')
}

export default FloodPricing
