import { useEffect, useMemo, useState } from 'react'
import { Stack, TextField, Typography, MenuItem } from '@mui/material'
import * as xlsx from 'xlsx'

const SpreadSheetHandler = ({ data, onSelectAll }) => {
  const [name, setName] = useState('')
  const [reference, setReference] = useState('')
  const [address, setAddress] = useState('')

  const [sheetName, setSheetName] = useState('')
  const sheets = data['Sheets']

  const rows = useMemo(() => {
    try {
      return xlsx.utils.sheet_to_json(sheets[sheetName])
    } catch (e) {
      console.warn(e)
      return null
    }
  }, [sheetName])

  useEffect(() => {
    if (name && reference && address) {
      const properties = rows?.map((row: any) => ({
        name: row[name] ?? '',
        reference: row[reference] ?? '',
        address: row[address] ?? '',
        manualAddress: null,
        dpa: null,
        dpaSelection: [],
      }))
      onSelectAll(properties)
    } else {
      onSelectAll([])
    }
  }, [name, reference, address])

  const columns = Object.keys(rows?.[0] ?? {})

  return (
    <Stack gap={2} sx={{ mt: 2 }}>
      {/* Select sheet */}
      <Stack gap={1}>
        <Typography variant="body1">Select sheet</Typography>
        <TextField fullWidth select label="Name" value={sheetName} onChange={e => setSheetName(e.target.value)}>
          <MenuItem key="" value="" />
          {Object.keys(sheets).map(sheetName => (
            <MenuItem key={sheetName} value={sheetName}>
              {sheetName}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      {/* Select relevant columns */}
      <Stack gap={1}>
        <Typography variant="body1">Select columns</Typography>
        <Stack gap={1}>
          <TextField select label="Name" value={name} onChange={e => setName(e.target.value)}>
            <MenuItem key="" value="" />
            {columns.map(col => (
              <MenuItem key={col} value={col}>
                {col}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label="Reference" value={reference} onChange={e => setReference(e.target.value)}>
            <MenuItem key="" value="" />
            {columns.map(col => (
              <MenuItem key={col} value={col}>
                {col}
              </MenuItem>
            ))}
          </TextField>
          <TextField select label="Address" value={address} onChange={e => setAddress(e.target.value)}>
            <MenuItem key="" value="" />
            {columns.map(col => (
              <MenuItem key={col} value={col}>
                {col}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SpreadSheetHandler
