import { useLocation, useLocationSubscriptionsReadings } from '../../hooks/locations'
import AreaReadingsChart from './AreaReadingsChart'
import { CircularProgress, Stack } from '@mui/material'

const SmartIntegrationTab = ({ propertyId }) => {
  const { data: property, isLoading } = useLocation(propertyId)
  const { data: areaReadings, isLoading: isLoadingReadings } = useLocationSubscriptionsReadings(propertyId)

  const processReadings = areaReading => {
    areaReading.configurations.sort((a, b) => a.severity - b.severity)
    areaReading.readings = areaReading.readings.map(reading => {
      const out = { ...reading }
      areaReading.configurations.forEach(config => {
        out[config.id] = config.threshold
      })
      return out
    })
    return areaReading
  }

  const showReadings = () => {
    if (!isLoadingReadings) {
      return areaReadings.map(areaReading => {
        areaReading = processReadings(areaReading)
        switch (areaReading.alert_type.name) {
          case 'wind':
            return <AreaReadingsChart key={areaReading.id} areaReading={areaReading} chartType="area" />
          case 'rain_measurement':
            return <AreaReadingsChart key={areaReading.id} areaReading={areaReading} chartType="bar" />
          case 'rain':
            return <AreaReadingsChart key={areaReading.id} areaReading={areaReading} chartType="bar" />
          case 'rain_forecast_long_range':
            return <AreaReadingsChart key={areaReading.id} areaReading={areaReading} chartType="bar" />
          case 'heat':
            return <AreaReadingsChart key={areaReading.id} areaReading={areaReading} chartType="area" />
          case 'river_gauge':
            return <AreaReadingsChart key={areaReading.id} areaReading={areaReading} chartType="area" />
          case 'snow':
            return <AreaReadingsChart key={areaReading.id} areaReading={areaReading} chartType="area" />
          default:
            // Don't show anything if we don't know what to do with the alert type (e.g. rain_forecast_12h)
            return null
        }
      })
    } else {
      return <CircularProgress />
    }
  }
  if (!isLoading && !!property) {
    let tenDays = new Date()
    tenDays.setDate(tenDays.getDate() - 10)

    return (
      <Stack spacing={3} mb={6}>
        {showReadings()}
      </Stack>
    )
  } else {
    return <></>
  }
}

export default SmartIntegrationTab
