import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Stack, Button, TextField, Grid, Card, MenuItem } from '@mui/material'
import api from 'balkerne-core/api'
import { useEffect, useMemo, useState } from 'react'
import { useFormTypes, useTemplateTags } from '../../hooks/forms'
import 'bootstrap/dist/css/bootstrap.min.css'
import Page from '../../components/Page'
import NewTemplateBuilder, {
  TemplateBuilderSubmitData,
  templateSchema,
} from '../../components/templateManager/NewTemplateBuilder'
import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import siteMap from '../../siteMap'
import { TemplatePermissions, usePermissions } from 'balkerne-core/roles'

export const principalSchema = yup.object({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  type: yup.number().required('Type is required'),
  category: yup.number().required('Category is required'),
})

const schema =
  templateSchema &&
  yup.object({
    principal: principalSchema,
  })

type CreateTemplateMutation = {
  principal: {
    title: string
    description: string
    type: number
    category: number
  }
  data: any
  publish: boolean
}
const CreateTemplate = () => {
  const { possible } = usePermissions()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const form = useForm({
    defaultValues: {
      principal: {
        title: '',
        description: '',
        type: null,
        category: null,
      },
      template: {
        schema: '{}',
        uischema: '{}',
        tags: [],
      },
    },
    resolver: yupResolver(schema),
  })
  const { handleSubmit: formSubmit } = form
  const isAdmin = possible(TemplatePermissions.ManageSystem)

  const createTemplate = useMutation({
    mutationFn: (data: CreateTemplateMutation) => {
      console.log('mutation data', data)
      const _data = {
        principal_data: {
          title: data.principal.title,
          description: data.principal.description,
          type_id: data.principal.type,
          category_id: data.principal.category,
          latest_version: 1,
          property_id: null,
          is_active: true,
          highlight: false,
          default: true,
        },
        template_data: {
          version: 1,
          description: data.principal.description,
          data: JSON.parse(data.data.schema),
          ui_schema: JSON.parse(data.data.uischema),
          expired: false,
          published: data.publish,
        },
        tags: data.data.tags,
        is_global: isAdmin,
      }
      console.log('create new template')
      return api.post('/forms/templates', _data)
    },
    onSuccess: data => {
      enqueueSnackbar('Successfully created new template', { variant: 'success' })
      console.log('onSuccess data', data)
      history.push(siteMap.FormTemplate.getPath(data.data.data))
    },
    onError: () => {
      enqueueSnackbar('Error creating template', { variant: 'error' })
    },
  })

  const handlePublish = data => {
    console.log('handlePublish', data)
    createTemplate.mutate({
      principal: data.principal,
      data: data.template,
      publish: true,
    })
  }

  const handleSave = data => {
    console.log('handleSave', data)
    createTemplate.mutate({
      principal: data.principal,
      data: data.template,
      publish: false,
    })
  }

  return (
    <Page
      title="Create Template"
      size="lg"
      back
      PageActions={() => (
        <Stack direction="row" gap={1}>
          <Button onClick={formSubmit(handleSave)} variant="outlined">
            Save
          </Button>
          <Button onClick={formSubmit(handlePublish)} variant="outlined">
            Publish
          </Button>
        </Stack>
      )}>
      <FormProvider {...form}>
        <TemplateDetailsForm />
        <NewTemplateBuilder />
      </FormProvider>
    </Page>
  )
}
type Template = {
  id: number
  versionId: number
}
type TemplateBuilderMode = 'create-version' | 'edit-version' | 'create-template' | 'edit-template'
type TemplateBuilderProps = {
  template: Template | null
  mode: TemplateBuilderMode
}
export const TemplateDetailsForm = () => {
  const { data: formTypes } = useFormTypes()
  const { watch, register } = useFormContext()
  const type = watch('principal.type')

  const formTypeList = useMemo(() => {
    return formTypes.sort((a, b) => {
      return ('' + a['title']).localeCompare(b['title'])
    })
  }, [formTypes])

  const categories = useMemo(() => {
    if (type && formTypeList) {
      return formTypeList
        .find(formType => formType.id === type)
        .categories.sort((a, b) => {
          return ('' + a['title']).localeCompare(b['title'])
        })
    }
    return []
  }, [type, formTypeList])

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField {...register('principal.title')} fullWidth label="Title" />
        </Grid>
        <Grid item xs={12}>
          <TextField {...register('principal.description')} fullWidth label="Description" multiline rows={3} />
        </Grid>
        <Grid item xs={6}>
          <TextField {...register('principal.type')} fullWidth label="Type" select>
            {formTypeList.map((formType, index) => (
              <MenuItem key={index} value={formType.id}>
                {formType.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField {...register('principal.category')} fullWidth label="Category" select disabled={type === null}>
            {categories.map((category, index) => (
              <MenuItem key={index} value={category.id}>
                {category.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Card>
  )
}

export default CreateTemplate
