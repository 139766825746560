import React, { useState, useEffect } from 'react'
import CardLoader from 'balkerne-components/dashboard/CardLoader'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import ViewForm from 'balkerne-components/ViewForm'
import { useFormInstances } from '../../hooks/forms'
import FormatDate from 'balkerne-components/FormatDate'
import { useHistory } from 'react-router-dom'
import { FormPermissions, PermissionGate } from 'balkerne-core/roles'
import { RootState } from '../../store'

const IncidentsList = ({ propertyId }) => {
  const history = useHistory()
  const user = useSelector((state: RootState) => state.auth.user)
  const { data: incidents, isLoading: loading } = useFormInstances({
    property_id: propertyId,
    type_id: 2,
    organisation_id: user.organisation.id,
    form_state: 'CLOSED',
  })
  const [forms, setForms] = useState([])
  useEffect(() => {
    if (incidents) {
      const data: any = []
      data.push(...incidents)
      data.sort((a: any, b: any) => {
        const dateA: any = new Date(a['updated_at'])
        const dateB: any = new Date(b['updated_at'])
        return dateB - dateA
      })
      setForms(data)
    }
  }, [incidents])

  const previousPath = {
    previousPathTitle: 'Risk Report',
    previousPathName: '/riskreport',
    previousPathState: {
      propertyForms: false,
    },
  }

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      width: 300,
      editable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      editable: false,
      valueGetter: params => {
        return params.row.template.template.type.title
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 150,
      editable: false,
      valueGetter: params => {
        return params.row.template.template.category.title
      },
    },
    {
      field: 'full_name',
      headerName: 'Author',
      width: 150,
      editable: false,
      valueGetter: params => {
        if (params.row.assignee !== null) return params.row.assignee.full_name
        else return 'No Assignee'
      },
    },
    {
      field: 'updated_at',
      headerName: 'Last Updated',
      width: 175,
      editable: false,
      valueGetter: params => {
        return FormatDate(params.row.updated_at)
      },
    },
  ]

  return (
    <>
      {loading ? (
        <CardLoader />
      ) : (
        <PermissionGate action={FormPermissions.ListCompleted}>
          <div style={{ height: '800px' }}>
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              rows={forms}
              loading={loading}
              columns={columns}
              disableColumnSelector={true}
              onRowDoubleClick={form => ViewForm(form, history, null, previousPath)}
              density="standard"
            />
          </div>
        </PermissionGate>
      )}
    </>
  )
}

export default IncidentsList
