import { SwipeableDrawer } from '@mui/material'
import React from 'react'
import systemActions from '../store/system'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'

const NotificationDrawer = () => {
  const { notificationDrawerOpen } = useSelector((state: RootState) => state.system)
  const dispatch = useDispatch()
  const handleOpen = () => dispatch(systemActions.setNotificationDrawerOpen(true))
  const handleClose = () => dispatch(systemActions.setNotificationDrawerOpen(false))
  const width = '240px'
  return (
    <SwipeableDrawer
      sx={{
        width: width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          backgroundColor: 'background.paper',
          width: width,
          boxSizing: 'border-box',
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
      open={notificationDrawerOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      variant="temporary"
      anchor="right"></SwipeableDrawer>
  )
}

export default NotificationDrawer
