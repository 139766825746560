import { useMemo, useState } from 'react'
import { usePortfolioPricingProjections } from '../../hooks/analytics'
import LoadingIcon from '../LoadingIcon'
import { Box, Card, FormControl, InputLabel, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material'
import { CardTitle } from './common/Card'
import { Line } from 'react-chartjs-2'

const floodTypes = ['coastal', 'surface', 'river'] as const
type FloodType = (typeof floodTypes)[number]
const floodTypeToLabel: Record<FloodType, string> = {
  coastal: 'Coastal',
  surface: 'Surface',
  river: 'River',
}

const lossType = ['building', 'contents'] as const
type LossType = (typeof lossType)[number]
const lossTypeToLabel: Record<LossType, string> = {
  building: 'Building',
  contents: 'Contents',
}

type FloodV2Props = {
  rcp: string
  floodType: FloodType
  lossType: LossType
}

const scenarios = ['rcp26', 'rcp45', 'rcp60', 'rcp85'] as const

const FloodV2 = (props: FloodV2Props) => {
  const { rcp, floodType, lossType } = props
  const { data: portfolioPricing, isLoading: isPortfolioPricingLoading } = usePortfolioPricingProjections()
  const theme = useTheme()
  const mainColor = '#fa6f5a'
  const gridSpacing = '16px'

  const summaryData = useMemo(() => {
    if (!portfolioPricing) {
      return null
    }
    const result: Record<(typeof scenarios)[number], any> = {
      rcp26: [],
      rcp45: [],
      rcp60: [],
      rcp85: [],
    }
    for (const scenario of scenarios) {
      for (const row of portfolioPricing?.portfolio[scenario]) {
        result[scenario].push(row[floodType][lossType])
      }
    }
    return result
  }, [portfolioPricing, rcp, floodType, lossType])

  if (isPortfolioPricingLoading) {
    return <LoadingIcon />
  }

  console.log('😅')
  console.log(summaryData)
  console.log(portfolioPricing.total_insurance_value)

  return (
    <Stack alignItems={'center'} pb={6}>
      <Stack sx={{ maxWidth: theme.breakpoints.values?.['lg'], width: '100%' }} direction="column">
        {/* Page Contents */}
        {summaryData !== undefined && summaryData !== null ? (
          <Box
            sx={{
              display: 'grid',
              gridGap: gridSpacing,
              gridTemplateRows: 'repeat(10, 180px)',
              gridTemplateColumns: 'repeat(8, 1fr)',
              gridTemplateAreas: `
              "chart chart chart chart card1 card1 card2 card2"
              "chart chart chart chart card3 card3 card4 card4"
              "comp comp comp comp comp comp comp comp"
              "comp comp comp comp comp comp comp comp"
              "ls ls ls ls ls ls ls ls"
              "ls ls ls ls ls ls ls ls"
              "ls ls ls ls ls ls ls ls"
              "map map map map map map map map"
              "map map map map map map map map"
              "map map map map map map map map"
              `,
            }}>
            {/* Summary Chart */}
            <Card
              sx={{
                gridArea: 'chart',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                background: 'linear-gradient(90deg, #4D5C8D 0%, #6D67A4 100%);',
                p: 4,
              }}>
              <CardTitle title="Costs by Scenario" subtitle="Annual average expected cost by scenario" />
              <Box height={250}>
                <Line
                  data={{
                    labels: Array.from({ length: 16 }, (_, i) => i * 5 + 2020),
                    datasets: scenarios.map(scenario => ({
                      data: summaryData[scenario].map(val => val.ad),
                      label: scenario.toUpperCase(),
                      borderColor: rcp === scenario ? mainColor : theme.palette.grey[300],
                      backgroundColor: rcp === scenario ? mainColor : theme.palette.grey[300],
                      order: rcp === scenario ? 1 : 0,
                      tension: 0.3,
                      pointRadius: 5,
                      borderDash: rcp === scenario ? undefined : [5, 5],
                    })),
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      x: {
                        ticks: {
                          color: 'white',
                        },
                        border: {
                          color: 'grey',
                        },
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        ticks: {
                          stepSize: 1,
                          color: 'white',
                          callback: (value: string | number) => {
                            return value
                              .toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'GBP',
                              })
                              .replace('.00', '')
                          },
                        },
                        border: {
                          color: 'grey',
                        },
                        grid: {
                          display: true,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Card>
            <Card sx={{ gridArea: 'card1' }}>
              <CardTitle title="Site Valuation" subtitle={``} />
              <Typography variant="h4">
                {portfolioPricing.total_insurance_value
                  .toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'GBP',
                  })
                  .replace('.00', '')}
              </Typography>
            </Card>
            <Card sx={{ gridArea: 'card2' }}>
              <CardTitle title="Near-Term Dry Summers" subtitle={`Severe/Extreme Property Counts`} />
              <Typography variant="h4">219</Typography>
              <Typography variant="subtitle1">11% of portfolio</Typography>
            </Card>
            <Card sx={{ gridArea: 'card3' }}>
              <CardTitle title="Mid-Term Summers" subtitle="Average Summer Condition" />
              <Typography variant="h4">Moderate Dry</Typography>
            </Card>
            <Card sx={{ gridArea: 'card4' }}>
              <CardTitle title="Mid-Term Dry Summers" subtitle={`Severe/extreme property counts`} />
              <Typography variant="h4">392</Typography>
              <Typography variant="subtitle1">23% of portfolio</Typography>
            </Card>
          </Box>
        ) : (
          <>Unable to load data</>
        )}
      </Stack>
    </Stack>
  )
}

export const useFloodV2Controls = () => {
  const [type, setType] = useState<FloodType>('river')
  const [loss, setLoss] = useState<LossType>('contents')

  return {
    components: (
      <>
        <FormControl variant="outlined" size="small" sx={{ width: 120 }}>
          <InputLabel id="flood-source-label">Flood</InputLabel>
          <Select
            value={type}
            labelId="flood-source-label"
            onChange={e => {
              setType(e.target.value as FloodType)
            }}>
            {floodTypes.map(hazard => (
              <MenuItem key={hazard} value={hazard}>
                {floodTypeToLabel[hazard]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" sx={{ width: 120 }}>
          <InputLabel id="loss-type-label">Cost</InputLabel>
          <Select
            value={loss}
            labelId="loss-type-label"
            onChange={e => {
              setLoss(e.target.value as LossType)
            }}>
            {lossType.map(loss => (
              <MenuItem key={loss} value={loss}>
                {lossTypeToLabel[loss]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    ),
    params: {
      floodType: type,
      lossType: loss,
    },
  }
}

export default FloodV2
