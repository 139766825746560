import React from 'react'
import { useTheme } from '@mui/material'
import { ReactComponent as SevereIcon } from '../images/alert_icons/severe.svg'
import { ReactComponent as WarningIcon } from '../images/alert_icons/warning.svg'
import { ReactComponent as AlertIcon } from '../images/alert_icons/alert.svg'

type Icons = {
  [key: number]: React.FC<React.SVGProps<SVGSVGElement>>
}

const icons: Icons = {
  1: SevereIcon,
  2: WarningIcon,
  3: AlertIcon,
}

type SeverityIconProps = {
  severity: number
} & React.SVGProps<SVGSVGElement>

const SeverityIcon: React.FC<SeverityIconProps> = ({ severity, ...props }) => {
  const theme = useTheme()

  const Icon = icons[severity]
  // const color = theme.palette.severity[severity]

  return <Icon {...props} />
}

export default SeverityIcon
