export const colorToGradient = (colors: Record<number, string>, degrees: number) => {
  const { min, max }: { min: number; max: number } = Object.entries(colors).reduce(
    (acc, [k, v]) => {
      const num = Number(k)
      if (num < acc.min) {
        acc.min = num
      } else if (num > acc.max) {
        acc.max = num
      }
      return acc
    },
    { min: Infinity, max: -Infinity },
  )
  const gradient = Object.entries(colors).sort((a, b) => Number(a[0]) - Number(b[0]))
  const css = `linear-gradient(${degrees}deg, ${gradient
    .map(([k, v]) => v + ' ' + ((Number(k) - min) / (max - min)) * 100 + '%')
    .join(', ')})`
  return css
}
