import { useLocation } from 'react-router-dom'
import { DynamicForm } from 'balkerne-components/DynamicForm'
import PageContainer from 'balkerne-components/Container'

const CompleteForm = () => {
  const location = useLocation()
  return (
    <>
      <PageContainer>
        <PageContainer.Center>
          <DynamicForm {...location.state} />
        </PageContainer.Center>
      </PageContainer>
    </>
  )
}

export default CompleteForm
