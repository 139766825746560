import { createSlice } from '@reduxjs/toolkit'

type AuthState = {
  user?: any
  authenticated: boolean
}
const initialState: AuthState = {
  user: undefined,
  authenticated: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.user = payload.user
      state.authenticated = true
    },
    logout: state => {
      state.authenticated = false
      state.user = undefined
    },
    updateUser: (state, { payload }) => {
      state.user = payload.user
    },
  },
})

export const authReducer = authSlice.reducer
export default authSlice.actions
