import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../components/Page'
import { RootState } from '../store'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormHelperText,
  Grid,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useEditCurrentUser } from '../hooks/users'
import authActions from '../store/auth'
import { EditUser, User } from '../types/organisations'
import Auth from '@aws-amplify/auth'
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'
import { validatePhoneNumber } from 'balkerne-core/forms'
import FormPhoneInput from '../components/forms/FormPhoneInput'
import { formatPhoneNumber } from 'balkerne-fn/phone'

yup.addMethod(yup.string, 'phone', validatePhoneNumber)
const schema = yup.object({
  full_name: yup.string().required('Full Name is required'),
  position: yup.string().required('Position is required'),
  phone: yup.string().phone().nullable().optional(),
})

const Settings = () => {
  const user = useSelector((state: RootState) => state.auth.user) as User
  return (
    <Page
      title="Account Settings"
      size="md"
      PageActions={() => (
        <Typography variant="body1" sx={{ marginTop: 'auto' }}>
          {user.email}
        </Typography>
      )}>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
        <Card>
          <CardHeader
            title={<Typography variant="h6">Profile Details</Typography>}
            subheader={
              <Typography variant="subtitle2">
                Manage your personal information and update your contact details.
              </Typography>
            }
          />
          <CardContent>
            <UserDetailsForm user={user} />
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title={<Typography variant="h6">Change Password</Typography>}
            subheader={
              <Typography variant="subtitle2">
                To update your password, click the button below. A verification code will be sent to your email.
              </Typography>
            }
          />
          <CardContent>
            <ChangePasswordForm user={user} />
          </CardContent>
        </Card>
      </Box>
    </Page>
  )
}
const UserDetailsForm = ({ user }: { user: User }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const updateUser = useEditCurrentUser({
    onSuccess: response => {
      dispatch(authActions.updateUser({ user: response.data }))
      enqueueSnackbar('User details updated successfully.', { variant: 'success' })
    },
    onError: error => {
      enqueueSnackbar('Error updating user details.', { variant: 'error' })
    },
  })
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    control,
  } = useForm<EditUser>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      full_name: user.full_name,
      position: user.position,
      phone: formatPhoneNumber(user.phone),
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: EditUser) => {
    let formData = { ...data }
    if (formData.phone) {
      const phoneNumber = parsePhoneNumberFromString(formData.phone)
      if (phoneNumber) {
        // set phone number to E.164 format
        formData.phone = phoneNumber.number
      }
    }
    console.log('data', formData)
    updateUser.mutate(formData)
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography>Full Name</Typography>
        <TextField
          fullWidth
          {...register('full_name')}
          error={Boolean(errors?.full_name)}
          helperText={errors?.full_name?.message}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Position</Typography>
        <TextField
          fullWidth
          {...register('position')}
          error={Boolean(errors?.position)}
          helperText={errors?.position?.message}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography>Phone Number</Typography>
        <FormHelperText>Please enter your phone number in international format</FormHelperText>
        <FormPhoneInput control={control} name="phone" errors={errors} fullWidth placeholder="(e.g +1234567890)" />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isSubmitting}>
          Save changes
        </Button>
      </Grid>
    </Grid>
  )
}
const passwordRequirements = [
  { id: 'length', text: 'Is longer than 7 characters' },
  { id: 'uppercase', text: 'Contains uppercase letters' },
  { id: 'lowercase', text: 'Contains lowercase letters' },
  { id: 'numbers', text: 'Contains numbers' },
]
const changePasswordFormSchema = yup.object({
  v_code: yup.string().required('Verification Code is required'),
  newPassword: yup
    .string()
    .required('Password is required')
    .test('length', 'Password must be at least 8 characters', val => Boolean(val && val.length > 7))
    .test('uppercase', 'Password must contain at least one uppercase letter', val => Boolean(val && /[A-Z]/.test(val)))
    .test('lowercase', 'Password must contain at least one lowercase letter', val => Boolean(val && /[a-z]/.test(val)))
    .test('numbers', 'Password must contain at least one number', val => Boolean(val && /[0-9]/.test(val))),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
})

type ChangePassword = {
  v_code: string
  newPassword: string
  confirmPassword: string
}
const ChangePasswordForm = ({ user }: { user: User }) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ChangePassword>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      v_code: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(changePasswordFormSchema),
  })

  const sendVerificationCode = async () => {
    await Auth.forgotPassword(user.email)
      .then(data => {
        enqueueSnackbar('Verification code sent successfully.', { variant: 'success' })
      })
      .catch(error => {
        console.error('forgot password error', error)
        enqueueSnackbar('Error sending verification code.', { variant: 'error' })
      })
  }

  const onSubmit = async (data: ChangePassword) => {
    await Auth.forgotPasswordSubmit(user.email, data.v_code, data.newPassword)
      .then(() => {
        enqueueSnackbar('Password changed successfully.', { variant: 'success' })
      })
      .catch(error => {
        console.error('password change error', error)
        enqueueSnackbar('Error changing password.', { variant: 'error' })
      })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Verification Code"
          {...register('v_code')}
          error={Boolean(errors?.v_code)}
          helperText={errors?.v_code?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button variant="contained" color="primary" onClick={sendVerificationCode}>
          Send verification code
        </Button>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="New Password"
            type="password"
            {...register('newPassword')}
            error={Boolean(errors?.newPassword)}
            helperText={errors?.newPassword?.message}
            InputProps={{
              autoComplete: 'new-password',
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Confirm Password"
          type="password"
          {...register('confirmPassword')}
          error={Boolean(errors?.confirmPassword)}
          helperText={errors?.confirmPassword?.message}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="body2">In order to protect your account, make sure your password:</Typography>
        <List dense={true}>
          {passwordRequirements.map(requirement => (
            <ListItem disableGutters disablePadding key={requirement.id}>
              <Checkbox
                checked={Boolean(watch('newPassword') && !errors.newPassword?.types?.[requirement.id])}
                icon={<CheckCircleOutlineIcon color="disabled" fontSize="small" />}
                checkedIcon={<CheckCircleOutlineIcon color="success" fontSize="small" />}
                disabled
                sx={{
                  paddingY: 0,
                }}
              />
              <Typography variant="body2">{requirement.text}</Typography>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isSubmitting}>
          Update Password
        </Button>
      </Grid>
    </Grid>
  )
}

export default Settings
