import ConfigProvider from 'balkerne-core/config'

const config = {
  aws_project_region: ConfigProvider.cognitoRegion,
  aws_cognito_region: ConfigProvider.cognitoRegion,
  aws_user_pools_id: ConfigProvider.cognitoUserPoolId,
  aws_user_pools_web_client_id: ConfigProvider.cognitoWebClientId,
  oauth: {},
  // storage: localStorage,
  authenticationFlowType: 'USER_PASSWORD_AUTH', // 🚧 Must be present while user migration is active
  Auth: {
    storage: localStorage,
  },
}

export default config
