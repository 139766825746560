import { useState, useMemo, useCallback } from 'react'
import {
  DataGrid,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import api from 'balkerne-core/api'
import { useParams } from 'react-router-dom'
import Page from '../components/Page'
import { useAlertAreas, useAlertAreaTypes } from '../hooks/alerts'
import capitalise from 'balkerne-fn/capitalise'
import { Grid, Stack } from '@mui/material'
import AreaMap from '../components/AreaMap'
import { useQueryClient } from 'react-query'

const AlertAreas = () => {
  const PAGE_SIZE = 25
  const { subscriptionId } = useParams()
  const [page, setPage] = useState(0)
  const [mapAreaId, setMapAreaId] = useState<any>()
  const [assignedAreaId, setAssignedAreaId] = useState<any>(null)
  const [allAreaId, setAllAreaId] = useState<any>(null)
  const { data: areaTypes } = useAlertAreaTypes()
  const [args, setArgs] = useState({})
  const {
    data: areas,
    isFetching: isAreasLoading,
    path,
  } = useAlertAreas({
    subscription_id: subscriptionId,
  })
  const { data: allAreas, isLoading: isAllAreasLoading } = useAlertAreas({
    limit: PAGE_SIZE,
    offset: page * PAGE_SIZE,
    ...args,
  })

  const assignedAreaIds = useMemo(() => {
    return new Set(areas?.map(area => area.id) ?? [])
  }, [areas])

  const queryClient = useQueryClient()

  const columns = useMemo(
    () => [
      {
        field: 'ref',
        headerName: 'Reference',
        hideable: false,
        minWidth: 150,
        flex: 1,
        filterOperators: getGridStringOperators().filter(op => op.value === 'contains'),
      },

      {
        field: 'type',
        headerName: 'Type',
        hideable: false,
        type: 'singleSelect',
        filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
        valueGetter: params => capitalise(params.row.type.name.split('_').join(' ')),
        valueOptions:
          areaTypes?.map(areaType => ({
            value: areaType.type.name,
            label: capitalise(areaType.type.name.split('_').join(' ')),
          })) ?? [],
      },
      {
        field: 'subtype',
        hideable: false,
        headerName: 'Subtype',
        valueGetter: params => capitalise(params.row.subtype.name.split('_').join(' ')),
      },
    ],
    [areaTypes, assignedAreaIds],
  )

  const allColumns = useMemo(
    () => [
      ...columns,
      {
        field: 'isAssigned',
        headerName: 'Assigned',
        type: 'boolean',
        hideable: false,
        valueGetter: params => assignedAreaIds.has(params.row.id),
        filterable: false,
      },
    ],
    [columns, assignedAreaIds],
  )

  const handleAssignedRowSelect = useCallback(areaId => {
    setAssignedAreaId(areaId)
    setMapAreaId(areaId)
  }, [])

  const handleAllRowSelect = useCallback(
    areaId => {
      if (!assignedAreaIds.has(areaId)) {
        console.log('Setting all area id to ', areaId)
        setAllAreaId(areaId)
        setMapAreaId(areaId)
      }
    },
    [assignedAreaIds],
  )

  const handleFilterModelChange = args => {
    if (args.items.length === 0) {
      setArgs({})
    } else {
      let { columnField, value } = args.items[0]
      if (columnField === 'ref') columnField = 'reference'
      setArgs({ [columnField]: value })
    }
    setPage(0)
  }

  const handleAdd = async () => {
    await api
      .put(`/alerts/subscriptions/${subscriptionId}/areas/${allAreaId}`)
      .then(async () => {
        setAllAreaId(null)
        await queryClient.invalidateQueries({ queryKey: [path] })
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleRemove = async () => {
    await api
      .delete(`/alerts/subscriptions/${subscriptionId}/areas/${assignedAreaId}`)
      .then(async () => {
        setAssignedAreaId(null)
        await queryClient.invalidateQueries({ queryKey: [path] })
      })
      .catch(err => {
        console.error(err)
      })
  }

  const MappedAreasGridToolbar = () => (
    <GridToolbarContainer>
      <Stack direction="row" spacing={1}>
        <GridToolbarFilterButton />
        <Button size="small" variant="contained" disabled={assignedAreaId == undefined} onClick={handleRemove}>
          Remove
        </Button>
      </Stack>
    </GridToolbarContainer>
  )

  const AllAreasGridToolbar = () => (
    <GridToolbarContainer>
      <Stack direction="row" spacing={1}>
        <GridToolbarFilterButton />
        <Button size="small" variant="contained" disabled={allAreaId == undefined} onClick={handleAdd}>
          Add
        </Button>
      </Stack>
    </GridToolbarContainer>
  )

  return (
    <>
      <Page title="Subscription Areas" back>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DataGrid
              onCellClick={params => handleAssignedRowSelect(params.row.id)}
              components={{
                Toolbar: MappedAreasGridToolbar,
              }}
              rows={areas}
              loading={isAreasLoading}
              columns={columns}
              disableColumnSelector={true}
              density="compact"
              sx={{
                height: 500,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <AreaMap areaId={mapAreaId} />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              isRowSelectable={params => !assignedAreaIds.has(params.row.id)}
              onCellClick={params => handleAllRowSelect(params.row.id)}
              components={{
                Toolbar: AllAreasGridToolbar,
              }}
              rows={allAreas}
              rowsPerPageOptions={[PAGE_SIZE]}
              page={page}
              loading={isAllAreasLoading}
              columns={allColumns}
              pagination
              onFilterModelChange={handleFilterModelChange}
              disableColumnSelector={true}
              density="compact"
              onPageChange={setPage}
              rowCount={Number.MAX_VALUE}
              sx={{
                '.MuiTablePagination-displayedRows': {
                  display: 'none',
                },
                height: 400,
              }}
              filterMode="server"
              paginationMode="server"
            />
          </Grid>
        </Grid>
      </Page>
    </>
  )
}

export default AlertAreas
