import { Grid, Box, Link, Typography, Stack } from '@mui/material'
import { ReactComponent as LinkekinIcon } from '../images/linkedin-icon.svg'
import { ReactComponent as TwitterIcon } from '../images/twitter-icon.svg'
import { ReactComponent as CompanyLogo } from '../images/logo-icon.svg'
import { useTheme } from '@mui/system'

function Footer() {
  const theme = useTheme()
  const textColor = theme.palette.text.primary
  const logoColor = theme.palette.text.primary
  const textVariant = 'subtitle2'

  return (
    <Box
      p={2}
      sx={{
        width: '100%',
        minHeight: '64px',
        height: 'auto',
        // marginTop: '32px',
        backgroundColor: theme.palette.background.paper,
      }}>
      <Grid container alignItems="center" spacing={2}>
        {/* Copyright & Socials */}
        <Grid container gap={2} item xs={12} sm={5} justifyContent="center">
          <Typography variant={textVariant} color={textColor}>
            Copyright© {new Date().getFullYear()} Balkerne Ltd
          </Typography>
          <Stack direction="row" gap={2}>
            <Typography variant={textVariant} gutterBottom>
              <Link href="https://www.linkedin.com/company/balkerne" target="_blank">
                <LinkekinIcon fill={textColor} />
              </Link>
            </Typography>
            <Typography variant={textVariant} gutterBottom>
              <Link href="https://twitter.com/balkerne_tech" target="_blank">
                <TwitterIcon fill={textColor} />
              </Link>
            </Typography>
          </Stack>
        </Grid>
        {/* Company Logo Icon */}
        <Grid container item xs={12} sm={2} justifyContent="center">
          <Box>
            <CompanyLogo height={20} fill={logoColor} />
          </Box>
        </Grid>
        {/* Links */}
        <Grid container item xs={12} sm={5} gap={2} justifyContent="center">
          <Link href="https://www.balkerne.com/contact" target="_blank">
            <Typography variant={textVariant} color={textColor} gutterBottom>
              Contact
            </Typography>
          </Link>
          <Link href="https://www.balkerne.com/termsofuse" target="_blank">
            <Typography variant={textVariant} color={textColor} gutterBottom>
              Terms of Use
            </Typography>
          </Link>
          <Link href="https://www.balkerne.com/privacypolicy" target="_blank">
            <Typography variant={textVariant} color={textColor} gutterBottom>
              Privacy Policy
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}
export default Footer
