import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Card, Typography, useTheme } from '@mui/material'
import ActivityCard from 'balkerne-components/dashboard/ActivityCard'
import DashboardMap from '../components/DashboardMap'
import SavedDrafts from 'balkerne-components/dashboard/SavedDrafts'
import AlertsCard from 'balkerne-components/dashboard/AlertsCard'
import { useDispatch } from 'react-redux'
import systemActions from '../store/system'
import { Button, Grid, Stack } from '@mui/material'
import siteMap from '../siteMap'
import Page from '../components/Page'
import { useLocation } from '../hooks/locations'
import EditIcon from '@mui/icons-material/Edit'
import { FeatureGate, PermissionGate } from 'balkerne-core/roles'
import PropertyClimateHazardsCard from 'balkerne-components/dashboard/PropertyClimateHazardsCard'

const Property = () => {
  const history = useHistory()
  const { id: propertyId } = useParams()
  const { data: property } = useLocation(propertyId)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(systemActions.setPropertyId(Number.parseInt(propertyId)))
  }, [propertyId])

  const goToEditProperty = () => {
    history.push(siteMap.EditProperty.getPath(propertyId))
  }

  // redirects to report an incident and sets state to viewing Alerts
  const handleReportIncidentButton = () => {
    history.push(
      siteMap.FormList.getPath({
        typeID: 2,
        title: 'Report Incident',
        propertyID: propertyId,
      }),
    )
  }

  const handleAddMitigation = () => {
    history.push(
      siteMap.FormList.getPath({
        typeID: 5,
        title: 'Add Mitigations',
        propertyID: propertyId,
      }),
    )
  }

  // redirects to complete survey and sets state to viewing Alerts
  const handleCompleteSurveyButton = () => {
    history.push(
      siteMap.FormList.getPath({
        typeID: 3,
        title: 'Complete Survey',
        propertyID: propertyId,
      }),
    )
  }

  const handleCompleteChecklist = () => {
    history.push(
      siteMap.FormList.getPath({
        typeID: 7,
        title: 'Complete Checklist',
        propertyID: propertyId,
      }),
    )
  }

  // redirects to complete survey and sets state to viewing Alerts
  const handleAddDocumentButton = () => {
    history.push(
      siteMap.FormList.getPath({
        typeID: 1,
        title: 'Add Document',
        propertyID: propertyId,
      }),
    )
  }

  return (
    <Page
      title={property?.name ?? null}
      PageActions={() => (
        <Button variant="outlined" onClick={goToEditProperty}>
          <EditIcon />
        </Button>
      )}>
      <Grid container spacing={2}>
        {/* Map */}
        <Grid item minWidth={300} xs={0} md lg={7} sx={{ display: { xs: 'none', md: 'block' } }}>
          <DashboardMap propertyId={propertyId} />
        </Grid>

        <Grid item md lg={5}>
          <Stack spacing={2}>
            {/* Climate Hazards */}
            <FeatureGate feature="projections">
              <PropertyClimateHazardsCard propertyId={propertyId} />
            </FeatureGate>
            {/* Alerts Card */}
            <FeatureGate feature="alerts">
              <AlertsCard propertyId={propertyId} />
            </FeatureGate>

            {/* Actions Card */}
            <Card>
              <Typography variant="subtitle1">Actions</Typography>
              {/* ❌ Temporarily disabled to avoid potentially storing sensitive data from clients ❌ */}
              <Stack direction="row" m={1} spacing={1}>
                <Button variant="outlined" size="small" onClick={handleReportIncidentButton}>
                  Report Incident
                </Button>
                {/* <Button
                  variant="outlined"
                  size="small"
                  className="button-drafts-1"
                  onClick={handleCompleteSurveyButton}>
                  Survey
                </Button> */}
                <Button variant="outlined" size="small" className="button-drafts-1" onClick={handleCompleteChecklist}>
                  Checklist
                </Button>
                <Button variant="outlined" size="small" className="button-drafts-1" onClick={handleAddMitigation}>
                  Add Mitigation
                </Button>
              </Stack>

              <hr style={{ marginBottom: 0 }}></hr>
              <SavedDrafts property_id={propertyId} />
            </Card>

            {/* Activity Card */}
            <ActivityCard property_id={propertyId} />
          </Stack>
        </Grid>
      </Grid>
    </Page>
  )
}

export default Property
