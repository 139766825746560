import { Stack, Typography } from '@mui/material'

export const CardTitle = (props: { title: string; subtitle: string }) => {
  return (
    <Stack pb={2}>
      <Typography fontWeight="700" fontSize="16" fontFamily="open sans">
        {props.title}
      </Typography>
      <Typography fontWeight="600" fontSize="11" fontFamily="open sans">
        {props.subtitle}
      </Typography>
    </Stack>
  )
}
