import React, { useEffect } from 'react'

const TemplateTags = ({ ...props }) => {
  useEffect(() => {
    if (props.templateTags && Object.values(props.templateTags).length > 0) {
      if (props.tagPaths !== null) {
        var index = 1
        if (props.tag_path in props.tagPaths) {
          Object.values(props.templateTags).map((tag: any) => {
            if (props.tagPaths[props.tag_path].id === tag.id) {
              var select: any = document.getElementById(props.tag_path + '-tagcontainer')
              if (select) {
                select = select.children[1]
                select.selectedIndex = index
              }
            }
            index += 1
          })
        }
      }
    }
  }, [props.templateTags, props.tagPaths, props.tag_path])
  const handleChange = e => {
    var object_h5 = document.getElementsByTagName('h5')
    var tempTagPaths = {}
    Array.from(object_h5).map((object: any) => {
      if (object.textContent == 'Object Name ') {
        var parentDiv = object.parentElement.parentElement.parentElement
        if (parentDiv.children[2].children[1] !== undefined) {
          if (parentDiv.children[2].children[1].name === 'Select Data Tag') {
            const tag_path =
              parentDiv.parentElement.parentElement.parentElement.parentElement.getAttribute('data-rfd-draggable-id')
            const tagSelect = parentDiv.children[2].children[1]
            if (Object.values(props.templateTags).length > 0) {
              Object.values(props.templateTags).map(tag => {
                if (tagSelect.selectedIndex != 0) {
                  tempTagPaths[tag_path] = props.templateTags[tagSelect.options[tagSelect.selectedIndex].value]
                }
              })
            }
          }
        }
      }
    })
    props.updateTagPaths({ ...tempTagPaths })
  }
  return (
    <>
      <h5>Select Data Tag For Field</h5>
      <select id="selectTag" name="Select Data Tag" onChange={e => handleChange(e)}>
        <option value={0}>Select A Tag</option>
        {props.templateTags &&
          Object.values(props.templateTags).map((template_tag: any) => (
            <option value={template_tag.id}>{template_tag.name}</option>
          ))}
      </select>
    </>
  )
}

export default TemplateTags
