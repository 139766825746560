import { configureStore } from '@reduxjs/toolkit'
import { systemReducer } from './system'
import { authReducer } from './auth'
import { customReducer } from './custom'

export const store = configureStore({
  reducer: {
    system: systemReducer,
    auth: authReducer,
    custom: customReducer,
  },
})

export default store
export type RootState = ReturnType<typeof store.getState>
