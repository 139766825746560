import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
  Card,
  useTheme,
  capitalize,
  Slider,
  InputLabel,
  FormControl,
  Grid,
  Chip,
  Divider,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useClimateAnalyticsWeather,
  useClimateAnalyticsWeatherBreakdown,
  useClimatePropertyData,
  usePropertyClimateAnalyticsExtremeWind,
  usePropertyClimateAnalyticsHeatStress,
} from '../../../hooks/analytics'
import ConfigProvider from 'balkerne-core/config'
import { Line, Chart } from 'react-chartjs-2'
import { useParams } from 'react-router-dom'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
} from 'chart.js'
import { DataGrid } from '@mui/x-data-grid'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { Layer, Map, MapRef, Source } from 'react-map-gl'
import { FeatureCollection, Point } from '@turf/turf'
import { HazardProps } from '../types'
import { CardTitle } from '../common/Card'
import LoadingIcon from '../../LoadingIcon'
import { useLocation, useLocations } from '../../../hooks/locations'
import mapboxgl from 'mapbox-gl'
import { GradientLegend } from '../common/Legend'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
)

const curveParams = {
  'Brick Veneer - Tile - N2': [21.73195434, -80.96879561, 132.70114855],
  'Double Brick - Tile - N2': [7.71250784, -3.17062794, 55.68061974],
  'Brick Veneer - Tile - N3': [6.32018049, -2.37943325, 61.46776434],
  'Double Brick - Tile - N3': [14.25689951, -44.13427811, 108.67795767],
  'Timber Frame - Metal C2': [7.62759349, -1.230649, 66.96595983],
  'Concrete Block Metal - C2': [14.65007072, -33.51824477, 111.61782879],
}

function WeibullCDF(x, c, loc, scale) {
  x = ((x - loc) / scale) ** c
  return 1 - Math.exp(-x)
}

const PropertyExtremeWind: React.FC<HazardProps> = ({ selectedScenario, setScenarios }) => {
  const { id: propertyId } = useParams()
  const { data } = useClimatePropertyData(propertyId, 'wind')
  const { data: property } = useLocation(propertyId)
  const [curve, setCurve] = useState('Brick Veneer - Tile - N2')
  const params = curveParams[curve]

  const availableScenarios = useMemo(() => {
    return Object.keys(data?.indicators ?? [])
  }, [data])

  // Set hazard's scenarios
  useEffect(() => {
    setScenarios(availableScenarios)
    return () => {
      setScenarios([])
    }
  }, [availableScenarios])

  const scenarioData = data?.indicators?.[selectedScenario]?.['wg'].data ?? null

  if (!data || !scenarioData) {
    return <Typography>No data available for this scenario</Typography>
  }

  const top7Day = scenarioData['q98'].percentiles['50th'][44]
  const top30Day = scenarioData['q915'].percentiles['50th'][44]

  const top7DayCategory = beaufortToCategory(beaufortScale(top7Day))
  const top30DayCategory = beaufortToCategory(beaufortScale(top30Day))

  const top7DayLabel = categoryToLabel(top7DayCategory)
  const top30DayLabel = categoryToLabel(top30DayCategory)

  return (
    <Stack direction="column" spacing={5}>
      {/* Overview */}
      <Stack direction="column">
        <Typography variant="overline">
          <strong>Overview</strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InfoCard
              lstring="Top 7 Day"
              rstring={`${top7DayLabel} (${(top7Day * 3.6).toFixed(1)} km/h)`}
              description={`Daily maximum wind gust speeds are expected to be ${top7DayLabel.toLowerCase()} in the top 7th day per year.`}
            />
          </Grid>
          <Grid item xs={6}>
            <InfoCard
              lstring="Top 30 Day"
              rstring={`${top30DayLabel} (${(top30Day * 3.6).toFixed(1)} km/h)`}
              description={`Daily maximum wind gust speeds are expected to be ${top30DayLabel.toLowerCase()} in the top 30th day per year.`}
            />
          </Grid>
        </Grid>
      </Stack>

      <Stack direction="column">
        <Typography variant="overline">
          <strong>Construction Type</strong>
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="curve-select-label">Select Curve</InputLabel>
          <Select
            labelId="curve-select-label"
            id="curve-select"
            value={curve}
            onChange={event => setCurve(event.target.value as string)}>
            {Object.keys(curveParams).map((curve, index) => (
              <MenuItem key={index} value={curve}>
                {curve}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      {/* Top 7 Day */}
      <Stack direction="column">
        <Typography variant="overline">
          <strong>Top 7 Days</strong>
        </Typography>
        <Grid container>
          {/* Chart */}
          <Grid item xs={12}>
            <Line
              height={400}
              data={{
                labels: scenarioData.years,
                datasets: [
                  {
                    label: 'Damage Ratio/Costs',
                    data: scenarioData.q98.percentiles['50th'].map((x, i) => {
                      return WeibullCDF(x, params[0], params[1], params[2]) * (property.insurance?.value ?? 1)
                    }),
                    fill: true,
                    backgroundColor: '#74416c22',
                    borderColor: '#74416c44',
                    tension: 0.5,
                    pointRadius: 3,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  y: {
                    min: 0.0,
                    suggestedMax: 0.2,
                    ticks: {
                      callback: function (value, index, values) {
                        const percent = 100 * (value as number)
                        return property.insurance?.value == null ? `${percent.toFixed(0)}%` : `£${value}`
                      },
                    },
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Line
              height={400}
              data={{
                labels: scenarioData.years,
                datasets: [
                  {
                    label: 'Annual 98pct Daily Maximum Wind Gust',
                    data: scenarioData.q98.percentiles['50th'],
                    fill: true,
                    backgroundColor: '#74416c22',
                    borderColor: '#74416c44',
                    tension: 0.5,
                    pointRadius: 3,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const value = context.dataset.data[context.dataIndex]
                        const kmh = (value as number) * 3.6
                        return `${kmh.toFixed(0)} km/h`
                      },
                    },
                  },
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  y: {
                    min: 0,
                    max: 35,
                    ticks: {
                      callback: function (value, index, values) {
                        const kmh = (value as number) * 3.6
                        return `${kmh} km/h`
                      },
                    },
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Stack>

      {/* Top 30 Day */}
      <Stack direction="column">
        <Typography variant="overline">
          <strong>Top 30 Days</strong>
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Line
              height={400}
              data={{
                labels: scenarioData.years,
                datasets: [
                  {
                    label: 'Annual 91.5pct Daily Maximum Wind Gust',
                    data: scenarioData.q915.percentiles['50th'],
                    fill: true,
                    backgroundColor: '#74416c22',
                    borderColor: '#74416c44',
                    tension: 0.5,
                    pointRadius: 3,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        const value = context.dataset.data[context.dataIndex]
                        const kmh = (value as number) * 3.6
                        return `${kmh.toFixed(0)} km/h`
                      },
                    },
                  },
                },
                maintainAspectRatio: false,
                // responsive: true,
                scales: {
                  y: {
                    min: 0,
                    max: 35,
                    ticks: {
                      callback: function (value, index, values) {
                        const kmh = (value as number) * 3.6
                        return `${kmh} km/h`
                      },
                    },
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

type InfoCardProps = {
  lstring: string
  rstring: string
  description: string
}

function InfoCard({ lstring, rstring, description }: InfoCardProps) {
  return (
    <Card variant="outlined">
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography gutterBottom variant="h5" component="div">
            {lstring}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {rstring}
          </Typography>
        </Stack>
        <Typography color="text.secondary" variant="body2">
          {description}
        </Typography>
      </Box>
      {/* <Divider />
      <Box sx={{ px: 2, pt: 2 }}>
        <Stack direction="row" spacing={1}>
          {chips.map((chip, index) => (
            <Chip key={index} label={chip} size="small" />
          ))}
        </Stack>
      </Box> */}
    </Card>
  )
}

function beaufortScale(windSpeed: number): number {
  /**
   * Convert wind speed to Beaufort scale
   *
   * @param windSpeed - Wind speed in m/s
   * @return Beaufort scale [0-12]
   */
  if (windSpeed < 0.3) {
    return 0
  } else if (windSpeed < 1.6) {
    return 1
  } else if (windSpeed < 3.4) {
    return 2
  } else if (windSpeed < 5.5) {
    return 3
  } else if (windSpeed < 8.0) {
    return 4
  } else if (windSpeed < 10.8) {
    return 5
  } else if (windSpeed < 13.9) {
    return 6
  } else if (windSpeed < 17.2) {
    return 7
  } else if (windSpeed < 20.8) {
    return 8
  } else if (windSpeed < 24.5) {
    return 9
  } else if (windSpeed < 28.5) {
    return 10
  } else if (windSpeed < 32.7) {
    return 11
  } else {
    return 12
  }
}

function beaufortToCategory(beaufort: number): number {
  /**
   * Convert Beaufort scale to risk category
   *
   * Risk categories by Beaufort scale:
   * -1: 0
   * 1: 1-3
   * 2: 4-5
   * 3: 6-7
   * 4: 8-9
   * 5: 10-12
   *
   * @param beaufort - Beaufort scale [0-12]
   * @return Risk category [1-5]
   */
  if (beaufort === 0) {
    return -1
  } else if (beaufort < 4) {
    return 1
  } else if (beaufort < 6) {
    return 2
  } else if (beaufort < 8) {
    return 3
  } else if (beaufort < 10) {
    return 4
  } else if (beaufort < 13) {
    return 5
  } else {
    throw new Error('Beaufort scale is not defined for values greater than 12')
  }
}

function categoryToLabel(category: number): string {
  /**
   * Convert category integer to label
   *
   * @param category - int
   * @return Category label
   */
  if (category === -1) {
    return 'Inapplicable'
  } else if (category === 1) {
    return 'Negligible'
  } else if (category === 2) {
    return 'Low'
  } else if (category === 3) {
    return 'Moderate'
  } else if (category === 4) {
    return 'High'
  } else if (category === 5) {
    return 'Extreme'
  } else {
    throw new Error('Category not defined')
  }
}
export default PropertyExtremeWind
