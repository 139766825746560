import { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import api from 'balkerne-core/api'
import {
  Stack,
  Button,
  TextField,
  Typography,
  Grid,
  Card,
} from '@mui/material'
import Page from '../components/Page'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import DrawMap from '../components/userManagement/DrawMap'
import { useSnackbar } from 'notistack'
import { useLocation } from '../hooks/locations'
import { useQueryClient } from 'react-query'

function prepareGeometry(geometry): any {
  // Convert MultiPolygon to an array of Polygons
  const polygons = geometry.coordinates.map(polygon => {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: polygon,
      },
    }
  })

  return {
    type: 'FeatureCollection',
    features: polygons,
  }
}

const EditProperty = () => {
  const { id: propertyId } = useParams()
  const getLocationParams = {
    geojson: true,
  }
  const { data: property, isLoading } = useLocation(propertyId, getLocationParams)
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  let defaultProperty = {
    name: property ? property.name : '',
    reference: property ? property.property_customer_reference : '',
    geometry: property ? prepareGeometry(property.geometry) : null,
  }

  const schema = yup.object({
    name: yup.string().required('Name is required'),
    reference: yup.string().required('Reference number is required'),
    geometry: yup.object().optional().nullable(),
  })

  const {
    register,
    setValue,
    handleSubmit: formSubmit,
    getValues,
    formState: { isValid, isSubmitting, errors },
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: { ...defaultProperty },
    resolver: yupResolver(schema),
  })
  useEffect(() => {
    if (property) {
      reset({ ...defaultProperty })
    }
  }, [property])

  const submitForm = async data => {
    const _data = {}
    Object.keys(data).forEach(key => {
      if (key === 'geometry') {
        if (JSON.stringify(data[key]) !== JSON.stringify(defaultProperty[key])) {
          _data[key] = data[key]
        }
      } else if (data[key] !== defaultProperty[key]) {
        _data[key] = data[key]
      }
    })
    await api
      .patch(`/locations/${propertyId}`, data)
      .then(res => {
        console.log('success', res)
        enqueueSnackbar('Property updated', { variant: 'success' })
        queryClient.invalidateQueries({ queryKey: [`/locations/${propertyId}`, getLocationParams] })
      })
      .catch(err => {
        console.error('error', err)
        enqueueSnackbar('Error updating property', { variant: 'error' })
      })
  }
  //DrawMap submit and cancel
  const onMapSubmit = featureCollection => {
    if (featureCollection) {
      setValue('geometry', featureCollection)
    }
  }

  const onMapCancel = () => {}

  return (
    <Page
      title="Edit Property"
      size="lg"
      PageActions={() => (
        <Stack gap={1} direction="row">
          <Button onClick={formSubmit(submitForm)} variant="outlined" disabled={!isValid || isSubmitting}>
            Save
          </Button>
        </Stack>
      )}>
      {isLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Card elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography>Name</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>Reference</Typography>
            </Grid>
          </Grid>
          <Grid container key={property?.id} spacing={2} py={1}>
            <Grid item xs={5}>
              <TextField {...register('name')} name="name" fullWidth />
            </Grid>
            <Grid item xs={5}>
              <TextField {...register('reference')} name="reference" fullWidth />
            </Grid>
          </Grid>
          <DrawMap title="Draw Property Boundary" onSubmit={onMapSubmit} onCancel={onMapCancel} onError={error => enqueueSnackbar(error, { variant: 'error' })} initGeometry={prepareGeometry(property.geometry)} />
        </Card>
      )}
    </Page>
  )
}

export default EditProperty
