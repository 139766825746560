import { useEffect, useState } from 'react'
import axios from 'axios'

export const useCountryList = () => {
  const [countries, setCountries] = useState<string[]>([])

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all')
        const countryNames = response.data.map((country: { name: { common: string } }) => country.name.common)
        const sortedCountries = countryNames.sort((a: string, b: string) => a.localeCompare(b))
        setCountries(sortedCountries)
      } catch (error) {
        console.error('Error fetching list of countries:', error)
        setCountries([])
      }
    }

    fetchCountries()
  }, [])

  return countries
}
