import { useState } from 'react'
import { signIn } from 'balkerne-core/auth'
import authActions from '../store/auth'
import customActions from '../store/custom'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import siteMap from '../siteMap'
import { ReactComponent as CompanyLogo } from '../images/logo-full.svg'
import BackgroundImage from '../images/login-background.jpg'
import { Box, Button, Paper, Stack, TextField, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import webLogo from '../images/logo_web.png'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme()

  const forgotPassword = () => {
    history.push({
      pathname: '/ResetPassword',
    })
  }

  const handleSignIn = async e => {
    e.preventDefault()
    setIsLoading(true)

    if (email?.length > 0 && !email.toLowerCase().endsWith('arcusfm.com')) {
      await signIn(email, password)
        .then(({ token, user }) => {
          dispatch(authActions.login({ token, user }))
          dispatch(customActions.setup(user.organisation.id))
          setIsLoading(false)
          history.push(siteMap.Portfolio.path)
        })
        .catch(err => {
          console.error(err)
          setIsLoading(false)
          setError(err.message)
          setSuccess(false)
        })
    }
  }

  const showNotice = () => {
    if (!success) {
      return (
        <div className="alert alert-danger" role="alert">
          {error ?? '🔴 Error'}
        </div>
      )
    }
  }

  const validateForm = () => {
    return email?.length > 0 && password?.length > 0
  }

  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
      }}>
      <Box
        component={'img'}
        src={BackgroundImage}
        sx={{
          '@keyframes slow-pan': {
            '0%': {
              transform: 'translate(-5%, -5%) scale(1) rotate(-5deg)',
            },
            '100%': {
              transform: 'translate(-15%, -15%) scale(1.15) rotate(15deg)',
            },
          },
          animation: 'slow-pan 30s cubic-bezier(.21,0,.7,1) infinite',
          animationDelay: '-5s',
          animationDirection: 'alternate',
          position: 'absolute',
          top: 0,
          left: 0,
          minWidth: '120vw',
          minHeight: '120vh',
          zIndex: 0,
        }}
      />

      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '100vh',
          width: '100vw',
          backgroundColor: '#000000' + 'AD',
          p: 2,
          zIndex: 1,
          position: 'relative',
        }}>
        <Paper sx={{ width: '100%', maxWidth: 400, p: 4 }}>
          <Stack alignItems="stretch" spacing={2}>
            <Stack alignItems="center" mb={2}>
              <img src={webLogo} width={280} height="auto" />
            </Stack>
            <TextField
              autoFocus
              type="email"
              id="email"
              name="login"
              placeholder="email"
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              type="password"
              id="password"
              name="password"
              placeholder="password"
              onChange={e => setPassword(e.target.value)}
            />
            <Box />
            <LoadingButton
              loading={isLoading}
              variant="contained"
              size="large"
              fullWidth
              onClick={handleSignIn}
              disabled={!validateForm()}>
              Login
            </LoadingButton>
            <Button variant="text" fullWidth onClick={forgotPassword}>
              Forgot Password
            </Button>
            {!isLoading && showNotice()}
          </Stack>
        </Paper>
      </Stack>
    </Box>
  )
}

export default Login
