import { useEffect, useMemo, useRef, useState } from 'react'
import { useClimatePortfolioData } from '../../../../hooks/analytics'
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Layer, Map, MapRef, Source } from 'react-map-gl'
import ConfigProvider from 'balkerne-core/config'
import { Line, Chart } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
} from 'chart.js'
import { FeatureCollection, Point } from '@turf/turf'
import { CategoryLegend, GradientLegend } from '../../common/Legend'
import { useLocations } from '../../../../hooks/locations'
import LoadingIcon from '../../../LoadingIcon'
import scoreColor from './utils'

const mapboxCircleColor: mapboxgl.Expression = [
  'match',
  ['get', 'value'],
  ...Object.entries(scoreColor).flatMap(([key, color]) => [parseInt(key), color]),
  '#000000',
]

const PortfolioExtremeWindMap = ({ scenario }) => {
  const { data, isLoading } = useClimatePortfolioData('wind')
  const { data: locations, isLoading: isLocationsLoading } = useLocations()
  const [mapYear, setMapYear] = useState<number>(0)

  const mapRef = useRef<MapRef | null>(null)

  const locationById = useMemo(() => {
    return locations.reduce((acc, location) => {
      acc[location.id] = location
      return acc
    }, {})
  }, [locations])

  const scenarioData = useMemo(() => {
    return data?.['data'][scenario] ?? null
  }, [data, scenario])

  const featureCollection: FeatureCollection<Point, any> | undefined = useMemo(() => {
    if (scenarioData !== null) {
      return {
        type: 'FeatureCollection',
        features: Object.entries(scenarioData?.['wg'] ?? {}).map(([propertyId, indicators]) => {
          return {
            type: 'Feature',
            properties: {
              id: propertyId,
              value: (indicators as any)['wg'][mapYear],
            },
            geometry: {
              type: 'Point',
              coordinates: [
                locationById[propertyId].coordinates.longitude,
                locationById[propertyId].coordinates.latitude,
              ],
            },
          }
        }),
      }
    } else {
      return undefined
    }
  }, [scenarioData, locationById, mapYear])

  if (isLoading || isLocationsLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
        <LoadingIcon />
      </Box>
    )
  }

  const years = data?.['indicators']?.['wg']['years'] ?? []

  return (
    <Map
      ref={mapRef}
      reuseMaps
      initialViewState={{
        longitude: -2.3,
        latitude: 51.8,
        zoom: 6,
        pitch: 0,
        bearing: 0,
      }}
      style={{
        flexGrow: 1,
        zIndex: 0,
        borderRadius: 5,
      }}
      mapStyle={`mapbox://styles/balkerne/clmqen49o00bm01ns7ur41hzj`}
      mapboxAccessToken={ConfigProvider.mapboxPublicKey}>
      <Source id="climate-analytics-wind-gust" type="geojson" data={featureCollection}>
        <Layer
          id="climate-analytics-wind-gust"
          type="circle"
          paint={{
            'circle-radius': 3,
            'circle-stroke-width': 1,
            'circle-color': mapboxCircleColor,
            'circle-stroke-color': '#706f6f',
          }}
        />
      </Source>

      {/* Legend */}
      <Stack
        sx={{
          position: 'absolute',
          bottom: 25,
          left: 5,
          borderRadius: 1,
          width: 'fit-content',
          height: '200px',
          p: 1,
          background: 'white',
        }}>
        <CategoryLegend categories={Object.keys(scoreColor).reverse()} colors={scoreColor} width={20} />
      </Stack>
      {/* Time Slider */}
      <Stack
        sx={{
          position: 'absolute',
          bottom: 5,
          left: '50%',
          transform: 'translateX(-50%)',
          borderRadius: 1,
          width: '400px',
          py: 1,
          px: 4,
          background: 'white',
        }}>
        <Slider
          min={0}
          max={years.length - 1}
          step={1}
          track={false}
          value={mapYear}
          onChange={(_, value) => setMapYear(value as number)}
          sx={{
            color: 'grey.700',
          }}
          marks={[
            {
              value: 0,
              label: years[0],
            },
            {
              value: Math.floor(years.length / 2),
              label: years[Math.floor(years.length / 2)],
            },
            {
              value: years.length - 1,
              label: years[years.length - 1],
            },
          ]}
        />
      </Stack>
    </Map>
  )
}

export default PortfolioExtremeWindMap
