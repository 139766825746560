import React from 'react'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import c from '../../pages/GetStarted.module.scss'
import './LastPageGetStarted.css'

export const LastPageGetStarted = props => {
  if (props.currentStep !== 3) {
    return null
  }

  if (props.databaseSuccess !== true) {
    return (
      <div className={c.page}>
        <Container>
          <Card>
            <Card.Header>Error</Card.Header>
            <Card.Body>
              <div>
                <p>There was a problem with the application process, please try again.</p>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    )
  }

  return (
    <div className={c.page}>
      <Container>
        <Card>
          <Card.Header>Success</Card.Header>
          <Card.Body>
            <div className="submissionProperty">
              <span id="submittedBulkProperty" className="glyphicon glyphicon-ok-sign" />

              <p>Thank you for your application, please check your email for your login details.</p>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  )
}

export default LastPageGetStarted
