import { useMemo } from 'react'
import { Box, Divider, Grid, Stack, Typography, Card, Skeleton } from '@mui/material'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { MapRounded } from '@mui/icons-material'
import CardDataGrid from '../components/CardDataGrid'
import { useAlertingSubscriptions } from '../hooks/alerts'
import { useLocations } from '../hooks/locations'
import siteMap from '../siteMap'
import { useHistory } from 'react-router-dom'
import Page from '../components/Page'

const AlertSubscriptions = () => {
  const { data: subscriptions, isLoading: isSubscriptionsLoading } = useAlertingSubscriptions()
  const { data: locations, isLoading: isLocationsLoading } = useLocations()
  const history = useHistory()

  const subscriptionColumn = useMemo(
    () => [
      { field: 'name', headerName: 'Name', flex: 1, valueGetter: params => params.row.property.name },
      { field: 'area_count', headerName: 'Areas' },
      { field: 'configuration_count', headerName: 'Configurations', minWidth: 150 },
      {
        field: 'is_active',
        headerName: 'Active',
        type: 'boolean',
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: params => [
          <GridActionsCellItem
            icon={<MapRounded />}
            onClick={() => history.push(siteMap.AlertAreas.getPath(params.id))}
            label="Areas"
          />,
          <GridActionsCellItem
            label={params.row.is_active ? 'Deactivate' : 'Activate'}
            // 🚧 TODO: Implement subsciption activation/deactivation at later point
            onClick={() => console.log(params)}
            showInMenu
          />,
        ],
      },
    ],
    [],
  )

  const numOfInactive = useMemo(() => {
    return subscriptions.filter(sub => !sub.is_active).length
  }, [subscriptions])

  return (
    <Page title="Alerting Subscriptions" size="lg" back>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card>
            <Box>
              <Typography variant="subtitle2">All Properties</Typography>
              {!isLocationsLoading ? (
                <Typography variant="h4">{locations.length}</Typography>
              ) : (
                <Skeleton variant="text" width={100} height={42} />
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <Box>
              <Typography variant="subtitle2">Alerting Subscriptions</Typography>
              {!isSubscriptionsLoading ? (
                <Typography variant="h4">{subscriptions.length}</Typography>
              ) : (
                <Skeleton variant="text" width={100} height={42} />
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <Box>
              <Typography variant="subtitle2">Inactive Subscriptions</Typography>
              {!isSubscriptionsLoading ? (
                <Typography variant="h4">{numOfInactive}</Typography>
              ) : (
                <Skeleton variant="text" width={100} height={42} />
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CardDataGrid
            disableSelectionOnClick
            sx={{
              height: 500,
              '.MuiTablePagination-displayedRows': {
                display: 'none',
              },
            }}
            columns={subscriptionColumn}
            loading={isSubscriptionsLoading}
            rows={subscriptions}
            density="compact"
            autoPageSize
          />
        </Grid>
      </Grid>
    </Page>
  )
}

export default AlertSubscriptions
