import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { RootState } from '../../store'

export const PrivateRoute = props => {
  const authenticated = useSelector((state: RootState) => state.auth.authenticated)

  if (!authenticated) {
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  }

  return <Route {...props} />
}

export default PrivateRoute
