import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Search from 'balkerne-components/Search'
import Card from 'react-bootstrap/Card'
import { useHistory } from 'react-router-dom'
import api from 'balkerne-core/api'
import Back from '../../../images/icons/Back.svg'
import { useQueryClient } from 'react-query'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import siteMap from '../../../siteMap'

const FormsList = props => {
  const [subCategoryTemplates, setSubCategoryTemplates] = useState(null)
  var history = useHistory()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (props.categoryForms !== null) {
      var tempPaths = { ...props.paths }
      tempPaths[Object.keys(props.paths).length + 1] = {
        title: props.categoryForms['title'],
        onClick: () => {
          setSubCategoryTemplates(null)
          let subcategoryTemplates: any = document.getElementById('subcategoryTemplates')
          let categoryTemplates: any = document.getElementById('categoryTemplates')
          if (subcategoryTemplates) subcategoryTemplates.style.display = 'none'
          
          if (categoryTemplates) categoryTemplates.style.display = 'table'
        },
      }
      props.setPaths({ ...tempPaths })
    }
  }, [props.categoryForms])

  const loadForm = async template => {
    // start a new form instance and redirect to dynamic form
    const versionId = parseInt(template.versions[0].id)
    const data = props.propertyID
    await api.post(`/forms/templates/versions/${versionId}/start_form`, data).then(response => {
      queryClient.invalidateQueries(`/forms/instances`)
      console.log(response)
      history.push(
        siteMap.CompleteForm.getPath({
          propertyID: props.propertyID,
          formInstanceId: response.data.data,
          previousPathTitle: props.previousPathTitle,
          previousPathName: '/FormSelector',
          previousPathState: {
            typeID: props.typeID,
            title: props.previousPathTitle,
            propertyID: props.propertyID,
          },
        }),
      )
    })
  }

  const showTemplates = () => {
    if (props.categoryForms['templates'].length > 0) {
      return Object.values(props.categoryForms['templates']).map((template: any) => (
        <tr
          style={{ cursor: 'pointer' }}
          key={template['id'] + '-template'}
          id={template['id'] + '-template'}
          // searchdescription={template['title']}
          onClick={() => loadForm(template)}>
          <td style={{ display: 'flex' }}>
            <div style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 16, paddingLeft: 0, width: 'auto' }}>
              <Tooltip title={template['description']}>
                <InfoOutlinedIcon color="disabled" />
              </Tooltip>
            </div>
            <div style={{ marginTop: 'auto', marginBottom: 'auto', paddingRight: 16 }}>{template['title']}</div>
          </td>
          <td></td>
        </tr>
      ))
    }
  }

  const showSubcategoryTemplates = () => {
    if (subCategoryTemplates !== null) {
      return Object.values(subCategoryTemplates).map((template: any) => (
        <tr
          style={{ cursor: 'pointer' }}
          key={template['id'] + '-template'}
          id={template['id'] + '-template'}
          // searchdescription={template['title']}
          onClick={() => loadForm(template)}>
          <td style={{ paddingTop: 16, paddingBottom: 16, paddingRight: 0, paddingLeft: 16, width: '3%' }}>
            <Tooltip title={template['description']}>
              <InfoOutlinedIcon color="disabled" />
            </Tooltip>
          </td>
          <td style={{ width: '97%' }}>{template['title']}</td>
        </tr>
      ))
    }
  }

  const backButton = () => {
    const current_path_key = Object.keys(props.paths).length
    var tempPaths = { ...props.paths }

    delete tempPaths[current_path_key]
    props.setPaths({ ...tempPaths })

    if (subCategoryTemplates !== null) {
      setSubCategoryTemplates(null)
      let subcategoryTemplates: any = document.getElementById('subcategoryTemplates')
      if (subcategoryTemplates) subcategoryTemplates.style.display = 'none'
      let categoryTemplates: any = document.getElementById('categoryTemplates')
      if (categoryTemplates) categoryTemplates.style.display = 'table'
    } else {
      props.setCategoryForms(null)
      props.setCategoryDisplay('block')
    }
  }
  if (props.categoryForms !== null) {
    return (
      <Card.Body>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a style={{ cursor: 'pointer' }} onClick={backButton}>
            <img src={Back}></img>
          </a>
          <Search
            style={{ width: '80%' }}
            placeholder="Search"
            onChange={
              subCategoryTemplates == null
                ? e => props.handleSearch(e, 'subcategories')
                : e => props.handleSearch(e, 'forms')
            }
            transparent
          />
        </div>
        {props.filteredTemplates !== null ? (
          <Table>
            <tbody id="searchRows">
              {Object.values(props.filteredTemplates).map((filtered_template: any) => (
                <tr
                  style={{ cursor: 'pointer' }}
                  key={filtered_template.id}
                  id={filtered_template.id}
                  onClick={() => loadForm(filtered_template.id)}>
                  <td style={{ width: '70%' }}>{filtered_template.searchString}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <></>
        )}
        <Table id="categoryTemplates">
          <tbody id="subcategoryRows">{showTemplates()}</tbody>
        </Table>
        <Table id="subcategoryTemplates" style={{ display: 'none' }}>
          <tbody id="formRows">{showSubcategoryTemplates()}</tbody>
        </Table>
      </Card.Body>
    )
  } else {
    return <></>
  }
}

export default FormsList
